import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { Autocomplete } from '@app/root-store/suggestions';
import { Template } from '@app/types/template';
import { RolePickerUtils } from '@app/utils/custom-controls/people-role-picker/roles';
import { CustomValidators } from '@app/utils/validators';
import { Store, select } from '@ngrx/store';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { Observable, debounceTime } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';

export interface RenameTemplateDialogData {
  template: Template
}
@Component({
  selector: 'atr-rename-template-dialog',
  templateUrl: './rename-template-dialog.component.html',
  styleUrls: ['./rename-template-dialog.component.scss']
})
export class RenameTemplateDialogComponent {

  public form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.maxLength(100)])
  });

  constructor(@Inject(RDS_DIALOG_DATA) public data: RenameTemplateDialogData, private auth: AuthService, private store$: Store<any>) {
    if (!!data) {
      this.form.controls.title.patchValue(data.template.title)
    }
   }
}
