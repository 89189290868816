  <div class="row">
    <div class="col lmy-3">
      <rds-breadcrumbs [items]="breadcrumbs" (itemClick)="breadcrumbItemClicked($event)"></rds-breadcrumbs>
      
    </div>
  </div>
  <rnb-view-bar 
    [breadcrumbRoutes]="[{ label: 'Channels', route: 'channels/my'}]"
  >
    <span title>Channels</span>
    <button
      rds-secondary-button
      size="l"
      routerLink="/channels/create"
    >
      Create new channel
    </button>
  </rnb-view-bar>
  <div class="row">
    <div class="col bg-white px-10 py-7 lmb-8">
      <rds-tabs #tabs (click)="tabsClicked(tabs)">
        <rds-tab label="My Channels"></rds-tab>
        <rds-tab label="All Channels"></rds-tab>
    </rds-tabs>
    <router-outlet></router-outlet>
    </div>
  </div>
