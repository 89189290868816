<rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
<rnb-table
  #table
  [data]="channels$ | async"
  [defaultSortActive]="(defaultSort$ | async).active"
  [defaultSortDirection]="(defaultSort$ | async).direction"
  [columns]="columns"
  [hasBulk]="isAdmin || isSuperAdmin"
  [hasActions]="isAdmin || isSuperAdmin"
  [pagination]="pagination$ | async"
  [totalObs]="total$"
  [isLoading]="isLoading$ | async"
  [highlight]="search$ | async"
  (sortChanged)="sortChanged($event)"
  (paginationChanged)="paginationChanged($event)"
  (selectionChanged)="selectionChanged($event)"
  (linkClicked)="onLinkClick($event)"
  [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
  >
  <span bulkText>
    {{table.selectedCount}} Channel{{table.selectedCount > 1? 's ':' '}} selected 
  </span>
  <ng-container bulkActions>
    <button rds-warning-button (click)="deleteChannels(table.selection)">Delete</button>
  </ng-container>

  <ng-template #actions let-channel>
    <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
    #trigger="rdsMenuTrigger">
      <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
    </button>

    <rds-menu #menu="rdsMenu">
      <button rds-menu-item [routerLink]="'/channel/'+channel.id+'/edit'" [state]="{redirect: 'channels'}">
        <span>Edit</span>
      </button>
      <button rds-menu-item (click)="deleteChannel(channel.id, channel.name)">
        <span>Delete</span>
      </button>
    </rds-menu>
  </ng-template>

</rnb-table>
