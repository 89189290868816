<ng-container [formGroup]="form">
  <ng-container *ngIf="form.get('assignement') as assignementForm" [formGroup]="assignementForm">
    <div class="row">
      <div class="col mt-9">
        <h4 class="ui-heading-4">Assign to channels
          <span [rdsTooltip]="assignTooltipTemplate">
            <rds-icon class="info-icon" icon="info" size="s" namespace="outlined"></rds-icon>
          </span>
          <ng-template #assignTooltipTemplate>
              <span style="text-align: left" [innerHTML]="'Please note that once you assign the news to selected channels, they cannot be un-assigned.<br/>After clicking saving the news, you will be able to assign more channels but will not be able to remove any assigned channel.'"></span>
          </ng-template>
        </h4>
        <p class="ui-label-m ">News will be automatically published in all assigned channels on the specified publish date. </p>
        <div class="row">
          <div class="col mt-5">
            <rnb-channel-picker 
            class="mb-7"
            [multi]="true"
            formControlName="assign"
            [options]="assignableChannels$ | async"
          >
            <rds-control-error *ngIf="assignementForm.get('assign').hasError('atLeastOneRequired') && assignementForm.get('assign').touched">At leas one "Assign to" OR "Suggest to" channel is required</rds-control-error>
          </rnb-channel-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-9">
        <h4 class="ui-heading-4">Suggest to channels
          <span [rdsTooltip]="suggestTooltipTemplate">
            <rds-icon class="info-icon" icon="info" size="s" namespace="outlined"></rds-icon>
          </span>
          <ng-template #suggestTooltipTemplate>
              <span style="text-align: left" [innerHTML]="'Only the person who made the original suggestion can un-suggest for as long as the suggestion request status is \'pending\'.<br/>This means other editors and owners of this news will not be able to modify your suggestions.'"></span>
          </ng-template>
        </h4>
        <p class="ui-label-m ">Selected channels will receive a news suggestion request. Your news will need to get approved by selected channels in order to be published. You’ll receive an email notification about accepted/ declined suggestion. </p>
        <div class="row">
          <div class="col mt-5">
            <rnb-channel-picker 
            class="mb-7"
            [multi]="true"
            formControlName="suggest"
            [options]="suggestableChannels$ | async"
            >
              <rds-control-error *ngIf="assignementForm.get('assign').hasError('atLeastOneRequired') && assignementForm.get('assign').touched">At leas one "Assign to" OR "Suggest to" channel is required</rds-control-error>
            </rnb-channel-picker>
          </div>
        </div>
        <rds-divider class="mt-5"></rds-divider>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Publishing & expiry dates</h4>
      <div class="row">
        <div class="col-4 mt-5">
          <rds-form-field
          ><rds-control-label>Select Time Zone</rds-control-label>
          <rds-select
            #selectTimeZone
            required
            formControlName="timeZone"
            (valueChange)="selectTimeZone.resetFilterValue()"
            [filterBy]="filterBy"
          >
            <rds-select-search-input #searchInput></rds-select-search-input>
            <rds-select-option-separator></rds-select-option-separator>
            <rds-select-option *ngFor="let timezone of timezones$ | async" [value]="timezone.id" [id]="timezone.name" [innerHTML]="timezone.name | highlight:searchInput.control.value"></rds-select-option>
          </rds-select>
        </rds-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 mt-5">
          <rds-form-field>
            <rds-control-label>Select publishing date</rds-control-label>
            <input #publishPickerInput rdsInput required formControlName="publishDate" (focus)="publishPicker.open()" [rdsDatepicker]="publishPicker" />
            <rds-datepicker-toggle
              rds-control-suffix
              [forDatepicker]="publishPicker"
            ></rds-datepicker-toggle>
            <rds-datepicker #publishPicker (closedStream)="publishPickerInput.blur()"></rds-datepicker>
            <rds-control-error *ngIf="form.get('publishDate').errors?.expBeforePub || form.get('publishDate').errors?.publishDateFromPast">
              <span *ngIf="form.get('publishDate').errors?.expBeforePub">
                Publish date can't be after expiry date<br/>
              </span>
              <span *ngIf="form.get('publishDate').errors?.publishDateFromPast">
                Publish date can't be in the past<br/>
              </span>
            </rds-control-error>
            <rds-control-error *ngIf="form.get('publishDate').errors?.required">
              This field is required<br/>
            </rds-control-error>
          </rds-form-field>
        </div>
        <div class="col-4 mt-5">
          <rds-form-field>
            <rds-control-label>Set publishing time</rds-control-label>
            <input #publishTime rdsInput required formControlName="publishTime" type="time" step="900"/>
            <button
              rds-icon-button
              rds-control-suffix
              (click)="publishTime.showPicker()"
              size="m">
              <rds-icon
                namespace="outlined"
                icon="clock"
              ></rds-icon>
            </button>
            <rds-control-error *ngIf="form.get('publishTime').errors?.required">
              This field is required<br/>
            </rds-control-error>
          </rds-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 mt-5">
          <rds-form-field>
            <rds-control-label>Select expiry date</rds-control-label>
            <input #expiryPickerInput rdsInput required formControlName="expiryDate" (focus)="expiryPicker.open()" [rdsDatepicker]="expiryPicker" />
            <rds-datepicker-toggle
              rds-control-suffix
              [forDatepicker]="expiryPicker"
            ></rds-datepicker-toggle>
            <rds-datepicker #expiryPicker (closedStream)="expiryPickerInput.blur()"></rds-datepicker>
            <rds-control-error *ngIf="form.get('expiryDate').errors?.expBeforePub || form.get('expiryDate').errors?.expiryDateFromPast">
              <span *ngIf="form.get('expiryDate').errors?.expBeforePub">
                Expiry date can't be before publish date<br/>
              </span>
              <span *ngIf="form.get('expiryDate').errors?.expiryDateFromPast">
                Expiry date can't be in the past<br/>
              </span>
            </rds-control-error>
            <rds-control-error *ngIf="form.get('expiryDate').errors?.required">
              This field is required<br/>
            </rds-control-error>
          </rds-form-field>
        </div>
        <div class="col-4 mt-5">
          <rds-form-field>
            <rds-control-label>Set expiry time</rds-control-label>
            <input #expiryTime rdsInput required formControlName="expiryTime" type="time" step="900"/>
            <button
              rds-icon-button
              rds-control-suffix
              (click)="expiryTime.showPicker()"
              size="m">
              <rds-icon
                namespace="outlined"
                icon="clock"
              ></rds-icon>
            </button>
            <rds-control-error *ngIf="form.get('expiryTime').errors?.required">
              This field is required<br/>
            </rds-control-error>
          </rds-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>
