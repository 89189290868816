// import { createAction, props } from '@ngrx/store';

import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';
import { Pagination } from '@app/types/pagination';
import { News, ChannelDetails, UserLogin } from '@app/types';
import { AdminTableFilters } from '@app/utils/table/table-filters/filters-model';

export const initAdmins = createAction(
  '[ADMINISTRATORS] Initialize Administrators'
);

export const clearAll = createAction(
  '[ADMINISTRATORS] Clear all'
);

export const getAdminsRequest = createAction(
  '[ADMINISTRATORS] Get all admins Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: AdminTableFilters
  }>()
);

export const getAdminsSuccess = createAction(
  '[ADMINISTRATORS] Get all admins Success',
  props<{ data: Array<Partial<UserLogin>>, pagination: Pagination }>()
);

export const getAdminsFailure = createAction(
  '[ADMINISTRATORS] Get all admins Failure',
  props<{ error: any }>()
);

export const deleteAdminRequest = createAction(
  '[ADMINISTRATORS] Delete all admins Request',
  props<{identifier: string}>()
);

export const deleteAdminSuccess = createAction(
  '[ADMINISTRATORS] Delete all admins Success',
);

export const deleteAdminFailure = createAction(
  '[ADMINISTRATORS] Delete all admins Failure',
  props<{ error: any }>()
);

export const changeAdminsFilters = createAction(
  '[ADMINISTRATORS] Change all admins filters',
  props<{ filters: AdminTableFilters }>()
);

export const changeAdminsSort = createAction(
  '[ADMINISTRATORS] Change all admins sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeAdminsPagination = createAction(
  '[ADMINISTRATORS] Change all admins pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const addAdminRequest = createAction(
  '[API KEYS] Add admin Request',
  props<{identifier: string}>()
);

export const addAdminSuccess = createAction(
  '[API KEYS] Add admin Success',
);

export const addAdminFailure = createAction(
  '[API KEYS] Add admin Failure',
  props<{ error: any }>()
);

export const promoteAdminRequest = createAction(
  '[API KEYS] Promote admin Request',
  props<{identifier: string}>()
);

export const promoteAdminSuccess = createAction(
  '[API KEYS] Promote admin Success',
);

export const promoteAdminFailure = createAction(
  '[API KEYS] Promote admin Failure',
  props<{ error: any }>()
);