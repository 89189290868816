<rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
<rnb-table
  #table
  *ngIf="(channelDetails$ | async) as channel"
  [data]="news$ | async"
  [defaultSortActive]="(defaultSort$ | async).active"
  [defaultSortDirection]="(defaultSort$ | async).direction"
  [columns]="columns"
  [hasBulk]="true"
  [hasActions]="true"
  [pagination]="pagination$ | async"
  [totalObs]="total$"
  [isLoading]="isLoading$ | async"
  [highlight]="search$ | async"
  (sortChanged)="sortChanged($event)"
  (paginationChanged)="paginationChanged($event)"
  (selectionChanged)="selectionChanged($event)"
  (linkClicked)="onLinkClick($event, channel.name, channel.id)"
  (pinClicked)="onPinClick($event)"
  [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
  >
  <span bulkText>
    {{table.selectedCount}} News selected 
  </span>
  <ng-container bulkActions>
    <button rds-warning-button (click)="removeNews(table.selection)">Remove</button>
  </ng-container>

  <ng-template #actions let-news>
    <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
    #trigger="rdsMenuTrigger">
      <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
    </button>

    <rds-menu #menu="rdsMenu">
      <button [disabled]="!canEditNews(news)" rnbBackButtonSet [label]="'Channel: '+ channel.name" [routeBack]="'channel/'+ channel.id+'/all'" [routeCurrent]="'/news/'+news.id+'/edit'"  rds-menu-item [routerLink]="'/news/'+news.id+'/edit'">
        <span>Edit</span>
      </button>
      <button rds-menu-item (click)="duplicate(news.id)">
        <span>Duplicate as draft</span>
      </button>
      <button rds-menu-item (click)="removeSingleNews(news.id, news.title)">
        <span>Remove from Channel</span>
      </button>
    </rds-menu>
  </ng-template>

  <span emptyTitle>
    Looks like you don’t have any news here yet.
  </span>
  <span emptySubtitle>
    Fortunately, it's very easy to create news.
    <rnb-add-news-button 
    [breadcrumbRoutes]="[
      {
        label: 'Channel',
        route: 'channels/my'
      },
      {
        label: channel.name,
        route: 'channel/'+ channel.id+'/all'
      },
    ]"  class="add-button"></rnb-add-news-button>
  </span>
</rnb-table>
