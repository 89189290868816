import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { ChannelAssignment, NewsContent, NewsDetails } from '../../types';
import { OembedPipe } from '../oembed.pipe';

@Component({
  selector: 'rnb-news-content-preview',
  templateUrl: './news-content-preview.component.html',
  styleUrls: ['./news-content-preview.component.scss']
})
export class NewsContentPreviewComponent implements OnInit, OnChanges  {
  @Input() noBackground: boolean = false;
  @Input() news: NewsDetails;
  @Input() author: string;
  @Input() language: string;
  @Input() languageName: string;
  @Input() channelsDict: Array<ChannelAssignment>;
  @Input() isEdit: string;
  @Input() viewMode: 'desktop' | 'mobile' = 'desktop';
  @Input() channelName = 'Channel name';

  placeholderDate = new Date(null);
  innerHTML: SafeHtml;
  get newsContent(): NewsContent {
    return this.news.content.find((newsContent) => newsContent.language === this.language);
  }
  ngOnChanges(changes: SimpleChanges) {
    const oembedHTML = this.oembedPipe.transform(this.newsContent.html);
    this.innerHTML =  this.sanitizer.bypassSecurityTrustHtml(oembedHTML);
  }
  get getSecureHTML() {
    const oembedHTML = this.oembedPipe.transform(this.newsContent.html);
    return this.sanitizer.bypassSecurityTrustHtml(oembedHTML);
  }

  get isWebsiteLinkGsite() {
    return this.news.websiteLinkUrl.includes('//sites.google.com');
  }

  get websiteLinkIcon() {
    return this.isWebsiteLinkGsite ? `<use xlink:href="assets/icons/google_sites.svg#google_sites"></use>` : 'link'
  }

  constructor(private sanitizer: DomSanitizer, private oembedPipe: OembedPipe) {}

  ngOnInit(): void {
  }

  getImageUrl(): string {
    return this.newsContent.image?.url || '/assets/news-placeholder.webp';
  }

  getChannelName(id) {
    return this.channelsDict.find(c => c.id === id).name;
  }
}
