import {Plugin} from 'ckeditor5/src/core';
import {WidgetToolbarRepository} from 'ckeditor5/src/widget';
import {createNewButtons} from './blocks-utils'

export default class CustomImageToolbar extends Plugin {

    static get requires() {
        return [WidgetToolbarRepository];
    }

    static get pluginName() {
        return 'CustomImageToolbar';
    }


    afterInit() {
        const editor = this.editor;
        const t = editor.t;
        const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);
        const upload = {
            name: 'upload',
            label: 'Change image',
            command: 'customImageUpload',
            type: 'dialog'
        };
        const alternativeText = {
            name: 'alternative',
            label: 'Alternative Text',
            command: 'customImageAlternativeText',
            type: 'dropdown'
        }

        const options = [
            upload,
            alternativeText
        ]
        createNewButtons(this, options);
        const optionsCommand = options.map(option => {
            if (option.commandValue) {
                return `${option.command}:${option.commandValue}`;
            } else {
                return option.command;
            }
        });
        if (options) {
            widgetToolbarRepository.register('ImageContent', {
                ariaLabel: t('Custom image toolbar'),
                items: optionsCommand,
                getRelatedElement: getSelectedImagesWidget,
                balloonClassName: 'ck-custom-fieldset ck-custom-fieldset-with-form'
            });
        }
    }
}

export function getSelectedImagesWidget(selection) {
    const viewElement = selection.getSelectedElement();

    if (viewElement && isImagesWidget(viewElement)) {
        return viewElement;
    }

    return null;
}

function isImagesWidget(viewElement) {
    if (viewElement.parent && viewElement.parent.hasClass('image-and-text-box')) {
        return viewElement.name === 'figure' && viewElement.hasClass('custom-figure');
    }
    if (viewElement.parent && viewElement.parent.hasClass('image-and-quote-box')) {
        return viewElement.name === 'figure' && viewElement.hasClass('custom-figure');
    }
    if (viewElement.parent && viewElement.parent.hasClass('images-and-text-box')) {
        return viewElement.name === 'figure' && viewElement.hasClass('custom-figure');
    }
    if (viewElement.parent && viewElement.parent.hasClass('images-box')) {
        return viewElement.name === 'figure' && viewElement.hasClass('custom-figure');
    }
    if (viewElement.parent && viewElement.parent.hasClass('announcement-box')) {
        return viewElement.name === 'figure' && viewElement.hasClass('custom-figure');
    }
}
