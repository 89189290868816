import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class ImageAndQuoteBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options?.value;
            const box = createImageAndQuoteBox(writer);
            const image = createImage(writer);
            const text = createQuote(writer);
            const signatureBold = createSignatureBold(writer);
            const signatureBoldText = createSignatureBoldText(writer);
            const signature = createSignature(writer);

            writer.append(image, box);
            writer.append(signatureBoldText, signatureBold);
            writer.append(signatureBold, text);
            writer.append(signature, text);
            writer.append(text, box);
            model.insertContent(box);
            writer.setAttribute('bold', 'bold', signatureBoldText);
            writer.setAttribute('src', './assets/images/block-placeholder.png', image);
            writer.setAttribute('data-image-side', value, box);
            writer.setAttribute('data-image-width', '50%', box);
            writer.setSelection(box, 'on');
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = model.document.selection.getSelectedElement();
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'imageAndQuoteBox');
        const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
        this.isEnabled = allowedIn !== null && !customBlockSelected;
    }
}

export function createImageAndQuoteBox(writer) {
    const imageAndQuoteBox = writer.createElement('imageAndQuoteBox');
    return imageAndQuoteBox;
}
export function createImage(writer) {
    return writer.createElement('customImageBox');
}

export function createQuote(writer) {

    const div = writer.createElement('imageAndQuoteBoxText');
    const quote = writer.createElement('quote-s');
    writer.insertText('Morbi posuere ac dui non pellentesque. Nullam viverra sem tellus, vel semper est egestas molestie. Sed id accumsan magna.', quote);
    writer.append(quote, div);
    return div;
}

export function createSignatureBold (writer) {
    const signature = writer.createElement('imageAndQuoteBoxTextSignatureBold');
    return signature;
}
export function createSignatureBoldText (writer) {
    return writer.createText('Signature: Name Surname');
}

export function createSignature (writer) {
    const position = writer.createElement('imageAndQuoteBoxTextSignature');
    writer.insertText('Signature: Position', position);
    return position;
}
