import { Plugin, icons } from 'ckeditor5/src/core';
import { WidgetToolbarRepository } from 'ckeditor5/src/widget';
import { isWidget } from 'ckeditor5/src/widget';
import { createNewButtons} from '../blocks/blocks-utils'


export default class ImageAndQuoteBoxToolbar extends Plugin {

	static get requires() {
		return [ WidgetToolbarRepository ];
	}

	static get pluginName() {
		return 'ImageAndQuoteBoxToolbar';
	}


	afterInit() {
		const editor = this.editor;
		const t = editor.t;
		const widgetToolbarRepository = editor.plugins.get( WidgetToolbarRepository );

		const options = [
			{
				name: 'imageAndQuoteLeft',
				label: 'Image on the left side',
				command: 'imageAndQuoteBoxAlignment',
				commandValue: 'left',
				type: 'action'
			},
			{
				name: 'imageAndQuoteRight',
				label: 'Image on the right side',
				command: 'imageAndQuoteBoxAlignment',
				commandValue: 'right',
				type: 'action'
			},
			{
				name: 'image',
				label: 'Image resize',
				command: 'customBoxResize',
				type: 'dropdown'
			}
		]
		createNewButtons(this, options);
		const optionsCommand = options.map(option => {
				if(option.commandValue){
					return `${option.command}:${option.commandValue}`;
				}else {
					return option.command;
				}
			});
		if ( options ) {
			widgetToolbarRepository.register( 'ImageAndQuoteContent', {
				ariaLabel: t( 'Image and quote box toolbar' ),
				items: optionsCommand,
				getRelatedElement: getSelectedImageAndQuoteWidget,
				balloonClassName: 'ck-custom-fieldset-toolbar'
			} );
		}
	}
}

export function getSelectedImageAndQuoteWidget( selection ) {
	const viewElement = selection.getSelectedElement();

	if ( viewElement && isImageAndQuoteWidget( viewElement ) ) {
		return viewElement;
	}

	return null;
}

export function getImageAndQuoteWidgetAncestor( selection ) {
	const selectionPosition = selection.getFirstPosition();

	if ( !selectionPosition ) {
		return null;
	}

	let parent = selectionPosition.parent;

	while ( parent ) {
		if ( parent.is( 'element' ) && isImageAndQuoteWidget( parent ) ) {
			return parent;
		}

		parent = parent.parent;
	}

	return null;
}

function isImageAndQuoteWidget( viewElement ) {
	return !!viewElement.hasClass( 'image-and-quote-box') && isWidget(viewElement);
}

