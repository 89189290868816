import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import  { Collection } from '@ckeditor/ckeditor5-utils/src';
import { Model, createDropdown, addListToDropdown } from 'ckeditor5/src/ui';

export default class TextBoxBlockBackgroundUi extends Plugin {
    init() {
        const editor = this.editor;
        const t = editor.t;
        const options = getOptions( editor );
        const defaultTitle = t( 'Block background' );
        const dropdownTooltip = t( 'Block background' );

        editor.ui.componentFactory.add('blockBackgroundClass', locale => {
            const titles = {};
            const itemDefinitions = new Collection();

            const sectionColorsCommand = editor.commands.get('changeBlockBackgroundClass');

            const commands = [sectionColorsCommand];

            for (const option of options) {
                const def = {
                    type: 'button',
                    model: new Model({
                        label: option.title,
                        class:  'button-with-background-color '+option.model,
                        withText: true
                    })
                };


                def.model.bind('isOn').to(sectionColorsCommand, 'value', value => value === option.model);
                def.model.set({
                    commandName: 'changeBlockBackgroundClass',
                    commandParam: option.model,
                    commandValue: option.model
                });

                // Add the option to the collection.
                itemDefinitions.add(def);

                titles[option.model] = option.title;
            }

            const dropdownView = createDropdown(locale);
            addListToDropdown(dropdownView, itemDefinitions);

            dropdownView.buttonView.set({
                isOn: true,
                withText: true,
                tooltip: dropdownTooltip,
                class: 'ck-custom-toolbar-dropdown'
            });



            const changeTypeTextBox = editor.commands.get('changeTypeTextBox');
            dropdownView.bind( 'isEnabled' ).to( changeTypeTextBox, 'value', ( value ) => value === 'block' );

            dropdownView.buttonView.bind('label').to(sectionColorsCommand, 'value', (value, para) => {
                return titles[value] ? titles[value] : defaultTitle;
            });

            // Execute command when an item from the dropdown is selected.
            this.listenTo(dropdownView, 'execute', evt => {
                editor.execute(evt.source.commandName, evt.source.commandValue ? {value: evt.source.commandValue} : undefined);
                editor.editing.view.focus();
            });

            return dropdownView;
        });
    }
}

export function getOptions( editor ) {
    const t = editor.t;
    const localizedTitles = {
        '': t( 'Transparent' ),
        'bg-neutral-dark-colour':'Neutral dark colour',
        'bg-neutral-medium-colour':'Neutral medium colour',
        'bg-neutral-light-colour':'Neutral light colour',
        'bg-blue-gradient-top-left': t( 'Blue gradient (top left)' ),
        'bg-blue-gradient-bottom-right': t( 'Blue gradient (bottom right)' ),
        'bg-neutral-gradient-top-left': t( 'Neutral gradient (top left)' ),
        'bg-neutral-gradient-bottom-right': t( 'Neutral gradient (bottom right)' ),
        'bg-neutral-gradient': t( 'Neutral gradient' ),
        'bg-warm-blue-gradient-top-left': t( 'Warm blue gradient (top left)' ),
        'bg-warm-blue-gradient-bottom-right': t( 'Warm blue gradient (bottom right)' ),
        'bg-gradient-white-to-grey': t( 'Gray gradient' )
    };

    return editor.config.get( 'sectionColorsBox.options' ).map( option => {
        const title = localizedTitles[ option.model ];

        if ( title && title != option.model ) {
            option.title = title;
        }

        return option;
    } );
}
