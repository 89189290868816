import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilsModule } from '../utils/utils.module';
import { TourModule } from '../tour/tour.module';
import { RdsModule } from '../rds.module';
import { ChannelsPageComponent } from './components/all-channels/channels-page/channels-page.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TableEffects } from './store/channel-table';
import { feature, reducer } from './store/channel.reducer';
import { ChannelRoutingModule } from './channel-routing.module';
import { MyChannelsTableContainer } from './components/all-channels/my-channels-table/my-channels-table.container';
import { AllChannelsTableContainer } from './components/all-channels/all-channels-table/all-channels-table.container';
import { ChannelDetailsPageComponent } from './components/single-channel/channel-details-page/channel-details-page.component';
import { MatSelectModule } from '@angular/material/select';
import { AllNewsTableContainer } from './components/single-channel/tables/all-news-table/all-news-table.container';
import { ArchivedNewsTableContainer } from './components/single-channel/tables/archived-news-table/archived-news-table.container';
import { NewsSuggestionsTableContainer } from './components/single-channel/tables/news-suggestions-table/news-suggestions-table.container';
import { DetailsEffects } from './store/channel-details';
import { ChannelFormComponent } from './components/channel-form/channel-form.component';
import { FormEffects } from './store/form';

@NgModule({
  declarations: [
    ChannelsPageComponent,
    MyChannelsTableContainer,
    AllChannelsTableContainer,
    ChannelDetailsPageComponent,
    AllNewsTableContainer,
    NewsSuggestionsTableContainer,
    ArchivedNewsTableContainer,
    ChannelFormComponent,
  ],
  imports: [
    CommonModule,
    RdsModule,
    UtilsModule,
    TourModule,
    MatSelectModule,
    ChannelRoutingModule,
    StoreModule.forFeature(feature, reducer),
    EffectsModule.forFeature([TableEffects, DetailsEffects, FormEffects]),
  ],
  providers: []
})
export class ChannelModule {}
