import { createAction, props } from '@ngrx/store';
import { ScreenModeEnum, ScreenOrientationEnum } from 'src/app/types/screen';


// consider that we need a boolean checking or swap to base on enum
export const setMobile = createAction(
  '[SCREEN] Set Mobile',
);

export const unsetMobile = createAction(
  '[SCREEN] Unset Mobile',
);

export const changeOrientation = createAction(
  '[SCREEN] Change screen orientation',
  props<{orientation: ScreenOrientationEnum}>()
);

export const changeMode = createAction(
  '[SCREEN] Change screen mode',
  props<{mode: ScreenModeEnum}>()
);

export const breakpointChanged = createAction(
  '[SCREEN] Screen breakpoint changed'
);

export const screenResized = createAction(
  '[SCREEN] Screen resized',
  props<{viewportWidth: number}>()
);
