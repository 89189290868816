import { Component, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { News, NewsService } from '@app/news/news.service';
import { RdsDialogService } from '@rds/angular-components';
import * as fromRelatedNews from '@app/news/store/related-news';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { SubSink } from 'subsink';

const SLIDES_IN_ROW = 3;

@Component({
  selector: 'rnb-related-news',
  templateUrl: './related-news.component.html',
  styleUrls: [ './related-news.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: RelatedNewsComponent
    }
  ]
})
export class RelatedNewsComponent implements OnInit {
  private subs: SubSink = new SubSink();

  selectedNews: Array<Partial<News>> = [];

  relatedNews$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromRelatedNews.selectSelectedNews));

  get slidesCount() {
    return Math.ceil(this.selectedNews.length / SLIDES_IN_ROW);
  }

  trackByFn(index, item) {
    return index;
  }
  
  currentSlideItems(slide: number) {
    return this.selectedNews.slice(SLIDES_IN_ROW * slide, SLIDES_IN_ROW * slide + SLIDES_IN_ROW );
  }

  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  
  onChange = (file) => {};

  onTouched = () => {};

  touched = false;
  disabled = false;

  writeValue(ids: Array<number>) {
    if (ids.length > 0 && !ids.every(id => this.selectedNews.findIndex(n => n.id === id) > -1)) {
      this.store$.dispatch(fromRelatedNews.fetchRelatedNewsRequest({ids}));
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  constructor(
    private store$: Store<any>,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {
  }
  ngOnInit(): void {
    this.subs.sink = this.relatedNews$.subscribe(news => {
      this.selectedNews = news;
      this.onChange(this.selectedNews.map(c => c.id));
    })
  }
  
  openRelatedNewsDialog() {
    this.store$.dispatch(fromRelatedNews.openRelatedNewsDialog())
  }

  remove(news: Partial<News>) {
    this.store$.dispatch(fromRelatedNews.removeRelatedNews({id: news.id}))

  }
}
