import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class SectionColorsBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;

        model.change(writer => {
            const selection = model.document.selection;
            const backgroundName = options?.value;
            const selectedModelBlocks = Array.from(selection.getSelectedBlocks());
            const selectedElement = selection.getSelectedElement();
            const sectionColorsBox = this._findParentSectionColorsBox(selectedElement, selectedModelBlocks);
            const otherBlockBox = this._findBlockBox(selectedElement, selectedModelBlocks);


            if (sectionColorsBox) {
                const sectionChildrens = Array.from(sectionColorsBox.getChildren());
                const sectionContent = sectionChildrens[0];
                if (backgroundName) {
                    writer.rename(sectionContent, backgroundName)
                } else {
                    writer.unwrap(sectionContent);
                    writer.unwrap(sectionColorsBox);
                }

            } else {
                if (backgroundName) {
                    const box = createSection(writer);
                    const content = createSectionContent(writer, backgroundName);
                    const elements = [...selectedModelBlocks];
                    writer.append(content, box);

                    if (otherBlockBox || selectedElement) {

                        if (otherBlockBox) {
                            writer.append(writer.cloneElement(otherBlockBox), content);
                            writer.remove(otherBlockBox);
                            model.insertObject(box, null, {setSelection: 'on', findOptimalPosition: true});
                        } else {
                            model.insertObject(box, null, {setSelection: 'on'});
                            const range = writer.createRangeOn(selectedElement);
                            writer.move(range, content, 'end');
                        }


                    } else {
                        for (const block of elements) {
                            writer.append(writer.cloneElement(block), content);
                            writer.remove(block);
                        }
                        model.insertObject(box, null, {setSelection: 'on'});
                    }
                }
            }
        });
    }

    refresh() {
        this.isEnabled = true;
    }

    _findParentSectionColorsBox(element, blocks) {
        if (!element) {
            element = blocks[0];
        }
        if (element?.name && this._checkIsSectionColorsBox(element.name)) {
            return element;
        }
        if (element?.parent) {
            return this._findParentSectionColorsBox(element.parent)
        }
        return null;
    }

    _findBlockBox(element, blocks) {
        if (!element) {
            element = blocks[0];
        }
        if (element?.name && checkIsCustomBlock(element.name)) {
            return element;
        }
        if (element?.parent) {
            return this._findBlockBox(element.parent)
        }
        return null;
    }

    _checkIsSectionColorsBox(name) {
        return name === 'sectionColorsBox';
    }


}

function createSection(writer) {
    return writer.createElement('sectionColorsBox');
}

function createSectionContent(writer, model) {
    return writer.createElement(model);
}
