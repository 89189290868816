import { createSelector } from '@ngrx/store';

import * as fromSuggestions from './suggestions.reducer';

export const selectAutocomplete = (prop: string) => createSelector(
  fromSuggestions.selectState,
  state => state[prop]
);

export const selectSuggestions
= (prop: string) => createSelector(
  selectAutocomplete(prop),
  fromSuggestions.getSuggestions,
);

export const selectLoading
= (prop: string) => createSelector(
  selectAutocomplete(prop),
  fromSuggestions.getLoading,
);
