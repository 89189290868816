import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromDetails from '../../../../store/channel-details';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel, ChannelDetails, News } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { AuthService } from '@app/auth/auth.service';
import { TableColumn } from '@app/utils/table/table.component';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { NewsTableFilters } from '@app/news/store/news-table';

@Component({
  selector: 'rnb-news-suggestions-table',
  templateUrl: './news-suggestions-table.container.html',
  styleUrls: ['./news-suggestions-table.container.scss']
})
export class NewsSuggestionsTableContainer implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }
  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  roleInChannel: string;
  
  get showActions() {
    return this.isAdmin || this.isSuperAdmin || this.roleInChannel === 'Owner' || this.roleInChannel === 'Manager';
  }

  get columns(): Array<string> {
    const actions = this.showActions ? ['reject', 'accept'] : ['reject-disabled', 'accept-disabled']
    return  ['title', 'suggestedBy', 'type', 'published', 'requested', 'status', ...actions]
  }

  roleInChannel$: Observable<string> = this.store$.pipe(select(fromDetails.selectRoleInChannel));
  channelDetails$: Observable<ChannelDetails> = this.store$.pipe(select(fromDetails.selectChannelDetails));

  news: Array<Partial<News>> = []; 
  news$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsData));
  
  pagination$: Observable<Pagination> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsDefaultSort));

  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromDetails.selectNewsSuggestionsSearch));

  constructor(
    private store$: Store<fromDetails.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.sink = this.roleInChannel$.subscribe(role => this.roleInChannel = role)
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromDetails.changeNewsSuggestionsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromDetails.changeNewsSuggestionsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromDetails.changeNewsSuggestionsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<number>) {
  }
  
  rejectNews({id, title}) {
    const ids = [+id]
    const data: ConfirmDialogData = {
      ids,
      title: `Are you sure you want to reject "${title}" news suggestion?`,
      messages: [`Please note that this action is irreversible. You will not be able to accept the suggestion once it's rejected.`],
      cancelButtonLabel: `Cancel`,
      confirmButtonLabel: `Yes, reject`,
      confirmationReason: {
        label: 'Reason for rejection',
        required: true,
      },
      confirmButtonType: 'warning'
    }
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    this.subs.sink = dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromDetails.rejectNewsRequest({newsId: data.ids[0], reason: data.confirmationReason.value})));
  }

  acceptNews({id}) {
    this.store$.dispatch(fromDetails.acceptNewsRequest({newsId: id}))
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}, channelName, channelId) {
    this.openNewsPreview(+link, channelName, channelId);
  }

  openNewsPreview(newsId: number, channelName, channelId) {
    this.store$.dispatch(fromDetails.openNewsPreview({newsId, backButton: {
      label: `Channel: ${channelName}`,
      routeBack: `channel/${channelId}/all`,
      routeCurrent: `/news/${newsId}/edit`
    }}))
  }
}
