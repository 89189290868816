import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromNewsTable from '../../../../store/news-table';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel, News } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { AuthService, SimpleUser } from '@app/auth/auth.service';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { NewsTableFilters } from '@app/utils/table/table-filters/filters-model';
import { getDeleteNewsDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';

@Component({
  selector: 'rnb-my-archived-news-table',
  templateUrl: './my-archived-news-table.container.html',
  styleUrls: ['./my-archived-news-table.container.scss']
})
export class MyArchivedNewsTableContainer implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();

    get identifier(): string {
    return this.auth.currentUser.identifier;
  }

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  columns: Array<string> = ['title', 'owners', 'type', 'created', 'published', 'archived', 'actions'];


  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  news: Array<Partial<News>> = [];
  news$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsData));

  pagination$: Observable<Pagination> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsDefaultSort));

  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromNewsTable.selectMyArchivedNewsSearch));

  constructor(
    private store$: Store<fromNewsTable.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

  }

  canEditNews(owners: Array<SimpleUser>, editors: Array<SimpleUser>) {
    return this.isAdmin || this.isSuperAdmin || !!owners.find(u => u.identifier === this.identifier) || !!editors.find(u => u.identifier === this.identifier)
  }

  canDeleteNews(news: News) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier)
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromNewsTable.changeMyArchivedNewsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromNewsTable.changeMyArchivedNewsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromNewsTable.changeMyArchivedNewsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<News>) {
  }

  deleteNews(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = getDeleteNewsDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteMyArchivedNewsRequest({ids: data.ids})));
  }

  deleteSingleNews(id: string, newsTitle) {
    const ids = [+id];
    const data: ConfirmDialogData = getDeleteNewsDialog(ids, newsTitle);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteMyArchivedNewsRequest({ids: data.ids})));
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    this.openNewsPreview(+link);
  }

  openNewsPreview(newsId: number) {
    this.store$.dispatch(fromNewsTable.openNewsPreview({newsId, backButton: {
      label: 'My News',
      routeBack: 'news/my',
      routeCurrent: `/news/${newsId}/edit`
    }}))
  }

  duplicate(id) {
    this.store$.dispatch(fromNewsTable.duplicateNewsRequest({newsId: id }));
  }

  saveAsTemplate(id) {
    this.store$.dispatch(fromNewsTable.openSaveAsTemplateDialog({newsId: id }));
  }

  moveAsDraft(id) {
    this.store$.dispatch(fromNewsTable.moveAsDraftRequest({ids: [id] }));
  }
}
