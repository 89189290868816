import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class MovieAndTextBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options?.value;
            const box = createMovieAndTextBox(writer);
            const movie = createMovie(writer);
            const text = createText(writer);

            writer.append(movie, box);
            writer.append(text, box);
            model.insertContent(box);

            writer.setAttribute('data-side', value, box);
            writer.setSelection(box, 'on');
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = model.document.selection.getSelectedElement();
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'movieAndTextBox');
        const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
        this.isEnabled = allowedIn !== null && !customBlockSelected;
    }
}

export function createMovieAndTextBox(writer) {
    const movieAndTextBox = writer.createElement('movieAndTextBox');
    return movieAndTextBox;
}
export function createMovie(writer) {
    const div = writer.createElement('movieAndTextBoxMedia');
    const mediaElement = writer.createElement( 'media', { url: '' } );
    writer.append(mediaElement, div)
    return div;
}


export function createText(writer) {

    const div = writer.createElement('movieAndTextBoxText');
    const paragraph = writer.createElement('paragraph');
    writer.insertText('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut' +
        ' labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut' +
        ' aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore' +
        ' eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt' +
        ' mollit anim id est laborum', paragraph);
    writer.append(paragraph, div)
    return div;
}
