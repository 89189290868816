<div class="row">
  <div class="col lmb-3">
    <div class="header">
      <h1 class="ui-heading-1-bold me-auto">
        <ng-content select="[title]"></ng-content>
      </h1>
      <ng-content select="[rds-secondary-button],[rds-primary-button],ng-container"></ng-content>
      <rnb-add-news-button *ngIf="!hideAddNews" [breadcrumbRoutes]="breadcrumbRoutes"></rnb-add-news-button>
    </div>
  </div>
</div>