import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import toArray from '@ckeditor/ckeditor5-utils/src/toarray';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import MovieAndTextBoxCommand from './movie-and-text-box-command';
import MovieAndTextBoxAlignmentCommand from "./movie-and-text-box-alignment-command";
import CustomMovieSrcCommand from "./custom-movie-src-command";

export default class MovieAndTextBoxEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        this._defineSchema();
        this._defineConverters();


        this.editor.commands.add('insertMovieAndTextBox', new MovieAndTextBoxCommand(this.editor));
        this.editor.commands.add('movieAndTextAlignment', new MovieAndTextBoxAlignmentCommand(this.editor));
        this.editor.commands.add('customMovieSrc', new CustomMovieSrcCommand(this.editor));

        this.listenTo(editor.editing.view.document, 'delete', (evt, data) => {
            const element = data.document.selection.getSelectedElement();
            if (element?.name === 'figure' && element.hasClass('media') && element.parent.hasClass('movie-and-text-media')) {
                editor.execute( 'customMovieSrc' );
                evt.stop();
            }
        });
        this.listenTo(editor.editing.view.document, 'paste', (evt, data) => {
            const text = data.dataTransfer.getData("text")
            const element = data.document.selection.getSelectedElement();
            if (element?.name === 'figure' && element.hasClass('media') && element.parent.hasClass('movie-and-text-media')) {
                if (!!text) {
                    const mediaEmbedCfg = editor.config.get('mediaEmbed');
                    const providers = [...mediaEmbedCfg.providers, ...mediaEmbedCfg.extraProviders];
                    for (const provider of providers) {
                        const patterns = toArray( provider.url );
                        for (const pattern of patterns) {
                            const match = _getUrlMatches(text, pattern);
                            if (match) {
                                editor.execute('customMovieSrc', text);
                            }
                        }
                    }
                }
                evt.stop();
            }
        });
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('movieAndTextBox', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,
            isLimit: true,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',
            allowedChild: ['movieAndTextBoxText','movieAndTextBoxMedia'],
            allowAttributes: ['data-side']
        });

        schema.register('movieAndTextBoxText', {
            // Cannot be split or left by the caret.
            inheritAllFrom: '$blockObject',

            allowIn: 'movieAndTextBox',

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        });
        schema.register('movieAndTextBoxMedia', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'movieAndTextBox',
            allowedChild: ['figure'],

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        });

        schema.addChildCheck((context, childDefinition) => {
            if (context.endsWith('movieAndTextBoxText') && childDefinition.name == 'movieAndTextBox') {
                return false;
            }
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        conversion.attributeToAttribute( {
            model: {
                name: 'movieAndTextBox',
                key: 'data-side'
            },
            view: {
                key: 'data-side'
            }
        } );
        conversion.for('upcast').elementToElement({
            model: 'movieAndTextBox',
            view: {
                name: 'div',
                classes: ['movie-and-text-box']
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'movieAndTextBox',
            view: {
                name: 'div',
                classes: ['movie-and-text-box', 'mb-6']
            }
        });


        conversion.for('editingDowncast').elementToElement({
            model: 'movieAndTextBox',
            view: (modelElement, {writer: viewWriter}) => {
                const div = viewWriter.createContainerElement('div', {class: 'py-6 my-6 movie-and-text-box'});
                return toWidget(div, viewWriter, {label: 'simple box widget'});
            }
        })

        conversion.for('upcast').elementToElement({
            model: 'movieAndTextBoxText',
            view: {
                name: 'div',
                classes: 'movie-and-text-text'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'movieAndTextBoxText',
            view: {
                name: 'div',
                classes: 'movie-and-text-text'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'movieAndTextBoxText',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement('div', {class: 'movie-and-text-text'});
                return toWidgetEditable(div, viewWriter);
            }
        });
        conversion.for('upcast').elementToElement({
            model: 'movieAndTextBoxMedia',
            view: {
                name: 'div',
                classes: 'movie-and-text-media'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'movieAndTextBoxMedia',
            view: {
                name: 'div',
                classes: 'movie-and-text-media'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'movieAndTextBoxMedia',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createContainerElement('div', {class: 'movie-and-text-media'});
                return toWidget(div, viewWriter);
            }
        });

        conversion.for('upcast').elementToElement({
            view: {
                name: 'oembed',
            },
            model: ( viewMedia, { writer } ) => {
                const url = viewMedia.getAttribute( 'url' );
                if(!url) {
                    return writer.createElement( 'media', { url: '' } );
                }
            }
        });
        
    }
}
export function isHtmlIncluded( dataTransfer ) {
	return Array.from( dataTransfer.types ).includes( 'text/html' ) && dataTransfer.getData( 'text/html' ) !== '';
}

export function _getUrlMatches( url, pattern ) {
    // 1. Try to match without stripping the protocol and "www" subdomain.
    let match = url.match( pattern );

    if ( match ) {
        return match;
    }

    // 2. Try to match after stripping the protocol.
    let rawUrl = url.replace( /^https?:\/\//, '' );
    match = rawUrl.match( pattern );

    if ( match ) {
        return match;
    }

    // 3. Try to match after stripping the "www" subdomain.
    rawUrl = rawUrl.replace( /^www\./, '' );
    match = rawUrl.match( pattern );

    if ( match ) {
        return match;
    }

    return null;
}
