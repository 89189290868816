import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { debounceTime } from 'rxjs';

export interface ConfirmDialogData {
  ids: Array<number | string>;
  title: string;
  messages: Array<string>;
  confirmationReason?: {
    label: string;
    required?: boolean;
    value?: string; 
  }
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmButtonType?: 'warning' | 'primary'
}
@Component({
  selector: 'atr-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class Confirm2DialogComponent {

  confirmation: FormGroup = new FormGroup({})

  constructor(@Inject(RDS_DIALOG_DATA) public data: ConfirmDialogData) {
    if (!!this.data.confirmationReason) {
      this.confirmation.addControl('confirm', new FormControl(''));
      if (this.data.confirmationReason.required) {
        this.confirmation.controls.confirm.addValidators(Validators.required)
      }
      this.confirmation.controls.confirm.valueChanges.pipe(debounceTime(200)).subscribe(value => {
        this.data.confirmationReason.value = value
      })
    }
   }
}
