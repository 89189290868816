<ng-container [formGroup]="form">
  <div *ngIf="isSuperAdmin" class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Predefined template</h4>
      <div class="row">
        <div class="col-12 mt-5">
          <rds-switch formControlName="isPredefined">Define this template as predefined for all users</rds-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Template users and their role</h4>
      <div *ngIf="!form.get('isPredefined').value" class="row">
        <div class="col mt-5">
          <rnb-people-role-picker 
          (search)="getAutocompletes('permissions', $event)"
          (selected)="getAutocompletes('permissions', '')"
          (removed)="getAutocompletes('permissions', '')"
          [notRemovable]="[userIdentifier]"
          formControlName="permissions"
          [roles]="TEMPLATE_ROLES"
          defaultRole="viewers"
          [autocomplete]="permissionsAutocomplete$ | async">
          <rds-control-error *ngIf="form.controls.permissions.errors?.atLeastOneOwnerRequired">At least one Owner is required</rds-control-error>
        </rnb-people-role-picker>
        </div>
      </div>
      <div *ngIf="form.get('isPredefined').value" class="row">
        <div class="col mt-5" [formGroup]="superAdminForm">
          <rnb-people-role-picker 
          (search)="getAutocompletes('permissions', $event)"
          [notRemovable]="[userIdentifier]"
          formControlName="permissions"
          [roles]="TEMPLATE_ROLES"
          [readonly]="true"
          defaultRole="viewers"
          [autocomplete]="permissionsAutocomplete$ | async"></rnb-people-role-picker>
        </div>
      </div>
    </div>
  </div>
</ng-container>
