import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormControl } from '@angular/forms';
import {get} from 'lodash';

export class CustomValidators {
  static date(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const parsed = Date.parse(control.value);
      return Number.isNaN(parsed) ? { date: { value: control.value } } : null;
    };
  }

  static url(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    let valid = false;
    try {
      const url = new URL(control.value);
      valid = url.protocol === 'https:' || url.protocol === 'http:';
    } catch (_) {}
    return !valid ? { url: { value: control.value } } : null;
  }

  static notEmptyList(control: AbstractControl) {
    return control.value && control.value.length ? null : {
      notEmptyList: {
        valid: false
      }
    };
  }

  static validateTemplatePermissions(control: FormControl) {
    if (control.value.owners.length === 0) {
      return { atLeastOneOwnerRequired: { valid: false}}
    } else {
      return null
    }
  }

  static validateNewsPermissions(control: FormControl) {
    if (control.value.owners.length === 0) {
      return { atLeastOneOwnerRequired: { valid: false}}
    } else {
      return null
    }
  }

  static validateChannelPermissions(control: FormControl) {
    if (control.value.owners.length === 0) {
      return { atLeastOneOwnerRequired: { valid: false}}
    } else {
      return null
    }
  }

  static maxLengthList(max: number) {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length <= max)
        return null
        return { maxLengthList: { valid: false } }
    }
  }

  static minLengthList(min: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length >= min)
            return null;
        return { minLengthList: {valid: false }};
    }
  }

  static isMasterControlValid(control: AbstractControl) {
    return (c: AbstractControl): {[key: string]: any} => {
      if (control.valid)
        return null
      return {invalid: true}
    }
  }
  
  static validateWebsiteLink(control: FormGroup) {
    const websiteLinkName = control.controls.websiteLinkName;
    const websiteLinkUrl = control.controls.websiteLinkUrl;
    if ((!websiteLinkName.value || websiteLinkName.value.length === 0) && (!websiteLinkUrl.value || websiteLinkUrl.value.length === 0)) {
      websiteLinkName.setErrors(null);
      websiteLinkUrl.setErrors(null);
      return null;
    }
    if ((websiteLinkUrl.value?.length > 0) && (!websiteLinkName.value || websiteLinkName.value.length === 0)) {
      websiteLinkName.setErrors({missingLinkName: { valid: false}});
      websiteLinkUrl.setErrors(null);
      return null;
    }
    if ((websiteLinkName.value?.length > 0) && (!websiteLinkUrl.value || websiteLinkUrl.value.length === 0)) {
      websiteLinkName.setErrors(null);
      websiteLinkUrl.setErrors({missingLinkUrl: { valid: false}});
      return null;
    }
  }
  
  static validateAtLeastOneRequired(rControls: Array<string>): ValidatorFn {
    return (controls: FormGroup) => {
      const selectedValuesCount = rControls.reduce((acc, curr, idx) => acc = acc + controls.get(curr).value.length, 0)
      if (selectedValuesCount > 0) {
        rControls.map(formControlName => controls.get(formControlName).setErrors(null))
      } else {
        rControls.map(formControlName => controls.get(formControlName).setErrors({atLeastOneRequired: true}))
        const isAnyTouched = rControls.reduce((acc, curr, idx) => acc = acc || controls.get(curr).touched, false)
        if (isAnyTouched) {
          rControls.map(formControlName => controls.get(formControlName).markAsTouched())
        }
      }
      return null
    };
  }
}



export function isValidUrl(c: AbstractControl) {
  let validUrl = true;
  if (!c.value) return null;

  try {
    new URL(c.value)
  } catch {
    validUrl = false;
  }

  return validUrl ? null : {invalidUrl: true};
}

export function notEmptyListCheck(list: Array<any>) {
  return (control: AbstractControl) => {
    return list && list.length ? null : {
      notEmptyList: {
        valid: false
      }
    };
  };
}

export function validateNewsPublishingDates(fillFormStartedAt: Date, originalPublishDate: Date) {
  return (group: FormGroup) => {
    const now = fillFormStartedAt;
    const publishDateControl = group.controls.publishDate as FormControl;
    const publishTimeControl = group.controls.publishTime as FormControl;
    const expiryDateControl = group.controls.expiryDate as FormControl;
    const expiryTimeControl = group.controls.expiryTime as FormControl;
    if (!publishDateControl.value || !expiryDateControl.value || !publishTimeControl.value || !expiryTimeControl.value) {
      return null;
    }
    let orgPublishDate = originalPublishDate ? new Date(originalPublishDate) : now;
    let publishDate = new Date(publishDateControl.value);
    let expiryDate = new Date(expiryDateControl.value);
    const publishTime = publishTimeControl.value.split(':');
    const expiryTime = expiryTimeControl.value.split(':');
    publishDate = new Date(publishDate.setHours(publishTime[0], publishTime[1]));
    expiryDate = new Date(expiryDate.setHours(expiryTime[0], expiryTime[1]));
    const expBeforePub = expiryDate < publishDate;
    const publishDateFromPast = publishDate < now && orgPublishDate.getTime() !== publishDate.getTime();
    const expiryDateFromPast = expiryDate < now
    if (expBeforePub || publishDateFromPast || expiryDateFromPast) {
      publishDateControl.setErrors({expBeforePub, publishDateFromPast});
      publishTimeControl.setErrors({expBeforePub, publishDateFromPast});
      expiryDateControl.setErrors({expBeforePub, expiryDateFromPast});
      expiryTimeControl.setErrors({expBeforePub, expiryDateFromPast});
      publishDateControl.markAsTouched();
      publishTimeControl.markAsTouched();
      expiryDateControl.markAsTouched();
      expiryTimeControl.markAsTouched();
    }
    return null;
  }
}