import Command from '@ckeditor/ckeditor5-core/src/command';

export default class CustomImageDefaultSrcCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const selection = model.document.selection;
            const element = selection.getSelectedElement();

            writer.setAttribute('src', 'assets/images/block-placeholder.png', element);
            writer.setSelection( element, 'on' );
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = selection.getSelectedElement();
        this.isEnabled = !!element;
    }
}
