import { SimpleUser } from './user';
import { Channel, ChannelAssignment, ChannelBase } from './channel';
import { Announcement, PeopleAnnouncement } from './announcement';

export type ContentType = 'Link' | 'Embedded' | 'Announcement';
export enum ContentTypeEnum {
  LINK = 'Link',
  EMBEDDED = 'Embedded',
  Announcement = 'Announcement',
  TEMPLATE = 'Template'
}

export type NewsStatus = 'Draft' | 'Published' | 'Scheduled' | 'Archived';

export interface News {
  id: number;
  title: string;
  owners: Array<SimpleUser>;
  editors?: Array<SimpleUser>;
  type: ContentType;
  createdDate: Date;
  modifiedDate?: Date;
  publishDate?: Date;
  status: NewsStatus;
  assignedCount: number;
  assignedBy: SimpleUser;
  isPinned: boolean;
  isTemplate?: boolean;
}

export interface NewsForm {
  // general
  id?: number;
  type?: ContentType;
  title: string;
  authors?: Array<SimpleUser>;
  byline: string;
  contact: string;
  language: string;
  // language versions
  content: Array<NewsContent>;
  websiteLink?: {
    url: string;
    name: string;
  }
  topics: Array<string>;
  related: Array<number>;
  // publishing
  assignement: {
    assign: Array<number>;
    suggest: Array<number>;
  };
  timeZone: string;
  publishDate: Date;
  publishTime: string;
  expiryDate: Date;
  expiryTime: string;
  // permissions
  permissions: {
    owners: Array<SimpleUser>;
    editors: Array<SimpleUser>;
  };
  // 
  commentsEnabled: boolean;
}

export interface NewsSuggestion extends News {
  channel: ChannelBase;
  suggestedDate: Date;
  responseDate?: Date;
  approvalStatus: 'Pending' | 'Approved' | 'Rejected';
  comment?: string;
  rejectedBy?: SimpleUser;
}

export interface NewsDetails {
  id: number;
  authors?: Array<SimpleUser>;
  topics?: Array<string>;
  websiteLinkUrl?: string;
  websiteLinkName?: string;
  contentType: ContentType;
  byline: string;
  contact: string;
  publishDate?: Date;
  publishTimeZone?: string;
  expiryDate?: Date;
  expiryTimeZone?: string;
  language: string;
  owners: Array<SimpleUser>;
  editors: Array<SimpleUser>;
  isOwner: boolean;
  isEditor: boolean;
  status: 'Draft' | 'Published' | 'Scheduled';
  assignedChannels: Array<number>;
  suggestedChannels: Array<number>;
  content: Array<NewsContent>;
  relatedNews: Array<number>;
  announcement?: Announcement;
  bylineEmail?: string;
  commentsEnabled?: boolean;
  isTemplate?: boolean;
}

export interface NewsCreation {
  authors?: Array<SimpleUser>;
  topics?: Array<string>;
  contentType: ContentType;
  byline: string;
  contact: string;
  publishDate?: Date | string;
  publishTimeZone?: string;
  expiryDate?: Date | string;
  expiryTimeZone?: string;
  content: Array<NewsContent>;
  assign?: Array<number>;
  suggest?: Array<number>;
  owners?: Array<SimpleUser>;
  editors?: Array<SimpleUser>;
  relatedNews?: Array<number>;
  isDraft?: boolean;
  announcement?: Announcement;
  websiteLinkUrl?: string;
  websiteLinkName?: string;
  language: string;
  commentsEnabled: boolean;
}

export interface NewsUpdate {
  authors?: Array<SimpleUser>;
  topics?: Array<string>;
  byline?: string;
  contact?: string;
  publishDate?: Date | string;
  publishTimeZone?: string,
  expiryDate?: Date | string;
  expiryTimeZone?: string,
  contentCreate: Array<NewsContent>;
  contentUpdate: Array<NewsContent>;
  contentDelete: Array<string>;
  assign?: Array<number>;
  suggest?: Array<number>;
  owners?: Array<SimpleUser>;
  editors?: Array<SimpleUser>;
  relatedNews?: Array<number>;
  isDraft?: boolean;
  updateMetaData?: boolean;
  updateRelatedNews?: boolean;
  announcementCreate?: Array<Announcement>;
  announcementUpdate?: Array<Announcement>;
  announcementDelete?: Array<String>;
  websiteLinkUrl?: string;
  websiteLinkName?: string;
  language?: string;
  commentsEnabled?: boolean;
}

export interface NewsContent {
  language: string;
  html?: string;
  title: string;
  abstract: string;
  link?: string;
  image?: NewsImage;
  syncImage?: boolean;
}

export interface NewsImage {
  id: string;
  url: string;
  name: string;
  size: number;
}

export interface NewsListRequest {
  view?: 'my' | 'suggested' | 'archived' | 'channel' | 'approve' | 'carchive' | 'all';
  offset?: number;
  count?: number;
  sortBy?: string;
  order?: string;
  query?: string;
  channelId?: number;
  types?: Array<string>;
  statuses?: Array<string>;
  approvalStatuses?: Array<string>;
  // remove
  type?: string;
  status?: string;
  approvalStatus?: string;
}

export interface NewsMetaDataExternal {
  author?: string;
  contact?: string;
  language?: string;
  title?: string;
  abstract?: string;
  url?: string;
  image?: string | NewsImage;
}

export const EMPTY_NEWS_FORM: NewsForm = {
  id: null,
  type: ContentTypeEnum.EMBEDDED,
  title: "",
  authors: [],
  byline: "",
  contact: "",
  language: 'en',
  content: [{
    language: 'en',
    abstract: "",
    title: "",
    html: "",
    image: null,
    link: "",
  }],
  websiteLink: {
    name: "",
    url: ""
  },
  topics: [],
  related: [],
  assignement: {
    assign: [],
    suggest: [],
  },
  timeZone: "",
  publishDate: null,
  publishTime: null,
  expiryDate: null,
  expiryTime: null,
  permissions: {
    owners: [],
    editors: [],
  },
  commentsEnabled: true
}

export const EMPTY_NEWS_DETAILS_FOR_TEMPLATE: NewsDetails = {
  id: null,
  contentType: ContentTypeEnum.EMBEDDED,
  authors: [],
  byline: "",
  contact: "",
  language: 'en',
  content: [],
  websiteLinkUrl: "",
  websiteLinkName: "",
  topics: [],
  relatedNews: [],
  assignedChannels: [],
  suggestedChannels: [],
  publishTimeZone: "",
  isOwner: true,
  isEditor: true,
  status: "Published",
  publishDate: null,
  expiryDate: null,
  expiryTimeZone: null,
  owners: [],
  editors: [],
  isTemplate: true,
}