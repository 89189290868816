import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import  { Collection } from '@ckeditor/ckeditor5-utils/src';
import { Model, createDropdown, addListToDropdown } from 'ckeditor5/src/ui';

export default class CustomBoxResizeUi extends Plugin {
    init() {
        const editor = this.editor;
        const t = editor.t;
        const options = getOptions( editor );
        const defaultTitle = t( 'Resize image' );
        const dropdownTooltip = t( 'Resize image' );

        editor.ui.componentFactory.add('customBoxResize', locale => {
            const titles = {};
            const itemDefinitions = new Collection();

            const customBoxResize = editor.commands.get('customBoxResize');

            const commands = [customBoxResize];

            for (const option of options) {
                const def = {
                    type: 'button',
                    model: new Model({
                        label: option.title,
                        class:  option.name,
                        withText: true
                    })
                };


                def.model.bind('isOn').to(customBoxResize, 'value', value => value === option.name);
                def.model.set({
                    commandName: 'customBoxResize',
                    commandParam: option.name,
                    commandValue: option.label
                });

                // Add the option to the collection.
                itemDefinitions.add(def);

                titles[option.model] = option.title;
            }

            const dropdownView = createDropdown(locale);
            addListToDropdown(dropdownView, itemDefinitions);

            dropdownView.buttonView.set({
                isOn: false,
                withText: true,
                tooltip: dropdownTooltip
            });

            dropdownView.extendTemplate({
                attributes: {
                    class: [
                        'ck-font-size-dropdown'
                    ]
                }
            });

            dropdownView.bind('isEnabled').toMany(commands, 'isEnabled', (...areEnabled) => {
                return areEnabled.some(isEnabled => isEnabled);
            });

            dropdownView.buttonView.bind('label').to(customBoxResize, 'value', (value, para) => {
                return value || defaultTitle;
            });

            // Execute command when an item from the dropdown is selected.
            this.listenTo(dropdownView, 'execute', evt => {
                editor.execute(evt.source.commandName, evt.source.commandValue ? {value: evt.source.commandValue} : undefined);
                editor.editing.view.focus();
                dropdownView.buttonView.label = evt.source.commandValue;
            });

            this.listenTo(dropdownView, 'render', evt => {
                dropdownView.buttonView.label = defaultTitle;
            });

            return dropdownView;
        });
    }
}

export function getOptions( editor ) {
    return editor.config.get( 'customImage.options' ).map( option => {
        return {...option, title: option.label};
    } );
}
