import { createReducer, on } from '@ngrx/store';
import * as fromToastActions from './toast.actions';
import { ToastParams } from '@rhd/angular';

export const feature = 'toast';
export interface State {
}

export const initialState: State = {
};

export const reducer = createReducer(
  initialState,

);
