import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RDS_DIALOG_DATA, RdsSelectFilterByFunc, RdsSelectOptionComponent } from '@rds/angular-components';
import { Observable, filter, merge } from 'rxjs';
import { CustomValidators } from '@app/utils/validators';
import { ChannelAssignment } from '@app/types';
import { SubSink } from 'subsink';

export type SelectType = 'select' | 'autocomplete';

export interface AssignSuggestChannelsDialogData {
  assignableChannels?: Observable<Array<ChannelAssignment>>;
  nonAassignableChannels?: Observable<Array<ChannelAssignment>>;
}
@Component({
  selector: 'rnb-assign-suggest-channels-dialog',
  templateUrl: './assign-suggest-channels-dialog.component.html',
  styleUrls: ['./assign-suggest-channels-dialog.component.scss']
})
export class AssignSuggestChannelsDialogComponent implements OnDestroy {
  private subs: SubSink = new SubSink();
  assignement: FormGroup = new FormGroup({
    assign: new FormControl([]),
    suggest: new FormControl([]),
  }, CustomValidators.validateAtLeastOneRequired([
    'assign', 'suggest',
  ]));

  initialAssignable: Array<number>;
  initialNonAssignable: Array<number>;
  assignable: Array<ChannelAssignment>;
  nonAssignable: Array<ChannelAssignment>;

  filterBy: RdsSelectFilterByFunc = (
    text: string | null,
    item: RdsSelectOptionComponent
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.id.toLowerCase().includes(text.toLowerCase());
    }
  };

  get confirmData() {
    return {
      assign: this.assignement.controls.assign.value,
      suggest: this.assignement.controls.suggest.value,
      assignable: this.assignable,
      nonAssignable: this.nonAssignable
    }
  }

  get noChangesMade() {
    return this.assignement.controls.assign.value.every(id => this.initialAssignable?.includes(id)) && this.assignement.controls.suggest.value.every(id => this.initialNonAssignable?.includes(id));
  }

  constructor(@Inject(RDS_DIALOG_DATA) public data: AssignSuggestChannelsDialogData) {
    if (!!data) {
      this.data === data;
      this.subs.sink = this.data.assignableChannels.pipe(filter((channels) => channels.length > 0)).subscribe(channels => {
        this.assignable = channels.sort((a,b) => a.name.localeCompare(b.name));
        this.initialAssignable = channels.filter(c => c.assignmentStatus !== 'None').map((c) => c.id)
      });

      this.subs.sink = this.data.nonAassignableChannels.pipe(filter((channels) => channels.length > 0)).subscribe(channels => {
        this.nonAssignable = channels.sort((a,b) => a.name.localeCompare(b.name));
        this.initialNonAssignable = channels.filter(c => c.assignmentStatus !== 'None').map((c) => c.id)
      });
    }
   }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
