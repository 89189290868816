import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFromArray'
})
export class ListFromArrayPipe implements PipeTransform {
  constructor() {}

  transform(value: Array<any>,  prop: string = 'name', separator: string = ', '): string {
    let list = [];
    if (value[0] instanceof Array) {
      value.forEach(a => list = [...list, ...a]);
    } else {
      list = value;
    }
    let result = '';
    const length = list?.length || 0;
    for (let i = 0; i < length; i++) {
      const item = list[i];
      result = result + item[prop];

      if (i + 1 !== length) {
        result = result + separator;
      }
    }
    return result;
  }
}
