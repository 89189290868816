import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from '@app/root-store/dictionaries/timezones/timezones.actions';
import { Language, TimeZone } from '@app/types';

export interface State extends EntityState<TimeZone> {}

export const adapter: EntityAdapter<TimeZone> =
  createEntityAdapter<TimeZone>({
    selectId: location => location.name
});

export const initialState: State = {
  ...adapter.getInitialState()
};

const timezonesReducer = createReducer(
  initialState,
  on(fromActions.timezonesCollectionAddMany, (state, {timezones}) => (
    adapter.addMany(timezones, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return timezonesReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;

export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<TimeZone>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<TimeZone>, {ids}) => ids.map(id => entities[id]);

