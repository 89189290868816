import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material.module';
import { TourModule } from '../tour/tour.module';

import { CustomIconsService } from './custom-icons.service';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogDirective } from './confirm-dialog/confirm-dialog.directive';
import { UserSelectComponent } from './user-select/user-select.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { TilePreviewComponent } from './tile-preview/tile-preview.component';
import { TimeAgoPipe } from './time-ago.pipe';
import { SelectComponent } from './select/select.component';
import { UserListPipe } from './user-list.pipe';
import { SearchComponent } from './search/search.component';
import { SimpleSelectComponent } from './simple-select/simple-select.component';
import { PreviewDialogComponent } from './preview-dialog/preview-dialog.component';
import { NewsContentPreviewComponent } from './preview-dialog/news-content-preview.component';
import { ContainerComponent } from './container/container.component';
import { FooterComponent } from './footer/footer.component';
import { BoxComponent } from './box/box.component';
import { BoxNestedComponent } from './box/box-nested.component';
import { EmptyTableCtaComponent } from './empty-table-cta/empty-table-cta.component';
import { AddNewsButtonComponent } from './add-news-menu/add-news-button.component';
import { TruncateDirective } from './truncate.directive';
import { TruncateWrapperComponent } from './truncate-wrapper/truncate-wrapper.component';
import { NoMobileComponent } from './no-mobile/no-mobile.component';
import { SelectedUserComponent } from './selected-user/selected-user.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TaxonomyDisplayComponent } from './taxonomy-display/taxonomy-display.component';
import { PageFabComponent } from './page-fab/page-fab.component';
import { DatetimeComponent } from './datetime/datetime.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { TimezonePickerComponent } from './timezone-picker/timezone-picker.component';
import { HeaderComponent } from './header/header.component';
import { DataPrivacyDialogComponent } from './data-privacy-dialog/data-privacy-dialog.component';
import { ErrorMessagePipe } from './error-message.pipe';
import { NewsSelectComponent } from './news-select/news-select.component';
import { TopicSelectComponent } from './topic-select/topic-select.component';
import { BypassIconSrcPipe } from './bypass-icon-src.pipe';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { RdsConfirmDialogComponent } from './confirm-dialog/rdsconfirm-dialog.component';
import { RdsModule } from '../rds.module';
import { Confirm2DialogComponent } from './confirm2-dialog/confirm-dialog.component';
import { HighlightPipe } from './highlight.pipe';
import { InitialsPipe } from './initials.pipe';
import { NewsTableComponent } from './news-table/news-table.component';
import { TableComponent } from './table/table.component';
import { TableFiltersComponent } from './table/table-filters/table-filters.component';
import { NewsCardItemComponent } from './news-card-item/news-card-item.component';
import { CardComponent } from './card/card.component';
import { IsSameObjectPipe } from './is-same-object.pipe';
import { RejectionCommentDialogComponent } from './rejection-comment-dialog/rejection-comment-dialog.component';
import { PeoplePickerComponent } from './custom-controls/people-picker/people-picker.component';
import { ChannelPickerComponent } from './custom-controls/channel-picker/channel-picker.component';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ListFromArrayPipe } from './list-from-array.pipe';
import { UserGroupComponent } from './user-group/user-group.component';
import { UserSingleComponent } from './user-single/user-single.component';
import { ViewBarComponent } from './view-bar/view-bar.component';
import { RequireAsteriskDirective } from './require-asterisk.directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownNodeComponent } from './dropdown/dropdown-node/dropdown-node.component';
import { DropdownKeyPipe } from './dropdown/dropdown-key.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverflowingContentDirective } from './overflowing-content.directive';
import { TaxonomyGetPipe } from './taxonomy-get.pipe';
import { TaxonomyNamePipe } from './taxonomy-name.pipe';
import { ShouldTruncateDirective } from './should-truncate.directive';
import { MarkDuplicatedPipe } from './mark-duplicated';
import { TileComponent } from './tile/tile.component';
import { TopicPickerComponent } from './custom-controls/topic-picker/topic-picker.component';
import { UploadComponent } from './upload/upload.component';
import { CropImageDialogComponent } from './crop-image-dialog/crop-image-dialog.component';
import { LanguageNamePipe } from './language-name.pipe';
import { SubscriptWrapperComponent } from './custom-controls/subscript-wrapper/subscript-wrapper.component';
import { EditorComponent } from './custom-controls/editor/editor.component';
import { RelatedNewsDialogComponent } from './related-news-dialog/related-news-dialog.component';
import { RelatedNewsComponent } from './custom-controls/related-news/related-news.component';
import { NumToArrayPipe } from './numToArray.pipe';
import { TemplateCardComponent } from './template-card/template-card.component';
import { SelectTemplateDialogComponent } from './select-template-dialog/select-template-dialog.component';
import { PeopleRolePickerComponent } from './custom-controls/people-role-picker/people-role-picker.component';
import { MergeUsersPipe } from './custom-controls/people-role-picker/merge-users.pipe';
import { StreamingMediaDialogComponent } from './custom-controls/editor/streaming-media-dialog/streaming-media-dialog.component';
import { ShareTemplateDialogComponent } from './dialogs/share-template-dialog/share-template-dialog.component';
import { RenameTemplateDialogComponent } from './dialogs/rename-template-dialog/rename-template-dialog.component';
import { SaveTemplateDialogComponent } from './dialogs/save-template-dialog/save-template-dialog.component';
import { SendTemplateCopyDialogComponent } from './dialogs/send-template-copy-dialog/send-template-copy-dialog.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { BackButtonSetDirective } from './back-button/back-button-set.directive';
import { SkeletonDirective } from './dialogs/save-template-dialog/skeleton.directive';
import { OembedPipe } from './oembed.pipe';
import { AssignSuggestChannelsDialogComponent } from './dialogs/assign-suggest-channels-dialog/assign-suggest-channels-dialog.component';
import { RandomLetterDirective } from './dialogs/save-template-dialog/random-letter.directive';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    RdsConfirmDialogComponent,
    ConfirmDialogDirective,
    UserSelectComponent,
    SidenavComponent,
    PageHeaderComponent,
    DisclaimerComponent,
    TilePreviewComponent,
    TimeAgoPipe,
    SelectComponent,
    UserListPipe,
    SearchComponent,
    SimpleSelectComponent,
    PreviewDialogComponent,
    NewsContentPreviewComponent,
    ContainerComponent,
    FooterComponent,
    BoxComponent,
    BoxNestedComponent,
    EmptyTableCtaComponent,
    AddNewsButtonComponent,
    TruncateDirective,
    TruncateWrapperComponent,
    NoMobileComponent,
    SelectedUserComponent,
    SpinnerComponent,
    TaxonomyDisplayComponent,
    PageFabComponent,
    TaxonomyDisplayComponent,
    DatetimeComponent,
    TimepickerComponent,
    TimezonePickerComponent,
    HeaderComponent,
    DataPrivacyDialogComponent,
    ErrorMessagePipe,
    NewsSelectComponent,
    TopicSelectComponent,
    BypassIconSrcPipe,
    Confirm2DialogComponent,
    HighlightPipe,
    InitialsPipe,
    NewsTableComponent,
    TableComponent,
    TableFiltersComponent,
    NewsCardItemComponent,
    CardComponent,
    IsSameObjectPipe,
    RejectionCommentDialogComponent,
    PeoplePickerComponent,
    SelectDialogComponent,
    ChannelPickerComponent,
    ListFromArrayPipe,
    UserGroupComponent,
    UserSingleComponent,
    ViewBarComponent,
    RequireAsteriskDirective,
    DropdownComponent,
    DropdownKeyPipe,
    DropdownNodeComponent,
    OverflowingContentDirective,
    TaxonomyGetPipe,
    TaxonomyNamePipe,
    ShouldTruncateDirective,
    MarkDuplicatedPipe,
    TileComponent,
    TopicPickerComponent,
    UploadComponent,
    CropImageDialogComponent,
    LanguageNamePipe,
    SubscriptWrapperComponent,
    EditorComponent,
    RelatedNewsDialogComponent,
    RelatedNewsComponent,
    NumToArrayPipe,
    TemplateCardComponent,
    SelectTemplateDialogComponent,
    PeopleRolePickerComponent,
    MergeUsersPipe,
    StreamingMediaDialogComponent,
    ShareTemplateDialogComponent,
    RenameTemplateDialogComponent,
    SaveTemplateDialogComponent,
    SendTemplateCopyDialogComponent,
    BackButtonComponent,
    BackButtonSetDirective,
    SkeletonDirective,
    RandomLetterDirective,
    OembedPipe,
    AssignSuggestChannelsDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    RdsModule,
    TourModule,
    HeaderModule,
    FooterModule,
    ScrollingModule,
    OverlayModule
  ],
  exports: [
    ConfirmDialogComponent,
    ConfirmDialogDirective,
    UserSelectComponent,
    SidenavComponent,
    PageHeaderComponent,
    DisclaimerComponent,
    TilePreviewComponent,
    TimeAgoPipe,
    UserListPipe,
    ErrorMessagePipe,
    SelectComponent,
    SearchComponent,
    SimpleSelectComponent,
    PreviewDialogComponent,
    NewsContentPreviewComponent,
    ContainerComponent,
    FooterComponent,
    BoxComponent,
    BoxNestedComponent,
    EmptyTableCtaComponent,
    AddNewsButtonComponent,
    TruncateDirective,
    NoMobileComponent,
    TruncateWrapperComponent,
    NoMobileComponent,
    SelectedUserComponent,
    SpinnerComponent,
    TaxonomyDisplayComponent,
    DatetimeComponent,
    TimezonePickerComponent,
    HeaderComponent,
    NewsSelectComponent,
    TopicSelectComponent,
    BypassIconSrcPipe,
    Confirm2DialogComponent,
    HighlightPipe,
    InitialsPipe,
    TableComponent,
    TableFiltersComponent,
    NewsCardItemComponent,
    IsSameObjectPipe,
    RejectionCommentDialogComponent,
    PeoplePickerComponent,
    SelectDialogComponent,
    ChannelPickerComponent,
    ViewBarComponent,
    RequireAsteriskDirective,
    DropdownComponent,
    OverflowingContentDirective,
    TaxonomyGetPipe,
    TaxonomyNamePipe,
    ShouldTruncateDirective,
    MarkDuplicatedPipe,
    TileComponent,
    TopicPickerComponent,
    UploadComponent,
    CropImageDialogComponent,
    LanguageNamePipe,
    EditorComponent,
    RelatedNewsDialogComponent,
    RelatedNewsComponent,
    NumToArrayPipe,
    TemplateCardComponent,
    SelectTemplateDialogComponent,
    PeopleRolePickerComponent,
    MergeUsersPipe,
    StreamingMediaDialogComponent,
    SaveTemplateDialogComponent,
    SendTemplateCopyDialogComponent,
    BackButtonComponent,
    BackButtonSetDirective,
    OembedPipe,
    AssignSuggestChannelsDialogComponent
  ],
  providers: [
    OembedPipe
  ]
})
export class UtilsModule {
  constructor(
    private customIconsService: CustomIconsService,
  ) {
    this.customIconsService.init();
  }
}

