<div [class.desktop]="viewMode === 'desktop'" [ngClass]="viewMode === 'mobile' ? 'mobile' : 'desktop'" [class.bg-neutral-gradient-top-left]="!noBackground" class="news-content-preview ">
  <div class="container">
    <div class="row">
      <div class="news-column col-12 mb-10">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 lpy-3">
                <button *ngIf="news.content.length > 1" class="bg-white" rds-dropdown-toggle-button>
                  {{ languageName }}
                </button>
              </div>
            </div>
            <div class="row">
              <div [class.col-6]="viewMode === 'desktop'" [class.col-12]="viewMode === 'mobile'" class="info-column">
                <h4 class="article-heading-4--xxl title mb-5" [class.placeholder]="!newsContent.title" [rdsTooltipDisabled]="!!newsContent.title" [rdsTooltip]="'Title is not defined'">
                  {{newsContent.title || 'Title of the news for '+ languageName + ' version'}}
                </h4>
                <div class="info">
                  <p class="ui-body-m--l mb-5">
                    <span [class.placeholder]="!news.publishDate" [rdsTooltipDisabled]="news.publishDate" [rdsTooltip]="'Publish date is not defined'">{{news.publishDate ? (news.publishDate | date: 'mediumDate' ) : 'Mmm dd, yyyy'}}</span> by
                    <ng-container *ngIf="news.authors.length > 0">
                      <button rds-text-button size="m">
                        {{news.authors[0].name}}
                        <rds-icon icon="add_circle" namespace="outlined"></rds-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="news.authors.length === 0">
                      <span [class.placeholder]="!news.byline" [rdsTooltipDisabled]="news.byline" [rdsTooltip]="'Publisher name or author is not defined'">
                      {{news.byline || 'Name Surname'}}
                      </span>
                    </ng-container>
                  </p>
                  <div class="actions">
                    <div class="action">
                      <button rds-icon-button>
                        <rds-icon icon="heart" namespace="outlined"></rds-icon>
                      </button>
                      <span class="action__count">
                        0
                      </span>
                    </div>
                    <div class="action">
                      <button rds-icon-button>
                        <rds-icon icon="message" namespace="outlined"></rds-icon>
                      </button>
                      <span class="action__count">
                        0
                      </span>
                    </div>
                    <div class="action">
                      <button rds-icon-button>
                        <rds-icon icon="bookmark" namespace="outlined"></rds-icon>
                      </button>
                    </div>
                    <div class="action">
                      <button rds-icon-button>
                        <rds-icon icon="share" namespace="outlined"></rds-icon>
                      </button>
                    </div>
                    <div class="action">
                      <button rds-icon-button>
                        <rds-icon icon="link" namespace="outlined"></rds-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div [class.col-6]="viewMode === 'desktop'" [class.col-12]="viewMode === 'mobile'" [class.mt-7]="viewMode === 'mobile'" >
                <div class="thumbnail">
                  <img [src]="newsContent.image?.url || 'assets/news-placeholder.png'">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div *ngIf="!!newsContent.html" class="ck-content ck" [innerHTML]="innerHTML"></div>
            <div *ngIf="!newsContent.html" class="ck-content ck">
              <ng-container *ngTemplateOutlet="contentPlaceholder"></ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div *ngIf="!!news.websiteLinkName && !!news.websiteLinkUrl" class="row">
              <div class="col-12 mb-8">
                <p class="website-link">
                  <rds-icon *ngIf="!isWebsiteLinkGsite" namespace="filled" [icon]="websiteLinkIcon"></rds-icon>
                  <mat-icon *ngIf="isWebsiteLinkGsite" class="purple-icon" svgIcon="gsite"></mat-icon>
                  <a rds-link href="news.websiteLinkUrl">{{news.websiteLinkName}} </a>
                </p>
              </div>
            </div>
            <div *ngIf="isEdit || channelName" class="row">
              <div class="col-12 mb-3 channels-col">
                <p class="ui-body-m--l m-0">Channels</p>
                <rds-chip-list size="s" class="p-2" type="action">
                  <rds-chip
                    *ngIf="news.assignedChannels.length === 0"
                    class="no-click placeholder"
                    [rdsTooltipDisabled]="news.assignedChannels.length !== 0" [rdsTooltip]="'Assigned channels are not defined'"
                    [disabled]="true"
                  >
                  <span>
                    Assigned channel
                  </span>
                  </rds-chip>
                  <rds-chip *ngIf="news.assignedChannels.length > 0">
                    <span>
                      {{channelName}}
                    </span>
                  </rds-chip>
                </rds-chip-list>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3 topics-col">
                <p class="ui-body-m--l m-0">Topics</p>
                <rds-chip-list size="s" class="p-2" type="action">
                  <rds-chip
                    *ngIf="news.topics.length === 0"
                    class="no-click placeholder"
                    [rdsTooltipDisabled]="news.topics.length !== 0" [rdsTooltip]="'Topics are not defined'"
                    [disabled]="true"
                  >
                  <span>
                    Selected topic
                  </span>
                  </rds-chip>
                  <rds-chip
                    *ngFor="let topic of news.topics"
                    class="no-click"
                  >
                  <span>
                    {{topic}}
                  </span>
                  </rds-chip>
                </rds-chip-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentPlaceholder>
  <div class="placeholder" [rdsTooltip]="'Content is not defined'">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ut eros quis nibh ullamcorper vulputate a nec ante. Suspendisse interdum molestie ligula at consectetur. Curabitur nec gravida tellus. Morbi laoreet dignissim leo, vulputate vehicula lorem luctus eu. Sed eu nisl venenatis ipsum mollis viverra. Praesent porta nibh ac sollicitudin semper. Morbi facilisis sapien nisl, nec vulputate arcu malesuada nec. Nulla et nisl elit. Suspendisse ultrices scelerisque massa a scelerisque.
    Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed mi sapien, sollicitudin ac leo sit amet, pellentesque fermentum metus. Cras ut dolor gravida, pellentesque magna non, sollicitudin orci. Etiam imperdiet pharetra mi vel ullamcorper. Maecenas eleifend nec sapien sed venenatis. Pellentesque quis laoreet tortor. Praesent dignissim vitae ex ac pulvinar. Nam hendrerit ultricies ante. Morbi nisl tortor, vestibulum et molestie a, euismod eu odio. Donec feugiat leo mauris, ac rutrum libero bibendum sit amet. Sed metus enim, mattis at mauris non, volutpat ultrices orci. Nunc et accumsan ligula. Mauris in urna non tellus mollis porta.
  </div>
</ng-template>
