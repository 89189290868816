import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromTimezones from '@app/root-store/dictionaries/timezones';
import * as fromForm from '../../../store/form';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChannelAssignment, NewsForm, TimeZone } from '@app/types';
import { DateAdapter, NativeDateAdapter, RDS_DATE_LOCALE, RdsSelectFilterByFunc, RdsSelectOptionComponent } from '@rds/angular-components';
import { FormStepStatus } from '../form-status-badge/form-status-badge.component';
import { SubSink } from 'subsink';
import { CustomValidators, validateNewsPublishingDates } from '@app/utils/validators';

const DEFAULT_EXPIRY_YEARS = 3;

@Component({
  selector: 'rnb-form-publishing-info',
  templateUrl: './form-publishing-info.component.html',
  styleUrls: ['./form-publishing-info.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: NativeDateAdapter, 
  }]
})
export class FormPublishingInfoComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  
  ownersAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('author')))
  timezones$: Observable<Array<TimeZone>> = this.store$.pipe(select(fromTimezones.selectAll));
  assignableChannels$: Observable<Array<ChannelAssignment>> = this.store$.pipe(select(fromForm.selectAssignableChannels));
  suggestableChannels$: Observable<Array<ChannelAssignment>> = this.store$.pipe(select(fromForm.selectSuggestableChannels));
  validationDates$: Observable<{fillFormStartedAt: Date, originalPublishDate: Date}> = this.store$.pipe(select(fromForm.selectDatesForValidation));
  _isActive: Array<boolean>;
  get isActive(): Array<boolean> {
      return this._isActive;
  }
  @Input() set isActive(value: Array<boolean>) {
      this._isActive = value;
      this.store$.dispatch(fromForm.setPublishingFormStatus({status: this.form.status}));
      if (value) {
      this.store$.dispatch(fromForm.setPublishingStepStatus({status: FormStepStatus.IN_PROGRESS}));
      } else {
        this.form.markAllAsTouched();
        if (this.form.valid) {
          this.store$.dispatch(fromForm.setPublishingStepStatus({status: FormStepStatus.COMPLETED}));
        } else {
          this.store$.dispatch(fromForm.setPublishingStepStatus({status: FormStepStatus.INCOMPLETE}));
        }
      }
  }

  filterBy: RdsSelectFilterByFunc = (
    text: string | null,
    item: RdsSelectOptionComponent
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.id.toLowerCase().includes(text.toLowerCase());
    }
  };

  get minPublishDate() {
    return new Date();
  }

  get maxPublishDate() {
    return this.form.controls.expiryDate?.value;

  }

  get minExpiryDate() {
    return this.form.controls.publishDate?.value;
  }
  
  form$: Observable<Partial<NewsForm>> = this.store$.pipe(select(fromForm.selectPublishingForm));
  public form: FormGroup = new FormGroup({
    assignement: new FormGroup({
      assign: new FormControl([]),
      suggest: new FormControl([]),
    }, CustomValidators.validateAtLeastOneRequired([
      'assign', 'suggest',
    ])),
    timeZone: new FormControl(null),
    publishDate: new FormControl(null),
    publishTime: new FormControl(null),
    expiryDate: new FormControl(null),
    expiryTime: new FormControl(null),
  },[validateNewsPublishingDates(new Date(new Date().setSeconds(0,0)), null)]);

  constructor(private store$: Store<any>) {
    
  }

  ngOnDestroy(): void {
    this.form.markAllAsTouched();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(fromTimezones.loadTimezones());
    
    this.subs.sink = this.validationDates$.subscribe(({fillFormStartedAt, originalPublishDate}) => {
      this.form.setValidators(validateNewsPublishingDates(fillFormStartedAt, originalPublishDate));
    })
    this.subs.sink = this.form$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(news => {
      this.form.patchValue(news, {emitEvent: false});
      this.store$.dispatch(fromForm.setPublishingFormStatus({status: this.form.status}));
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(form => {
      this.store$.dispatch(fromForm.setFormValue({form}))
    });
    
    this.subs.sink = this.form.statusChanges.subscribe(value => {
      this.store$.dispatch(fromForm.setPublishingFormStatus({status: value}));
    });

    this.subs.sink = this.form.controls.publishDate.valueChanges.subscribe(date => {
      const publishTimeControl = this.form.controls.publishTime as FormControl;
      const expiryDateControl = this.form.controls.expiryDate as FormControl;
      const expiryTimeControl = this.form.controls.expiryTime as FormControl;

      if (!expiryDateControl.value) {
        const expiryDate = new Date(date);
        expiryDate.setFullYear(expiryDate.getFullYear() + DEFAULT_EXPIRY_YEARS);
        expiryDateControl.patchValue(expiryDate);
      }
      const timeNow = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false});
      if (!publishTimeControl.value) {
        publishTimeControl.patchValue(timeNow)
        publishTimeControl.updateValueAndValidity()
      }
      if (!expiryTimeControl.value) {
        expiryTimeControl.patchValue(timeNow)
        expiryTimeControl.updateValueAndValidity()
      }
    })
  }
   
}
