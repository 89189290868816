<div class="bg-white">
  <div class="container">
    <div class="row">
      <div class="col">
        <rds-header minimalMargins [scrollTarget]="null" searchPosition="none" (logoTriggered)="logoClick()">
          <rds-divider rds-header-start-divider></rds-divider>
          <rds-header-app-name></rds-header-app-name>
          <rds-header-navigation-primary>
            <div class="search-container">
              <rds-header-searchbar #searchBar placeholder="Search RocheHome and Google Cloud"
                (search)="search($event)"></rds-header-searchbar>
            </div>
          </rds-header-navigation-primary>
          <rds-header-actions>
            <button rds-dropdown-toggle-icon-button [rdsMenuTriggerFor]="gcsMenu" #gcsMenuTrigger="rdsMenuTrigger"
              [isOpen]="gcsMenuTrigger.menuOpen">
              <rds-icon class="action" namespace="outlined" icon="app_launcher"></rds-icon>
            </button>
            <rds-menu #gcsMenu="rdsMenu" class="gcs-menu" xPosition="before" yPosition="below">
              <rnb-header-gcs-menu></rnb-header-gcs-menu>
            </rds-menu>
            <button rds-dropdown-toggle-icon-button [rdsMenuTriggerFor]="appMenuPanel.menu"
              #appMenuTrigger="rdsMenuTrigger" [isOpen]="appMenuTrigger.menuOpen">
              <rds-icon class="action" namespace="outlined" icon="menu"></rds-icon>
            </button>
            <rnb-header-app-menu style="display: none" #appMenuPanel>
            </rnb-header-app-menu>
          </rds-header-actions>
        </rds-header>
      </div>
    </div>
  </div>
</div>