export const environment = {
  production: false,
  googleClientId: '1068501526172-74k9ui8fl3tvkrnu39tqudp0edosifu1.apps.googleusercontent.com',
  apiBase: 'https://dev-api-news.roche.com/api/v1',
  newsletterLink: 'https://dev-at.roche.com/newsletter',
  rocheHomeLink: 'https://dev-at.roche.com',
  statsLink: 'https://datastudio.google.com/reporting/f6fb356c-cf4e-4839-80a1-fcd6ee63870c',
  newsHomeLink: 'https://dev-at.roche.com/content/news:{channelId}:{newsId}',
  newsBoardLink: 'https://dev-news.roche.com',
  mediaLibraryLink: 'https://www.roche.com/media/media-library.htm',
  rocheHomeHeader: {
    script: 'https://dev-at.roche.com/rochehomeheadernewsboard/rocheHomeHeaderNewsBoard.6644d3f4.js',
    style: 'https://dev-at.roche.com/rochehomeheadernewsboard/rocheHomeHeaderNewsBoard.e5b3ee0a.css'
  },
  chromeExtensionId: 'edeppkhpcgonmeledilieiamdnlaicod'
};
