import { createReducer, on } from '@ngrx/store';
import { ScreenBreakpoint, ScreenModeEnum, ScreenOrientationEnum } from 'src/app/types/screen';
import * as fromScreenActions from './screen.actions';

export interface State {
  isMobile: boolean;
  isDesktop: boolean;
  actualBreakpoint: ScreenBreakpoint;
  viewportWidth: number;
}

export const initialState: State = {
  isMobile: false,
  isDesktop: false,
  actualBreakpoint: {
    mode: ScreenModeEnum.WEB,
    orientation: ScreenOrientationEnum.PORTRAIT,
  },
  viewportWidth: 0
};

export const reducer = createReducer(
  initialState,
  on(fromScreenActions.setMobile, (state) => ({
    ...state,
    isMobile: true,
  })),
  on(fromScreenActions.unsetMobile, (state) => ({
    ...state,
    isMobile: false,
  })),
  on(fromScreenActions.changeMode, (state, { mode }) => ({
    ...state,
    actualBreakpoint: {
      ...state.actualBreakpoint,
      mode,
    }
  })),
  on(fromScreenActions.changeOrientation, (state, { orientation }) => ({
    ...state,
    actualBreakpoint: {
      ...state.actualBreakpoint,
      orientation
    }
  })),
  on(fromScreenActions.screenResized, (state, { viewportWidth }) => ({
    ...state,
    viewportWidth,
    isDesktop: (viewportWidth > 1240)
  }))
);

export const getMobileSetting = (state: State) => state.isMobile;
export const getActualBreakpoint = (state: State) => state.actualBreakpoint;
export const getScreenMode = (state: State) => state.actualBreakpoint.mode;
export const getScreenOrientation = (state: State) => state.actualBreakpoint.orientation;
export const getViewportWidth = (state: State) => state.viewportWidth;
export const getIsDesktop = (state: State) => state.isDesktop;
