import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, RdsTableComponent, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromTable from '../../../store/channel-table';
import * as fromRouter from '@app/root-store/router';

// import * as fromForm from '../../store/form';

import { AuthService } from '@app/auth/auth.service';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { Channel } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { TableColumn, TableComponent } from '@app/utils/table/table.component';
import { ChannelTableFilters } from '@app/utils/table/table-filters/filters-model';
import { getDeleteChannelDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';

@Component({
  selector: 'rnb-my-channels-table',
  templateUrl: './my-channels-table.container.html',
  styleUrls: ['./my-channels-table.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyChannelsTableContainer implements OnInit, OnDestroy {
  @ViewChild('table') tableRef: TableComponent;
  subs: SubSink = new SubSink();

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }
  
  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  columns: Array<string> = [ 'name', 'role', 'owners', 'managers', 'publishers', 'creationDate'];

  bulkPredicate = (channel: Channel) => {
    return this.isAdmin || this.isSuperAdmin || channel.role === 'Owner';
  }

  channels: Array<Partial<Channel>> = []; 
  channels$: Observable<Array<Partial<Channel>>> = this.store$.pipe(select(fromTable.selectMyChannelsData));
  
  pagination$: Observable<Pagination> = this.store$.pipe(select(fromTable.selectMyChannelsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromTable.selectMyChannelsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromTable.selectMyChannelsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromTable.selectMyChannelsDefaultSort));

  filters$: Observable<ChannelTableFilters> = this.store$.pipe(select(fromTable.selectMyChannelsFilters));
  defaultFilters$: Observable<ChannelTableFilters> = this.store$.pipe(select(fromTable.selectMyChannelsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromTable.selectMyChannelsSearch));

  constructor(
    private store$: Store<fromTable.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  canEditChannel(channel: Channel) {
    return this.isAdmin || this.isSuperAdmin || channel.role === 'Manager' || channel.role === 'Owner';
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

  }

  filtersChanged(filters: ChannelTableFilters) {
    this.store$.dispatch(fromTable.changeMyChannelsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromTable.changeMyChannelsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromTable.changeMyChannelsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }
  
  selectionChanged(event: SelectionChange<Channel>) {
  }

  deleteChannels(selection: SelectionModel<Channel>) {
    const ids = selection.selected.map(c => c.id);
    const data: ConfirmDialogData = getDeleteChannelDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    this.subs.sink = dialog.afterClosed().pipe(
      filter(ids => !!ids)
    ).subscribe(data => {
      this.store$.dispatch(fromTable.deleteMyChannelsRequest({ids: data.ids}))
    });
  }

  deleteChannel(id: string, name: string) {
    const ids = [+id]
    const data: ConfirmDialogData = getDeleteChannelDialog(ids, name);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    this.subs.sink = dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromTable.deleteMyChannelsRequest({ids: data.ids})));
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    switch (linkType) {
      case 'route':
        this.store$.dispatch(fromRouter.go({path: link, queryParams: {}}));
        break;
      case 'url':
        this.store$.dispatch(fromRouter.goOutsideInNewTab({url: link, redirect: true}))
        break;
    }
  }
}
