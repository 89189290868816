<h2 rds-dialog-header>Edit template users and their roles</h2>
<div rds-dialog-content>
  <div class="row" [formGroup]="form">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Template users and their role</h4>
      <div class="row">
        <div class="col mt-5">
          <rnb-people-role-picker 
          (search)="getAutocompletes('permissions', $event)"
          (selected)="getAutocompletes('permissions', '')"
          (removed)="getAutocompletes('permissions', '')"
          [notRemovable]="[userIdentifier]"
          formControlName="permissions"
          [roles]="TEMPLATE_ROLES"
          defaultRole="viewers"
          [autocomplete]="permissionsAutocomplete$ | async">
          <rds-control-error *ngIf="form.controls.permissions.errors?.atLeastOneOwnerRequired">At least one Owner is required</rds-control-error>
        </rnb-people-role-picker>
        </div>
      </div>
    </div>
  </div>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Cancel</button>
    <button
        rds-primary-button
        [disabled]="form.get('permissions').valid === false || (initialRolesNotChanged && initialUsersNotChanged)"
        [rds-dialog-close]="{value: form.get('permissions').value, initialUsersNotChanged, initialRolesNotChanged, isMyTemplate}"
        size="m"
    >Update user permissions</button>
</div>