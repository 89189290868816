import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ImageAndTextBoxUi from './image-and-text-box-ui';
import ImageAndTextEditing from './image-and-text-editing';
import CustomBoxResizeUi from "../blocks/custom-box-resize-ui";

export default class ImageAndTextBox extends Plugin {
  static get requires() {
      return [ ImageAndTextEditing, ImageAndTextBoxUi, CustomBoxResizeUi ];
  }
}
