import {Component, ViewChild} from '@angular/core';
import { Store} from '@ngrx/store';
import { RdsMenuComponent } from '@rds/angular-components';
import { environment } from 'src/environments/environment';

export interface AppMenuTreeItem {
  link: string;
  icon: string;
  route?: string;
  url?: string;
}

export interface AppMenuTreeNode {
  item: AppMenuTreeItem;
  selected ? : boolean;
  children ? : Array<AppMenuTreeNode>;
}

export interface AppMenuFlatTreeNode {
  selected: boolean;
  expandable: boolean;
  item: AppMenuTreeItem;
  level: number;
}

@Component({
  selector: 'rnb-header-app-menu',
  templateUrl: './header-app-menu.container.html',
  styleUrls: ['./header-app-menu.container.scss'],
})
export class HeaderAppMenuContainer {
  @ViewChild("menu", {static: true}) menu: RdsMenuComponent;

  newsBoardLink = environment.newsBoardLink;
  rocheHomeLink = environment.rocheHomeLink;

  constructor() {
  }

}
