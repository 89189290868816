import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ImageAndTextBoxCommand from './image-and-text-box-command';
import CustomBoxResize from "../blocks/custom-box-resize-command";
import CustomBoxImageAlignmentCommand from "../blocks/custom-box-image-alignment-command";

export default class ImageAndTextEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        this._defineSchema();
        this._defineConverters();


        this.editor.commands.add('imageAndTextBox', new ImageAndTextBoxCommand(this.editor));
        this.editor.commands.add('imageAndTextBoxAlignment', new CustomBoxImageAlignmentCommand(this.editor));
        this.editor.commands.add('customBoxResize', new CustomBoxResize(this.editor));

    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('imageAndTextBox', {
            // Behaves like a self-contained object (e.g. an image).
            inheritAllFrom: '$blockObject',

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',
            allowAttributes: ['data-image-side', 'data-image-width'],
            allowChildren: ['imageAndTextBoxText', 'customImageBox']
        });

        schema.register('imageAndTextBoxText', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'imageAndTextBox',

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        });

        schema.addChildCheck((context, childDefinition) => {

            if (context.endsWith('announcementBoxDescription') && (childDefinition.name == 'announcementBox' || childDefinition.name == 'imageAndTextBox' || childDefinition.name == 'imageAndQuoteBox' || childDefinition.name == 'imagesAndTextBox' || childDefinition.name == 'textBox' || childDefinition.name == 'movieAndTextBox' || childDefinition.name == 'imagesBox')) {
                return false;
            }
            if (context.endsWith('imageAndTextBoxText') && (childDefinition.name == 'announcementBox' || childDefinition.name == 'imageAndTextBox' || childDefinition.name == 'imageAndQuoteBox' || childDefinition.name == 'imagesAndTextBox' || childDefinition.name == 'textBox' || childDefinition.name == 'movieAndTextBox' || childDefinition.name == 'imagesBox')) {
                return false;
            }
            if (context.endsWith('movieAndTextBoxText') && (childDefinition.name == 'announcementBox' || childDefinition.name == 'imageAndTextBox' || childDefinition.name == 'imageAndQuoteBox' || childDefinition.name == 'imagesAndTextBox' || childDefinition.name == 'textBox' || childDefinition.name == 'movieAndTextBox' || childDefinition.name == 'imagesBox')) {
                return false;
            }
            if (context.endsWith('imageAndQuoteBoxText') && (childDefinition.name == 'announcementBox' || childDefinition.name == 'imageAndTextBox' || childDefinition.name == 'imageAndQuoteBox' || childDefinition.name == 'imagesAndTextBox' || childDefinition.name == 'textBox' || childDefinition.name == 'movieAndTextBox' || childDefinition.name == 'imagesBox')) {
                return false;
            }
            if (context.endsWith('textBoxText') && (childDefinition.name == 'announcementBox' || childDefinition.name == 'imageAndTextBox' || childDefinition.name == 'imageAndQuoteBox' || childDefinition.name == 'imagesAndTextBox' || childDefinition.name == 'textBox' || childDefinition.name == 'movieAndTextBox' || childDefinition.name == 'imagesBox')) {
                return false;
            }
            if (context.endsWith('imagesAndTextBoxText') && (childDefinition.name == 'announcementBox' || childDefinition.name == 'imageAndTextBox' || childDefinition.name == 'imageAndQuoteBox' || childDefinition.name == 'imagesAndTextBox' || childDefinition.name == 'textBox' || childDefinition.name == 'movieAndTextBox' || childDefinition.name == 'imagesBox')) {
                return false;
            }
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        conversion.attributeToAttribute({
            model: {
                name: 'imageAndTextBox',
                key: 'data-image-side'
            },
            view: {
                key: 'data-image-side'
            }
        });
        conversion.attributeToAttribute({
            model: {
                name: 'imageAndTextBox',
                key: 'data-image-width'
            },
            view: {
                key: 'data-image-width'
            }
        });
        conversion.for('upcast').elementToElement({
            model: 'imageAndTextBox',
            view: {
                name: 'div',
                classes: ['image-and-text-box']
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'imageAndTextBox',
            view: {
                name: 'div',
                classes: ['image-and-text-box', 'mb-6']
            }
        });


        conversion.for('editingDowncast').elementToElement({
            model: 'imageAndTextBox',
            view: (modelElement, {writer: viewWriter}) => {
                const div = viewWriter.createContainerElement('div', {class: 'py-6 my-6 image-and-text-box'});
                return toWidget(div, viewWriter, {label: 'simple box widget'});
            }
        })

        conversion.for('upcast').elementToElement({
            model: 'imageAndTextBoxText',
            view: {
                name: 'div',
                classes: 'image-and-text-box-text'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imageAndTextBoxText',
            view: {
                name: 'div',
                classes: 'image-and-text-box-text'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'imageAndTextBoxText',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement('div', {class: 'image-and-text-box-text'});
                return toWidgetEditable(div, viewWriter);
            }
        });

    }
}

export function isHtmlIncluded(dataTransfer) {
    return Array.from(dataTransfer.types).includes('text/html') && dataTransfer.getData('text/html') !== '';
}
