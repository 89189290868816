import { Injectable } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable, of, Subject } from 'rxjs';

import { TourService as TourServiceModule } from 'ngx-tour-md-menu';

import { TourStep, TourData, TourDialogData } from '../types';

import { TourDialogComponent } from './tour-dialog/tour-dialog.component';
import { tours } from './tour.data';

import { AuthService } from '../auth/auth.service';
import { ApiClient } from '../api-client.service';

const COMPLETED_TOURS = 'completedTours';

@Injectable({
  providedIn: 'root'
})
export class TourService {
  private _isTourActive = new Subject<{ active: boolean; tourId: string }>();
  private _completedTours = [];
  private _tours = tours;
  private _currentTour: TourData;
  private _stepDefaults = {
    enableBackdrop: true,
    preventScrolling: true,
    prevBtnTitle: 'Back',
    nextBtnTitle: 'Next',
    endBtnTitle: ' Got it!'
  };

  get isTourActive(): Observable<{ active: boolean; tourId: string }> {
    return this._isTourActive.asObservable();
  }

  constructor(
    private tourService: TourServiceModule,
    private dialog: MatDialog,
    private auth: AuthService,
    private client: ApiClient
  ) {
    this.tourService.end$.subscribe(() => {
      this._isTourActive.next({ active: false, tourId: this._currentTour.tourId });
    });
    this.fetchCompletedTours().subscribe((data) => {
      this._completedTours = data;
    });
  }

  public start(id: string): void {
    if (!id) {
      return;
    }
    this.init(id);
    let dialogRef: MatDialogRef<TourDialogComponent>;
    const data = this.getIntroData();
    const dialogConfig = TourDialogComponent.dialogDefaults[data.dialogType];
    dialogRef = this.dialog.open(TourDialogComponent, {
      ...dialogConfig,
      data
    });
    dialogRef.afterClosed().subscribe((start) => {
      this.pushCompletedTour(id);
      if (!start) {
        return;
      }
      if (this.hasSteps()) {
        this.startTour();
      } else {
        this.startVideoTutorial();
      }
    });
  }

  public autoStart(id: string) {
    // todo: refactor after persisted on user object
    if (!this._completedTours.includes(id)) {
      setTimeout(() => this.start(id), 600);
    }
  }

  private getTourById(id: string): TourData {
    return this._tours.find((tour) => tour.tourId === id);
  }

  public canReplay(id: string): boolean {
    return !!this.getTourById(id)?.canReplay;
  }

  private init(tourId: string): void {
    const tourData = this.getTourById(tourId);

    if (!tourData) {
      throw new Error('invalid tour id provided: ' + tourId);
    }

    this._currentTour = tourData;
  }

  private hasSteps(): boolean {
    return this.getSteps().length > 0;
  }

  private getSteps(): Array<TourStep> {
    if (!this._currentTour?.steps) {
      return [];
    }
    return this._currentTour.steps.filter(({ anchorId }) => Object.keys(this.tourService.anchors).includes(anchorId));
  }

  private getIntroData(): TourDialogData {
    if (!this._currentTour.intro) {
      return null;
    }
    const { title, ...rest }: TourDialogData = this._currentTour.intro;
    const name = this.auth.currentUser.name;
    return {
      title: title.replace('{{name}}', name),
      ...rest
    };
  }

  private startVideoTutorial(): void {
    const data = this._currentTour.tutorial;
    if (data) {
      const dialogConfig = TourDialogComponent.dialogDefaults[data.dialogType];
      this.dialog.open(TourDialogComponent, {
        ...dialogConfig,
        data
      });
    }
  }

  private startTour(): void {
    this._isTourActive.next({ active: true, tourId: this._currentTour.tourId });

    // wait for dom updates to finish
    setTimeout(() => {
      this.tourService.initialize(this.getSteps(), this._stepDefaults);
      this.tourService.start();
    }, 100);
  }

  private fetchCompletedTours(): Observable<Array<string>> {
    const result = JSON.parse(localStorage.getItem(COMPLETED_TOURS)) || [];
    return of(result);
    // todo: create backend call
    // const identifier = this.auth.currentUser.identifier;
    // return this.client.get(`/user/${identifier}/tours`);
  }

  private pushCompletedTour(tourId: string): void {
    this._completedTours.push(tourId);
    this._completedTours = [...new Set(this._completedTours)];
    localStorage.setItem(COMPLETED_TOURS, JSON.stringify(this._completedTours));
    // todo: create backend call
    // fire and forget
    // const identifier = this.auth.currentUser.identifier;
    // this.client.post(`/user/${identifier}/update_tours`, this._completedTours);
  }
}
