<ng-template #formActions>
    <button rds-secondary-button size="l" (click)="openPreviewDialog()">Preview</button>
    <div [rdsTooltip]="disableSaveTemplateTooltip$ | async" [rdsTooltipDisabled]="(canSaveTemplate$ | async) === true">
      <button rds-primary-button  [disabled]="(canSaveTemplate$ | async) === false" size="l" (click)="!id ? saveTemplate() : updateTemplate(id)">{{!id ? 'Save template' : 'Update template'}}</button>
    </div>
</ng-template>
<div class="row">
  <div class="col lmy-3">
    <rnb-back-button [leavePageWarning]="true" [changesMade]="changesMade$ | async" defaultLabel="Add News" defaultRoute="news/create"></rnb-back-button>
  </div>
</div>
<rnb-view-bar [hideAddNews]="true">
  <span title>
    <span *ngIf="!!id">
      Edit template
    </span>
    <span *ngIf="!id">
      Create template
    </span>
  </span>
  <ng-container *ngTemplateOutlet="formActions"></ng-container>
</rnb-view-bar>
<div class="row">
  <div class="col mb-7">
    <rds-stepper  #stepper [linear]="false" orientation="vertical" [sideBySide]="true">
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            General information
            <atr-form-status-badge [status]="(generalStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="1"
            stepName="General information"
            [stepStatus]="(generalStatus$ | async)">
            <rnb-template-form-general-information [isActive]="stepper.selectedIndex === 0"></rnb-template-form-general-information>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>

          </rnb-step-content>
      </ng-template>
      </rds-step>
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            Content
            <atr-form-status-badge [status]="(contentStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="2"
            stepName="Content"
            [stepStatus]="(contentStatus$ | async)">
            <button actions rds-secondary-button size="m" (click)="openAddLanguageDialog()">Add language version</button>
            <rnb-template-form-content [isActive]="stepper.selectedIndex === 1"></rnb-template-form-content>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>
          </rnb-step-content>
      </ng-template>
      </rds-step>
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            <div>
              Share template <span class="ui-label-s">(optional)</span>
            </div>
            <atr-form-status-badge [status]="(permissionsStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="3"
            stepName="Share template"
            [stepStatus]="(permissionsStatus$ | async)">
            <rnb-template-form-assign-editors-and-owners [isActive]="stepper.selectedIndex === 2"></rnb-template-form-assign-editors-and-owners>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>
          </rnb-step-content>
      </ng-template>
      </rds-step>
    </rds-stepper>
    <ng-template #navigation>
      <button
        *ngIf="stepper.selectedIndex > 0"
        rds-text-button
        (click)="stepper.previous()"
      >
        <rds-icon namespace="filled" icon="arrow_left"></rds-icon>
        Previous
      </button>
      <button
          *ngIf="stepper.selectedIndex < stepper.steps.length -1"
          style="margin-left: auto"
          rds-text-button
          (click)="stepper.next()"
    
        >
        Next
        <rds-icon namespace="filled" icon="arrow_right"></rds-icon>
      </button>
    </ng-template>
  </div>
</div>

<rnb-view-bar [hideAddNews]="true">
  <ng-container *ngTemplateOutlet="formActions"></ng-container>
</rnb-view-bar>
