import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import * as fromHeaderActions from '../../store/header/header.actions';
import {RdsDropdownDirective, RdsHeaderSearchbarComponent, RdsMenuTriggerDirective} from '@rds/angular-components';
import * as fromHeader from '../../store/header'
import {Observable} from 'rxjs';
import * as fromUiScreen from '../../../root-store/ui/screen';
import {tap} from 'rxjs/operators';
import * as fromRouter from '../../../root-store/router';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rnb-header2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class HeaderComponents2 implements OnInit, OnDestroy {
  @HostBinding('style.top') top: string = '0';
  @HostBinding('style.opacity') opacity: string = '1';

  @HostListener('window:scroll')
  scrolled() {
    if (this.scrollPosition > this.window.scrollY && document.getElementsByTagName('html').item(0).className !=='cdk-global-scrollblock') {
      this.top = '0';
      this.opacity = '1'
    } else if (this.window.scrollY > 96) {
      this.top = '-98px';
      this.opacity = '0';
      this.gcsMenuTrigger?.closeMenu();
      this.appMenuTrigger?.closeMenu();
    }
    this.scrollPosition = this.window.scrollY;
  }

  @ViewChild("searchBar", {static: true}) searchBar: RdsHeaderSearchbarComponent;
  @ViewChild("gcsMenuTrigger", {static: true}) gcsMenuTrigger: RdsMenuTriggerDirective;
  @ViewChild("appMenuTrigger", {static: true}) appMenuTrigger: RdsMenuTriggerDirective;

  scrollPosition = this.window.scrollY;

  private subs: SubSink = new SubSink();

  public isMobile: true | false;
  public isMobile$: Observable<boolean> = this.store$.pipe(
    select(fromUiScreen.selectMobileSetting), tap((res) => this.isMobile = res));

  public newsBoardLink: string;

  ngOnInit(): void {
    this.setRdsInputHooks();
    this.newsBoardLink = environment.newsHomeLink;
    this.subs.sink = this.activatedRoute.queryParams.subscribe(params => {
      if (params.phrase) {
        this.searchBar.searchValue = params.phrase;
        (this.searchBar.searchInput.nativeElement as HTMLInputElement).value = params.phrase;
        this.store$.dispatch(fromHeaderActions.setPhrase({phrase: params.phrase}));
      } else {
        this.searchBar.searchValue = '';
        (this.searchBar.searchInput.nativeElement as HTMLInputElement).value = '';
      }
    })

  }

  logoClick() {
    this.store$.dispatch(fromRouter.goOutsideInCurrentTab({url: environment.rocheHomeLink}));
  }

  setRdsInputHooks() {
    (this.searchBar.searchInput.nativeElement as HTMLInputElement).onfocus = () => {
      this.store$.dispatch(fromHeaderActions.searchFocused());
    }
    (this.searchBar.searchInput.nativeElement as HTMLInputElement).onblur = () => {
      this.store$.dispatch(fromHeaderActions.searchBlurred());
    }
  }

  setScrollDisabled(disabled: boolean) {
    const className = 'disabled-scroll';
    if (disabled) {
      document.body.className = className;
    } else {
      if (document.body.className.indexOf(className) > -1) {
        document.body.className = document.body.className.replace(className, '');
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  search(phrase: string) {
    this.store$.dispatch(fromHeaderActions.goToSearch({phrase}));
  }

  timedOutCloser;

  itemEntered(trigger: RdsMenuTriggerDirective) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
  }

  holdMenuOpened(trigger: RdsMenuTriggerDirective) {
    trigger.openMenu();
  }

  constructor(private window: Window, private store$: Store, private activatedRoute: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) {
  }
}
