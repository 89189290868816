import {FileDialogButtonView} from 'ckeditor5/src/upload';
import {ButtonView} from 'ckeditor5/src/ui';
import {iconsMap} from "./blocks-icons-map";

export function createNewButtons(context, options) {
    options.forEach(option => {
        switch (option.type) {
            case 'dialog': {
                if (option.name === 'upload') {
                    uploadButton(context, option)
                }

                break;
            }
            case 'action': {
                addButton(context, option)
                break;
            }
        }
    })
}

export function uploadButton(context, option) {
    const editor = context.editor;
    const t = editor.t;
    editor.ui.componentFactory.add(`customImageUpload`, locale => {
        const view = new FileDialogButtonView(locale);
        const imageTypes = editor.config.get('image.upload.types');
        const imageTypesRegExp = createImageTypeRegExp(imageTypes);

        view.set({
            acceptedType: imageTypes.map(type => `image/${type}`).join(','),
            allowMultipleFiles: false
        });
        view.buttonView.set({
            label: t('Change image'),
            icon: iconsMap.get('upload'),
            tooltip: true
        });
        view.on('done', (evt, files) => {
            const imagesToUpload = Array.from(files).filter(file => imageTypesRegExp.test(file.type));
            if (imagesToUpload.length) {
                editor.execute(option.command, {file: imagesToUpload});
                editor.editing.view.focus();
            }
        })
        return view;
    });
}

export function addButton(context, option) {
    const editor = context.editor;
    let fullCommand = option.command;
    if (option.commandValue) {
        fullCommand += `:${option.commandValue}`
    }
    editor.ui.componentFactory.add(fullCommand, locale => {
        const command = editor.commands.get(option.command);
        const buttonView = new ButtonView(locale);

        buttonView.set({
            label: option.label || option.name,
            icon: iconsMap.get(option.name),
            tooltip: true,
            isToggleable: true
        });

        // Bind button model to command.
        buttonView.bind('isEnabled').to(command);
        buttonView.bind('isOn').to(command, 'value', value => {
            if(option.commandValue){
                return value === option.commandValue
            }
            return  value === option
        });

        // Execute command.
        context.listenTo(buttonView, 'execute', () => {
            editor.execute(option.command, {value: option?.commandValue});
            editor.editing.view.focus();
        });

        return buttonView;
    });

}


function createImageTypeRegExp(types) {
    // Sanitize the MIME type name which may include: "+", "-" or ".".
    const regExpSafeNames = types.map(type => type.replace('+', '\\+'));

    return new RegExp(`^image\\/(${ regExpSafeNames.join( '|' ) })$`);
}

export const BlocksNames = [
    'announcementBox',
    'imageAndQuoteBox',
    'imagesBox',
    'imageAndTextBox',
    'imagesAndTextBox',
    'movieAndTextBox',
    'textBox'
]

export function checkIsCustomBlock(name) {
    return BlocksNames.includes(name)
}
