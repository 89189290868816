import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import DividerCommand from './divider-command';

export default class DividerEditing extends Plugin {
	static get requires() {
		return [Widget];
	}
	init() {
		const editor = this.editor;
		const schema = editor.model.schema;
		const t = editor.t;
		const conversion = editor.conversion;

		schema.register( 'divider', {
			inheritAllFrom: '$blockObject',
			allowIn: ['$root', '$container']
		} );

		editor.commands.add( 'divider', new DividerCommand( editor ) );

		conversion.for( 'dataDowncast' ).elementToElement( {
			model: 'divider',
			view: ( modelElement, { writer } ) => {
				return writer.createUIElement( 'div' , {class: 'rds-divider mb-9 pt-5'},
					function (domDocument) {
						const domElement = this.toDomElement(domDocument);
						return domElement;
					});
			}
		} );

		conversion.for( 'editingDowncast' ).elementToStructure( {
			model: 'divider',
			view: ( modelElement, { writer } ) => {
				const label = t( 'Divider' );

				const viewWrapper = writer.createContainerElement( 'div', {class: 'py-9'},
					writer.createUIElement( 'div' , {class: 'rds-divider'},
						function (domDocument) {
						const domElement = this.toDomElement(domDocument);
						return domElement;
					})
				);

				writer.setCustomProperty( 'div', true, viewWrapper );

				return toHorizontalLineWidget( viewWrapper, writer, label );
			}
		} );
		conversion.for( 'upcast' ).elementToElement({
			view: {
				name: 'div',
				classes: ['rds-divider', 'mb-9', 'pt-5']
			},
			model: 'divider'
		})
	}
}

// Converts a given {@link module:engine/view/element~Element} to a horizontal line widget:
// * Adds a {@link module:engine/view/element~Element#_setCustomProperty custom property} allowing to
//   recognize the horizontal line widget element.
// * Calls the {@link module:widget/utils~toWidget} function with the proper element's label creator.
//
//  @param {module:engine/view/element~Element} viewElement
//  @param {module:engine/view/downcastwriter~DowncastWriter} writer An instance of the view writer.
//  @param {String} label The element's label.
//  @returns {module:engine/view/element~Element}
function toHorizontalLineWidget( viewElement, writer, label ) {
	writer.setCustomProperty( 'divider', true, viewElement );

	return toWidget( viewElement, writer, { label } );
}
