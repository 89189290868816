import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';
import * as fromHeader from './header/header.reducer';

export const feature = 'header';

export interface State {
  header: fromHeader.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    header: fromHeader.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getHeader = (state: State) => state.header;


