import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { TourModule } from '../tour/tour.module';
import { RdsModule } from '../rds.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { feature, reducer } from './store/news.reducer';
import { NewsRoutingModule } from './news-routing.module';
import { MatSelectModule } from '@angular/material/select';
import { NewsTableEffects } from './store/news-table';
import { NewsPageComponent } from './components/single-channel/news-page/news-page.component';
import { MyNewsTableContainer } from './components/single-channel/tables/my-news-table/my-news-table.container';
import { MySuggestedNewsTableContainer } from './components/single-channel/tables/my-suggested-news-table/my-suggested-news-table.container';
import { MyArchivedNewsTableContainer } from './components/single-channel/tables/my-archived-news-table/my-archived-news-table.container';
import { AllNewsTableContainer } from './components/single-channel/tables/all-news-table/all-news-table.container';
import { CreateNewsDashboardComponent } from './components/create-news-dashboard/create-news-dashboard.component';
import { NewsFormComponent } from './components/news-form/news-form.component';
import { StepContentComponent } from './components/news-form/step-content/step-content.component';
import { FormStatusBadgeComponent } from './components/news-form/form-status-badge/form-status-badge.component';
import { FormGeneralInformationComponent } from './components/news-form/form-general-information/form-general-information.component';
import { FormContentComponent } from './components/news-form/form-content/form-content.component';
import { FormPublishingInfoComponent } from './components/news-form/form-publishing-info/form-publishing-info.component';
import { FormAssignEditorsAndOwnersComponent } from './components/news-form/form-assign-editors-and-owners/form-assign-editors-and-owners.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormEffects } from './store/form';
import { RelatedNewsEffects } from './store/related-news';
import { TemplatesComponent } from './components/create-news-dashboard/templates/templates.component';
import { TemplateFormEffects } from './store/template-form';
import { TemplateFormGeneralInformationComponent } from './components/template-form/template-form-general-information/template-form-general-information.component';
import { TemplateFormContentComponent } from './components/template-form/template-form-content/template-form-content.component';
import { TemplateFormAssignEditorsAndOwnersComponent } from './components/template-form/template-form-assign-editors-and-owners/template-form-assign-editors-and-owners.component';
import { TemplateFormComponent } from './components/template-form/template-form.component';
import { TemplatesEffects } from './store/templates';

@NgModule({
  declarations: [
    NewsPageComponent,
    MyNewsTableContainer,
    AllNewsTableContainer,
    MySuggestedNewsTableContainer,
    MyArchivedNewsTableContainer,
    CreateNewsDashboardComponent,
    NewsFormComponent,
    StepContentComponent,
    FormStatusBadgeComponent,
    FormGeneralInformationComponent,
    FormContentComponent,
    FormPublishingInfoComponent,
    FormAssignEditorsAndOwnersComponent,
    TemplatesComponent,
    TemplateFormComponent,
    TemplateFormGeneralInformationComponent,
    TemplateFormContentComponent,
    TemplateFormAssignEditorsAndOwnersComponent,
  ],
  imports: [
    CommonModule,
    RdsModule,
    UtilsModule,
    TourModule,
    MatSelectModule,
    NewsRoutingModule,
    CKEditorModule,
    StoreModule.forFeature(feature, reducer),
    EffectsModule.forFeature([NewsTableEffects, FormEffects, TemplateFormEffects, RelatedNewsEffects, TemplatesEffects]),
  ],
  providers: []
})
export class NewsModule {}
