<ng-container [formGroup]="form">
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Title</h4>
      <div class="row" [formGroup]="titleForm">
        <div class="col mt-5">
          <rds-form-field>
            <rds-control-label>Title of the News</rds-control-label>
            <input formControlName="title" rdsInput required/>
            <rds-control-hint>Provide a title to be able to save your draft</rds-control-hint>
            <rds-control-hint class="right">{{titleForm.get('title').value?.length || 0}}/100</rds-control-hint>
            <rds-control-error *ngIf="titleForm.get('title').hasError('required') && titleForm.get('title').touched">This field is required</rds-control-error>
            <rds-control-error *ngIf="titleForm.get('title').hasError('maxlength') && titleForm.get('title').touched">Maximum number of characters exceeded</rds-control-error>
            <rds-control-error *ngIf="(titleForm.get('title').hasError('required') || titleForm.get('title').hasError('maxlength')) && titleForm.get('title').touched" class="right">{{titleForm.get('title').value?.length || 0}}/100</rds-control-error>
          </rds-form-field>
        </div>
      </div>
      <rds-divider class="mt-5"></rds-divider>
    </div>
  </div>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Author <span class="ui-label-l">(optional)</span></h4>
      <div class="row">
        <div class="col mt-5">
          <rnb-people-picker 
          (search)="getAutocompletes('author', $event)"
          [multi]="false"
          formControlName="authors"
          [autocomplete]="authorAutocomplete$ | async"></rnb-people-picker>
        </div>
      </div>
      <rds-divider class="mt-5"></rds-divider>
    </div>
  </div>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Author <span class="ui-label-l">(optional)</span></h4>
      <div class="row">
        <div class="col mt-5">
          <rnb-people-picker 
          (search)="getAutocompletes('author', $event)"
          [multi]="false"
          formControlName="authors"
          [autocomplete]="authorAutocomplete$ | async"></rnb-people-picker>
        </div>
      </div>
      <rds-divider class="mt-5"></rds-divider>
    </div>
  </div>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Publisher</h4>
      <p class="ui-label-m ">The person or a group responsible for publishing the news. </p>
      <div class="row">
        <div class="col mt-5">
          <rds-form-field>
            <rds-control-label>Publishers Name</rds-control-label>
            <input formControlName="byline" rdsInput required/>
            <rds-control-error *ngIf="form.get('byline').hasError('required')">This field is required</rds-control-error>
          </rds-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col mt-5">
          <rds-form-field>
            <rds-control-label>Publishers email</rds-control-label>
            <input formControlName="contact" rdsInput required/>
            <rds-control-error *ngIf="form.get('contact').hasError('required')">This field is required</rds-control-error>
          </rds-form-field>
        </div>
      </div>
      <rds-divider class="mt-5"></rds-divider>
    </div>
  </div>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Primary language</h4>
      <p class="ui-label-m mb-5">Additional langauge versions can be added in Content section</p>
      <div class="row">
        <div class="col-4 mb-5">
          <rds-form-field
          ><rds-control-label>Primary language</rds-control-label>
          <rds-select
            #select
            formControlName="language"
            (valueChange)="select.resetFilterValue()"
            [filterBy]="filterBy"
          >
            <rds-select-search-input #searchInput></rds-select-search-input>
            <rds-select-option-separator></rds-select-option-separator>
            <rds-select-option *ngFor="let language of languages$ | async" [value]="language.code" [id]="language.name" [innerHTML]="language.name | highlight:searchInput.control.value"></rds-select-option>
          </rds-select>
        </rds-form-field>
        </div>
      </div>
      <rds-divider *ngIf="isAdmin && form.controls.type.value === 'Embedded'" class="mt-5"></rds-divider>
    </div>
  </div>
  <div *ngIf="isAdmin && form.controls.type.value === 'Embedded'" class="row">
    <div class="col mt-9">
      <div class="row row-centered">
        <div class="col-auto pe-2">
          <span class="ui-label-m-bold">
            <h4 class="ui-heading-4">Comments</h4>
          </span>
        </div>
        <div class="col-auto ps-2">
          <ng-template #tooltipTemplate>
            <div [innerHtml]="'If comments are disabled for already published articles,<br/> they will remain visible but the edit and reply options will be hidden.'">
            </div>
          </ng-template>
        <rds-icon [rdsTooltip]="tooltipTemplate" icon="info" namespace="outlined"></rds-icon>
          </div>
      </div>
      <div class="row">
        <div class="col mt-5">
          <rds-switch formControlName="commentsEnabled">Enable comments for this News</rds-switch>
        </div>
      </div>
    </div>
  </div>
</ng-container>
