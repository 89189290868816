import {Plugin} from 'ckeditor5/src/core';
import {isWidget, WidgetToolbarRepository} from 'ckeditor5/src/widget';
import {createNewButtons} from "../blocks/blocks-utils";

export default class AnnouncementBoxToolbar extends Plugin {

    static get requires() {
        return [WidgetToolbarRepository];
    }

    static get pluginName() {
        return 'AnnouncementBoxToolbar';
    }


    afterInit() {
        const editor = this.editor;
        const t = editor.t;
        const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);

        const options = [
            {
                name: 'paLeft',
                label: 'Image on the left side',
                command: 'announcementAlignment',
                commandValue: 'left',
                type: 'action'
            },
            {
                name: 'paRight',
                label: 'Image on the right side',
                command: 'announcementAlignment',
                commandValue: 'right',
                type: 'action'
            }
        ]
        createNewButtons(this, options);
        const optionsCommand = options.map(option => {
            if (option.commandValue) {
                return `${option.command}:${option.commandValue}`;
            } else {
                return option.command;
            }
        });
        if (optionsCommand) {
            widgetToolbarRepository.register('AnnouncementContent', {
                ariaLabel: t('Announcement box toolbar'),
                items: optionsCommand,
                getRelatedElement: getSelectedAnnouncementWidget,
                balloonClassName: 'ck-custom-fieldset-toolbar'
            });
        }
    }
}

export function getSelectedAnnouncementWidget(selection) {
    const viewElement = selection.getSelectedElement();

    if (viewElement && isAnnouncementWidget(viewElement)) {
        return viewElement;
    }

    return null;
}

export function getAnnouncementWidgetAncestor(selection) {
    const selectionPosition = selection.getFirstPosition();

    if (!selectionPosition) {
        return null;
    }

    let parent = selectionPosition.parent;

    while (parent) {
        if (parent.is('element') && isAnnouncementWidget(parent)) {
            return parent;
        }

        parent = parent.parent;
    }

    return null;
}

function isAnnouncementWidget(viewElement) {
    return !!viewElement.hasClass('announcement-box') && isWidget(viewElement);
}



