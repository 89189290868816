import { TourData } from '../types';

export const tours: Array<TourData> = [
  {
    tourId: 'dashboard',
    canReplay: false,
    intro: {
      title: 'Hello {{name}}',
      content: 'Welcome to Newsboard\na simple platform for news creation.',
      dialogType: 'full-page'
    },
    steps: [
      {
        anchorId: 'page-fab',
        title: 'Need help or guidance?',
        content:
          'If you need a quick guided tour in certain sections or you are looking for more information, click on the icon below and select "Take a tour" or “Help”.'
      }
    ]
  },
  {
    tourId: 'news',
    canReplay: true,
    intro: {
      title: 'My news',
      content:
        'This is your workspace. Here you can see all news that you have created and published as well as news that you are collaborating on as a News Editor.',
      dialogType: 'top-full-width'
    },
    steps: [
      {
        anchorId: 'status',
        title: 'Track the status of news',
        content:
          'Your news can have 3 status - draft, scheduled or published. To schedule or immediately publish a news, you need to set a publishing date, create content and assign/suggest to a channel.'
      },
      {
        anchorId: 'assign',
        title: 'Assign and suggest your news',
        content:
          'In order to be published, a news has to be assigned or suggested to a channel. You can use this quick link to manage the channels your news has been assigned or suggested to.'
      },
      {
        anchorId: 'more-actions',
        title: 'Manage a news',
        content: 'Click on "More actions" to manage a news. Here you can edit, duplicate, archive, and delete news.'
      },
      {
        anchorId: 'filters',
        title: 'Search and apply filters',
        content:
          'Find news by searching for a news title or news owner name. You can also apply filters to narrow down your search.'
      },
      {
        anchorId: 'tabs',
        title: 'Manage tabs',
        content:
          'In My News, you will find all your news you are working on either as a News Owner or New Editor. In My Suggested News, you can track whether your news suggestions were approved or rejected by a channel. My Archived News is an inventory of news you have archived.'
      }
    ]
  },
  {
    tourId: 'channels',
    canReplay: true,
    intro: {
      title: 'Channels',
      content:
        'In My Channels tab, you will find all channels in which you have the role of Channel Owner, Manager, or Publisher. You can click on the channel title to enter the channel.\nIn the All Channels tab, you will find all channels on Newsboard.',
      dialogType: 'top-full-width'
    },
    steps: [
      {
        anchorId: 'create-channel',
        title: 'Create new channels',
        content:
          'You can create a new channel, assign roles to it, and assign the audience that your channel will be dedicated to./suggest to a channel.'
      },
      {
        anchorId: 'channel-publisher-role',
        title: 'Publisher role',
        content: 'As a Publisher, you can directly assign your news to the channel without any approval workflow.'
      },
      {
        anchorId: 'channel-manager-role',
        title: 'Channel Manager role',
        content:
          'As a Channel Manager, you can manage news within the channel including removing news, accepting or rejecting news suggestions. You can also assign other Managers and Publishers.'
      },
      {
        anchorId: 'channel-owner-role',
        title: 'Channel owner role',
        content:
          'As a Channel Owner, you can edit channel settings, assign news roles or even delete the entire channel.'
      },
      {
        anchorId: 'filters',
        title: 'Search and apply filters',
        content:
          'Find news by searching for a news title or news owner name. You can also apply filters to narrow down your search.'
      },
      {
        anchorId: 'tabs',
        title: 'Manage tabs',
        content:
          'In My Channels tab, you will find all channels in which you have the role of Channel Owner, Manager, or Publisher. In the All Channels tab, you will find all channels on Newsboard.'
      }
    ]
  },
  {
    tourId: 'channel-id',
    canReplay: true,
    intro: {
      title: 'Individual Channels',
      content:
        'As a Publisher, you can directly assign your news to the channel. As a Channel Manager, you can manage news within the channel including removing news, accepting or rejecting news suggestions. You can also assign other Managers and Publishers.\nAs a Channel Owner, you can edit or even delete the channel.',
      dialogType: 'top-full-width'
    },
    steps: [
      {
        anchorId: 'edit-channel',
        title: 'Edit Channel',
        content:
          'Click on the icon to edit the channel settings including changing the title and source. You can also assign roles and specify the audience. Publishers in channels will be able to access all channel information without the option to edit.'
      },
      {
        anchorId: 'channel-info',
        title: 'Information about the channel',
        content:
          'Click on the icon to see more information about the channel settings such as assigned roles and audiences.'
      },
      {
        anchorId: 'more-actions',
        title: 'Manage news in channel',
        content:
          'Click on "More actions" to manage a news. Here you can view preview, duplicate or remove the news from the channel. If you are the News Owner or News Editor of the news, you would also be able to edit it directly from here.'
      },
      {
        anchorId: 'pin-news',
        title: 'Pin news to the top',
        content:
          'Click on the icon to pin this news to the top. This news will then remain at the top of the channel until it is unpinned. You can only pin one news at the time.'
      },
      {
        anchorId: 'filters',
        title: 'Search and apply filters',
        content:
          'Find news by searching for a news title, news owner name or name of the assigner. You can also apply filters to narrow down your search.'
      },
      {
        anchorId: 'tabs',
        title: 'Manage tabs',
        content:
          'In the All News tab, you will find all news that have been assigned to the channel. Check the News Suggestions tab to approve or reject the news that have been suggested to this channel. In the Archived News tab, you will find news that have been archived by the News Owner or Editor.'
      }
    ]
  },
  {
    tourId: 'create-news-link',
    canReplay: true,
    intro: {
      title: 'Add News via Link',
      content:
        'You can add news via a link in just 4 steps. Once you are done with a step, click “Next” or simply click on the next step. You need to assign or suggest the news to at least one channel to be able to publish the news.',
      dialogType: 'top-full-width'
    },
    tutorial: {
      videoUrl: '/assets/tour/CREATE_NEWS_VIA_LINK.mp4',
      dialogType: 'default'
    }
  },
  {
    tourId: 'create-news-content',
    canReplay: true,
    intro: {
      title: 'Create News in Editor',
      content:
        'You can create news in the editor in just 5 steps. Once you are done with a step, click “Next” or simply click on the next step. You need to assign or suggest the news to at least one channel to be able to publish the news.',
      dialogType: 'top-full-width'
    },
    tutorial: {
      videoUrl: '/assets/tour/CREATE NEWS VIA EDITOR.mp4',
      dialogType: 'default'
    }
  } /*,
  {
    tourId: 'channel-create',
    canReplay: true,
    intro: {
      title: 'Create Channel',
      content:
        '...',
      dialogType: 'top-full-width'
    },
    tutorial: {
      videoUrl: '/assets/tour/roche-newsroom--news-create.mp4',
      dialogType: 'default'
    }
  }*/
];
