import { createSelector } from '@ngrx/store';
import * as fromSerializer from './router.serializer';

export const selectState = createSelector(
  fromSerializer.selectRouterState,
  state => state.state
);

export const selectUrl = createSelector(
  selectState,
  state => state.url
);

export const selectParams = createSelector(
  selectState,
  state => state.params
);

export const selectQueryParams = createSelector(
  selectState,
  state => state.queryParams
);

export const selectQueryParamId = createSelector(
  selectQueryParams,
  state => state.id
);

