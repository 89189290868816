import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, of } from 'rxjs';

import { ApiClient } from '../api-client.service';

import { Language } from '../types';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _languages = new ReplaySubject<Language[]>(1);

  private _initialized = false;

  get languages(): Observable<Language[]> {
    this._loadLanguages();
    return this._languages.asObservable();
  }

  constructor(private client: ApiClient) {}

  getLanguages() {
    return this.client.get('/languages');
  }
  
  private _loadLanguages(): void {
    if (!this._initialized) {
      this.client.get('/languages').subscribe((data: Language[]) => {
        this._languages.next(data);
        this._initialized = true;
      });
    }
  }
}
