<ng-container [formGroup]="form">
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">News users and their role</h4>
      <div class="row">
        <div class="col mt-5">
          <rnb-people-role-picker 
          (search)="getAutocompletes('newsPermissions', $event)"
          (selected)="getAutocompletes('newsPermissions', '')"
          (removed)="getAutocompletes('newsPermissions', '')"
          [notRemovable]="[userIdentifier]"
          formControlName="permissions"
          [roles]="NEWS_ROLES"
          defaultRole="editors"
          [autocomplete]="newsPermissionsAutocomplete$ | async">
          <rds-control-error *ngIf="form.controls.permissions.errors?.atLeastOneOwnerRequired">At least one Owner is required</rds-control-error>
        </rnb-people-role-picker>
        </div>
      </div>
    </div>
  </div>
</ng-container>
