<button
  *ngIf="hasDropZone; else simpleButton"
  [class.rds-upload-drop-zone-error]="hasError && !uploader.isUploading"
  rds-upload-drop-zone
  [disabled]="disabled"
  (click)="fileInput.click()"
  (fileDropped)="onFileSelected($event)"
>
<rds-icon namespace="outlined" icon="upload"></rds-icon>
    <strong>Upload</strong>
    or drop files here
</button>
<rnb-subscript-wrapper [hasError]="hasError && !uploader.isUploading" [disabled]="disabled">
  <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
  <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rnb-subscript-wrapper>
<ng-template #simpleButton>
  <button
    rds-secondary-button
    [disabled]="disabled"
    (click)="fileInput.click()"
  >
    Choose files...
  </button>
</ng-template>

<ul>
  <li *ngIf="!!file" [class]="'rds-upload-item-size-'+size+' rds-upload-item-success'">
    <div class="rds-upload-name-and-actions">
      <div class="rds-upload-name">{{file.name}}</div>
      <div class="rds-upload-actions">
        <button rds-icon-button [size]="size === 'l' ? 'm' : 's'" (click)="clearControl()">
          <rds-icon namespace="outlined" icon="close"></rds-icon>
        </button>
      </div>
    </div>
    <rds-progress-bar mode="determinate" [value]="100" ></rds-progress-bar>
    <div class="rds-upload-size-and-state">
      <span class="rds-upload-size">{{file.size | rdsFileSize }}</span>
    </div>
  </li>
  <li *ngFor="let item of uploader.queue"
    rds-upload-list-item       
    [size]="size"
    [fileItem]="item"
    (remove)="clearQueue($event)"
  >
  </li>
</ul>

<button
  *ngIf="!uploader.config.autoUpload && uploader.queue.length"
  rds-primary-button
  [disabled]="uploader.isUploading || disabled"
  (click)="uploader.uploadAll()"
>
  Upload
</button>

<input
  #fileInput
  rds-upload
  [config]="uploader.config"
  (fileSelected)="onFileSelected($event)"
/>