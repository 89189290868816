import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import * as fromActions from './suggestions.actions';

import {Store} from '@ngrx/store';
import { RdsToastService } from '@rds/angular-components';
import { ApiClient } from '@app/api-client.service';
import { of } from 'rxjs';

@Injectable()
export class SuggestionsEffects {

  public loadUserSuggestion$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.loadSuggestion),
    filter(({suggestionType, prop, phrase}) => phrase.length > 2),
    distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
    debounceTime(200),
    map(({suggestionType, prop, phrase}) => fromActions.loadSuggestionRequest({suggestionType, prop, phrase})),
  ), {dispatch: true}
);

  public loadUserSuggestionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuggestionRequest),
      filter(({suggestionType, phrase}) => suggestionType === 'user'),
      debounceTime(200),
      switchMap(({prop, phrase}) => this.client.get(`/users/${encodeURIComponent(phrase)}`).pipe(
        map((response) => ({prop, suggestions: response})
      ))),
      map(({prop, suggestions}) => fromActions.loadSuggestionSuccess({prop, suggestions})),
      catchError(({prop, error}) => of(fromActions.loadSuggestionFailure({prop, error})))
    ), {dispatch: true}
  );

  public loadTopicSuggestionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuggestionRequest),
      filter(({suggestionType, phrase}) => suggestionType === 'topic'),
      debounceTime(200),
      switchMap(({prop, phrase}) => this.client.get(`/news/topics?phrase=${encodeURIComponent(phrase)}`).pipe(
        map((response) => ({prop, suggestions: response})
      ))),
      map(({prop, suggestions}) => fromActions.loadSuggestionSuccess({prop, suggestions})),
      catchError(({prop, error}) => of(fromActions.loadSuggestionFailure({prop, error})))
    ), {dispatch: true}
  );

  public clearSuggestion$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.loadSuggestion),
    filter(({suggestionType, prop, phrase}) => phrase.length === 0),
    debounceTime(200),
    map(({suggestionType, prop, phrase}) => fromActions.clearSuggestion({suggestionType, prop, phrase})),
  ), {dispatch: true}
);
  constructor(
    private actions$: Actions,
    private client: ApiClient,
    private store$: Store<any>
  ) {
  }
}
