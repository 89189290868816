<div
class="subscript-wrapper"
[ngSwitch]="hasError"
>
<div
  class="subscript subscript--error"
  *ngSwitchCase="true"
  [@transitionMessages]="subscriptAnimationState"
>
  <ng-content select="rds-control-error"></ng-content>
</div>
<div
  class="subscript subscript--hint"
  *ngSwitchCase="false"
  [@transitionMessages]="subscriptAnimationState"
>
  <ng-content select="rds-control-hint"></ng-content>
</div>
</div>
