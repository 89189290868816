import { Language } from '@app/types';
import { createAction, props } from '@ngrx/store';


export const loadLanguages = createAction(
  '[LANGUAGES DICT] load Languages'
);

export const getLanguagesRequest = createAction(
  '[LANGUAGES DICT] Get Languages Request'
);

export const getLanguagesSuccess = createAction(
  '[LANGUAGES DICT] Get Languages Success',
  props<{ languages: Language[]}>()
);

export const getLanguagesFailure = createAction(
  '[LANGUAGES DICT] Get Languages Failure',
  props<{ error: any }>()
);

export const languagesCollectionAddMany = createAction(
  '[LANGUAGES DICT] Languages collection add many.',
  props<{ languages: Language[]}>()
);
