<div class="row">
  <div class="col mb-5">
    <rds-tabs #tabs size="s">
      <rds-tab label="Predefined templates">
        <div class="row">
          <div class="col-xl-3 col-l-4 mt-5" *ngFor="let template of predefinedTemplates$ | async; trackBy: trackByFn">
            <rnb-template-card
            [predefined]="true"  
            [title]="template.title"
            [template]="template"
            >
              <button rds-secondary-button (click)="useTemplate(template, 'predefined')">Use template</button>
              <button *ngIf="!isSuperAdmin" rds-text-button (click)="openPreview(template, 'predefined')">Preview</button>
              <button *ngIf="isSuperAdmin" rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
                #trigger="rdsMenuTrigger">
                  <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
                </button>
            
                <rds-menu #menu="rdsMenu">
                  <button rds-menu-item (click)="openPreview(template, 'predefined')">
                    <span>Preview</span>
                  </button>
                  <button rds-menu-item (click)="useTemplate(template, 'predefined')">
                    <span>Use template</span>
                  </button>
                  <button rds-menu-item rnbBackButtonSet label="Add News" routeBack="news/create" [routeBackQueryParams]="{activeTab: 'predefined'}" [routeCurrent]="'/news/edit/template/'+template.id" [routerLink]="'/news/edit/template/'+template.id">
                    <span>Edit template</span>
                  </button>
                  <button rds-menu-item (click)="openRenameTemplateDialog(template, 'predefined')">
                    <span>Rename template</span>
                  </button>
                  <button rds-menu-item (click)="openDeleteTemplateDialog(template, 'predefined')">
                    <span>Delete</span>
                  </button>
                </rds-menu>
            </rnb-template-card>
          </div>
        </div>
        <ng-container *ngIf="(predefinedTemplatesPagination$ | async) as pagination">
          <div *ngIf="pagination.total === 0 || !pagination.isLast"  class="row">
            <div class="col"></div>
            <div class="col-auto mt-7">
              <button rds-text-button (click)="loadMore('predefined')">Load more</button>
            </div>
          </div>
        </ng-container>
      </rds-tab>
      <rds-tab label="My templates">
        <ng-container *ngIf="(myTemplates$ | async).length + (sentToMeTemplates$ | async).length > 0">
          <ng-container *ngIf="(sentToMeTemplates$ | async).length > 0">
            <div class="row">
              <div class="col mt-5">
                <h4 class="ui-heading-4">
                  Templates sent to me
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-l-4 mt-5" *ngFor="let template of sentToMeTemplates$ | async; trackBy: trackByFn">
                <rnb-template-card
                [title]="template.title"
                [template]="template"
                [attentionNeeded]="true"
                >
                <button rds-text-button (click)="reject(template)">Reject</button>
                <div>
                  <button rds-secondary-button class="me-5" (click)="openPreview(template, 'mytemplates')">Preview</button>
                  <button rds-secondary-button (click)="accept(template)">Accept</button>
                </div>
              </rnb-template-card>
              </div>
            </div>
            <ng-container *ngIf="(sentToMeTemplatesPagination$ | async) as pagination">
              <div *ngIf="pagination.total === 0 || !pagination.isLast"  class="row">
                <div class="col"></div>
                <div class="col-auto mt-7">
                  <button rds-text-button (click)="loadMoreSentToMe()">Load more</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="(myTemplates$ | async).length > 0">
            <ng-container *ngIf="(sentToMeTemplates$ | async).length > 0 && (myTemplates$ | async).length > 0">
              <div class="row">
                <div class="col mt-5">
                  <h4 class="ui-heading-4">
                    My Templates
                  </h4>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-xl-3 col-l-4 mt-5" *ngFor="let template of myTemplates$ | async; trackBy: trackByFn">
                <rnb-template-card
                [title]="template.title"
                [template]="template"
                >
                  <button rds-secondary-button (click)="useTemplate(template, 'mytemplates')">Use template</button>
                  <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
                  #trigger="rdsMenuTrigger">
                    <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
                  </button>
              
                  <rds-menu #menu="rdsMenu">
                    <button rds-menu-item (click)="openPreview(template, 'mytemplates')">
                      <span>Preview</span>
                    </button>
                    <button rds-menu-item (click)="useTemplate(template, 'mytemplates')">
                      <span>Use template</span>
                    </button>
                    <button rds-menu-item rnbBackButtonSet label="Add News" routeBack="news/create" [routeBackQueryParams]="{activeTab: 'mytemplates'}" [routeCurrent]="'/news/edit/template/'+template.id" [routerLink]="'/news/edit/template/'+template.id">
                      <span>Edit template</span>
                    </button>
                    <button rds-menu-item (click)="openSendTemplateCopyDialog(template)">
                      <span>Send template copy</span>
                    </button>
                    <button rds-menu-item (click)="openShareTemplateDialog(template, 'mytemplates')">
                      <span>Share template</span>
                    </button>
                    <button rds-menu-item (click)="openRenameTemplateDialog(template, 'mytemplates')">
                      <span>Rename template</span>
                    </button>
                    <button rds-menu-item (click)="openDeleteTemplateDialog(template, 'mytemplates')">
                      <span>Delete</span>
                    </button>
                  </rds-menu>
                </rnb-template-card>
              </div>
            </div>
            <ng-container *ngIf="(myTemplatesPagination$ | async) as pagination">
              <div *ngIf="pagination.total === 0 || !pagination.isLast"  class="row">
                <div class="col"></div>
                <div class="col-auto mt-7">
                  <button rds-text-button (click)="loadMore('mytemplates')">Load more</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="(myTemplates$ | async).length === 0 && (sentToMeTemplates$ | async).length === 0" class="row">
          <div class="col mt-10 mb-10">
            <rds-empty-state  size="m">
              <rds-empty-state-title>
                Looks like you don't have any templates
              </rds-empty-state-title>
              <rds-empty-state-subtitle>
              <button rds-secondary-button class="ms-auto" [routerLink]="'/news/create/template'">
                  Create template
                </button>
              </rds-empty-state-subtitle>
            </rds-empty-state>
          </div>
        </div>
      </rds-tab>
      <rds-tab label="Shared templates">
        <ng-container *ngIf="(readonlyTemplates$ | async).length + (sharedTemplates$ | async).length > 0">
          <ng-container *ngIf="(readonlyTemplates$ | async).length > 0">
            <div class="row">
              <div class="col mt-5">
                <h4 class="ui-heading-4">
                  Templates you can use
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-l-4 mt-5" *ngFor="let template of readonlyTemplates$ | async; trackBy: trackByFn">
                <rnb-template-card
                [title]="template.title"
                [template]="template"
                >
                <button rds-secondary-button (click)="useTemplate(template, 'readonly')">Use shared template</button>
                <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
                #trigger="rdsMenuTrigger">
                  <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
                </button>
            
                <rds-menu #menu="rdsMenu">
                  <button rds-menu-item (click)="openPreview(template, 'readonly')">
                    <span>Preview</span>
                  </button>
                  <button rds-menu-item (click)="useTemplate(template, 'readonly')">
                    <span>Use template</span>
                  </button>
                  <button rds-menu-item (click)="openSendTemplateCopyDialog(template)">
                    <span>Send template copy</span>
                  </button>
                </rds-menu>
              </rnb-template-card>
              </div>
            </div>
            <ng-container *ngIf="(readonlyTemplatesPagination$ | async) as pagination">
              <div *ngIf="pagination.total === 0 || !pagination.isLast"  class="row">
                <div class="col"></div>
                <div class="col-auto mt-7">
                  <button rds-text-button (click)="loadMore('readonly')">Load more</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="(sharedTemplates$ | async).length > 0">
            <div class="row">
              <div class="col mt-5">
                <h4 class="ui-heading-4">
                  Templates you can use and edit
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-l-4 mt-5" *ngFor="let template of sharedTemplates$ | async; trackBy: trackByFn">
                <rnb-template-card
                [title]="template.title"
                [template]="template"
                >
                <button rds-secondary-button (click)="useTemplate(template, 'shared')">Use shared template</button>
                  <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
                  #trigger="rdsMenuTrigger">
                    <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
                  </button>
              
                  <rds-menu #menu="rdsMenu">
                    <button rds-menu-item (click)="openPreview(template, 'shared')">
                      <span>Preview</span>
                    </button>
                    <button rds-menu-item (click)="useTemplate(template, 'shared')">
                      <span>Use template</span>
                    </button>
                    <button rds-menu-item rnbBackButtonSet label="Add News" routeBack="news/create" [routeBackQueryParams]="{activeTab: 'shared'}" [routeCurrent]="'/news/edit/template/'+template.id" [routerLink]="'/news/edit/template/'+template.id">
                      <span>Edit template</span>
                    </button>
                    <button rds-menu-item (click)="openSendTemplateCopyDialog(template)">
                      <span>Send template copy</span>
                    </button>
                    <button rds-menu-item (click)="openShareTemplateDialog(template, 'shared')">
                      <span>Share template</span>
                    </button>
                    <button rds-menu-item (click)="openRenameTemplateDialog(template, 'shared')">
                      <span>Rename template</span>
                    </button>
                    <button rds-menu-item (click)="openDeleteTemplateDialog(template, 'shared')">
                      <span>Delete</span>
                    </button>
                  </rds-menu>
                </rnb-template-card>
              </div>
            </div>
            <ng-container *ngIf="(sharedTemplatesPagination$ | async) as pagination">
              <div *ngIf="pagination.total === 0 || !pagination.isLast"  class="row">
                <div class="col"></div>
                <div class="col-auto mt-7">
                  <button rds-text-button (click)="loadMore('shared')">Load more</button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="(readonlyTemplates$ | async).length + (sharedTemplates$ | async).length === 0" class="row">
          <div class="col mt-10 mb-10">
            <rds-empty-state  size="m">
              <rds-empty-state-title>
                Looks like you don't have any shared templates
              </rds-empty-state-title>
            </rds-empty-state>
          </div>
        </div>
      </rds-tab>
    </rds-tabs>
