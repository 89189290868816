import { Component, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import * as fromRouter from '@app/root-store/router';
import * as fromScreen from '@app/root-store/ui/screen';
import * as fromDetails from '../../../store/channel-details';
import { RdsBreadcrumbItem, RdsTabsComponent } from '@rds/angular-components';
import { ChannelDetails } from '@app/types';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rnb-channel-details-page',
  templateUrl: './channel-details-page.component.html',
  styleUrls: ['./channel-details-page.component.scss']
})
export class ChannelDetailsPageComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('tabs') tabs: RdsTabsComponent;

  breadcrumbs: Array<RdsBreadcrumbItem> = [
    { text: 'NewsBoard', title: '/' },
    { text: 'Channels', title: '/channels' },
  ];

  activeTable: 'all' | 'suggestions' | 'archived' = 'all';
  
  selectIsDesktop$: Observable<boolean> = this.store$.pipe(
    select(fromScreen.selectIsDesktop),
  );

  channel$: Observable<ChannelDetails> = this.store$.pipe(
    select(fromDetails.selectChannelDetails),
    tap((channel) => this.breadcrumbs.push({ text: channel.name}))
  )
  constructor(
    private store$: Store<fromScreen.State>,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.store$.dispatch(fromDetails.initAllNews());
    this.store$.dispatch(fromDetails.initNewsSuggestions());
    this.store$.dispatch(fromDetails.initArchivedNews());
  }

  ngAfterViewInit(): void {
    this.activeTable = this.route.snapshot.firstChild.data.table;
    var tabIndex = 0;
    switch ( this.activeTable) {
      case 'all': {
        tabIndex = 0;
        break;
      }
      case 'suggestions': {
        tabIndex = 1;
        break;
      }
      case 'archived': {
        tabIndex = 2;
        break;
      }
    }
    this.tabs.selectTab(tabIndex)
}

  ngOnDestroy(): void {
      this.store$.dispatch(fromDetails.clearAll());
  }

  
  tabsClicked(tabs: RdsTabsComponent, id: number) {
    switch ( tabs.selectedIndex) {
      case 0: {
        this.store$.dispatch(fromRouter.go({path: `channel/${id}/all`, queryParams: {}}));
        break;
      }
      case 1: {
        this.store$.dispatch(fromRouter.go({path: `channel/${id}/suggestions`, queryParams: {}}));
        break;
      }
      case 2: {
        this.store$.dispatch(fromRouter.go({path: `channel/${id}/archived`, queryParams: {}}));
        break;
      }
    }
  }
  
  
  breadcrumbItemClicked(event) {
    if (event.title) {
      this.store$.dispatch(fromRouter.go({path: event.title, queryParams: {}}));
    }
  }
}
