import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';

import * as fromActions from './screen.actions';
import * as fromReducer from './screen.reducer';
import * as fromSelector from './screen.selectors';

import { switchMap, debounce, map, distinctUntilChanged, filter, mergeMap, first } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { fromEvent, timer, of, merge } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ScreenModeEnum, ScreenOrientationEnum } from 'src/app/types/screen';

@Injectable()
export class ScreenEffects {

  public resize$ = fromEvent(window, 'resize');

  private observeWeb$ = this.breakpointObserver.observe([Breakpoints.Web]);
  private observeTablet$ = this.breakpointObserver.observe([Breakpoints.Tablet]);
  private observeHandset$ = this.breakpointObserver.observe([Breakpoints.Handset]);

  private observePortrait$ = this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait]);
  private observeLandscape$ = this.breakpointObserver.observe([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape]);
  // TODO REFACTOR BREAKPOINTS
  // private observeMobile$ = this.breakpointsService.isMobile;

  public init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store$.pipe(
        select(fromSelector.selectScreenMode),
        first(),
        map(mode => mode === ScreenModeEnum.HANDSET)
      )),
      mergeMap((isMobile) => ([
        (isMobile) ? fromActions.setMobile() : fromActions.unsetMobile()
      ]))
    ), { dispatch: true }
  );

  public screenResized$ = createEffect(() =>
    merge(
      of(window.innerWidth),
      this.resize$.pipe(map((event) => (event.target as Window).innerWidth))
    ).pipe(
      debounce(() => timer(100)),
      map((event) => fromActions.screenResized({viewportWidth: event}))
    )
  );

  public breakpointChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeMode, fromActions.changeOrientation),
      switchMap(() => [
        fromActions.breakpointChanged()
      ])
    )
  );

  public screenModeChangedToWeb$ = createEffect(() =>
    this.observeWeb$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.changeMode({ mode: ScreenModeEnum.WEB}))
    ),
  );

  public screenModeChangedToTablet$ = createEffect(() =>
    this.observeTablet$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.changeMode({ mode: ScreenModeEnum.TABLET}))
    ),
  );

  public screenModeChangedToHandset$ = createEffect(() =>
    this.observeHandset$.pipe(
      filter((value) => value.matches),
      map(() => fromActions.changeMode({ mode: ScreenModeEnum.HANDSET}))
    ),
  );

  public screenOrientationChangedToPortrait$ = createEffect(() =>
    this.observePortrait$.pipe(
      filter((value) => value.matches),
      distinctUntilChanged(),
      map(() => fromActions.changeOrientation({ orientation: ScreenOrientationEnum.PORTRAIT}))
    ),
  );

  public screenOrientationChangedToLandscape$ = createEffect(() =>
    this.observeLandscape$.pipe(
      filter((value) => value.matches),
      distinctUntilChanged(),
      map(() => fromActions.changeOrientation({ orientation: ScreenOrientationEnum.LANDSCAPE}))
    ),
  );

  public setMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeMode),
      map(({ mode }) => {
        if (mode === ScreenModeEnum.HANDSET) {
          return fromActions.setMobile();
        }
        return fromActions.unsetMobile();
      })
    )
  );

  constructor(
    private actions$: Actions,
    private breakpointObserver: BreakpointObserver,
    private store$: Store<fromReducer.State>
  ) {}
}
