import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { MaterialModule } from '../material.module';

import { TourStepTemplateComponent } from './tour-step-template/tour-step-template.component';
import { TourDialogComponent } from './tour-dialog/tour-dialog.component';

@NgModule({
  declarations: [TourStepTemplateComponent, TourDialogComponent],
  imports: [CommonModule, MaterialModule, TourMatMenuModule.forRoot()],
  exports: [TourStepTemplateComponent, TourMatMenuModule]
})
export class TourModule {}
