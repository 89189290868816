import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromActions from './channel-table.actions';

import { SortDirection } from '@rds/angular-components';
import { Channel } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { ChannelTableFilters, INITIAL_ALL_CHANNELS_FILTERS, INITIAL_MY_CHANNELS_FILTERS, MY_ROLES } from '@app/utils/table/table-filters/filters-model';

export const feature = 'table';

// export interface Suggestions {
//   name: Suggestion;
//   description: Suggestion;
// }

export interface TableState {
  data: EntityState<Partial<Channel>>,
  pagination: Pagination,
  sort: {
    active: string;
    direction: SortDirection
  },
  defaultSort: {
    active: string;
    direction: SortDirection
  },
  filters: ChannelTableFilters;
  defaultFilters: ChannelTableFilters;
  isLoading: boolean;
  resetIndex: boolean;
}

export const adapter: EntityAdapter<Partial<Channel>> =
 createEntityAdapter<Partial<Channel>>({
  selectId: (e) => e.id
 });

export interface State {
  // modalForm: QuicklinksSetModalForm;
  myChannels: TableState;
  allChannels: TableState;
}

export const initialState: State = {
  myChannels: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'name',
      direction: 'asc'
    },
    defaultSort: {
      active: 'name',
      direction: 'asc'
    },
    filters: INITIAL_MY_CHANNELS_FILTERS,
    defaultFilters: INITIAL_MY_CHANNELS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  allChannels: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'name',
      direction: 'asc'
    },
    defaultSort: {
      active: 'name',
      direction: 'asc'
    },
    filters: INITIAL_ALL_CHANNELS_FILTERS,
    defaultFilters: INITIAL_ALL_CHANNELS_FILTERS,
    isLoading: false,
    resetIndex: false,
  }

};

const tableReducer = createReducer(
  initialState,
  on(fromActions.getMyChannelsRequest, (state) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      isLoading: true
    }
  })),
  on(fromActions.getMyChannelsSuccess, fromActions.getMyChannelsFailure, (state) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      isLoading: false
    }
  })),
  on(fromActions.getMyChannelsSuccess, (state, {data, pagination}) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      data: adapter.setAll(data, state.myChannels.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.deleteMyChannelsSuccess, (state) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyChannelsFilters, (state, {filters}) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeMyChannelsSort, (state, {active, direction}) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeMyChannelsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    myChannels: {
      ...state.myChannels,
      pagination: {
        ...state.myChannels.pagination,
        pageIndex,
        pageSize
      }
    }
  })),

  on(fromActions.getAllChannelsRequest, (state) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      isLoading: true
    }
  })),
  on(fromActions.getAllChannelsSuccess, fromActions.getAllChannelsFailure, (state) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      isLoading: false
    }
  })),
  on(fromActions.getAllChannelsSuccess, (state, {data, pagination}) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      data: adapter.setAll(data, state.allChannels.data),
      pagination,
      resetIndex: false
    }
  })),
  on(fromActions.deleteAllChannelsSuccess, (state) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      resetIndex: true
    }
  })),
  on(fromActions.changeAllChannelsFilters, (state, {filters}) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeAllChannelsSort, (state, {active, direction}) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeAllChannelsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    allChannels: {
      ...state.allChannels,
      pagination: {
        ...state.allChannels.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const getMyChannels = (state: State) => state.myChannels;
export const getAllChannels = (state: State) => state.allChannels;

export const getPagination = (table: TableState) => table.pagination;
export const getSort = (table: TableState) => table.sort;
export const getDefaultSort = (table: TableState) => table.defaultSort;
export const getFilters = (table: TableState) => table.filters;
export const getDefaultFilters = (table: TableState) => table.defaultFilters;
export const getIsLoading = (table: TableState) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters,
  resetIndex});

export const getEventsData = (table: TableState) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: ChannelTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<Channel>, ids: string[]): Channel[] => ids.map(id => entities[id]);
