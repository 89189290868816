<div class="related-news">
  <button *ngIf="slidesCount > 0" class="me-auto" rds-secondary-button (click)="openRelatedNewsDialog()">
    Search News to add
  </button>
  <rds-empty-state *ngIf="slidesCount === 0" size="m">
    <rds-empty-state-title>
      No related News defined
    </rds-empty-state-title>
    <rds-empty-state-subtitle>
    <button rds-secondary-button class="ms-auto" (click)="openRelatedNewsDialog()">
        Search news to add
      </button>
    </rds-empty-state-subtitle>
  </rds-empty-state>
  <div *ngIf="slidesCount > 0" class="mb-10 carousel-container">
    <rds-carousel
    #carousel
    [showButtons]="true"
    [class.error]="!control.valid && control.touched"
  >
    <rds-carousel-item 
      *ngFor="let i of (slidesCount | numToArray)"
      [class.rds-carousel__item--visible]="carousel._indexOfActiveCarouselItem === i" >
      <div class="row carousel-slides">
        <div 
          *ngFor="let news of currentSlideItems(i); trackBy: trackByFn" 
          class="col-4"
        >
        <div class="card-wrapper">
          <div class="card">
            <rnb-news-card-item
            [id]="news.id"
            [hasRead]="false"
            [imageUrl]="news.image?.url"
            [title]="news.title"
            [description]="news.abstract"
            [creationDate]="news.publishDate"
            [likeEnabled]="true"
            [likeCount]="0"
            [hasBookmarked]="false"
            [enableBookmark]="true"
            [hasLiked]="false"
            [url]="null"
            [type]="news.type"
            [color]="'neutral'">
          </rnb-news-card-item>
          </div>
          <div class="action">
            <button rds-text-button size="m" (click)="remove(news)">Remove news</button>
          </div>
        </div>
        </div>
      </div>
    </rds-carousel-item>
</rds-carousel>
<rnb-subscript-wrapper [hasError]="!control.valid && control.touched" [disabled]="disabled">
  <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
  <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rnb-subscript-wrapper>
  </div>

</div>
