<ng-container *ngIf="channel$ | async as channel">
  <div class="row">
    <div class="col lmy-3">
      <rds-breadcrumbs [items]="breadcrumbs" (itemClick)="breadcrumbItemClicked($event)"></rds-breadcrumbs>
    </div>
  </div>
  <rnb-view-bar 
  [breadcrumbRoutes]="[
      {
        label: 'Channel',
        route: 'channels/my'
      },
      {
        label: channel.name,
        route: 'channel/'+ channel.id+'/all'
      },
    ]">
    <span title>Channel: {{channel.name}}</span>
    <button
      *ngIf="channel.role === 'Publisher'"
      rds-secondary-button
      size="l"
      rnbBackButtonSet 
      [label]="'Channel: '+ channel.name"
      [routeBack]="'channel/'+ channel.id+'/all'"
      [routeCurrent]="'/channel/' + channel.id + '/info'"
      [routerLink]="'/channel/' + channel.id + '/info'"
    >
      Channel info
    </button>
    <button
      *ngIf="channel.role !== 'Publisher'"
      rds-secondary-button
      size="l"
      rnbBackButtonSet 
      [label]="'Channel: '+ channel.name"
      [routeBack]="'channel/'+ channel.id+'/all'"
      [routeCurrent]="'/channel/' + channel.id + '/edit'"
      [routerLink]="'/channel/' + channel.id + '/edit'"
      [state]="{redirect: '/channel/' + channel.id }"
    >
      Edit channel info
    </button>
  </rnb-view-bar>
  <div class="row">
    <div class="col bg-white px-10 py-7 lmb-8">
      <rds-tabs #tabs (click)="tabsClicked(tabs, channel.id)">
        <rds-tab label="All News"></rds-tab>
        <rds-tab label="News Suggestions"></rds-tab>
        <rds-tab label="Archived News"></rds-tab>
    </rds-tabs>
    <router-outlet></router-outlet>
    </div>
  </div>
  <router-outlet name="dialog"></router-outlet>
</ng-container>
