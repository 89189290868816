import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'oembed'
})
export class OembedPipe implements PipeTransform {
  transform(htmlContent: any): any {
    const oembed = htmlContent.split('</oembed>');
    let body = '';
    oembed.forEach((item, index) => {
      body += oembed[index] + '</oembed>';
      const oembed1 = item.split('url="')[1];
      if (oembed1) {
        const oembed2 = oembed1.split('">')[0];
        if (oembed2) {
          const youtube = oembed2.split('https://www.youtube.com/watch?v=')[1];
          if (youtube) {
            // tslint:disable-next-line
            body += `<div class="iframe-container"><iframe src="https://youtube.com/embed/` + youtube + `" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
          }
          const spotify = oembed2.split('https://open.spotify.com/track/')[1];
          if (spotify) {
            const spotifyId = spotify.split('?')[0];
            // tslint:disable-next-line
            body += '<div class="iframe-container"><iframe src="https://open.spotify.com/embed/track/' + spotifyId + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
          }
          const dailymotion = oembed2.split('https://www.dailymotion.com/video/')[1];
          if (dailymotion) {
            // tslint:disable-next-line
            body += '<div class="iframe-container"><iframe src="https://www.dailymotion.com/embed/video/' + dailymotion + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
          }
          const vimeo = oembed2.split('https://player.vimeo.com/')[1];
          if (vimeo) {
            // tslint:disable-next-line
            body += '<div class="iframe-container"><iframe src="https://player.vimeo.com/' + vimeo + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
          }
          const vimeo2 = oembed2.split('https://vimeo.com/')[1];
          if (vimeo2) {
            // tslint:disable-next-line
            body += '<div class="iframe-container"><iframe src="https://player.vimeo.com/video/' + vimeo2 + '" frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
          }
          const streamingMedia = oembed2.split('https://streamingmedia.roche.com/')[1];
          if (streamingMedia) {
            const data = streamingMedia.includes('?') ? streamingMedia.split('?')[0].split('/') : streamingMedia.split('/');
            const params = streamingMedia.includes('?') ? streamingMedia.split('?')[1].split('&') : [];
            let title;
            let entryId;
            let src;
            switch (data[0]) {
              // ['media', /TITLE/, /ENTRY_ID/]
              case 'media': {
                title = data[1].split('+').join(' ');
                entryId = data[2];
                src = `https://streamingmedia.roche.com/embed/secure/iframe/entryId/${entryId}/uiConfId/45631991`;
                break;
              }
              case 'playlist': {
                // ['playlist', 'dedicated', /PBC/, /???/, /ENTRY_ID/]
                title = '';
                entryId = data[4];
                src = `https://streamingmedia.roche.com/embed/secure/iframe/entryId/${entryId}/uiConfId/45631991`;
                break;
              }
              case 'embed': {
                // ['embed', 'secure', 'iframe', 'entryId', /ENTRY_ID/, 'uiConfId', CONF_ID, 'pbc', /PBC/]
                title = '';
                entryId = data[4];
                src = `https://streamingmedia.roche.com/embed/secure/iframe/entryId/${entryId}/uiConfId/45631991`;
                break;
              }
            }

            if (params.length > 0) {
              params.forEach(param => {
                src += `/${param.split('=')[0]}/${param.split('=')[0]}`
              })
            } else {
              src += '/st/0'
            }

            body +=
              `
              <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%; width: 100%;"><iframe
              id="kmsembed-${entryId}"
              src="${src}"
              style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
              allowfullscreen webkitallowfullscreen mozAllowFullScreen
              allow="autoplay *;fullscreen *; encrypted-media *"
              referrerPolicy="no-referrer-when-downgrade"
              sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
              frameborder="0"
              title="${title}"></iframe>
              </div>
              `;
          }
        }

      }
    });
    return body.replace(new RegExp('<oembed></oembed>', 'g'), '<oembed class="empty-oembed"></oembed>');
  }
}
