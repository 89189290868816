import Command from '@ckeditor/ckeditor5-core/src/command';

export default class MovieAndTextBoxAlignmentCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const selection = model.document.selection;
            const element = selection.getSelectedElement();
            const clonedElement = writer.cloneElement(element, true);
            const range = writer.createRangeIn(element);
            writer.remove(range);
            model.insertContent(clonedElement);
            writer.setAttribute('data-side', options.value, clonedElement);
            writer.setSelection(clonedElement, 'on');
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'movieAndTextBox');
        const element = selection.getSelectedElement();
        const selectedModelName = element?.name;
        this.isEnabled = allowedIn !== null;
        if (selectedModelName === 'movieAndTextBox') {
            if (this.isEnabled && element.hasAttribute('data-side')) {
                this.value = element.getAttribute('data-side');
            } else {
                this.value = false;
            }
        }
    }
}
