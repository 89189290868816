import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as fromUi from './ui/ui.reducer';
import * as fromSuggestions from './suggestions/suggestions.reducer';
import * as fromDictionaries from './dictionaries/dictionaries.reducer';
import * as fromRouter from './router/router.serializer';

export interface State {
  router: RouterReducerState<fromRouter.RouterStateUrl>;
  ui: fromUi.State;
  suggestions: fromSuggestions.State;
  dictionaries: fromDictionaries.State;
}

export const ROOT_REDUCER: ActionReducerMap<State> = {
  router: routerReducer,
  ui: fromUi.reducer,
  suggestions: fromSuggestions.reducer,
  dictionaries: fromDictionaries.reducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ui: ['backButton']}], rehydrate: true})(reducer);
}

export const metaReducers: MetaReducer<State>[] = environment.production
  ? [localStorageSyncReducer]
  : [logger, localStorageSyncReducer];

export const getRouter = (state: State) => state.router;
export const getUi = (state: State) => state.ui;
export const getSuggestions = (state: State) => state.suggestions;