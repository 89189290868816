import { Component, EventEmitter, Input, Output, OnInit, ViewChild, Optional, Host, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ChannelAssignment } from '@app/types';
import { RdsSelectFilterByFunc, RdsSelectOptionComponent } from '@rds/angular-components';
import { OverflowingContentDirective } from '../../overflowing-content.directive';
import { CustomValidators, notEmptyListCheck } from '../../validators';
import { debounceTime } from 'rxjs';


@Component({
  selector: 'rnb-channel-picker',
  templateUrl: './channel-picker.component.html',
  styleUrls: ['./channel-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ChannelPickerComponent
    }
  ]
})
export class ChannelPickerComponent implements OnInit {
  @ViewChild('overflowing', {static: false}) overflowing: OverflowingContentDirective;
  @ViewChild('channelSelectNgModel', {static: true}) channelSelectNgModel: NgModel;

  selectedChannels: Array<number> = [];

  get assignedChannels() {
    return this.options?.filter(c => this.selectedChannels.some(id => id === c.id ) && c.assignmentStatus !== 'None')
  }

  get nonAssignedChannels() {
    return this.options?.filter(c => this.selectedChannels.some(id => id === c.id ) && c.assignmentStatus === 'None')
  }

  onChange = (users) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  hostWidth: number;

  writeValue(suggestions: Array<number>) {
    this.selectedChannels = suggestions;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  selectChanged(event) {
    this.selectedChannels = event;
    this.selected.emit();

    this.onChange(this.selectedChannels);
    if (!!this.overflowing) {
      setTimeout(() => {
        this.overflowing.update();
      },1)
    }
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<string> = new EventEmitter<string>();

  _options: Array<ChannelAssignment>;
  get options(): Array<ChannelAssignment> {
      return this._options;
  }
  @Input() set options(value: Array<ChannelAssignment>) {
    if (!!value) {
      value.filter(c => c.assignmentStatus !== 'None').forEach(c => {
        this.select(c.id)
      });
      this._options = value;
    }
}

@Input() formControl!: FormControl;
@Input() formControlName!: string;
@Input() required = false;

get control() {
  return this.formControl || this.controlContainer.control?.get(this.formControlName);
}

  @Input() label: string = 'Select channels';
  @Input() myLabel: string;
  @Input() addedLabel: string;
  @Input() allowRemoveSelf: boolean = false;
  @Input() multi: boolean = false;

  select(id: number) {
    this.multi ? this.selectedChannels.push(id) : this.selectedChannels = [id];
    this.selected.emit();
    this.onChange(this.selectedChannels)
  }

  remove(id: number) {
    this.selectedChannels = this.selectedChannels.filter(cid => cid !== id);
    this.removed.emit();
    this.onChange(this.selectedChannels)
  }

  ngOnInit(): void {
    this.channelSelectNgModel.control.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.channelSelectNgModel.control.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function() {
      originalMarkAsTouched.apply(this, arguments);
      that.channelSelectNgModel.control.markAsTouched();
      that.channelSelectNgModel.control.updateValueAndValidity();
    }

    this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
      this.channelSelectNgModel.control.updateValueAndValidity();
    });
  }

  constructor(    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,) {
  }

   filterBy: RdsSelectFilterByFunc = (
    text: string | null,
    item: RdsSelectOptionComponent
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.id.toLowerCase().includes(text.toLowerCase());
    }
  }

  openChange(opened: boolean) {
    this.control.markAsTouched();
  }
}
