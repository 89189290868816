<h2 mat-dialog-title *ngIf="data.title">{{ data.title }}</h2>
<section mat-dialog-content *ngIf="data.content">
  <p>{{ data.content }}</p>
</section>
<div mat-dialog-content *ngIf="data.videoUrl">
  <video autobuffer autoplay controls>
    <source [src]="data.videoUrl" type="video/mp4" />
  </video>
</div>
<ng-container [ngSwitch]="data.dialogType">
  <div mat-dialog-actions *ngSwitchCase="'full-page'">
    <rhd-button size="large" [mat-dialog-close]="true">Start exploring</rhd-button>
  </div>
  <div mat-dialog-actions *ngSwitchCase="'top-full-width'">
    <rhd-button type="secondary" [mat-dialog-close]="false" size="medium" class="bit-less-padding">Skip</rhd-button>
    <rhd-button size="medium" [mat-dialog-close]="true">Take a tour</rhd-button>
  </div>
  <button
    type="button"
    class="mat-dialog-close"
    mat-icon-button
    title="Close"
    *ngSwitchCase="'default'"
    [mat-dialog-close]="true"
  >
    <mat-icon size="small">close</mat-icon>
  </button>
</ng-container>
