import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map} from 'rxjs/operators';

import * as fromDepartmentsFlat from '@app/root-store/dictionaries/departments-flat';
import * as fromFunctionsFlat from '@app/root-store/dictionaries/functions-flat';
import * as fromLocationsFlat from '@app/root-store/dictionaries/locations-flat';

export type TaxonomyType = 'location' | 'department' | 'function';

@Pipe({
  name: 'taxonomy'
})
export class TaxonomyGetPipe implements PipeTransform {
  transform(id: string, taxonomyType: TaxonomyType, withParent: boolean = true): Observable<string> {
    switch (taxonomyType) {
      case 'location': {
        return this.store$.pipe(
          select(fromLocationsFlat.selectEntityById, {id}),
          first(taxonomy => !!taxonomy && !!taxonomy.name),
          map(taxonomy => {
            if (withParent) {
              return taxonomy.parentName ? `${taxonomy.parentName}: ${taxonomy.name}` : `${taxonomy.name}`;
            } else {
              return taxonomy.name;
            }
          })
        );
      }
      case 'department': {
        return this.store$.pipe(
          select(fromDepartmentsFlat.selectEntityById, {id}),
          first(taxonomy => !!taxonomy && !!taxonomy.name),
          map(taxonomy => {
            if (withParent) {
              return taxonomy.parentName ? `${taxonomy.parentName}: ${taxonomy.name}` : `${taxonomy.name}`;
            } else {
              return taxonomy.name;
            }
          })
        );
      }
      case 'function': {
        return this.store$.pipe(
          select(fromFunctionsFlat.selectEntityById, {id}),
          first(taxonomy => !!taxonomy && !!taxonomy.name),
          map(taxonomy => {
            if (withParent) {
              return taxonomy.parentName ? `${taxonomy.parentName}: ${taxonomy.name}` : `${taxonomy.name}`;
            } else {
              return taxonomy.name;
            }
          })
        );
      }
    }
  }

  constructor(
    private store$: Store<any>) {
  }
}
