import Command from '@ckeditor/ckeditor5-core/src/command';
import {toArray} from 'ckeditor5/src/utils';

export default class CustomImageUploadCommand extends Command {
    execute(options) {
        const files = toArray(options.file);
        const selection = this.editor.model.document.selection;
        const imageUtils = this.editor.plugins.get('ImageUtils');

        // In case of multiple files, each file (starting from the 2nd) will be inserted at a position that
        // follows the previous one. That will move the selection and, to stay on the safe side and make sure
        // all images inherit the same selection attributes, they are collected beforehand.
        //
        // Applying these attributes ensures, for instance, that inserting an (inline) image into a link does
        // not split that link but preserves its continuity.
        //
        // Note: Selection attributes that do not make sense for images will be filtered out by insertImage() anyway.
        const selectionAttributes = Object.fromEntries(selection.getAttributes());

        files.forEach((file, index) => {
            const selectedElement = selection.getSelectedElement();

            // Inserting of an inline image replace the selected element and make a selection on the inserted image.
            // Therefore inserting multiple inline images requires creating position after each element.
            const positionOther = this.editor.model.createPositionAfter(selectedElement);
            this._uploadImage(file, selectionAttributes, positionOther);


        });
    }

    refresh() {
        this.isEnabled = true;
    }

    _uploadImage(file, attributes, position) {
        const editor = this.editor;
        const fileRepository = editor.plugins.get('FileRepository');
        const loader = fileRepository.createLoader(file);
        const imageUtils = editor.plugins.get('ImageUtils');
        console.log(loader)
        console.log();

        // Do not throw when upload adapter is not set. FileRepository will log an error anyway.
        if (!loader) {
            return;
        }
        loader.upload().then((res) => {
            const imageSrc = res.default;
            const model = this.editor.model;
            model.change(writer => {
                const selection = model.document.selection;
                const element = selection.getSelectedElement();
                let elementAttributes = [];
                if (element.name === 'customImageBox') {
                    elementAttributes = Array.from(element.getAttributes());
                    if (!elementAttributes.length) {
                        writer.setAttribute('src', imageSrc, element);
                    }
                    elementAttributes.forEach(attr => {
                        if (attr[0] === 'src') {
                            writer.setAttribute(attr[0], imageSrc, element);
                        } else {
                            writer.setAttribute(attr[0], attr[1], element);
                        }
                    });
                } else {
                    const customImage = Array.from(element.getChildren()).find(item => item.name === 'customImageBox');
                    elementAttributes = Array.from(customImage.getAttributes());
                    elementAttributes.forEach(attr => {
                        if (attr[0] === 'src') {
                            writer.setAttribute(attr[0], imageSrc, customImage);
                        } else {
                            writer.setAttribute(attr[0], attr[1], customImage);
                        }
                    });
                }


                writer.setSelection(element, 'after');
                fileRepository.destroyLoader(loader);
            });
        })
    }
}
