import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as fromActions from './suggestions.actions';
import { ToastParams } from '@rhd/angular';
import { ChannelAssignment, SimpleUser } from '@app/types';

export const feature = 'suggestions';

export type SuggestionType = 'channel' | 'user' | 'news' | 'topic' | 'language';

export interface Autocomplete {
  type: SuggestionType;
  suggestions: Array<SimpleUser | ChannelAssignment>;
  loading: boolean;
}

export interface State {
  [key: string]: Autocomplete
}

export const initialState: State = {
};

export const reducer = createReducer(
  initialState,
  on(fromActions.initSuggestion, fromActions.clearSuggestion, (state, {suggestionType, prop}) => ({
    ...state,
    [prop]: {
      type: suggestionType,
      suggestions: [],
      loading: false
    }
  })),
  on(fromActions.loadSuggestionRequest, (state, {prop}) => ({
    ...state,
    [prop]: {
      ...state[prop],
      loading: true
    }
  })),
  on(fromActions.loadSuggestionSuccess, fromActions.loadSuggestionFailure, (state, {prop}) => ({
    ...state,
    [prop]: {
      ...state[prop],
      loading: false
    }
  })),
  on(fromActions.loadSuggestionSuccess, (state, {prop, suggestions}) => ({
    ...state,
    [prop]: {
      ...state[prop],
      suggestions
    }
  })),
);

export const selectState = createFeatureSelector<State>(feature);

export const getSuggestions = (autocomplete: {
  suggestions: Array<SimpleUser | ChannelAssignment>,
  loading: boolean
}) => autocomplete.suggestions;

export const getLoading = (autocomplete: {
  suggestions: Array<SimpleUser | ChannelAssignment>,
  loading: boolean
}) => autocomplete.loading;
