import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map} from 'rxjs/operators';

import * as fromLanguages from '@app/root-store/dictionaries/languages';

@Pipe({
  name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {
  transform(code: string): Observable<string> {
    return this.store$.pipe(
      select(fromLanguages.selectEntityById, {id: code}),
      first(language => !!language && !!language.name),
      map(language => {
          return language.name;
      })
    );
  }

  constructor(
    private store$: Store<any>) {
  }
}
