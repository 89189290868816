import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChannelCreation, EMPTY_CHANNEL_FORM, SimpleUser, UserRole, taxonomyOptionsModel } from '@app/types';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import { Autocomplete } from '@app/root-store/suggestions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as fromLocationsFlat from '@app/root-store/dictionaries/locations-flat';
import * as fromDepartmentsFlat from '@app/root-store/dictionaries/departments-flat';
import * as fromFunctionsFlat from '@app/root-store/dictionaries/functions-flat';
import * as fromForm from '@app/channel/store/form';
import { SubSink } from 'subsink';
import { CustomValidators } from '@app/utils/validators';
import { AuthService } from '@app/auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { RdsDialogService } from '@rds/angular-components';
import { getDeleteChannelDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';
import { PeopleRolePickerModel } from '@app/utils/custom-controls/people-role-picker/people-role-picker.component';

@Component({
  selector: 'rnb-channel-form',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.scss']
})
export class ChannelFormComponent implements OnInit, OnDestroy{
  private subs: SubSink = new SubSink();

  iAm ='Owner';

  mode: 'edit' | 'info';
  channelPermissionsAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('channelPermissions')))
  
  CHANNEL_ROLES = this.getChannelRoles('Owner');

	taxonomyOptModel = taxonomyOptionsModel;
  initialForm = null;

  get nothingChanged() {
    return this.initialForm === JSON.stringify(this.form.value) || JSON.stringify(EMPTY_CHANNEL_FORM) === JSON.stringify(this.form.value);
  }

  get userIdentifier() {
    return this.auth.currentSimpleUser.identifier;
  }

	public departmentsDict$ = this.store$.pipe(select(fromDepartmentsFlat.selectAll));
	public functionsDict$ = this.store$.pipe(select(fromFunctionsFlat.selectAll));
	public locationsDict$ = this.store$.pipe(select(fromLocationsFlat.selectAll));
  
  public channel$: Observable<Partial<ChannelCreation>> = this.store$.pipe(select(fromForm.selectForm));
  private isNameUnique$: Observable<Partial<boolean>> = this.store$.pipe(select(fromForm.selectIsNameUnique));

  form: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl('', [Validators.required, Validators.maxLength(70)]),
    description: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    allowAutoAssign: new FormControl(false),
    permissions: new FormControl({
      owners: [],
      managers: [],
      publishers: [],
    }, [CustomValidators.validateChannelPermissions]),
    locations: new FormControl([]),
    departments: new FormControl([]),
    functions: new FormControl([]),
    role: new FormControl('')
  },CustomValidators.validateAtLeastOneRequired([
    'locations', 'departments', 'functions',
  ]));
  constructor(
    private store$: Store<any>,
    private auth: AuthService, 
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: RdsDialogService) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.store$.dispatch(fromForm.clearAll())
  }

  ngOnInit() {
    this.store$.dispatch(fromLocationsFlat.loadLocationsFlat());
    this.store$.dispatch(fromDepartmentsFlat.loadDepartmentsFlat());
    this.store$.dispatch(fromFunctionsFlat.loadFunctionsFlat());

    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'channelPermissions'}));

    this.subs.sink = this.channel$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(channel => {
      this.mode = this.route.snapshot.data.mode;
      const disable = this.mode === 'info';
      if (!this.CHANNEL_ROLES) {
        this.iAm = channel.role;
        this.CHANNEL_ROLES = this.getChannelRoles(this.iAm)
      }
      if (disable) {
        this.form.disable({emitEvent: false});
      }
      if (channel.role === 'Publisher') {
        this.form.controls.owners.disable({emitEvent: false});
      }
      this.form.patchValue(channel, {emitEvent: false})
      if (!this.initialForm) {
        this.initialForm = JSON.stringify(channel);
      }
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(form => {
      this.store$.dispatch(fromForm.setFormValue({form}))
    });

   
    this.subs.sink = this.isNameUnique$.subscribe(isUnique => {
      if (isUnique) {
        this.form.controls.name.setErrors(null)
      } else {
        this.form.controls.name.setErrors({isUnique: true})
        this.form.controls.name.markAsTouched();
      }
    })
  }

  save(id: number) {
    id ?
      this.store$.dispatch(fromForm.updateChannelRequest({id}))
    :
      this.store$.dispatch(fromForm.createChannelRequest());
  }

  ownerLabelFn = (user: UserRole): string => {
    const currentUserIdentifier = this.auth.currentSimpleUser.identifier;

    if ((user.identifier === user.assignedBy?.identifier || !user.assignedBy) && user.identifier === currentUserIdentifier) {
      return 'You created the Channel';
    }
    if (user.identifier === user.assignedBy?.identifier && user.identifier !== currentUserIdentifier) {
      return 'Created the Channel';
    }
    if (user.assignedBy?.identifier === currentUserIdentifier || !user.assignedBy) {
      return 'You added the user';
    }
    return'Added by ' + user.assignedBy.name;
  }

  userLabelFn = (user: UserRole): string => {
    const currentUserIdentifier = this.auth.currentSimpleUser.identifier;
    if (user.assignedBy?.identifier === currentUserIdentifier || !user.assignedBy) {
      return 'You added the user';
    }
    return 'Added by ' + user.assignedBy.name;
  }

  getRestrictedUsers(field: string) {
    switch (field) {
      case 'owners':
        return [...this.form.controls.managers.value, ...this.form.controls.publishers.value];
      case 'managers':
        return [...this.form.controls.owners.value, ...this.form.controls.publishers.value];
      case 'publishers':
        return [...this.form.controls.owners.value, ...this.form.controls.managers.value];
    }
  }

  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
   }

   deleteChannel(id: string, name: string) {
    const ids = [+id]
    const data: ConfirmDialogData = getDeleteChannelDialog(ids, name);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    this.subs.sink = dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromForm.deleteChannelRequest({ids: data.ids})));
  }

  getChannelRoles(userRole): PeopleRolePickerModel {
    return {
      owners: {
        label: 'Owner',
        description: 'Can assign news to channel, edit/delete channel and manage channel permissions',
        canEdit: userRole === 'Owner',
      },
      managers: {
        label: 'Manager',
        description: 'Can assign news to channel and edit channel',
        canEdit: userRole === 'Owner' || userRole === 'Manager',
      },
      publishers: {
        label: 'Publisher',
        description: 'Can assign news to channel',
        canEdit: userRole === 'Owner' || userRole === 'Manager',
      }
    }
  }
}
