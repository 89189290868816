  <div class="row">
    <div class="col lmy-3">
      <rds-breadcrumbs [items]="breadcrumbs" (itemClick)="breadcrumbItemClicked($event)"></rds-breadcrumbs>
    </div>
  </div>
  <rnb-view-bar [breadcrumbRoutes]="[{ label: 'My News', route: 'news/my'}]" >
    <span title>My News</span>
  </rnb-view-bar>
  <div class="row">
    <div class="col bg-white px-10 py-7 lmb-8">
      <rds-tabs #tabs (click)="tabsClicked(tabs)">
        <rds-tab label="My News"></rds-tab>
        <rds-tab label="My Suggested News"></rds-tab>
        <rds-tab label="My Archived News"></rds-tab>
        <rds-tab *ngIf="isAdmin || isSuperAdmin" label="All News"></rds-tab>
    </rds-tabs>
    <router-outlet></router-outlet>
    </div>
  </div>
