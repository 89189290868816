import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import MovieAndTextBoxUi from './movie-and-text-box-ui';
import MovieAndTextBoxEditing from './movie-and-text-box-editing';
import CustomMovieSrcUi from "./custom-movie-src-ui";

export default class MovieAndTextBox extends Plugin {
  static get requires() {
      return [ MovieAndTextBoxEditing, MovieAndTextBoxUi, CustomMovieSrcUi ];
  }
}
