import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromForm from '../../../store/form';
import { FormControl, FormGroup } from '@angular/forms';
import { FormStepStatus } from '../form-status-badge/form-status-badge.component';
import { NewsForm } from '@app/types';
import { SubSink } from 'subsink';
import { AuthService } from '@app/auth/auth.service';
import { CustomValidators } from '@app/utils/validators';
import { PeopleRolePickerModel } from '@app/utils/custom-controls/people-role-picker/people-role-picker.component';

export const NEWS_ROLES = {
  owners: {
    label: 'Owner',
    description: 'Can edit, manage permission and delete the News'
  },
  editors: {
    label: 'Editor',
    description: 'Can edit News'
  },
}

@Component({
  selector: 'rnb-form-assign-editors-and-owners',
  templateUrl: './form-assign-editors-and-owners.component.html',
  styleUrls: ['./form-assign-editors-and-owners.component.scss']
})
export class FormAssignEditorsAndOwnersComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  newsPermissionsAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('newsPermissions')))

  NEWS_ROLES = this.getNewsRoles();

  get userIdentifier() {
    return this.auth.currentSimpleUser.identifier;
  }

  _isActive: Array<boolean>;
  get isActive(): Array<boolean> {
      return this._isActive;
  }
  @Input() set isActive(value: Array<boolean>) {
      this._isActive = value;
      this.store$.dispatch(fromForm.setPermissionsFormStatus({status: this.form.status}));
      if (value) {
      this.store$.dispatch(fromForm.setPermissionsStepStatus({status: FormStepStatus.IN_PROGRESS}));
      } else {
        this.form.markAllAsTouched();
        if (this.form.valid) {
          this.store$.dispatch(fromForm.setPermissionsStepStatus({status: FormStepStatus.COMPLETED}));
        } else {
          this.store$.dispatch(fromForm.setPermissionsStepStatus({status: FormStepStatus.INCOMPLETE}));
        }
      }
  }

  form$: Observable<Partial<NewsForm>> = this.store$.pipe(select(fromForm.selectPermissionsForm));
  public form: FormGroup = new FormGroup({
    permissions: new FormControl({
      owners: [],
      editors: []
    }, CustomValidators.validateNewsPermissions)
  });

  constructor(
    private store$: Store<any>,
    private auth: AuthService
    ) {
    
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'newsPermissions'}));
    
    this.subs.sink = this.form$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(news => {
      this.form.patchValue(news, {emitEvent: false});
      this.store$.dispatch(fromForm.setPermissionsFormStatus({status: this.form.status}));
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(form => {
      this.store$.dispatch(fromForm.setFormValue({form}))
    });
    
    this.subs.sink = this.form.statusChanges.subscribe(value => {
      this.store$.dispatch(fromForm.setPermissionsFormStatus({status: value}));
    });
  }

  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
   }

   getNewsRoles(): PeopleRolePickerModel {
    return {
      owners: {
        label: 'Owner',
        description: 'Can edit, manage permission and delete the News',
        canEdit: true,
      },
      editors: {
        label: 'Editor',
        description: 'Can edit News',
        canEdit: true,
      }
    }
  }
   
}
