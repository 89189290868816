import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as fromCore from '@app/root-store/dictionaries/dictionaries.reducer';
import * as fromReducer from './departments-flat.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getDepartmentsFlat
);

export const selectAll = createSelector(
  selectState,
  fromReducer.getAll
);

export const selectAllActive = createSelector(
  selectAll,
  fromReducer.getAllActive
);

export const selectIsAllActiveByIds = (ids: Array<string>) => createSelector(
  selectAll,
  (taxonomies) => {
    return taxonomies.length === 0 ? null : taxonomies.filter(t => ids.includes(t.id)).every(t => t.isActive);
  }
);

export const selectEntities = createSelector(
  selectState,
  fromReducer.getEntities
);

export const selectIds = createSelector(
  selectState,
  fromReducer.getIds
);

export const selectTotal = createSelector(
  selectState,
  fromReducer.getTotal
);

export const selectEntityById = createSelector(
  selectEntities,
  fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromReducer.getEntitiesById
);

export const selectRootId = createSelector(
  selectAllActive,
  fromReducer.getRootId
);

export const selectAllByIdsWithParents = (ids: Array<string>) => createSelector(
  selectAllActive,
  (taxonomies) => {
    let selectedTaxonomies = taxonomies.filter(t => ids.includes(t.id));
    let parents = taxonomies.filter(t => selectedTaxonomies.some(st => st.parentId === t.id));
    while (parents.length > 0) {
      selectedTaxonomies = [...selectedTaxonomies, ...parents];
      parents = taxonomies.filter(t => parents.some(pt => pt.parentId === t.id));
    }
    return selectedTaxonomies;
  }
);

export const selectAllChildIds = (ids: Array<string>) => createSelector(
  selectAllActive,
  (taxonomies) => {
    const selectedTaxonomies = taxonomies.filter(t => ids.includes(t.id));
    let rootSelected = selectedTaxonomies.find(t => t.parentId === null);
    let childIds = selectedTaxonomies.reduce((acc, curr) => [...acc, ...curr.childrenIds], []);
    let outputIds = [...childIds];
    while (childIds.length > 0) {
      childIds = taxonomies.filter(t => childIds.includes(t.id)).reduce((acc, curr) => [...acc, ...curr.childrenIds], []);
      outputIds = [...outputIds, ...childIds];
    }
    if (!rootSelected && outputIds.length === 0) {
      rootSelected = taxonomies.find(t => t.parentId === null);
    }
    return { ids: [...outputIds, ...ids], rootSelected };
  }
);

export const selectEntitiesByIdWithParents = createSelector(
  selectEntities,
  selectEntitiesById,
  (entities, selectedEntities) => {

    const parents = [];

    _.forEach(selectedEntities, (ent) => {
      const finds = _.cloneDeep(_.find(entities, (item) => item.id === ent.parentId));
      if (finds) {
        finds.childrenIds = [ent.id];
        parents.push(finds);
      }
    });

    _.forEach(parents, (ent) => {
      const finds =  _.cloneDeep(_.find(entities, (item) => item.id === ent.parentId));
      if (finds) {
        finds.childrenIds = [ent.id];
        parents.push(finds);
      }
    });

    if (selectedEntities[0] !== undefined) {
      const o = {};
      _.forEach([...parents, ...selectedEntities.map(j => ({...j, childrenIds: []}))], (item) => {
        if (o[item.id]) {
          o[item.id].childrenIds = [...o[item.id].childrenIds, ...item.childrenIds];
        } else {
          o[item.id] = item;
        }
      });
      return o;
    } else {
      return {};
    }
  }
);

