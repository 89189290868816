import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DropdownFlatNode, NestedOptionsModel } from '../models';

@Component({
  selector: 'rnb-dropdown-node',
  templateUrl: './dropdown-node.component.html',
  styleUrls: ['./dropdown-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownNodeComponent implements OnInit, FocusableOption {

  @Input() nodeScope!: {
    hasChild: boolean;
    isAnyChildSelected: boolean;
    areAllChildsSelected: boolean;
    isSelected: boolean;
    searchPhrase: string;
    isExpanded: boolean;
    isAnyChildMatchingPhrase: boolean;
    node: DropdownFlatNode;
    hasSelectionValue: boolean;
    isMultiple: boolean;
    size: 'medium' | 'small',
    optionsModel: Partial<NestedOptionsModel>,
    selectParentWhenAllSelected: boolean
  }

  @Output() onToggle: EventEmitter<DropdownFlatNode> = new EventEmitter();
  @Output() onCheckboxChange: EventEmitter<DropdownFlatNode> = new EventEmitter();
  @Output() onNameClick: EventEmitter<DropdownFlatNode> = new EventEmitter();

  constructor(private host: ElementRef) {}
  focus() {
    this.host.nativeElement.focus();
  }
  
  disabled?: boolean;

  getLabel?(): string {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

  toggleCheckbox(node: DropdownFlatNode) {
    this.onCheckboxChange.emit(node);
  }

  toggleNode(node: DropdownFlatNode) {
    this.onToggle.emit(node);
  }

  nameClicked(node: DropdownFlatNode) {
    this.onNameClick.emit(node)
  }
}
