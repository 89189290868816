import { ChannelAssignment, SimpleUser } from '@app/types';
import { createAction, props } from '@ngrx/store';
import { RdsToastIndividualConfig } from '@rds/angular-components';
import { SuggestionType } from './suggestions.reducer';

export const initSuggestion = createAction(
  '[USERS] Init suggestions',
  props<{ suggestionType: SuggestionType, prop: string }>()
);

export const loadSuggestion = createAction(
  '[USERS] Load suggestions',
  props<{ suggestionType: SuggestionType, prop: string, phrase: string }>()
);

export const loadSuggestionRequest = createAction(
  '[USERS] Load suggestions request',
  props<{ suggestionType: SuggestionType, prop: string, phrase: string }>()
);

export const loadSuggestionSuccess = createAction(
  '[USERS] Load suggestions success',
  props<{ prop: string, suggestions: Array<SimpleUser | ChannelAssignment> }>()
);

export const loadSuggestionFailure = createAction(
  '[USERS] Load suggestions failure',
  props<{ prop: string, error: any }>()
);

export const clearSuggestion = createAction(
  '[USERS] Clear suggestions',
  props<{ suggestionType: SuggestionType, prop: string, phrase: string }>()
);