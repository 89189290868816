import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ImageAndQuoteBoxUi from './image-and-quote-box-ui';
import ImageAndQuoteEditing from './image-and-quote-editing';
import CustomBoxResizeUi from "../blocks/custom-box-resize-ui";

export default class ImageAndQuoteBox extends Plugin {
  static get requires() {
      return [ ImageAndQuoteEditing, ImageAndQuoteBoxUi, CustomBoxResizeUi ];
  }
}
