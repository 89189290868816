import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import TextBoxCommand from './text-box-command';
import TextBoxChangeTypeCommand from "./text-box-change-type-command";
import TextBoxBlockBackgroundCommand from "./text-box-block-background-command";
import {findViewImgElement} from "../blocks/custom-image-editing";

export default class TextBoxEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        this._defineSchema();
        this._defineConverters();


        this.editor.commands.add('insertTextBox', new TextBoxCommand(this.editor));
        this.editor.commands.add('changeTypeTextBox', new TextBoxChangeTypeCommand(this.editor));
        this.editor.commands.add('changeBlockBackgroundClass', new TextBoxBlockBackgroundCommand(this.editor));

    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('textBox', {
            isObject: true,
            allowIn: '$root',
            allowAttributes: ['custom-type', 'custom-background-class']
        });

        schema.register('textBoxText', {
            isLimit: true,
            allowIn: 'textBox',
            allowContentOf: '$root'
        });

        schema.addChildCheck((context, childDefinition) => {
            if (context.endsWith('textBox') && childDefinition.name == 'textBox') {
                return false;
            }
        });
    }

    _defineConverters() {
        const editor = this.editor;
        const conversion = this.editor.conversion;
        conversion.attributeToAttribute({
            model: {
                name: 'textBox',
                key: 'custom-type'
            },
            view: {
                key: 'custom-type'
            }
        });

        conversion.attributeToAttribute({
            model: {
                name: 'textBox',
                key: 'custom-background-class'
            },
            view: {
                key: 'custom-background-class'
            }
        });


        conversion.for('upcast').elementToElement({
            model: (viewElement, {writer}) => {
                return writer.createElement('textBox');
            },
            view: {
                name: 'section',
                classes: 'text-box'
            }
        }).add(upcastTextBox('custom-background-class', editor), {priority: 'highest'});
        conversion.for('dataDowncast').elementToElement({
            model: 'textBox',
            view: {
                name: 'section',
                classes: ['text-box', 'mb-6']
            }
        }).add(downcastTextBox('custom-background-class', editor), {priority: 'highest'});;

        conversion.for('editingDowncast').elementToElement({
            model: 'textBox',
            view: (modelElement, {writer: viewWriter}) => {
                const backgroundClass = modelElement.getAttribute('custom-background-class')

                const section = viewWriter.createContainerElement('section', {class: 'py-6 my-6 text-box ' + backgroundClass});

                return toWidget(section, viewWriter, {label: 'simple box widget'});
            }
        }).add(downcastTextBox('custom-background-class', editor), {priority: 'highest'});

        conversion.for('upcast').elementToElement({
            model: 'textBoxText',
            view: {
                name: 'div',
                classes: 'text-box-text'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'textBoxText',
            view: {
                name: 'div',
                classes: 'text-box-text'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'textBoxText',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement('div', {class: 'text-box-text'});
                return toWidgetEditable(div, viewWriter);
            }
        });

    }
}

export function isHtmlIncluded(dataTransfer) {
    return Array.from(dataTransfer.types).includes('text/html') && dataTransfer.getData('text/html') !== '';
}

export function downcastTextBox(attributeKey, editor) {

    return dispatcher => {
        dispatcher.on(`attribute:${attributeKey}`, converter);
    };

    function converter(evt, data, conversionApi) {
        const viewWriter = conversionApi.writer;
        const element = conversionApi.mapper.toViewElement(data.item);
        // const img = findViewImgElement(element, editor);
        const value = data.attributeNewValue;
        const oldValue = data.attributeOldValue;
        viewWriter.removeClass(oldValue,element);
        viewWriter.addClass(value, element);
    }
}

export function upcastTextBox(attributeKey, editor) {

    return dispatcher => {
        dispatcher.on(`attribute:${attributeKey}`, converter);
    };

    function converter(evt, data, conversionApi) {
        const viewWriter = conversionApi.writer;
        const element = conversionApi.mapper.toViewElement(data.item);
        // const img = findViewImgElement(element, editor);
        const value = data.attributeNewValue;
        const oldValue = data.attributeOldValue;
        viewWriter.removeClass(oldValue,element);
        viewWriter.addClass(value, element);
    }
}
