<div class="row">
  <div class="col">
    <div class="row">
      <div class="col">
        <div class="rds-step-header p-0 pe-4 mb-6">
          <div
            class="rds-step-icon rds-step-icon-state-number rds-step-icon-selected rds-step-icon__hovered">
            <div class="rds-step-icon-content"><span>{{stepNo}}</span></div>
          </div>
          <h3 class="ui-heading-h3-bold mb-0">{{stepName}}</h3>
          <ng-content select="[actions]"></ng-content>
        </div>
        <rds-divider></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="row">
      <div class="col mt-7 mb-10">
        <rds-divider class="mb-9"></rds-divider>
        <div class="event-step__navigation">
        <ng-content select="[navigation]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>