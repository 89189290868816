import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromNewsTable from '../../../../store/news-table';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel, News, NewsSuggestion } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { AuthService, SimpleUser } from '@app/auth/auth.service';
import { TableColumn } from '@app/utils/table/table.component';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { RejectionCommentDialogComponent, RejectionCommentDialogData } from '@app/utils/rejection-comment-dialog/rejection-comment-dialog.component';
import { NewsTableFilters } from '../../../../store/news-table';

@Component({
  selector: 'rnb-my-suggested-news-table',
  templateUrl: './my-suggested-news-table.container.html',
  styleUrls: ['./my-suggested-news-table.container.scss']
})
export class MySuggestedNewsTableContainer implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();

  get identifier(): string {
    return this.auth.currentUser.identifier;
  }

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  columns: Array<string> = ['title', 'channel', 'type', 'published', 'suggested', 'response', 'approvalStatus', 'actions'];

  news: Array<Partial<NewsSuggestion>> = []; 
  news$: Observable<Array<Partial<NewsSuggestion>>> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsData));
  
  pagination$: Observable<Pagination> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsIsLoading));
  
  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsDefaultSort));

  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromNewsTable.selectMySuggestedNewsSearch));

  constructor(
    private store$: Store<fromNewsTable.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

  }

  canEditNews(news: NewsSuggestion) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier) || !!news.editors.find(u => u.identifier === this.identifier)
  }
  
  canSeeComment(news: NewsSuggestion) {
    return news.approvalStatus === 'Rejected' && news.comment?.length > 0;
  }

  canRemoveSuggestion(news: NewsSuggestion) {
    return (this.isAdmin || this.isSuperAdmin || news.assignedBy.identifier === this.identifier) && news.approvalStatus === 'Pending'
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromNewsTable.changeMySuggestedNewsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromNewsTable.changeMySuggestedNewsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromNewsTable.changeMySuggestedNewsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<number>) {
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    this.openNewsPreview(+link);
  }

  openNewsPreview(newsId: number) {
    this.store$.dispatch(fromNewsTable.openNewsPreview({newsId, backButton: {
      label: 'My News',
      routeBack: 'news/my',
      routeCurrent: `/news/${newsId}/edit`
    }}))
  }

  removeSuggestions(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = {
      ids,
      title: `Are you sure you want to delete ${ids.length+' news'} from the channel?`,
      messages: [`Please note that this action is irreversible. The article${ids.length > 1 ? 's' : ''} will no longer be published in this channel.`],
      confirmButtonLabel: 'Yes, delete',
      confirmButtonType: 'warning'
    }
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteMyNewsRequest({ids: data.ids})));
  }

  removeSuggestion(id: number, channel: Channel) {
    this.store$.dispatch(fromNewsTable.removeSuggestionRequest({newsIds: [id], channelId: channel.id, channelName: channel.name}))
  }

  openRejectionComment(news: NewsSuggestion) {
    const data: RejectionCommentDialogData = {
      news
    }
    const dialog = this.dialogService.open(RejectionCommentDialogComponent, {
      data
    });
  }
}
