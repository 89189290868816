<rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
<rnb-table
  #table
  [data]="news$ | async"
  [defaultSortActive]="(defaultSort$ | async).active"
  [defaultSortDirection]="(defaultSort$ | async).direction"
  [columns]="columns"
  [hasBulk]="false"
  [pagination]="pagination$ | async"
  [totalObs]="total$"
  [isLoading]="isLoading$ | async"
  [highlight]="search$ | async"
  (sortChanged)="sortChanged($event)"
  (paginationChanged)="paginationChanged($event)"
  (selectionChanged)="selectionChanged($event)"
  (linkClicked)="onLinkClick($event)"
  [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
  >

  <ng-template #actions let-news>
    <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
    #trigger="rdsMenuTrigger">
      <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
    </button>

    <rds-menu #menu="rdsMenu">
      <button [disabled]="!canEditNews(news)" rnbBackButtonSet label="My News" routeBack="news/my" [routeCurrent]="'/news/'+news.id+'/edit'" rds-menu-item [routerLink]="'/news/'+news.id+'/edit'">
        <span>Edit</span>
      </button>
      <button [disabled]="!canSeeComment(news)" rds-menu-item (click)="openRejectionComment(news)">
        <span>See rejection comment</span>
      </button>
      <button [disabled]="!canRemoveSuggestion(news)" rds-menu-item (click)="removeSuggestion(news.id, news.channel)">
        <span>Remove suggestion</span>
      </button>
    </rds-menu>
  </ng-template>

  <span emptyTitle>
    Looks like you don’t have any news suggestion yet.
  </span>
  <span emptySubtitle>
    You will receive an email notification when a news is suggested to your channel.
  </span>
</rnb-table>
