import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { MaterialModule } from './material.module';
import { AuthModule } from './auth/auth.module';
import { ChannelModule } from './channel/channel.module';
import { TourModule } from './tour/tour.module';
import { UtilsModule } from './utils/utils.module';

import { AppComponent } from './app.component';
import { RootStoreModule } from './root-store/root-store.module';
import { RdsToastModule } from '@rds/angular-components';
import { NewsModule } from './news/news.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    AuthModule,
    ChannelModule,
    NewsModule,
    TourModule,
    UtilsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RdsToastModule,
    RootStoreModule.forRoot(),
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
