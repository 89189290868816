import { FormStepStatus } from '@app/news/components/news-form/form-status-badge/form-status-badge.component';
import { ChannelAssignment, ContentType, Language, News, NewsForm, NewsStatus, SimpleUser } from '@app/types';
import { Template } from '@app/types/template';
import { createAction, props } from '@ngrx/store';
export const initAddForm = createAction(
  '[NEWS - FORM] Init add form',
  props<{user: SimpleUser, contentType: ContentType, id?: number}>()
);

export const initCreateFormFromTemplate = createAction(
  '[NEWS - FORM] Init create form from template',
  props<{templateId: number, user: SimpleUser, contentType: ContentType}>()
);

export const initCreateFormFromTemplateSuccess = createAction(
  '[NEWS - FORM] Init create form from template success',
  props<{template: Template}>()
);

export const initCreateTemplateForm = createAction(
  '[NEWS - FORM] Init create template form',
  props<{user: SimpleUser, contentType: ContentType, id?: number}>()
);

export const getNewsDetailsRequest = createAction(
  '[NEWS - FORM] Get news details request',
  props<{id: number}>()
);

export const getNewsDetailsSuccess = createAction(
  '[NEWS - FORM] Get news details success',
  props<{news: Partial<News>, status: NewsStatus}>()
);

export const getNewsDetailsFailure = createAction(
  '[NEWS - FORM] Get news details failure',
  props<{error: string}>()
);

export const setGeneralStepStatus = createAction(
  '[NEWS - FORM] Set general step status',
  props<{status: FormStepStatus}>()
);

export const setGeneralFormStatus = createAction(
  '[NEWS - FORM] Set general form status',
  props<{status: string}>()
);

export const setContentStepStatus = createAction(
  '[NEWS - FORM] Set content step status',
  props<{status: FormStepStatus}>()
);

export const setContentFormStatus = createAction(
  '[NEWS - FORM] Set content form status',
  props<{status: string}>()
);

export const setPublishingStepStatus = createAction(
  '[NEWS - FORM] Set publishing step status',
  props<{status: FormStepStatus}>()
);

export const setPublishingFormStatus = createAction(
  '[NEWS - FORM] Set publishing form status',
  props<{status: string}>()
);

export const setPermissionsStepStatus = createAction(
  '[NEWS - FORM] Set permissions step status',
  props<{status: FormStepStatus}>()
);

export const setPermissionsFormStatus = createAction(
  '[NEWS - FORM] Set permissions form status',
  props<{status: string}>()
);

export const setFormValue = createAction(
  '[NEWS - FORM] Set form value',
  props<{form: Partial<NewsForm>}>()
);

export const setInitialFormValue = createAction(
  '[NEWS - FORM] Set initial form value',
  props<{form: Partial<NewsForm>}>()
);

export const primaryLanguageChanged = createAction(
  '[NEWS - FORM] Primary language changed',
  props<{lang: string}>()
);

export const updatePrimaryLanguage = createAction(
  '[NEWS - FORM] Update primary language',
  props<{oldLang: string; newLang: string}>()
);

export const setFormTouched = createAction(
  '[NEWS - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const saveAsDraftRequest = createAction(
  '[NEWS - FORM] Save as draft request',
);

export const saveAsDraftSuccess = createAction(
  '[NEWS - FORM] Save as draft success',
);

export const saveAsDraftFailure = createAction(
  '[NEWS - FORM] Save as draft failure',
  props<{error: string}>()
);

export const updateAsDraftRequest = createAction(
  '[NEWS - FORM] Update as draft request',
  props<{id: number}>()
);

export const updateAsDraftSuccess = createAction(
  '[NEWS - FORM] Update as draft success',
);

export const updateAsDraftFailure = createAction(
  '[NEWS - FORM] Update as draft failure',
  props<{error: string}>()
);

export const saveAsPublishRequest = createAction(
  '[NEWS - FORM] Save as Publish request',
);

export const saveAsPublishSuccess = createAction(
  '[NEWS - FORM] Save as Publish success',
);

export const saveAsPublishFailure = createAction(
  '[NEWS - FORM] Save as Publish failure',
  props<{error: string}>()
);

export const updateAsPublishRequest = createAction(
  '[NEWS - FORM] Update as Publish request',
  props<{id: number}>()
);

export const updateAsPublishSuccess = createAction(
  '[NEWS - FORM] Update as Publish success',
);

export const updateAsPublishFailure = createAction(
  '[NEWS - FORM] Update as Publish failure',
  props<{error: string}>()
);

export const openPublishChangesConfirmDialog = createAction(
  '[NEWS - FORM] Open publish changes confirm dialog',
  props<{ id: number }>()
);

export const clearAll = createAction(
  '[NEWS - FORM] Clear form'
);

export const openLeavePageDialog = createAction(
  '[NEWS - FORM] Open Leave Page dialog',
  props<{
    url: string
  }>()
);

export const closeForm = createAction(
  '[NEWS] Close form'
);

export const openAddLanguageDialog = createAction(
  '[NEWS] Open add language dialog',
);

export const openPreviewDialog = createAction(
  '[NEWS] Open preview dialog'
);


export const addLanguageVersion = createAction(
  '[NEWS] Add language version',
  props<{language: string}>()
);

export const removeLanguageVersion = createAction(
  '[NEWS] Remove language version',
  props<{language: string}>()
);

export const updatePrimaryTitle = createAction(
  '[NEWS] Update primary title',
  props<{title: string}>()
);

export const loadAssignements = createAction(
  '[NEWS] Load channel assignements',
  props<{id?: number}>()
);

export const loadAssignableRequest = createAction(
  '[NEWS - FORM] Load assignable request',
  props<{id?: number}>()
);

export const loadAssignableSuccess = createAction(
  '[NEWS - FORM] Load assignable success',
  props<{channels: Array<ChannelAssignment>}>()
);

export const loadAssignableFailure = createAction(
  '[NEWS - FORM] Load assignable failure',
  props<{error: string}>()
);

export const loadSuggestableRequest = createAction(
  '[NEWS - FORM] Load suggestable request',
  props<{id?: number}>()
);

export const loadSuggestableSuccess = createAction(
  '[NEWS - FORM] Load suggestable success',
  props<{channels: Array<ChannelAssignment>}>()
);

export const loadSuggestableFailure = createAction(
  '[NEWS - FORM] Load suggestable failure',
  props<{error: string}>()
);

export const saveAsTemplateRequest = createAction(
  '[NEWS - FORM] Save as template',
  props<{id: number}>()
);

export const saveAsTemplateSuccess = createAction(
  '[NEWS - FORM] Save as Template success',
);

export const saveAsTemplateFailure = createAction(
  '[NEWS - FORM] Save as Template failure',
  props<{error: string}>()
);

export const openUnpublishConfirmDialog = createAction(
  '[NEWS - FORM] Open Unpublish confirm dialog',
  props<{ id: number }>()
);

export const unpublishRequest = createAction(
  '[NEWS - FORM] Unpublish',
  props<{id: number}>()
);

export const unpublishSuccess = createAction(
  '[NEWS - FORM] Unpublish success',
  props<{id: number}>()
);

export const unpublishFailure = createAction(
  '[NEWS - FORM] Unpublish failure',
  props<{error: string}>()
);

