import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

import * as fromDetails from './store/channel-details';
import * as fromForm from './store/form';

import { ChannelService, ChannelBase } from './channel.service';
import { AuthService, SimpleUser } from '@app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CreateChannelResolver implements Resolve<boolean> {
  constructor(private service: ChannelService, private router: Router, private store$: Store<any>, private auth: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let user: SimpleUser = this.auth.currentSimpleUser;
    this.store$.dispatch(fromForm.initAddForm({user}))

    return of(true)
  }
}
