import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, of } from 'rxjs';

import { ApiClient } from '../api-client.service';

import { TimezoneData } from '../types';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  private _timezones = new ReplaySubject<TimezoneData>(1);

  private _initialized = false;

  get timezones(): Observable<TimezoneData> {
    this._loadTimezones();
    return this._timezones.asObservable();
  }

  private static _getUserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  constructor(private client: ApiClient) {}

  getTimezones() {
    return this.client.get(`/timezones/${TimezoneService._getUserTimezone()}`);
  }

  private _loadTimezones(): void {
    if (!this._initialized) {
      this.client.get(`/timezones/${TimezoneService._getUserTimezone()}`).subscribe((data: TimezoneData) => {
        this._timezones.next(data);
        this._initialized = true;
      });
    }
  }
}

export { TimezoneData };
