<rds-table #tableRef [dataSource]="data" [class.rds-table-loading-data]="isLoading" [rdsSortActive]="defaultSortActive" [rdsSortDirection]="defaultSortDirection" (rdsSortChange)="onSort($event)" rdsSort #sortRef density="spacious" size="l">
    <!-- Delete column -->
    <ng-container rdsColumnDef="delete-checkbox">
      <rds-header-cell *rdsHeaderCellDef>
        <div class="table__delete-row"></div>
      </rds-header-cell>
    </ng-container>
    <ng-container rdsColumnDef="delete">
      <rds-header-cell *rdsHeaderCellDef>
        <div class="table__delete-row">
          <ng-content select="[bulkText]"></ng-content>
          <ng-content select="[bulkActions]"></ng-content>
          <button rds-secondary-button (click)="clearAll()">
            Cancel
          </button>
        </div>
      </rds-header-cell>
    </ng-container>
  <!-- Checkbox column -->
  <ng-container rdsColumnDef="select">
    <rds-header-cell *rdsHeaderCellDef>
      <rds-checkbox (click)="$event.stopPropagation()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllOnPageSelected" [indeterminate]="selection.hasValue() && isAnyOnPageSelected  && !isAllOnPageSelected" [disabled]="disableMasterToggle">
    </rds-checkbox>
    </rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <rds-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element) || (initialSelectionPredicate ? initialSelectionPredicate(element) : false)" [disabled]="!!bulkPredicate ? !bulkPredicate(element) : (!!initialSelectionPredicate ? initialSelectionPredicate(element) : false)">
      </rds-checkbox>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="actions">
    <rds-header-cell *rdsHeaderCellDef></rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-template [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{$implicit: element}"></ng-template>
    </rds-cell>
  </ng-container>

  <!-- CHANNELS COLUMN DEFINITIONS -->
  <ng-container rdsColumnDef="name">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header class="sortable">Channel name</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="link;context:{
        tooltip: element.name,
        highlight: highlight,
        title: element.name,
        link: 'channel/' + element.id,
        linkType: 'route'
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="name-nolink">
    <rds-header-cell *rdsHeaderCellDef  rds-sort-header="name" class="sortable">Channel name</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: element.name,
        highlight: highlight,
        title: element.name
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="role">
    <rds-header-cell *rdsHeaderCellDef>My role</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-container *ngIf="{
        Owner: 'c-500-extra-dark-green',
        Manager: 'c-300-dark-red',
        Publisher: 'c-400-extra-dark-purple'
      } as colorClass">
        <ng-container *ngTemplateOutlet="icon;context:{
          icon: 'user',
          namespace: 'outlined',
          colorClass: colorClass[element.role],
          tooltip: element.role
        }"></ng-container>
      </ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="owners">
    <rds-header-cell *rdsHeaderCellDef>Owners</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <rnb-user-group [users]="element.owners"></rnb-user-group>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="managers">
    <rds-header-cell *rdsHeaderCellDef>Managers</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <rnb-user-group [users]="element.managers"></rnb-user-group>
    </rds-cell>
  </ng-container>
  
  <ng-container rdsColumnDef="publishers">
    <rds-header-cell *rdsHeaderCellDef>Publishers</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <rnb-user-group [users]="element.publishers"></rnb-user-group>
    </rds-cell>
  </ng-container>
  
  <ng-container rdsColumnDef="description">
    <rds-header-cell *rdsHeaderCellDef>Description</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: element.description,
        highlight: highlight,
        title: element.description
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="creationDate">
    <rds-header-cell *rdsHeaderCellDef  rds-sort-header="creationDate" class="sortable">Created</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable"> 
      <ng-container *ngTemplateOutlet="text;context:{
        title: (element.creationDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <!-- NEWS COLUMN DEFINITIONS -->
  <ng-container rdsColumnDef="pin">
    <rds-header-cell *rdsHeaderCellDef></rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <button rds-icon-button size="m" [class.pinned]="element.isPinned" (click)="pinClicked.emit({id: element.id, isPinned: element.isPinned})">
        <rds-icon [namespace]="element.isPinned ? 'filled' : 'outlined'" icon="pinned"></rds-icon>
      </button>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="title">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="content.Title" class="sortable">News title</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="link;context:{
        tooltip: element.title,
        highlight: highlight,
        title: element.title,
        link: element.id,
        linkType: 'route'
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="title.nosort">
    <rds-header-cell *rdsHeaderCellDef>News title</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-container *ngTemplateOutlet="link;context:{
        tooltip: element.title,
        highlight: highlight,
        title: element.title,
        link: element.id,
        linkType: 'route'
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="title-nolink">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="content.Title" class="sortable">News title</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: element.title,
        highlight: highlight,
        title: element.title
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="channel">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="channel.Name" class="sortable">Channel</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: element.channel.name,
        title: element.channel.name
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="assignedBy">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header class="sortable">Assigned by</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <rnb-user-single [user]="element.assignedBy"></rnb-user-single>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="suggestedBy">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="assignedBy" class="sortable">Suggested by</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <rnb-user-single [user]="element.assignedBy"></rnb-user-single>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="type">
    <rds-header-cell *rdsHeaderCellDef>Type</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-container *ngIf="{
        Link: {
          icon: 'link',
          color: 'c-500-extra-dark-green',
          tooltip: 'Added as a link'
        },
        Embedded: {
          icon: 'document',
          color: 'c-400-extra-dark-purple',
          tooltip: 'Created in the editor'
        },
        Announcement: {
          icon: 'document',
          color: 'c-400-extra-dark-purple',
          tooltip: 'People announcement'
        }
      } as types">
        <ng-container *ngTemplateOutlet="icon;context:{
          icon: types[element.type].icon,
          namespace: 'outlined',
          colorClass: types[element.type].color,
          tooltip: types[element.type].tooltip
        }"></ng-container>
      </ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="published">
    <rds-header-cell *rdsHeaderCellDef  rds-sort-header="publishDate" class="sortable">Published</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable"> 
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: element.status === 'Scheduled' ? (element.publishDate | date:'\'Scheduled to be published on \' M/d/yy \'at\' h:mm a') : (element.publishDate | date:'\'Published on \' M/d/yy \'at\' h:mm a'),
        title: (element.publishDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="published.nosort">
    <rds-header-cell *rdsHeaderCellDef>Published</rds-header-cell>
    <rds-cell *rdsCellDef="let element"> 
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: element.status === 'Scheduled' ? (element.publishDate | date:'\'Scheduled to be published on \' M/d/yy \'at\' h:mm a') : (element.publishDate | date:'\'Published on \' M/d/yy \'at\' h:mm a'),
        title: (element.publishDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="approved">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Approved</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.createdDate | date:'\'Approved on \' M/d/yy \'at\' h:mm a'),
        title: (element.createdDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="created">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Created</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.createdDate | date:'\'Created on \' M/d/yy \'at\' h:mm a'),
        title: (element.createdDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="requested">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="suggestedDate" class="sortable">Requested</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.suggestedDate | date:'\'Requested on \' M/d/yy \'at\' h:mm a'),
        title: (element.suggestedDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="archived">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="modifiedDate" class="sortable">Archived</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.modifiedDate | date:'\'Archived on \' M/d/yy \'at\' h:mm a'),
        title: (element.modifiedDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="suggested">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="suggestedDate" class="sortable">Suggested</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.suggestedDate | date:'\'Archived on \' M/d/yy \'at\' h:mm a'),
        title: (element.suggestedDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="response">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="responseDate" class="sortable">Response</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.approvalStatus === 'Rejected'? 'Rejected ' : 'Approved') + (element.responseDate | date:'\' on \' M/d/yy \'at\' h:mm a'),
        title: (element.responseDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="status">
    <rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-container *ngIf="{
        Published: {
          label: 'Published',
          color: 'green'
        },
        Draft: {
          label: 'Draft',
          color: 'blue'
        },
        Scheduled: {
          label: 'Scheduled',
          color: 'neutral'
        },
        Archived: {
          label: 'Archived',
          color: 'yellow'
        }
      } as statuses">
        <ng-container *ngTemplateOutlet="badge;context:{
          color: statuses[element.status].color,
          label: statuses[element.status].label
        }"></ng-container>
      </ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="approvalStatus">
    <rds-header-cell *rdsHeaderCellDef>Status</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <ng-container *ngIf="{
        Pending: {
          label: 'Pending',
          color: 'neutral',
          tooltip: 'Pending approval: ' + ([element.channel.owners, element.channel.managers, element.channel.publishers] | userList:' or ')
        },
        Approved: {
          label: 'Approved',
          color: 'green',
          tooltip: 'Approved by: ' + element.approvedBy?.name

        },
        Rejected: {
          label: 'Rejected',
          color: 'red',
          tooltip: 'Rejected by: ' + element.rejectedBy?.name
        }
      } as statuses">
        <ng-container *ngTemplateOutlet="badge;context:{
          color: statuses[element.approvalStatus].color,
          label: statuses[element.approvalStatus].label,
          tooltip: statuses[element.approvalStatus].tooltip
        }"></ng-container>
      </ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="reject">
    <rds-header-cell *rdsHeaderCellDef>Action</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <button rds-text-button size="m" (click)="rejectClicked.emit({id: element.id, title: element.title})">
        Reject
      </button>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="accept">
    <rds-header-cell *rdsHeaderCellDef></rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <button rds-text-button size="m" (click)="acceptClicked.emit({id: element.id})">
        Accept
      </button>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="reject-disabled">
    <rds-header-cell *rdsHeaderCellDef>Action</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <div rdsTooltip="The action is available only to Channel Owners and Channel Managers">
        <button rds-text-button size="m" [disabled]="true" >
          Reject
        </button>
      </div>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="accept-disabled">
    <rds-header-cell *rdsHeaderCellDef></rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <div rdsTooltip="The action is available only to Channel Owners and Channel Managers">
        <button rds-text-button size="m" [disabled]="true">
          Accept
        </button>
      </div>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="assign">
    <rds-header-cell *rdsHeaderCellDef>Channels assigned</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <button rds-text-button size="m" (click)="assignClicked.emit({id: element.id, isDraft: element.status === 'Draft'})">
        {{element.assignedCount > 0? 'Assigned ('+ element.assignedCount + ')' : 'Assign'}}
      </button>
    </rds-cell>
  </ng-container>

  <!-- API KEYS COLUMNS -->
  
  <ng-container rdsColumnDef="apiKey.identifier">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="user.Identifier" class="sortable">Login</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <rnb-user-single [user]="element.user"></rnb-user-single>
      <ng-container *ngTemplateOutlet="text;context:{
        title: element.user.identifier
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="apiKey.name">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="user.Name" class="sortable">Name</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        title: element.user.name
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="apiKey.email">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="user.Email" class="sortable">Email</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="mail;context:{
        email: element.user.email
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="apiKey.created">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Created</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.createdDate | date:'\'Created on \' M/d/yy \'at\' h:mm a'),
        title: (element.createdDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  
  <!-- ADMINS COLUMNS -->
  <ng-container rdsColumnDef="admin.identifier">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="identifier" class="sortable">Login</rds-header-cell>
    <rds-cell *rdsCellDef="let element">
      <rnb-user-single [user]="element"></rnb-user-single>
      <ng-container *ngTemplateOutlet="text;context:{
        title: element.identifier
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="admin.name">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="name" class="sortable">Name</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        title: element.name
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="admin.role">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="role" class="sortable">Role</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngIf="{
        Admin: {
          label: 'Administrator',
          color: 'c-600-roche-blue'
        },
        SuperAdmin: {
          label: 'Super Administrator',
          color: 'c-200-orange'
        }
      } as roles">
        <ng-container *ngTemplateOutlet="icon;context:{
          icon: 'user',
          namespace: 'outlined',
          colorClass: roles[element.role].color,
          tooltip: roles[element.role].label
        }"></ng-container>
      </ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="admin.email">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="email" class="sortable">Email</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="mail;context:{
        email: element.email
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <ng-container rdsColumnDef="admin.created">
    <rds-header-cell *rdsHeaderCellDef rds-sort-header="createdDate" class="sortable">Created</rds-header-cell>
    <rds-cell *rdsCellDef="let element" class="sortable">
      <ng-container *ngTemplateOutlet="text;context:{
        tooltip: (element.createdDate | date:'\'Created on \' M/d/yy \'at\' h:mm a'),
        title: (element.createdDate | date:'shortDate')
      }"></ng-container>
    </rds-cell>
  </ng-container>

  <rds-header-row class="table__header" *rdsHeaderRowDef="allColumns"></rds-header-row >
  <rds-header-row class="table__bulk" [class.table__hide]="!selection.hasValue()" [class.table__show]="selection.hasValue()" *rdsHeaderRowDef="deleteColumns"></rds-header-row >
  <rds-row *rdsRowDef="let row; columns: allColumns;" [selected]="selection.isSelected(row)"></rds-row>
  <div *rdsNoDataRow class="table__empty">
    <rds-empty-state *ngIf="isLoading === false && !filtersApplied" size="m">
      <rds-empty-state-title>
        <ng-content select="[emptyTitle]"></ng-content>
      </rds-empty-state-title>
      <rds-empty-state-subtitle>
        <ng-content select="[emptySubtitle]"></ng-content>
      </rds-empty-state-subtitle>
      <ng-content select="[rds-empty-state-button]"></ng-content>
    </rds-empty-state>
    <rds-empty-state *ngIf="isLoading === false && filtersApplied" size="m">
      <img rds-empty-state-image src="/assets/empty-results.png" alt="">
      <rds-empty-state-title>No search results.</rds-empty-state-title>
      <rds-empty-state-subtitle>Please check your spelling or adjust filter settings.</rds-empty-state-subtitle>
    </rds-empty-state>
  </div>
  </rds-table>
<rds-paginator
  *ngIf="pagination"
  #paginatorRef 
  class="table__pagination mt-9"
  [length]="pagination.totalCount"
  [pageIndex]="pagination.pageIndex"
  [pageSize]="pagination.pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [showItemsOfPageLabel]="true"
  [showFirstLastButtons]="true"
  [hidePageSize]="false"
  (page)="onPagination($event)"></rds-paginator>

<!-- CELL TEMPLATES -->
  <ng-template 
    #link
    let-tooltip="tooltip"
    let-highlight="highlight"
    let-title="title"
    let-link="link"
    let-linkType="linkType"
  >
    <div class="table__cell" [rdsTooltipDisabled]="!tooltip || !shouldTruncate.shouldTruncate" [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300">
      <a rds-link standalone size="m"
        #shouldTruncate="rnbShouldTruncate"
        rnbShouldTruncate
        (click)="linkClicked.emit({link,linkType})"
      >
      <span [innerHTML]="title | highlight:highlight"></span>
      </a>
    </div>
  </ng-template>

  <ng-template 
    #mail
    let-tooltip="tooltip"
    let-highlight="highlight"
    let-email="email"
  >
    <div class="table__cell" [rdsTooltipDisabled]="!tooltip" [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300">
      <a rds-link standalone size="m"
        [href]="'mailto:' + email"
        [innerHTML]="email | highlight:highlight"
      >
      </a>
    </div>
  </ng-template>

  <ng-template
    #text
    let-tooltip="tooltip"
    let-highlight="highlight"
    let-title="title"
  >
    <div class="table__cell" #shouldTruncate="rnbShouldTruncate" rnbShouldTruncate [rdsTooltipDisabled]="!tooltip || !shouldTruncate.shouldTruncate" [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300">
      <span
        [innerHTML]="title | highlight:highlight"
      >
      </span>
    </div>
  </ng-template>

  <ng-template
    #user
    let-user="user"
  >
    <rds-avatar [rdsTooltip]="user.name" [rdsTooltipShowDelay]="300">
      <img *ngIf="!!user.avatar" rds-avatar-image [src]="user.avatar" referrerpolicy="no-referrer"/>
      <span *ngIf="!user.avatar" rds-avatar-initials>{{user.name | initials}}</span>
    </rds-avatar>
  </ng-template>

  <ng-template
    #icon
    let-icon="icon"
    let-namespace="namespace"
    let-colorClass="colorClass"
    let-tooltip="tooltip"
  >
    <div [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300" style="display:flex">
      <rds-icon [ngClass]="colorClass"
      [namespace]="namespace"
      [icon]="icon"
    ></rds-icon>
    </div>
  </ng-template>

  <ng-template
  #date
  let-tooltip="tooltip"
  let-date="date"
>
  <div class="table__cell" [rdsTooltipDisabled]="!tooltip" [rdsTooltip]="tooltip | date:'shortTime'" [rdsTooltipShowDelay]="300">
    <span>
      {{date | date:'shortDate'}}
    </span>
  </div>
</ng-template>

<ng-template
#badge
let-tooltip="tooltip"
let-color="color"
let-label="label"
>
<div class="table__cell" [rdsTooltipDisabled]="!tooltip" [rdsTooltip]="tooltip" [rdsTooltipShowDelay]="300">
  <rds-badge
    size="m"
    [label]="label"
    type="minimal"
    [color]="color"
  ></rds-badge>
</div>
</ng-template>

