import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[overflowingContent]',
  exportAs: 'overflowingContent'
})
export class OverflowingContentDirective implements OnInit {

  @Input() overflowingContent = 300;

  isExpanded = false;
  isOverflowing = false;


  
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', this.overflowingContent + 'px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'overflow', 'hidden');
    this.isOverflowing = this.elementRef.nativeElement.offsetHeight < (this.elementRef.nativeElement.firstChild as HTMLElement).offsetHeight;
  }

  update() {
    this.isOverflowing = this.elementRef.nativeElement.offsetHeight < (this.elementRef.nativeElement.firstChild as HTMLElement).offsetHeight;
    if (!this.isOverflowing) {
      this.collapse();
    }
  }

  expand() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', 'unset');
    this.isExpanded = true;
  }

  collapse() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', this.overflowingContent + 'px');
    this.isExpanded = false;
  }

}
