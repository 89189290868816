import Command from '@ckeditor/ckeditor5-core/src/command';

export default class CustomBoxImageAlignmentCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const selection = model.document.selection;
            const element = selection.getSelectedElement();
            const elementAttributes = Array.from(element.getAttributes());

            elementAttributes.forEach(attr => {
                if (attr[0] === 'data-image-side') {
                    writer.setAttribute(attr[0], options.value, element);
                } else {
                    writer.setAttribute(attr[0], attr[1], element);
                }
            });
            writer.setSelection( element, 'on' );
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = selection.getSelectedElement();

        this.isEnabled = !!element;

        if ( this.isEnabled && element.hasAttribute( 'data-image-side' ) ) {
            this.value = element.getAttribute( 'data-image-side' );
        } else {
            this.value = false;
        }
    }
}
