import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, of, map } from 'rxjs';
// import { map, tap, catchError} from "rxjs/operators";

import { ApiClient } from '@app/api-client.service';

import { Taxonomy, TaxonomyFlat } from '@app/types';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {
  private _locationsLoaded: boolean;
  private _departmentsLoaded: boolean;
  private _functionsLoaded: boolean;

  private _locations: BehaviorSubject<Taxonomy[]> = new BehaviorSubject([]);
  private _departments: BehaviorSubject<Taxonomy[]> = new BehaviorSubject([]);
  private _functions: BehaviorSubject<Taxonomy[]> = new BehaviorSubject([]);

  get locations(): Observable<Taxonomy[]> {
    return this._locations.asObservable();
  }

  get departments(): Observable<Taxonomy[]> {
    return this._departments.asObservable();
  }

  get functions(): Observable<Taxonomy[]> {
    return this._functions.asObservable();
  }

  constructor(private client: ApiClient) {}

  loadLocations(): void {
    if (!this._locationsLoaded) {
      const url = '/taxonomy/location';
      this.client.get(url).subscribe((values: Taxonomy[]) => {
        this._locations.next(values);
        this._locationsLoaded = true;
      });
    }
  }

  getLocationsFlat() {
    return this.client.get('/taxonomy/location/flat').pipe(
      map((taxonomies: {[id: string]: TaxonomyFlat}) => Object.entries(taxonomies).map(([k,v]) => ({
        ...v,
        parentName: v.parentId ? taxonomies[v.parentId].name : null
      }))
      ))
  }

  loadDepartments(): void {
    if (!this._departmentsLoaded) {
      const url = '/taxonomy/department';
      this.client.get(url).subscribe((values: Taxonomy[]) => {
        this._departments.next(values);
        this._departmentsLoaded = true;
      });
    }
  }

  getDepartmentsFlat() {
    return this.client.get('/taxonomy/department/flat').pipe(
      map((taxonomies: {[id: string]: TaxonomyFlat}) => Object.entries(taxonomies).map(([k,v]) => ({
        ...v,
        parentName: v.parentId ? taxonomies[v.parentId].name : null
      }))
      ))
  }

  loadFunctions(): void {
    if (!this._functionsLoaded) {
      const url = '/taxonomy/function';
      this.client.get(url).subscribe((values: Taxonomy[]) => {
        this._functions.next(values);
        this._functionsLoaded = true;
      });
    }
  }
  
  getFunctionsFlat() {
    return this.client.get('/taxonomy/function/flat').pipe(
      map((taxonomies: {[id: string]: TaxonomyFlat}) => Object.entries(taxonomies).map(([k,v]) => ({
        ...v,
        parentName: v.parentId ? taxonomies[v.parentId].name : null
      }))
      ))
  }

  sync(): Observable<any> {
    return this.client.get('/taxonomy/sync');
  }

  /*
  restructure(values: Taxonomy[]): { [key: string]: TaxonomyFlat} {
    let vals = this.flatten(values);
    return vals.reduce((acc, current) => {
      acc[current.id] = current;
      return acc;
    }, { });
  }

  flatten(values: Taxonomy[], parent?: Taxonomy, addTo?: TaxonomyFlat[]): TaxonomyFlat[] {
    let result = addTo || [ ];
    for(let tax of values) {
      result.push({
        id: tax.id,
        name: tax.name,
        parentId: parent?.id || null,
        parentName: parent?.name || null,
        childrenIds: tax.children?.map((val) => val.id) || [ ],
        isActive: true
      });
      if(tax.children && tax.children.length)
        this.flatten(tax.children, tax, result);
    }
    return result;
  }
*/
}

export { Taxonomy };
