import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ImageAndQuoteBoxCommand from './image-and-quote-box-command';
import CustomBoxResize from "../blocks/custom-box-resize-command";
import CustomBoxImageAlignmentCommand from "../blocks/custom-box-image-alignment-command";

export default class ImageAndQuoteEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        this._defineSchema();
        this._defineConverters();


        this.editor.commands.add('imageAndQuoteBox', new ImageAndQuoteBoxCommand(this.editor));
        this.editor.commands.add('imageAndQuoteBoxAlignment', new CustomBoxImageAlignmentCommand(this.editor));
        this.editor.commands.add('customBoxResize', new CustomBoxResize(this.editor));

    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('imageAndQuoteBox', {
            // Behaves like a self-contained object (e.g. an image).
            inheritAllFrom: '$blockObject',

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',
            allowAttributes: ['data-image-side', 'data-image-width']
        });

        schema.register('imageAndQuoteBoxText', {
            allowIn: 'imageAndQuoteBox',
            inheritAllFrom: '$blockObject',
            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root',
            allowChildren: ['imageAndQuoteBoxTextSignatureBold', 'imageAndQuoteBoxTextSignature']
        });

        schema.register('imageAndQuoteBoxTextSignatureBold', {
            inheritAllFrom: '$block',
            allowIn: 'imageAndQuoteBoxText',
            allowContentOf: ['$text']
        });
        schema.register('imageAndQuoteBoxTextSignature', {
            inheritAllFrom: '$block',
            allowIn: 'imageAndQuoteBoxText',
            allowContentOf: ['$text']
        });

        schema.addChildCheck((context, childDefinition) => {
            if (context.endsWith('imageAndQuoteBoxQuote') && childDefinition.name == 'imageAndQuoteBox') {
                return false;
            }
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        conversion.attributeToAttribute( {
            model: {
                name: 'imageAndQuoteBox',
                key: 'data-image-side'
            },
            view: {
                key: 'data-image-side'
            }
        } );
        conversion.attributeToAttribute( {
            model: {
                name: 'imageAndQuoteBox',
                key: 'data-image-width'
            },
            view: {
                key: 'data-image-width'
            }
        } );
        conversion.for('upcast').elementToElement({
            model: 'imageAndQuoteBox',
            view: {
                name: 'div',
                classes: ['image-and-quote-box']
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'imageAndQuoteBox',
            view: {
                name: 'div',
                classes: ['image-and-quote-box', 'mb-6']
            }
        });


        conversion.for('editingDowncast').elementToElement({
            model: 'imageAndQuoteBox',
            view: (modelElement, {writer: viewWriter}) => {
                const div = viewWriter.createContainerElement('div', {class: 'py-6 my-6 image-and-quote-box'});
                return toWidget(div, viewWriter, {label: 'simple box widget'});

            }
        })

        conversion.for('upcast').elementToElement({
            model: 'imageAndQuoteBoxText',
            view: {
                name: 'div',
                classes: 'image-and-quote-box-text'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imageAndQuoteBoxText',
            view: {
                name: 'div',
                classes: 'image-and-quote-box-text'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'imageAndQuoteBoxText',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement('div', {class: 'image-and-quote-box-text'});
                return toWidgetEditable(div, viewWriter);
            }
        });

        conversion.for('upcast').elementToElement({
            model: 'imageAndQuoteBoxTextSignatureBold',
            view: {
                name: 'p',
                classes: ['image-and-quote-box-text-signature-bold']
            },
            converterPriority: 'high'
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imageAndQuoteBoxTextSignatureBold',
            view: {
                name: 'p',
                classes: ['article-body-s', 'image-and-quote-box-text-signature-bold']
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'imageAndQuoteBoxTextSignatureBold',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const text = viewWriter.createEditableElement('p', {class: 'article-body-s image-and-quote-box-text-signature-bold mt-8'});
                return toWidgetEditable(text, viewWriter);
            }
        });

        conversion.for('upcast').elementToElement({
            model: 'imageAndQuoteBoxTextSignature',
            view: {
                name: 'p',
                classes: ['image-and-quote-box-text-signature']
            },
            converterPriority: 'high'
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imageAndQuoteBoxTextSignature',
            view: {
                name: 'p',
                classes: ['image-and-quote-box-text-signature', 'article-body-s']
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'imageAndQuoteBoxTextSignature',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const text = viewWriter.createEditableElement('p', {class: 'article-body-s image-and-quote-box-text-signature mt-4'});
                return toWidgetEditable(text, viewWriter);
            }
        });



    }
}
export function isHtmlIncluded( dataTransfer ) {
	return Array.from( dataTransfer.types ).includes( 'text/html' ) && dataTransfer.getData( 'text/html' ) !== '';
}
