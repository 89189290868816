import { Component, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRouter from '@app/root-store/router';
import * as fromScreen from '@app/root-store/ui/screen';
import * as fromTable from '../../../store/channel-table';
import { RdsBreadcrumbItem, RdsTabsComponent } from '@rds/angular-components';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rnb-channels-page',
  templateUrl: './channels-page.component.html',
  styleUrls: ['./channels-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelsPageComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild('tabs') tabs: RdsTabsComponent;

  breadcrumbs: Array<RdsBreadcrumbItem> = [
    { text: 'NewsBoard', title: '/' },
    { text: 'Channels' },
  ];

  activeTable: 'all' | 'my' = 'my';

  selectIsDesktop$: Observable<boolean> = this.store$.pipe(
    select(fromScreen.selectIsDesktop)
  );
  constructor(
    private store$: Store<fromScreen.State>,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.store$.dispatch(fromTable.initMyChannels());
    this.store$.dispatch(fromTable.initAllChannels());
  }

  ngAfterViewInit(): void {
    this.activeTable = this.route.snapshot.firstChild.data.table;
    var tabIndex = 0;
    switch ( this.activeTable) {
      case 'my': {
        tabIndex = 0;
        break;
      }
      case 'all': {
        tabIndex = 1;
        break;
      }
    }
    this.tabs.selectTab(tabIndex)
}

  ngOnDestroy(): void {
      this.store$.dispatch(fromTable.clearAll());
  }

  tabsClicked(tabs: RdsTabsComponent) {
    switch ( tabs.selectedIndex) {
      case 0: {
        this.store$.dispatch(fromRouter.go({path: 'channels/my', queryParams: {}}));
        break;
      }
      case 1: {
        this.store$.dispatch(fromRouter.go({path: 'channels/all', queryParams: {}}));
        break;
      }
    }
  }

  breadcrumbItemClicked(event) {
    if (event.title) {
      this.store$.dispatch(fromRouter.go({path: event.title, queryParams: {}}));
    }
  }
}
