import { createSelector } from '@ngrx/store';

import * as fromUi from '../ui.reducer';
import * as fromToast from './toast.reducer';

export const selectState = createSelector(
  fromUi.selectState,
  fromUi.getToast
);

