import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../auth/auth-guard.service';
import { ContainerComponent } from '../utils/container/container.component';

import { NewsPageComponent } from './components/single-channel/news-page/news-page.component';
import { MyNewsTableContainer } from './components/single-channel/tables/my-news-table/my-news-table.container';
import { MySuggestedNewsTableContainer } from './components/single-channel/tables/my-suggested-news-table/my-suggested-news-table.container';
import { MyArchivedNewsTableContainer } from './components/single-channel/tables/my-archived-news-table/my-archived-news-table.container';
import { AllNewsTableContainer } from './components/single-channel/tables/all-news-table/all-news-table.container';
import { AdminGuardService } from '@app/admin/admin-guard.service';
import { CreateNewsDashboardComponent } from './components/create-news-dashboard/create-news-dashboard.component';
import { NewsFormComponent } from './components/news-form/news-form.component';
import { TemplateFormComponent } from './components/template-form/template-form.component';

const routes: Routes = [
  {
    path: 'news',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        component: ContainerComponent,
        children: [
          {
            path: '',
            component: NewsPageComponent,
            children: [
              {
                path: '',
                redirectTo: 'my',
                pathMatch: 'full'
              },
              {
                path: 'my',
                component: MyNewsTableContainer,
                data: { table: 'my' },
              },
              {
                path: 'suggested',
                component: MySuggestedNewsTableContainer,
                data: { table: 'suggested'},
              },
              {
                path: 'archived',
                component: MyArchivedNewsTableContainer,
                data: { table: 'archived'},
              },
                          {
                path: 'all',
                component: AllNewsTableContainer,
                canActivate: [AdminGuardService],
                data: { table: 'all' },
              },
            ]
          },
          {
            path: 'create',
            component: CreateNewsDashboardComponent
          },
          {
            path: 'create/link',
            component: NewsFormComponent,
            data: {
              contentType: 'Link',
              isTemplate: false
            }
          },
          {
            path: 'create/content',
            component: NewsFormComponent,
            data: {
              contentType: 'Embedded',
              isTemplate: false
            }
          },
          {
            path: 'create/template',
            component: TemplateFormComponent,
          },
          {
            path: 'edit/template/:id',
            component: TemplateFormComponent,
          },
          {
            path: ':id/edit',
            component: NewsFormComponent,
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule {}

