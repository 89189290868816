import { Component, EventEmitter, Host, HostBinding, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { RdsDialogService, RdsFileItem, RdsFileUploader } from '@rds/angular-components';
import { CropImageDialogComponent } from '@app/utils/crop-image-dialog/crop-image-dialog.component';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NewsImage } from '@app/types';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '@app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'rnb-upload',
  templateUrl: './upload.component.html',
  styleUrls: [ './upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UploadComponent
    }
  ]
})
export class UploadComponent implements OnInit {
  private subs: SubSink = new SubSink();
  file: NewsImage;
  @Input() id: string = 'upload';
  @Input() size: 's' | 'm' | 'l' = 'l';
  @Input() disabled: boolean;
  @Input() hasDropZone: boolean;
  @Input() aspectRatio = 16 / 9;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @HostBinding('class') class = `rds-upload-size-${this.size}`;

  @Output() uploadStarted: EventEmitter<string> = new EventEmitter();
  @Output() uploadEnded: EventEmitter<string> = new EventEmitter();
  @Output() uploadAborted: EventEmitter<string> = new EventEmitter();
  @Output() uploadFailed: EventEmitter<string> = new EventEmitter();
  
  headers: HttpHeaders = new HttpHeaders({'Authorization': `Bearer ${this.auth.accessToken}`});
  
  uploader = new RdsFileUploader({
    autoUpload: true,
    url: `${environment.apiBase}/uploadFile`,
    method: 'POST',
    headers: this.headers,
    removeAfterUpload: true,
    accept: ['image/*']
  });
  
  lastUploadedFile: RdsFileItem;
  
  onChange = (file) => {};

  onTouched = () => {};

  touched = false;

  writeValue(file: NewsImage) {
    this.file = file;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clearControl() {
    this.onChange(null);
    this.markAsTouched();
  }

  clearQueue(fileItem) {
    this.uploader.removeFromQueue(fileItem);
  }

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }
  
  get hasError() {
    return this.control?.status === 'INVALID' && this.control?.touched;
  }

  constructor(
    private auth: AuthService, private dialogService: RdsDialogService, @Optional() @Host() @SkipSelf()
  private controlContainer: ControlContainer) {
  }
  ngOnInit(): void {
    this.subs.sink = this.uploader.fileItemError$.subscribe(res => {
      this.uploadFailed.emit(this.id);
    });
    this.subs.sink = this.uploader.fileItemCanceled$.subscribe(res => {
      this.uploadAborted.emit(this.id);
    });
    this.subs.sink = this.uploader.fileItemSuccess$.subscribe(res => {
      this.uploadEnded.emit(this.id);
      this.lastUploadedFile = res.item;
      const {id, url} = JSON.parse(res.response);
      this.file = {
        id,
        url,
        name: res.item.file.name,
        size: res.item.file.size,
      }
      this.onChange(this.file);
    })
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onFileSelected(event) {
    if (this.uploader.queue.length > 0) {
      this.uploader.queue.map((q) => this.uploader.removeFromQueue(q))
    }
    const file = event[0];
    if (this.isGif(file.type) || !this.isImage(file.type)) {
      this.uploader.addToQueue([file]);
      this.uploadStarted.emit(this.id);
    } else {
      const dialogRef = this.dialogService.open(CropImageDialogComponent, {
        size: 'l',
        data: {
          aspectRatio: this.aspectRatio,
          file
        }
      });
      this.subs.sink = dialogRef.afterClosed().subscribe((croppedImage) => {
        this.control.markAsTouched();
        if (!!croppedImage) {
          this.uploader.addToQueue([croppedImage]);
          this.uploadStarted.emit(this.id);
        }
      });
    }
  }

  isGif(type: string): boolean {
    return /(gif)/i.test(type);
  }

  isImage(type: string): boolean {
    return /(image)/i.test(type);
  }
}
