import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Channel, NewsSuggestion, SimpleUser } from '@app/types';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { debounceTime } from 'rxjs';

export interface RejectionCommentDialogData {
  news: NewsSuggestion
}
@Component({
  selector: 'atr-rejection-comment-dialog',
  templateUrl: './rejection-comment-dialog.component.html',
  styleUrls: ['./rejection-comment-dialog.component.scss']
})
export class RejectionCommentDialogComponent {

  constructor(@Inject(RDS_DIALOG_DATA) public data: RejectionCommentDialogData) {
   }
}
