import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentType, NewsStatus } from '@app/types';
import { Store, select } from '@ngrx/store';
import * as fromForm from '../../store/form'
import * as fromRelatedNews from '../../store/related-news'
import { AuthService } from '@app/auth/auth.service';
import { Observable, debounceTime } from 'rxjs';
import { FormStepStatus } from './form-status-badge/form-status-badge.component';
import { useTemplate } from '@app/news/store/templates';
import { Template } from '@app/types/template';
import { SubSink } from 'subsink';

const SCROLL_DELAY = 60;

@Component({
  selector: 'rnb-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: [ './news-form.component.scss' ]
})
export class NewsFormComponent implements OnDestroy {
  private subs: SubSink = new SubSink();

  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLDivElement>;
  id: number;
  newsStatus: NewsStatus;

  newsStatus$: Observable<NewsStatus> = this.store$.pipe(select(fromForm.selectNewsStatus), debounceTime(100));
  newsType$: Observable<ContentType> = this.store$.pipe(select(fromForm.selectType), debounceTime(100));
  canSaveAsDraft$: Observable<boolean> = this.store$.pipe(select(fromForm.canSaveAsDraft), debounceTime(300));
  canUnpublish$: Observable<boolean> = this.store$.pipe(select(fromForm.canUnpublish), debounceTime(300));
  canSaveAsTemplate$: Observable<boolean> = this.store$.pipe(select(fromForm.canSaveAsTemplate), debounceTime(300));
  canPublish$: Observable<boolean> = this.store$.pipe(select(fromForm.canPublish), debounceTime(300));
  generalStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromForm.selectGeneralStepStatus), debounceTime(100));
  contentStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromForm.selectContentStepStatus), debounceTime(100));
  publishingStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromForm.selectPublishingStepStatus), debounceTime(100));
  permissionsStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromForm.selectPermissionsStepStatus), debounceTime(100));
  changesMade$: Observable<boolean> = this.store$.pipe(select(fromForm.selectChangesMade));
  usedTemplate$: Observable<Template> = this.store$.pipe(select(fromForm.selectCreateFromTemplate));

  constructor(private auth: AuthService, private store$: Store<any>, private route: ActivatedRoute) {
    const templateId = +this.route.snapshot.queryParams.useTemplate;
    const contentType = this.route.snapshot.data.contentType;
    this.id = this.route.snapshot.params.id;
    if (!!templateId) {
      this.store$.dispatch(fromForm.initCreateFormFromTemplate({templateId, user: this.auth.currentSimpleUser, contentType, }));
    } else {
      this.store$.dispatch(fromForm.initAddForm({user: this.auth.currentSimpleUser, contentType, id: this.id}));
    }
    if (!!this.id) {
      this.store$.dispatch(fromForm.loadAssignements({id: this.id}));
    } else {
      this.store$.dispatch(fromForm.loadAssignements({}));
    }
    this.subs.sink = this.newsStatus$.subscribe(res => {
      this.newsStatus = res;
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.store$.dispatch(fromForm.clearAll());
    this.store$.dispatch(fromRelatedNews.clearAll());
  }

  openAddLanguageDialog() {
    this.store$.dispatch(fromForm.openAddLanguageDialog());
  }

  openPreviewDialog() {
    this.store$.dispatch(fromForm.openPreviewDialog());
  }

  saveAsDraft() {
    this.store$.dispatch(fromForm.saveAsDraftRequest());
  }

  saveAsPublish() {
    this.store$.dispatch(fromForm.saveAsPublishRequest());
  }

  updateAsDraft(id) {
    this.store$.dispatch(fromForm.updateAsDraftRequest({id}));
  }

  updateAsPublish(id, status) {
    if (status === 'Published') {
      this.store$.dispatch(fromForm.openPublishChangesConfirmDialog({id}));
    } else {
      this.store$.dispatch(fromForm.updateAsPublishRequest({id}));
    }
  }

  openLeavePageModal() {
    this.store$.dispatch(fromForm.openLeavePageDialog({url: 'news/create'}));
  }

  openUnpublishConfirmModal(id) {
    this.store$.dispatch(fromForm.openUnpublishConfirmDialog({id}));
  }

  scrollTop() {
    setTimeout(() => window.scroll({top: 0, behavior: 'smooth'}), SCROLL_DELAY);
  }
}
