import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from '@app/root-store/dictionaries/languages/languages.actions';
import { Language } from '@app/types';

export interface State extends EntityState<Language> {}

export const adapter: EntityAdapter<Language> =
  createEntityAdapter<Language>({
    selectId: location => location.code
});

export const initialState: State = {
  ...adapter.getInitialState()
};

const languagesReducer = createReducer(
  initialState,
  on(fromActions.languagesCollectionAddMany, (state, {languages}) => (
    adapter.addMany(languages, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return languagesReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;

export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<Language>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<Language>, {ids}) => ids.map(id => entities[id]);

