import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromTable from '../../../store/channel-table';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { AuthService } from '@app/auth/auth.service';
import { TableColumn, TableComponent } from '@app/utils/table/table.component';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { ChannelTableFilters } from '@app/utils/table/table-filters/filters-model';
import { getDeleteChannelDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';

@Component({
  selector: 'rnb-all-channels-table',
  templateUrl: './all-channels-table.container.html',
  styleUrls: ['./all-channels-table.container.scss']
})
export class AllChannelsTableContainer implements OnInit, OnDestroy {
  @ViewChild('table') tableRef: TableComponent;

  subs: SubSink = new SubSink();

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  columns: Array<string> = [ this.isAdmin || this.isSuperAdmin ? 'name': 'name-nolink', 'owners', 'managers', 'publishers', 'description'];

  channels: Array<Partial<Channel>> = []; 
  channels$: Observable<Array<Partial<Channel>>> = this.store$.pipe(select(fromTable.selectAllChannelsData));
  
  pagination$: Observable<Pagination> = this.store$.pipe(select(fromTable.selectAllChannelsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromTable.selectAllChannelsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromTable.selectAllChannelsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromTable.selectAllChannelsDefaultSort));

  filters$: Observable<ChannelTableFilters> = this.store$.pipe(select(fromTable.selectAllChannelsFilters));
  defaultFilters$: Observable<ChannelTableFilters> = this.store$.pipe(select(fromTable.selectAllChannelsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromTable.selectAllChannelsSearch));

  constructor(
    private store$: Store<fromTable.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

  }

  filtersChanged(filters: ChannelTableFilters) {
    this.store$.dispatch(fromTable.changeAllChannelsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromTable.changeAllChannelsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromTable.changeAllChannelsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<Channel>) {
  }
  
  deleteChannels(selection: SelectionModel<Channel>) {
    const ids = selection.selected.map(c => c.id);
    const data: ConfirmDialogData = getDeleteChannelDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    this.subs.sink = dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => {
      this.store$.dispatch(fromTable.deleteAllChannelsRequest({ids: data.ids}))
    });
  }

  deleteChannel(id: string, name: string) {
    const ids = [+id]
    const data: ConfirmDialogData = getDeleteChannelDialog(ids, name);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    this.subs.sink = dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromTable.deleteAllChannelsRequest({ids: data.ids})));
  }
  
  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    switch (linkType) {
      case 'route':
        this.store$.dispatch(fromRouter.go({path: link, queryParams: {}}));
        break;
      case 'url':
        this.store$.dispatch(fromRouter.goOutsideInNewTab({url: link, redirect: true}))
        break;
    }
  }
}
