import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromReducer from '../header.reducer';
import * as fromActions from '../header/header.actions';
import {mergeMap} from 'rxjs/operators';
import * as fromRouter from '../../../root-store/router/router.actions';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderEffects {

  public goToSearch$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.goToSearch),
    mergeMap(({phrase}) => [fromRouter.goOutsideInCurrentTab({url: `${environment.rocheHomeLink}/search/intranet?phrase=${phrase}`})])
  ), {dispatch: true}
);

  constructor(
    private actions$: Actions,
  ) {
  }
}
