import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromForm from '../../../store/form';
import * as fromLanguages from '@app/root-store/dictionaries/languages';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentType, Language, NewsForm } from '@app/types';
import { RdsSelectFilterByFunc, RdsSelectOptionComponent } from '@rds/angular-components';
import { FormStepStatus } from '../form-status-badge/form-status-badge.component';
import { SubSink } from 'subsink';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'rnb-form-general-information',
  templateUrl: './form-general-information.component.html',
  styles: [
  ]
})
export class FormGeneralInformationComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();

  authorAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('author')))
  languages$: Observable<Array<Language>> = this.store$.pipe(select(fromLanguages.selectAll));

  get isAdmin() {
    return this.auth.currentUser.isAdmin || this.auth.currentUser.isSuperAdmin;
  }

  _isActive: Array<boolean>;
  get isActive(): Array<boolean> {
      return this._isActive;
  }
  @Input() set isActive(value: Array<boolean>) {
      this._isActive = value;
      this.store$.dispatch(fromForm.setGeneralFormStatus({status: this.form.status}));
      if (value) {
      this.store$.dispatch(fromForm.setGeneralStepStatus({status: FormStepStatus.IN_PROGRESS}));
      } else {
        this.form.markAllAsTouched();
        this.titleForm.markAllAsTouched();
        if (this.form.valid && this.titleForm.valid) {
          this.store$.dispatch(fromForm.setGeneralStepStatus({status: FormStepStatus.COMPLETED}));
        } else {
          this.store$.dispatch(fromForm.setGeneralStepStatus({status: FormStepStatus.INCOMPLETE}));
        }
      }
  }

  filterBy: RdsSelectFilterByFunc = (
    text: string | null,
    item: RdsSelectOptionComponent
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.value.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase());
    }
  };

  newsType$: Observable<ContentType> = this.store$.pipe(select(fromForm.selectType));

  primaryTitle$: Observable<string> = this.store$.pipe(select(fromForm.selectPrimaryLanguageTitle));
  public titleForm: FormGroup = new FormGroup({
    title: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    keepSync: new FormControl(true)
  });

  form$: Observable<Partial<NewsForm>> = this.store$.pipe(select(fromForm.selectGeneralForm));
  public form: FormGroup = new FormGroup({
    id: new FormControl(null),
    type: new FormControl(null),
    authors: new FormControl([]),
    byline: new FormControl(null, [Validators.required]),
    contact: new FormControl(null, [Validators.required, Validators.email]),
    language: new FormControl(null),
    commentsEnabled: new FormControl(false)
  });

  constructor(private store$: Store<any>, private auth: AuthService) {

  }

  ngOnDestroy(): void {
    this.form.markAllAsTouched();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(fromLanguages.loadLanguages());
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'author'}));

    this.subs.sink = this.form$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(news => {
      console.log(news);
      this.form.patchValue(news, {emitEvent: false});
      this.store$.dispatch(fromForm.setGeneralFormStatus({status: this.form.status}));
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(form => {
      this.store$.dispatch(fromForm.setFormValue({form}))
    });

    this.subs.sink = this.primaryTitle$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(title => {
      this.titleForm.patchValue({title}, {emitEvent: false});
    });

    this.subs.sink = this.titleForm.controls.title.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(title => {
      this.store$.dispatch(fromForm.updatePrimaryTitle({title}))
    });

    this.subs.sink = this.form.controls.language.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(lang => {
      this.store$.dispatch(fromForm.primaryLanguageChanged({lang}))
    });

    this.subs.sink = this.form.statusChanges.subscribe(value => {
      this.store$.dispatch(fromForm.setGeneralFormStatus({status: value}));
    });
  }

  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
   }


}
