import Command from '@ckeditor/ckeditor5-core/src/command';
import {  findOptimalInsertionRange } from '@ckeditor/ckeditor5-widget/src/utils';
import {checkIsCustomBlock} from "../blocks/blocks-utils";
export default class DividerCommand extends Command {
	refresh() {
		const model = this.editor.model;
		const schema = model.schema;
		const selection = model.document.selection;
		const element = model.document.selection.getSelectedElement();
		const isHorizontal = isHorizontalLineAllowedInParent( selection, schema, model );
		const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
		this.isEnabled = isHorizontal && !customBlockSelected;
	}
	execute() {
		const model = this.editor.model;

		model.change( writer => {
			const horizontalElement = writer.createElement( 'divider' );

			model.insertObject( horizontalElement, null, null, { setSelection: 'after',
				findOptimalPosition: 'auto'  } );
		} );
	}
}
function isHorizontalLineAllowedInParent( selection, schema, model ) {
	const parent = getInsertHorizontalLineParent( selection, model );

	return schema.checkChild( parent, 'divider' );
}

function getInsertHorizontalLineParent( selection, model ) {
	const insertionRange = findOptimalInsertionRange( selection, model );
	const parent = insertionRange.start.parent;

	if ( parent.isEmpty && !parent.is( 'element', '$root' ) ) {
		return parent.parent;
	}

	return parent;
}
