import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import TextBoxUi from './text-box-ui';
import TextBoxEditing from './text-box-editing';
import TextBoxBlockBackgroundUi from "./text-box-block-background-ui";

export default class TextBox extends Plugin {
  static get requires() {
      return [ TextBoxEditing, TextBoxUi, TextBoxBlockBackgroundUi ];
  }
}
