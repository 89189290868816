<div class="table-filters" [formGroup]="filters">
  <p *ngIf="showLabel" class="table-filters__label">Filter by:</p>
  <ng-container *ngFor="let filter of filters.controls | keyvalue: showFiltersInOrder">
    <ng-container *ngIf="filter.value.controls.changable.value" [formGroup]="filters.get(filter.key)">
      <rds-form-field *ngIf="!!filter.value.controls.options.value" class="table-filters__control--select">
        <rds-control-label>{{filter.value.controls.label.value}}</rds-control-label>
        <rds-select formControlName="value" [multiple]="filter.value.controls.multiple.value">
          <rds-select-option *ngFor="let option of filter.value.controls.options.value" [value]="option.value">{{option.label}}</rds-select-option>
        </rds-select>
      </rds-form-field>
      <rds-form-field *ngIf="!filter.value.controls.options.value" class="table-filters__control--search">
        <rds-control-label>Search</rds-control-label>
        <rds-icon
            rds-control-prefix
            namespace="outlined"
            icon="search"
        ></rds-icon>
        <input
            rdsInput
            formControlName="value"
        />
        <div class="table-filters__control--search-clear">
          <button *ngIf="filter.value.controls.value.value.length" rds-icon-button rds-control-suffix (click)="clearSearch()">
            <rds-icon
              rds-control-suffix
              namespace="outlined"
              icon="close"
            ></rds-icon>
          </button>
        </div>
    </rds-form-field>
    </ng-container>
  </ng-container>
  <button *ngIf="showLabel" class="table-filters__clear-button" [disabled]="!disableClear" rds-text-button (click)="clearAll()">Clear</button>
</div>