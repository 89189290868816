import {ModuleWithProviders, NgModule} from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, ROOT_REDUCER } from './root.reducer';
import { environment } from 'src/environments/environment';
import { CustomSerializer, RouterEffects } from './router';
import { ScreenEffects } from './ui/screen';
import { RouterStateSerializer } from '@ngrx/router-store';
import { ToastEffects } from './ui/toast';
import { RdsModule } from '@app/rds.module';
import { SuggestionsEffects } from './suggestions';
import { LocationsFlatEffects } from './dictionaries/locations-flat';
import { DepartmentsFlatEffects } from './dictionaries/departments-flat';
import { FunctionsFlatEffects } from './dictionaries/functions-flat';
import { LanguagesEffects } from './dictionaries/languages';
import { TimezonesEffects } from './dictionaries/timezones';
import { BackButtonEffects } from './ui/back-button';

const GLOBAL_EFFECTS = [
  RouterEffects,
  ScreenEffects,
  ToastEffects,
  SuggestionsEffects,
  LocationsFlatEffects,
  DepartmentsFlatEffects,
  FunctionsFlatEffects,
  LanguagesEffects,
  TimezonesEffects,
  BackButtonEffects
];

@NgModule({
  imports: [
    RdsModule,
    StoreModule.forRoot(ROOT_REDUCER, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      }
    }),
    EffectsModule.forRoot(GLOBAL_EFFECTS),
    environment.production ? [] : StoreDevtoolsModule.instrument({ name: 'NewsBoard state' }),
  ],
  declarations: [],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ]
})
export class RootStoreModule {
  static forRoot(): ModuleWithProviders<RootStoreModule> {
    return {
      ngModule: RootStoreModule,
      providers: []
    };
  }
}

