import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromActions from './channel-details.actions';

import { SortDirection } from '@rds/angular-components';
import { Channel, ChannelDetails, News } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { DefaultNewsTableFilters, NewsTableFilters, NEWS_APPROVAL_STATUSES, NEWS_STATUSES, NEWS_TYPES, INITIAL_CHANNEL_ALL_NEWS_FILTERS, INITIAL_CHANNEL_NEWS_SUGGESTION_FILTERS, INITIAL_CHANNEL_ARCHIVED_NEWS_FILTERS } from '@app/utils/table/table-filters/filters-model';

export const feature = 'details';

export interface TableState {
  data: EntityState<Partial<News>>,
  pagination: Pagination,
  sort: {
    active: string;
    direction: SortDirection
  },
  defaultSort: {
    active: string;
    direction: SortDirection
  },
  filters: NewsTableFilters;
  defaultFilters: NewsTableFilters;
  isLoading: boolean;
  resetIndex: boolean;
}


export const adapter: EntityAdapter<Partial<News>> =
 createEntityAdapter<Partial<News>>({
  selectId: (e) => e.id
 });

export interface State {
  details: ChannelDetails;
  newsPreviewDetails: News;
  allNews: TableState;
  newsSuggestions: TableState;
  archivedNews: TableState;
}

export const initialState: State = {
  details: null,
  newsPreviewDetails: null,
  allNews: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'createdDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'createdDate',
      direction: 'desc'
    },
    filters: INITIAL_CHANNEL_ALL_NEWS_FILTERS,
    defaultFilters: INITIAL_CHANNEL_ALL_NEWS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  newsSuggestions: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'suggestedDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'suggestedDate',
      direction: 'desc'
    },
    filters: INITIAL_CHANNEL_NEWS_SUGGESTION_FILTERS,
    defaultFilters: INITIAL_CHANNEL_NEWS_SUGGESTION_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
  archivedNews: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'modifiedDate',
      direction: 'desc'
    },
    defaultSort: {
      active: 'modifiedDate',
      direction: 'desc'
    },
    filters: INITIAL_CHANNEL_ARCHIVED_NEWS_FILTERS,
    defaultFilters: INITIAL_CHANNEL_ARCHIVED_NEWS_FILTERS,
    isLoading: false,
    resetIndex: false,
  },
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.setChannelDetails, (state, {details}) => ({
    ...state,
    details
  })),
  
  on(fromActions.getAllNewsRequest, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      isLoading: true
    }
  })),
  on(fromActions.getAllNewsSuccess, fromActions.getAllNewsFailure, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      isLoading: false
    }
  })),
  on(fromActions.getAllNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      data: adapter.setAll(data, state.allNews.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.removeAllNewsSuccess, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      resetIndex: true
    }
  })),
  on(fromActions.changeAllNewsFilters, (state, {filters}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeAllNewsSort, (state, {active, direction}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeAllNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    allNews: {
      ...state.allNews,
      pagination: {
        ...state.allNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.getNewsSuggestionsRequest, (state) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      isLoading: true
    }
  })),
  on(fromActions.getNewsSuggestionsSuccess, fromActions.getNewsSuggestionsFailure, (state) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      isLoading: false
    }
  })),
  on(fromActions.getNewsSuggestionsSuccess, (state, {data, pagination}) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      data: adapter.setAll(data, state.newsSuggestions.data),
      pagination,
      resetIndex: false
    }
  })),
  on(fromActions.changeNewsSuggestionsFilters, (state, {filters}) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeNewsSuggestionsSort, (state, {active, direction}) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeNewsSuggestionsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      pagination: {
        ...state.newsSuggestions.pagination,
        pageIndex,
        pageSize
      }
    }
  })),

  
  on(fromActions.getArchivedNewsRequest, (state) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      isLoading: true
    }
  })),
  on(fromActions.getArchivedNewsSuccess, fromActions.getArchivedNewsFailure, (state) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      isLoading: false
    }
  })),
  on(fromActions.getArchivedNewsSuccess, (state, {data, pagination}) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      data: adapter.setAll(data, state.archivedNews.data),
      pagination,
      resetIndex: false
    }
  })),
  on(fromActions.removeArchivedNewsSuccess, (state) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      resetIndex: true
    }
  })),
  on(fromActions.changeArchivedNewsFilters, (state, {filters}) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      filters,
      resetIndex: true
    }
  })),
  on(fromActions.changeArchivedNewsSort, (state, {active, direction}) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeArchivedNewsPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    archivedNews: {
      ...state.archivedNews,
      pagination: {
        ...state.archivedNews.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
  on(fromActions.rejectNewsSuccess, (state) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      resetIndex: true
    }
  })),
  on(fromActions.acceptNewsSuccess, (state) => ({
    ...state,
    newsSuggestions: {
      ...state.newsSuggestions,
      resetIndex: true
    },
    allNews: {
      ...state.allNews,
      resetIndex: true
    }
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
  on(fromActions.pinNewsSuccess, (state) => ({
    ...state,
    allNews: {
      ...state.allNews,
      resetIndex: true
    }
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}

export const getDetails = (state: State) => state.details;
export const getChannelId = (details: ChannelDetails) => details?.id;
export const getRoleInChannel = (details: ChannelDetails) => details?.role;

export const getAllNews = (state: State) => state.allNews;
export const getNewsSuggestions = (state: State) => state.newsSuggestions;
export const getArchivedNews = (state: State) => state.archivedNews;

export const getPagination = (table: TableState) => table.pagination;
export const getSort = (table: TableState) => table.sort;
export const getDefaultSort = (table: TableState) => table.defaultSort;
export const getFilters = (table: TableState) => table.filters;
export const getDefaultFilters = (table: TableState) => table.defaultFilters;
export const getIsLoading = (table: TableState) => table.isLoading;

export const getRequestData = ({pagination, sort, filters, resetIndex}: TableState) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  filters,
  resetIndex});

export const getEventsData = (table: TableState) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: NewsTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<Channel>, ids: string[]): Channel[] => ids.map(id => entities[id]);
