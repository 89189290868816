import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromNewsTable from '../../../../store/news-table';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel, News } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { NewsTableFilters } from '../../../../store/news-table';
import { AuthService, SimpleUser } from '@app/auth/auth.service';
import { TableColumn } from '@app/utils/table/table.component';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { getArchiveNewsDialog, getDeleteNewsDialog, getUnpublishNewsDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';

@Component({
  selector: 'rnb-my-news-table',
  templateUrl: './my-news-table.container.html',
  styleUrls: ['./my-news-table.container.scss']
})
export class MyNewsTableContainer implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();

  get identifier(): string {
    return this.auth.currentUser.identifier;
  }

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  columns: Array<string> = ['title', 'owners', 'type', 'created', 'published', 'status', 'assign'];

  bulkPredicate = (news: News) => {
    return this.canDeleteNews(news);
  }

  news: Array<Partial<News>> = []; 
  news$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromNewsTable.selectMyNewsData));
  
  pagination$: Observable<Pagination> = this.store$.pipe(select(fromNewsTable.selectMyNewsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromNewsTable.selectMyNewsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromNewsTable.selectMyNewsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromNewsTable.selectMyNewsDefaultSort));

  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectMyNewsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectMyNewsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromNewsTable.selectMyNewsSearch));

  constructor(
    private store$: Store<fromNewsTable.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

  }

  canEditNews(news: News) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier) || !!news.editors.find(u => u.identifier === this.identifier)
  }

  canDeleteNews(news: News) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier)
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromNewsTable.changeMyNewsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromNewsTable.changeMyNewsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromNewsTable.changeMyNewsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<News>) {
  }
  
  deleteNews(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = getDeleteNewsDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteMyNewsRequest({ids: data.ids})));
  }

  deleteSingleNews(id: string, newsTitle) {
    const ids = [+id];
    const data: ConfirmDialogData = getDeleteNewsDialog(ids, newsTitle);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteMyNewsRequest({ids: data.ids})));
  }

  archiveNews(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = getArchiveNewsDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.archiveMyNewsRequest({ids: data.ids})));
  }

  archiveSingleNews(id, title)  {
    const ids = [+id];
    const data: ConfirmDialogData = getArchiveNewsDialog(ids, title);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.archiveMyNewsRequest({ids: data.ids})));
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    this.openNewsPreview(+link);
  }

  onAssignClick({id, isDraft}) {
    this.store$.dispatch(fromNewsTable.openAssignementsDialog({newsId: id, isDraft}));
  }

  openNewsPreview(newsId: number) {
    this.store$.dispatch(fromNewsTable.openNewsPreview({newsId, backButton: {
      label: 'My News',
      routeBack: 'news/my',
      routeCurrent: `/news/${newsId}/edit`
    }}))
  }

  duplicate(id) {
    this.store$.dispatch(fromNewsTable.duplicateNewsRequest({newsId: id }));
  }

  saveAsTemplate(id) {
    this.store$.dispatch(fromNewsTable.openSaveAsTemplateDialog({newsId: id }));
  }

  unpublish(id, title)  {
    const ids = [+id];
    const data: ConfirmDialogData = getUnpublishNewsDialog(ids, title);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data =>  this.store$.dispatch(fromNewsTable.unpublishNewsRequest({newsId: id })));
  }

}
