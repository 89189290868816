<ng-template #formActions>
    <button rds-secondary-button *ngIf="newsStatus !== 'Published'" [disabled]="(canSaveAsDraft$ | async) === false" size="l" (click)="!id ? saveAsDraft() : updateAsDraft(id)">Save as draft</button>
    <button rds-secondary-button *ngIf="newsStatus === 'Published'" [disabled]="(canUnpublish$ | async) === false" size="l" (click)="openUnpublishConfirmModal(id)">Unpublish</button>
    <button rds-secondary-button size="l" (click)="openPreviewDialog()">Preview</button>
    <button rds-primary-button  [disabled]="(canPublish$ | async) === false" size="l" (click)="!id ? saveAsPublish() : updateAsPublish(id, newsStatus)">Publish</button>
</ng-template>
<div #scrollContainer class="row">
  <div class="col lmy-3">
    <rnb-back-button [leavePageWarning]="true" [changesMade]="changesMade$ | async" defaultLabel="Add News" defaultRoute="news/create"></rnb-back-button>
  </div>
</div>
<rnb-view-bar [hideAddNews]="true">
  <span title>
    <ng-container *ngIf="!(usedTemplate$ | async)">
      <span *ngIf="!!id">
        Edit news
      </span>
      <span *ngIf="!id && (newsType$ | async) === 'Embedded'">
        Create news in editor
      </span>
      <span *ngIf="!id && (newsType$ | async) === 'Link'">
        Add news via link
      </span>
    </ng-container>
    <ng-container *ngIf="(usedTemplate$ | async) as template">
      <span>
        Create news using Template
        <p class="ui-label-l">{{template.title}}</p>
      </span>
    </ng-container>
  </span>
  <ng-container *ngTemplateOutlet="formActions"></ng-container>
</rnb-view-bar>
<div class="row">
  <div class="col mb-7">
    <rds-stepper  #stepper [linear]="false" orientation="vertical" [sideBySide]="true" (selectionChange)="scrollTop()">
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            General information
            <atr-form-status-badge [status]="(generalStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="1"
            stepName="General information"
            [stepStatus]="(generalStatus$ | async)">
            <rnb-form-general-information [isActive]="stepper.selectedIndex === 0"></rnb-form-general-information>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>

          </rnb-step-content>
      </ng-template>
      </rds-step>
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            {{(newsType$ | async) === 'Link' ? 'Add link and create abstract' : 'Content'}}
            <atr-form-status-badge [status]="(contentStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="2"
            [stepName]="(newsType$ | async) === 'Link' ? 'Add link and create abstract' : 'Content'"
            [stepStatus]="(contentStatus$ | async)">
            <button actions rds-secondary-button size="m" (click)="openAddLanguageDialog()">Add language version</button>
            <rnb-form-content [isActive]="stepper.selectedIndex === 1"></rnb-form-content>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>
          </rnb-step-content>
      </ng-template>
      </rds-step>
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            Publishing info
            <atr-form-status-badge [status]="(publishingStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="3"
            stepName="Publishing info"
            [stepStatus]="(publishingStatus$ | async)">
            <rnb-form-publishing-info [isActive]="stepper.selectedIndex === 2"></rnb-form-publishing-info>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>
          </rnb-step-content>
      </ng-template>
      </rds-step>
      <rds-step [editable]="true">
        <ng-template rdsStepLabel>
          <div class="step-label">
            <div>
              Assign editors and owners <span class="ui-label-s">(optional)</span>
            </div>
            <atr-form-status-badge [status]="(permissionsStatus$ | async)"></atr-form-status-badge>
          </div>
        </ng-template>
        <ng-template rdsStepContent>
          <rnb-step-content
            [stepNo]="4"
            stepName="Assign editors and owners"
            [stepStatus]="(permissionsStatus$ | async)">
            <rnb-form-assign-editors-and-owners [isActive]="stepper.selectedIndex === 3"></rnb-form-assign-editors-and-owners>
            <ng-container navigation *ngTemplateOutlet="navigation"></ng-container>
          </rnb-step-content>
      </ng-template>
      </rds-step>
    </rds-stepper>
    <ng-template #navigation>
      <button
        *ngIf="stepper.selectedIndex > 0"
        rds-text-button
        (click)="stepper.previous()"
      >
        <rds-icon namespace="filled" icon="arrow_left"></rds-icon>
        Previous
      </button>
      <button
          *ngIf="stepper.selectedIndex < stepper.steps.length -1"
          style="margin-left: auto"
          rds-text-button
          (click)="stepper.next()"
    
        >
        Next
        <rds-icon namespace="filled" icon="arrow_right"></rds-icon>
      </button>
    </ng-template>
  </div>
</div>

<rnb-view-bar [hideAddNews]="true">
  <ng-container *ngTemplateOutlet="formActions"></ng-container>
</rnb-view-bar>
