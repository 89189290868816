<div class="tile grid" [class.animated]="!disableAnimation && animatedCard" [class.expanded]="!hasHeader || forceHoverState">
  <div class="tile__animated">
    <div #headerRef class="tile__header">
      <ng-content select="[body-header]"></ng-content>
    </div>
    <div #titleRef class="tile__body--visible">
      <ng-content select="[body-title]"></ng-content>
    </div>
    <div #descriptionRef class="tile__body--hidden">
      <ng-content select="[body-description]"></ng-content>
    </div>
  </div>
  <div class="tile__footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
