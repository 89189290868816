import * as fromReducer from '../header.reducer';
import * as fromHeader from './header.reducer';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';

export const selectHeader = createSelector(
  fromReducer.selectState,
  fromReducer.getHeader
);

export const selectPhrase = createSelector(
  selectHeader,
  fromHeader.getPhrase
);
