
export interface FilterDefinition {
  changable: boolean;
  value: any;
}

export interface SelectFilterDefinition extends FilterDefinition {
  label: string;
  multiple: boolean;
  options: Array<{label: string, value: any}>
}

export interface AdminTableFilters {
  roles: SelectFilterDefinition
}

export interface DefaultAdminTableFilters {
  roles: FilterDefinition
}

export interface ChannelTableFilters {
  roles: SelectFilterDefinition
  search: FilterDefinition;
}

export interface DefaultChannelTableFilters {
  roles: FilterDefinition
  search: FilterDefinition;
}

export interface NewsTableFilters {
  types?: SelectFilterDefinition;
  statuses?: SelectFilterDefinition;
  search?: FilterDefinition;
  approvalStatuses?: SelectFilterDefinition;
  scope?: SelectFilterDefinition;
}

export interface DefaultNewsTableFilters {
  types?: FilterDefinition;
  statuses?: FilterDefinition;
  search?: FilterDefinition;
  approvalStatuses?: FilterDefinition;
  scope?: FilterDefinition;
}

export const NEWS_TIME_RANGE = [
  { label: '6 months', value: '6'},
  { label: '1 year', value: '12'},
  { label: '2 years', value: '24'},
  { label: '5 years', value: '60'},

]

export const MY_ROLES = [
  { label: 'Owner', value:'Owner' },
  { label: 'Manager', value:'Manager' },
  { label: 'Publisher', value:'Publisher' }
];

export const NEWS_TYPES = [
  { label: 'Link', value:'link' },
  { label: 'Content', value:'embedded' }
];

export const NEWS_STATUSES = [
  { label: 'Draft', value:'draft' },
  { label: 'Scheduled', value:'scheduled' },
  { label: 'Published', value:'published' }
];

export const NEWS_STATUSES_EXTENDED = [
  ...NEWS_STATUSES,
  { label: 'Archived', value:'archived' }
];

export const NEWS_APPROVAL_STATUSES = [
  { label: 'Pending', value:'Pending' },
  { label: 'Approved', value:'Approved' },
  { label: 'Rejected', value:'Rejected' }
];

export const ADMIN_ROLES = [
  { label: 'Administrator', value:'Admin' },
  { label: 'Super Administrator', value:'SuperAdmin' },
]


//initial tables filters

//Channels
export const INITIAL_MY_CHANNELS_FILTERS = {
  roles: {
    changable: true,
    value: [],
    label: 'My role',
    multiple: true,
    options: MY_ROLES
  },
  search: {
    changable: true,
    value: ''
  }
}

export const INITIAL_ALL_CHANNELS_FILTERS = {
  roles: {
    changable: false,
    value: [],
    label: 'My role',
    multiple: true,
    options: MY_ROLES
  },
  search: {
    changable: true,
    value: ''
  }
}

//Single channel
export const INITIAL_CHANNEL_ALL_NEWS_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: true,
    value: [],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES
  },
  search: {
    changable: true,
    value: ''
  }
}

export const INITIAL_CHANNEL_NEWS_SUGGESTION_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: true,
    value: [],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES
  },
  search: {
    changable: true,
    value: ''
  },
  approvalStatuses: {
    changable: false,
    value: ['pending'],
    label: 'Status',
    multiple: true,
    options: NEWS_APPROVAL_STATUSES
  }
}

export const INITIAL_CHANNEL_ARCHIVED_NEWS_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: false,
    value: ['archived'],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES
  },
  search: {
    changable: true,
    value: ''
  },
  approvalStatuses: {
    changable: false,
    value: ['pending'],
    label: 'Status',
    multiple: true,
    options: NEWS_APPROVAL_STATUSES
  }
}

//News
export const INITIAL_ALL_NEWS_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: true,
    value: [],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES_EXTENDED
  },
  search: {
    changable: true,
    value: ''
  }
}

export const INITIAL_MY_NEWS_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: true,
    value: [],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES
  },
  search: {
    changable: true,
    value: ''
  }
}

export const INITIAL_MY_SUGGESTED_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: false,
    value: [],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES
  },
  approvalStatuses: {
    changable: true,
    value: [],
    label: 'Status',
    multiple: true,
    options: NEWS_APPROVAL_STATUSES
  },
  search: {
    changable: true,
    value: ''
  },
}

export const INITIAL_MY_ARCHIVED_FILTERS = {
  types: {
    changable: true,
    value: [],
    label: 'Type',
    multiple: true,
    options: NEWS_TYPES
  },
  statuses: {
    changable: false,
    value: ['archived'],
    label: 'Status',
    multiple: true,
    options: NEWS_STATUSES
  },
  search: {
    changable: true,
    value: ''
  },
  approvalStatuses: {
    changable: false,
    value: ['archived'],
    label: 'Status',
    multiple: true,
    options: NEWS_APPROVAL_STATUSES
  }
}

//Admin list
export const INITIAL_ADMIN_FILTERS = {
  roles: {
    changable: true,
    value: [],
    label: 'Role',
    multiple: true,
    options: ADMIN_ROLES
  }
}

//Related news
export const INITIAL_RELATED_NEWS_FILTERS = {
  scope: {
    changable: true,
    value: '6',
    label: 'Time range',
    multiple: false,
    options: NEWS_TIME_RANGE
  },
  search: {
    changable: true,
    value: ''
  }
}