export enum ScreenModeEnum {
    WEB = 'WEB',
    TABLET = 'TABLET',
    HANDSET = 'HANDSET',
}

export enum ScreenOrientationEnum {
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE'
}

export interface ScreenBreakpoint {
    mode: ScreenModeEnum;
    orientation: ScreenOrientationEnum;
}
