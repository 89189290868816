<rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
<rnb-table
  #table
  *ngIf="(channelDetails$ | async) as channel"
  [data]="news$ | async"
  [defaultSortActive]="(defaultSort$ | async).active"
  [defaultSortDirection]="(defaultSort$ | async).direction"
  [columns]="columns"
  [hasBulk]="false"
  [pagination]="pagination$ | async"
  [totalObs]="total$"
  [isLoading]="isLoading$ | async"
  [highlight]="search$ | async"
  (sortChanged)="sortChanged($event)"
  (paginationChanged)="paginationChanged($event)"
  (selectionChanged)="selectionChanged($event)"
  (linkClicked)="onLinkClick($event, channel.name, channel.id)"
  (rejectClicked)="rejectNews($event)"
  (acceptClicked)="acceptNews($event)"
  [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
  >
  <span emptyTitle>
    Looks like you don’t have any news suggestion yet.
  </span>
  <span emptySubtitle>
    You will receive an email notification when a news is suggested to your channel.
  </span>
</rnb-table>
