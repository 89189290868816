import { Component, EventEmitter, Input, Output, OnInit, ViewChild, Optional, Host, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleUser } from '@app/types';
import { RdsDropdownDirective, RdsMenuTriggerDirective } from '@rds/angular-components';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { CustomValidators } from '../../validators';

export interface PeopleRolePickerModel {
  [key: string]: {
    label: string;
    description: string;
    canEdit: boolean;
  }
}

@Component({
  selector: 'rnb-people-role-picker',
  templateUrl: './people-role-picker.component.html',
  styleUrls: ['./people-role-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: PeopleRolePickerComponent
    }
  ]
})
export class PeopleRolePickerComponent implements OnInit {
  @ViewChild("trigger", {static: false}) trigger: RdsMenuTriggerDirective;

  selectedUsers: {[key: string]: Array<SimpleUser>} = {};

  get selectedUsersMerged(): Array<SimpleUser> {
    return Object.keys(this.selectedUsers).reduce((acc, current) => [...acc, ...this.selectedUsers[current]], [])
  }

  onChange = (users) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  hostWidth: number;

  writeValue(suggestions: {[key: string]: Array<SimpleUser>}) {
    this.selectedUsers = suggestions;
    this.form.controls.search.updateValueAndValidity();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  showRolesInOrder() {
    return 0
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<string> = new EventEmitter<string>();

  _autocomplete: {suggestions: Array<SimpleUser>, loading: boolean};
  get autocomplete(): {suggestions: Array<SimpleUser>, loading: boolean} {
      return this._autocomplete;
  }
  @Input() set autocomplete(value: {suggestions: Array<SimpleUser>, loading: boolean}) {
    this._autocomplete = {suggestions: value.suggestions.filter(s => !this.selectedUsersMerged.some(u => u.identifier === s.identifier) ), loading: value.loading};
    if (!!this.trigger) {
      this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading ? this.trigger.openMenu() : this.trigger.closeMenu();
    }
}

@Input() restrictedUsers: Array<SimpleUser> = [];
@Input() formControl!: FormGroup;
@Input() formControlName!: string;
@Input() roles: PeopleRolePickerModel;
@Input() defaultRole: string;
@Input() readonly: boolean = false;

get control() {
  return this.formControl || this.controlContainer.control?.get(this.formControlName);
}

  form: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  @Input() labelFn: (args: any) => string;
  @Input() notRemovable: Array<string> = [];

  canRemove(identifier) {
    return !this.notRemovable.includes(identifier);
  }

  selectedIncludesUser(identifier: string) {
    return this.selectedUsersMerged.findIndex(a => a.identifier === identifier) > -1;
  }

  focus() {
    if (this.autocomplete.suggestions.length > 0 && !this.autocomplete.loading) {
      this.trigger.openMenu()
    }
  }

  blur() {
    this.form.controls.search.updateValueAndValidity();
  }

  select(user: SimpleUser) {
    const userWithRoleAndOrder = {
      ...user,
      role: this.defaultRole,
      order: this.selectedUsersMerged.length
    }
    this.selectedUsers[this.defaultRole].push(userWithRoleAndOrder);
    this.selectedUsers = {
      ...this.selectedUsers,
      [this.defaultRole]: this.selectedUsers[this.defaultRole].slice()
    }
    this.form.controls.search.setValue('');
    this.selected.emit();
    this.onChange(this.selectedUsers);
    this.control.markAsTouched();
  }

  remove(user: SimpleUser) {
    this.selectedUsers = {
      ...this.selectedUsers,
      [user.role]: this.selectedUsers[user.role].filter(u => u.identifier !== user.identifier),
    };
    this.updateOrderAfterRemove(user.order);
    this.form.controls.search.setValue('');
    this.removed.emit();
    this.onChange(this.selectedUsers);
    this.control.markAsTouched();
  }

  updateOrderAfterRemove(removedOrder: number) {
    Object.keys(this.selectedUsers).forEach(role => {
      this.selectedUsers[role] = this.selectedUsers[role].map(u => ({
        ...u,
        order: u.order > removedOrder ? u.order - 1 : u.order
      }))
    })
  }

  changeRole(user: SimpleUser, newRole: string) {
    if (user.role !== newRole) {
      this.selectedUsers[newRole].push({
        ...user,
        role: newRole
      });
      this.selectedUsers = {
        ...this.selectedUsers,
        [user.role]: this.selectedUsers[user.role].filter(u => u.identifier !== user.identifier),
        [newRole]: this.selectedUsers[newRole].slice()
      }
      this.selected.emit();
      this.onChange(this.selectedUsers);
      this.control.markAsTouched();
    }
  }

  ngOnInit(): void {
    this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.form.controls.search.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function() {
      originalMarkAsTouched.apply(this, arguments);
      that.form.controls.search.markAsTouched();
      that.form.controls.search.updateValueAndValidity();
    }

    this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
      this.form.controls.search.updateValueAndValidity();
    });
    this.form.controls.search.valueChanges.subscribe(s => this.search.emit(s));
  }

  constructor(    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,) {
  }

}
