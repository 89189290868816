import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { mergeMap, map, catchError, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from './functions-flat.actions';
import * as fromReducer from './functions-flat.reducer';
import * as fromSlectors from './functions-flat.selectors';
import { TaxonomyService } from '@app/utils/taxonomy.service';

@Injectable()
export class FunctionsFlatEffects {

  public loadFunctionsFlat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFunctionsFlat),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getFunctionsFlatRequest()
      ])
    ), { dispatch: true }
  );

  public getFunctionsFlatRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFunctionsFlatRequest),
      mergeMap(() =>
        this.taxonomyService.getFunctionsFlat().pipe(
          map((functionsFlat) => fromActions.getFunctionsFlatSuccess({ functionsFlat })),
          catchError(({ message }) => of(fromActions.getFunctionsFlatFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getFunctionsFlatSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFunctionsFlatSuccess),
      mergeMap(({ functionsFlat }) => [
        fromActions.functionsFlatCollectionAddMany({ functionsFlat }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private taxonomyService: TaxonomyService,
  ) {}
}
