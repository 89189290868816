<h2 rds-dialog-header>{{data.title}}</h2>
<div rds-dialog-content>
    <p *ngFor="let message of data.messages" class="mb-5" [innerHTML]="message">
    </p>
    <ng-container *ngIf="!!data.confirmationReason" [formGroup]="confirmation">
        <rds-form-field>
            <rds-control-label>{{data.confirmationReason.label}}</rds-control-label>
            <textarea
                rdsInput
                formControlName="confirm"
            ></textarea>
        </rds-form-field>
    </ng-container>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >{{data.cancelButtonLabel || 'Cancel'}}</button>
    <button
        *ngIf="data.confirmButtonType === 'primary'"
        rds-primary-button
        [rds-dialog-close]="data"
        size="m"
    >{{data.confirmButtonLabel || 'Confirm'}}</button>
    <button
        *ngIf="data.confirmButtonType === 'warning'"
        rds-warning-button
        [rds-dialog-close]="data"
        size="m"
    >{{data.confirmButtonLabel || 'Confirm'}}</button>
</div>