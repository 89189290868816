
import { Plugin } from 'ckeditor5/src/core';
import { WidgetToolbarRepository } from 'ckeditor5/src/widget';
import { isWidget } from 'ckeditor5/src/widget';

export default class SectionColorsToolbar extends Plugin {

	static get requires() {
		return [ WidgetToolbarRepository ];
	}

	static get pluginName() {
		return 'SectionColorsToolbar';
	}


	afterInit() {
		const editor = this.editor;
		const t = editor.t;
		const widgetToolbarRepository = editor.plugins.get( WidgetToolbarRepository );

		const contentToolbarItems = editor.config.get( 'sectionColorsBox.contentToolbar' );


		if ( contentToolbarItems ) {
			widgetToolbarRepository.register( 'sectionColorsBox', {
				ariaLabel: t( 'Section colors toolbar' ),
				items: contentToolbarItems,
				getRelatedElement: getSectionColorsWidgetAncestor
			} );
		}

		if ( contentToolbarItems ) {
			widgetToolbarRepository.register( 'sectionColorsBoxContent', {
				ariaLabel: t( 'Section colors toolbar' ),
				items: contentToolbarItems,
				getRelatedElement: getSelectedSectionColorsWidget
			} );
		}
	}
}

export function getSelectedSectionColorsWidget( selection ) {
	const viewElement = selection.getSelectedElement();

	if ( viewElement && isSectionBoxWidget( viewElement ) ) {
		return viewElement;
	}

	return null;
}

export function getSectionColorsWidgetAncestor( selection ) {
	const selectionPosition = selection.getFirstPosition();

	if ( !selectionPosition ) {
		return null;
	}

	let parent = selectionPosition.parent;

	while ( parent ) {
		if ( parent.is( 'element' ) && isSectionBoxWidget( parent ) ) {
			return parent;
		}

		parent = parent.parent;
	}

	return null;
}

function isSectionBoxWidget( viewElement ) {
	return !!viewElement.hasClass( 'bg-class') && isWidget(viewElement);
}


