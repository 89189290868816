import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromNewsTable from '../../../../store/news-table';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel, News } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { NewsTableFilters } from '../../../../store/news-table';
import { AuthService, SimpleUser } from '@app/auth/auth.service';
import { TableColumn } from '@app/utils/table/table.component';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { getArchiveNewsDialog, getDeleteNewsDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';

@Component({
  selector: 'rnb-all-news-table',
  templateUrl: './all-news-table.container.html',
  styleUrls: ['./all-news-table.container.scss']
})
export class AllNewsTableContainer implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();

  get identifier(): string {
    return this.auth.currentUser.identifier;
  }

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  columns: Array<string> = ['title', 'owners', 'type', 'created', 'published', 'status', 'assign'];

  bulkPredicate = (news: News) => {
    return this.canDeleteNews(news);
  }

  canBulkArchive(selection: SelectionModel<News>) {
    return selection.selected.every(n => n.status !== 'Archived');
  }

  canBulkMoveAsDraft(selection: SelectionModel<News>) {
    return selection.selected.every(n => n.status === 'Archived');
  }

  news: Array<Partial<News>> = [];
  news$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromNewsTable.selectAllNewsData));

  pagination$: Observable<Pagination> = this.store$.pipe(select(fromNewsTable.selectAllNewsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromNewsTable.selectAllNewsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromNewsTable.selectAllNewsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromNewsTable.selectAllNewsDefaultSort));

  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectAllNewsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromNewsTable.selectAllNewsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromNewsTable.selectAllNewsSearch));

  constructor(
    private store$: Store<fromNewsTable.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {

  }

  canEditNews(news: News) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier) || !!news.editors.find(u => u.identifier === this.identifier)
  }

  canDeleteNews(news: News) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier)
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromNewsTable.changeAllNewsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromNewsTable.changeAllNewsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromNewsTable.changeAllNewsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<News>) {
  }

  deleteNews(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = getDeleteNewsDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteAllNewsRequest({ids: data.ids})));
  }

  deleteSingleNews(id: string, newsTitle) {
    const ids = [+id];
    const data: ConfirmDialogData = getDeleteNewsDialog(ids, newsTitle);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.deleteAllNewsRequest({ids: data.ids})));
  }

  archiveNews(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = getArchiveNewsDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.archiveAllNewsRequest({ids: data.ids})));
  }

  archive(id, title)  {
    const ids = [+id];
    const data: ConfirmDialogData = getArchiveNewsDialog(ids, title);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromNewsTable.archiveAllNewsRequest({ids: data.ids})));
  }



  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    this.openNewsPreview(+link);
  }

  openNewsPreview(newsId: number) {
    this.store$.dispatch(fromNewsTable.openNewsPreview({newsId, backButton: {
      label: 'My News',
      routeBack: 'news/my',
      routeCurrent: `/news/${newsId}/edit`
    }}))
  }

  onAssignClick({id, isDraft}) {
    this.store$.dispatch(fromNewsTable.openAssignementsDialog({newsId: id, isDraft}));
  }

  duplicate(id) {
    this.store$.dispatch(fromNewsTable.duplicateNewsRequest({newsId: id }));
  }

  saveAsTemplate(id) {
    this.store$.dispatch(fromNewsTable.openSaveAsTemplateDialog({newsId: id }));
  }

  moveAsDraft(id) {
    this.store$.dispatch(fromNewsTable.moveAsDraftRequest({ids: [id] }));
  }

  bulkMoveAsDraft(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    this.store$.dispatch(fromNewsTable.moveAsDraftRequest({ids }));
  }
}
