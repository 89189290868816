import { NgModule } from '@angular/core';
import { RdsModule } from '../rds.module';
import { COMPONENTS } from './components';

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    RdsModule
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class FooterModule {}
