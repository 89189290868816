<rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
<rnb-table
  #table
  [data]="news$ | async"
  [defaultSortActive]="(defaultSort$ | async).active"
  [defaultSortDirection]="(defaultSort$ | async).direction"
  [columns]="columns"
  [hasBulk]="true"
  [pagination]="pagination$ | async"
  [totalObs]="total$"
  [isLoading]="isLoading$ | async"
  [highlight]="search$ | async"
  (sortChanged)="sortChanged($event)"
  (paginationChanged)="paginationChanged($event)"
  (selectionChanged)="selectionChanged($event)"
  (linkClicked)="onLinkClick($event)"
  [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
  >
  <span bulkText>
    {{table.selectedCount}} Channel{{table.selectedCount > 1? 's ':' '}} selected 
  </span>
  <ng-container bulkActions>
    <button rds-warning-button (click)="deleteNews(table.selection)">Delete</button>
  </ng-container>

  <ng-template #actions let-news>
    <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
    #trigger="rdsMenuTrigger">
      <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
    </button>

    <rds-menu #menu="rdsMenu">
      <button rds-menu-item (click)="moveAsDraft(news.id)">
        <span>Move as draft</span>
      </button>
      <button [disabled]="!canEditNews(news.owners, news.editors)" rnbBackButtonSet label="My News" routeBack="news/my" [routeCurrent]="'/news/'+news.id+'/edit'" rds-menu-item [routerLink]="'/news/'+news.id+'/edit'">
        <span>Edit</span>
      </button>
      <button rds-menu-item (click)="duplicate(news.id)">
        <span>Duplicate as draft</span>
      </button>
      <button rds-menu-item [disabled]="news.status !== 'Published' || news.type !== 'Embedded'" (click)="saveAsTemplate(news.id)">
        <span>Save as template</span>
      </button>
      <button [disabled]="!canDeleteNews(news)" rds-menu-item (click)="deleteSingleNews(news.id, news.title)">
        <span>Delete</span>
      </button>
    </rds-menu>
  </ng-template>

  <span emptyTitle>
    Looks like you don’t have any news here.
  </span>
</rnb-table>
