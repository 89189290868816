import { Component, Inject, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { PageEvent, RDS_DIALOG_DATA, Sort, SortDirection } from '@rds/angular-components';
import * as fromRelatedNews from '@app/news/store/related-news';
import { SubSink } from 'subsink';
import { News } from '@app/types';
import { Observable } from 'rxjs';
import { Pagination } from '@app/types/pagination';
import { NewsTableFilters } from '@app/news/store/related-news';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';

export interface ConfirmDialogData {

}
@Component({
  selector: 'atr-related-news-dialog',
  templateUrl: './related-news-dialog.component.html',
  styleUrls: ['./related-news-dialog.component.scss']
})
export class RelatedNewsDialogComponent implements OnInit {
  subs: SubSink = new SubSink();

  columns: Array<string> = ['title.nosort', 'owners', 'type','published.nosort'];
  
  initialSelectionPredicate = (news: News) => {
    return this.selectedNews.some(n => n.id === news.id);
  }

  bulkPredicate = (news: News) => {
    return !this.initialSelectionPredicate(news);
  }

  news: Array<Partial<News>> = []; 
  news$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsData));
  selectedNews$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromRelatedNews.selectSelectedNews));
  selectedNews: Array<Partial<News>> = [];

  pagination$: Observable<Pagination> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsDefaultSort));
  
  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromRelatedNews.selectRelatedNewsSearch));


  constructor(private store$: Store<any>, @Inject(RDS_DIALOG_DATA) public data: ConfirmDialogData) {

  }

   ngOnInit(): void {
    this.store$.dispatch(fromRelatedNews.initRelatedNews());
    this.subs.sink = this.selectedNews$.subscribe(news => {
      this.selectedNews = news
    })
  }
  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromRelatedNews.changeRelatedNewsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromRelatedNews.changeRelatedNewsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromRelatedNews.changeRelatedNewsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<News>) {
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}) {
    this.openNewsPreview(+link);
  }

  openNewsPreview(newsId: number) {
    this.store$.dispatch(fromRelatedNews.openNewsPreview({newsId}))
  }

  addRelated(selection: SelectionModel<News>) {
    this.store$.dispatch(fromRelatedNews.addRelatedNews({news: selection.selected}));
    selection.clear();
  }
}
