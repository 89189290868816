import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rnb-no-mobile',
  templateUrl: './no-mobile.component.html',
  styles: []
})
export class NoMobileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
