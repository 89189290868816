import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { SimpleUser } from '@app/types';

@Pipe({
  name: 'mergeUsers'
})
export class MergeUsersPipe implements PipeTransform {
  userLogin = this.auth.currentSimpleUser.identifier;

  transform(value: {[key: string]: Array<SimpleUser>}, ...args: unknown[]): unknown {
    const mappedValue = Object.keys(value).reduce((acc, current) => {
      const mapped = !!value[current] ? value[current].map(u => ({
        ...u,
        role: current
      })) : []
      return [...acc, ...mapped]
    }, [])
    return mappedValue.sort((a, b) => {
      return a.order - b.order;
    })
  }

  constructor(private auth: AuthService) {
    
  }

}
