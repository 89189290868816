<rds-form-field
><rds-control-label>{{label}}</rds-control-label>
<rds-select
  #channelSelect
  #channelSelectNgModel="ngModel"
  [(ngModel)]="selectedChannels"
  [multiple]="multi"
  [disabled]="!options"
  [required]="required"
  (valueChange)="channelSelect.resetFilterValue(); selectChanged($event)"
  (openChange)="openChange($event)"
  [filterBy]="filterBy"
>
  <rds-select-search-input #searchInput></rds-select-search-input>
  <rds-select-option-separator></rds-select-option-separator>
  <rds-select-option *ngFor="let option of options" [disabled]="option.assignmentStatus !== 'None'" [id]="option.name" [value]="option.id" ><span [innerHTML]="option.name | highlight:searchInput.control.value"></span></rds-select-option>
</rds-select>
</rds-form-field>
<div class="selected-channels">
  <div class="selected-channels__chips-list" overflowingContent="64" #overflowing="overflowingContent">
    <rds-chip-list size="s" type="filter">
      <rds-chip *ngIf="selectedChannels.length === 0" [disabled]="true">Chosen channel will appear here</rds-chip>
      <rds-chip *ngFor="let selected of assignedChannels" class="assigned-channel">{{selected.name}}</rds-chip>
      <rds-chip *ngFor="let selected of nonAssignedChannels" rdsChipInput (click)="remove(selected.id)">{{selected.name}}</rds-chip>
    </rds-chip-list>
  </div>
  <div *ngIf="overflowing.isOverflowing" class="selected-channels__actions-container">
    <button rds-text-button (click)="overflowing.isExpanded ? overflowing.collapse() : overflowing.expand()">
      Show {{overflowing.isExpanded? 'less' : 'all'}} <rds-icon [icon]="overflowing.isExpanded ? 'chevron_up' : 'chevron_down'"></rds-icon>
    </button>
  </div>
</div>
<rnb-subscript-wrapper [hasError]="!control.valid && control.touched" [disabled]="disabled">
  <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
  <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rnb-subscript-wrapper>
