import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { SubSink } from 'subsink';
import { FilterDefinition, SelectFilterDefinition } from './filters-model';


@Component({
  selector: 'rnb-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit {
  subs: SubSink = new SubSink();

  _tableFilters: {[prop: string]: FilterDefinition | SelectFilterDefinition};
  @Input() defaultFilters: {[prop: string]: FilterDefinition | SelectFilterDefinition};

  filters: FormGroup = new FormGroup({});

  @Input() set tableFilters(filters: {[key: string]: FilterDefinition | SelectFilterDefinition}) {
    this._tableFilters = filters;
    Object.keys(filters).forEach(prop => {
      if (!!this.filters.get(prop)) {
        (this.filters.get(prop) as FormGroup).controls.value.setValue(filters[prop].value, {emitEvent: false});
      } else {
        this.filters.addControl(prop, new FormGroup({
          changable: new FormControl(filters[prop].changable),
          label: new FormControl((filters[prop] as SelectFilterDefinition).label),
          multiple: new FormControl((filters[prop] as SelectFilterDefinition).multiple),
          options: new FormControl((filters[prop] as SelectFilterDefinition).options),
          value: new FormControl(filters[prop].value),
        }));
      }
    });
  }

  get tableFilters() {
    return this._tableFilters;
  }

  get disableClear() {
    const valTF = Object.keys(this.tableFilters).map(key => ({[key]: this.tableFilters[key].value}))
    const valDF = Object.keys(this.defaultFilters).map(key => ({[key]: this.defaultFilters[key].value}))
    return JSON.stringify(valTF) !== JSON.stringify(valDF);
  }
  get showLabel() { return Object.keys(this.tableFilters).some(key => !!(this.tableFilters[key] as SelectFilterDefinition).options && this.tableFilters[key].changable) }


  @Output() filtersChanged: EventEmitter< {[prop: string]: FilterDefinition}> = new EventEmitter();

  constructor() { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.sink = this.filters.valueChanges.pipe(debounceTime(300)).subscribe(filters => {
      this.filtersChanged.emit({
        ...filters,
      });
    })
  }

  clearSearch() {
    (this.filters.get('search') as FormGroup).controls.value.setValue('');
  }

  clearAll() {
    this.filters.patchValue(this.defaultFilters);
  }

  showFiltersInOrder() {
    return 0
  }
}
