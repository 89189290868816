import { Plugin } from 'ckeditor5/src/core';
import { Widget } from 'ckeditor5/src/widget';
import DividerEditing from './divider-editing';
import DividerUi from './divider-ui';


export default class Divider extends Plugin {
	static get requires() {
		return [ DividerEditing, DividerUi, Widget ];
	}
	static get pluginName() {
		return 'divider';
	}
}
