import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromTemplateForm from '../../../store/template-form';
import * as fromLanguages from '@app/root-store/dictionaries/languages';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentType, Language, NewsForm } from '@app/types';
import { RdsSelectFilterByFunc, RdsSelectOptionComponent } from '@rds/angular-components';
import { SubSink } from 'subsink';
import { FormStepStatus } from '../../news-form/form-status-badge/form-status-badge.component';

@Component({
  selector: 'rnb-template-form-general-information',
  templateUrl: './template-form-general-information.component.html',
  styles: [
  ]
})
export class TemplateFormGeneralInformationComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();

  languages$: Observable<Array<Language>> = this.store$.pipe(select(fromLanguages.selectAll));

  _isActive: Array<boolean>;
  get isActive(): Array<boolean> {
      return this._isActive;
  }
  @Input() set isActive(value: Array<boolean>) {
      this._isActive = value;
      this.store$.dispatch(fromTemplateForm.setGeneralFormStatus({status: this.form.status}));
      if (value) {
      this.store$.dispatch(fromTemplateForm.setGeneralStepStatus({status: FormStepStatus.IN_PROGRESS}));
      } else {
        this.form.markAllAsTouched();
        if (this.form.valid) {
          this.store$.dispatch(fromTemplateForm.setGeneralStepStatus({status: FormStepStatus.COMPLETED}));
        } else {
          this.store$.dispatch(fromTemplateForm.setGeneralStepStatus({status: FormStepStatus.INCOMPLETE}));
        }
      }
  }

  filterBy: RdsSelectFilterByFunc = (
    text: string | null,
    item: RdsSelectOptionComponent
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.value.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase());
    }
  };

  form$: Observable<Partial<NewsForm>> = this.store$.pipe(select(fromTemplateForm.selectGeneralForm));
  public form: FormGroup = new FormGroup({
    id: new FormControl(null),
    title: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    language: new FormControl(null),
  });

  constructor(private store$: Store<any>) {

  }

  ngOnDestroy(): void {
    this.form.markAllAsTouched();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(fromLanguages.loadLanguages());

    this.subs.sink = this.form$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(news => {
      this.form.patchValue(news, {emitEvent: false});
      this.store$.dispatch(fromTemplateForm.setGeneralFormStatus({status: this.form.status}));
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(form => {
      this.store$.dispatch(fromTemplateForm.setFormValue({form}))
    });

    this.subs.sink = this.form.controls.language.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(lang => {
      this.store$.dispatch(fromTemplateForm.primaryLanguageChanged({lang}))
    });

    this.subs.sink = this.form.statusChanges.subscribe(value => {
      this.store$.dispatch(fromTemplateForm.setGeneralFormStatus({status: value}));
    });
  }

}
