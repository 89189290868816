import { createSelector } from '@ngrx/store';

import * as fromChannel from '../news.reducer';
import * as fromReducer from './news-table.reducer';

export const selectState = createSelector(
  fromChannel.selectState,
  fromChannel.getNewsTable
);

export const selectRecentlyDuplicated = createSelector(
  selectState,
  fromReducer.getRecentlyDuplicated
);

// [ALL NEWS]
export const selectAllNewsState = createSelector(
  selectState,
  fromReducer.getAllNews
);

export const selectAllNewsRequestData = createSelector(
  selectAllNewsState,
  fromReducer.getRequestData
);

export const selectAllNewsData = createSelector(
  selectAllNewsState,
  fromReducer.getNewsData
);

export const selectAllNewsPagination = createSelector(
  selectAllNewsState,
  fromReducer.getPagination
);

export const selectAllNewsTotal = createSelector(
  selectAllNewsPagination,
  fromReducer.getTotalCount
);

export const selectAllNewsSort = createSelector(
  selectAllNewsState,
  fromReducer.getSort
);

export const selectAllNewsDefaultSort = createSelector(
  selectAllNewsState,
  fromReducer.getDefaultSort
);

export const selectAllNewsFilters = createSelector(
  selectAllNewsState,
  fromReducer.getFilters
);

export const selectAllNewsDefaultFilters = createSelector(
  selectAllNewsState,
  fromReducer.getDefaultFilters
);

export const selectAllNewsSearch = createSelector(
  selectAllNewsFilters,
  fromReducer.getSearch
);

export const selectAllNewsIsLoading = createSelector(
  selectAllNewsState,
  fromReducer.getIsLoading
);

// [ALL NEWS]
export const selectMyNewsState = createSelector(
  selectState,
  fromReducer.getMyNews
);

export const selectMyNewsRequestData = createSelector(
  selectMyNewsState,
  fromReducer.getRequestData
);

export const selectMyNewsData = createSelector(
  selectMyNewsState,
  fromReducer.getNewsData
);

export const selectMyNewsPagination = createSelector(
  selectMyNewsState,
  fromReducer.getPagination
);

export const selectMyNewsTotal = createSelector(
  selectMyNewsPagination,
  fromReducer.getTotalCount
);

export const selectMyNewsSort = createSelector(
  selectMyNewsState,
  fromReducer.getSort
);

export const selectMyNewsDefaultSort = createSelector(
  selectMyNewsState,
  fromReducer.getDefaultSort
);

export const selectMyNewsFilters = createSelector(
  selectMyNewsState,
  fromReducer.getFilters
);

export const selectMyNewsDefaultFilters = createSelector(
  selectMyNewsState,
  fromReducer.getDefaultFilters
);

export const selectMyNewsSearch = createSelector(
  selectMyNewsFilters,
  fromReducer.getSearch
);

export const selectMyNewsIsLoading = createSelector(
  selectMyNewsState,
  fromReducer.getIsLoading
);


// [NEWS SUGGESTIONS]
export const selectMySuggestedNewsState = createSelector(
  selectState,
  fromReducer.getMySuggestedNews
);

export const selectMySuggestedNewsRequestData = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getRequestData
);

export const selectMySuggestedNewsData = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getNewsSuggestionData
);

export const selectMySuggestedNewsPagination = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getPagination
);

export const selectMySuggestedNewsTotal = createSelector(
  selectMySuggestedNewsPagination,
  fromReducer.getTotalCount
);

export const selectMySuggestedNewsSort = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getSort
);

export const selectMySuggestedNewsDefaultSort = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getDefaultSort
);

export const selectMySuggestedNewsFilters = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getFilters
);

export const selectMySuggestedNewsDefaultFilters = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getDefaultFilters
);

export const selectMySuggestedNewsSearch = createSelector(
  selectMySuggestedNewsFilters,
  fromReducer.getSearch
);

export const selectMySuggestedNewsIsLoading = createSelector(
  selectMySuggestedNewsState,
  fromReducer.getIsLoading
);

// [ARCHIVED NEWS]
export const selectMyArchivedNewsState = createSelector(
  selectState,
  fromReducer.getMyArchivedNews
);

export const selectMyArchivedNewsRequestData = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getRequestData
);

export const selectMyArchivedNewsData = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getNewsData
);

export const selectMyArchivedNewsPagination = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getPagination
);

export const selectMyArchivedNewsTotal = createSelector(
  selectMyArchivedNewsPagination,
  fromReducer.getTotalCount
);

export const selectMyArchivedNewsSort = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getSort
);

export const selectMyArchivedNewsDefaultSort = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getDefaultSort
);

export const selectMyArchivedNewsFilters = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getFilters
);

export const selectMyArchivedNewsDefaultFilters = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getDefaultFilters
);

export const selectMyArchivedNewsSearch = createSelector(
  selectMyArchivedNewsFilters,
  fromReducer.getSearch
);

export const selectMyArchivedNewsIsLoading = createSelector(
  selectMyArchivedNewsState,
  fromReducer.getIsLoading
);