
export interface Taxonomy {
  id: string;
  externalId?: string;
  name: string;
  description?: string;
  isActive?: boolean;
  children?: Taxonomy[];
  parentId?: string;
  parentName?: string;
  // approval?: {
  //   isApproved: boolean;
  //   comment: string;
  // };
  isApproved?: boolean;
  comment?: string;
  isApprovalRelevant?: boolean;
}

export interface TaxonomyFlat {
  externalId: string;
  id: string;
  parentId: string;
  name: string;
  parentName: string;
  description: string;
  isActive: true;
  childrenIds: string[];
}
export interface UserDefaultTaxonomies {
  location: Taxonomy;
  department: Taxonomy;
  function: Taxonomy;
}

export interface ManagedTaxonomy {
  login: string;
  locations: Taxonomy[];
  departments: Taxonomy[];
  functions: Taxonomy[];
}

export const taxonomyOptionsModel = {
  idKey: 'id',
  parentIdKey: 'parentId',
  nestKey: 'childrenIds',
  nameKey: 'name',
  parentNameKey: 'parentName',
};
