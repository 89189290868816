<div class="row">
  <div class="col lmy-3">
    <rds-breadcrumbs [items]="breadcrumbs" (itemClick)="breadcrumbItemClicked($event)"></rds-breadcrumbs>
  </div>
</div>
<rnb-view-bar [hideAddNews]="true">
  <span title>Add News</span>
</rnb-view-bar>
<div class="row">
  <div *ngFor="let tile of newsTiles" class="col-xl-3 col-l-4 mb-9">
    <rnb-tile size="small" [tile]="tile" (dispatchAction)="dispatchAction(tile.action)"></rnb-tile>
  </div>
</div>
<div class="row">
  <div class="col mb-8">
    <h3 class="ui-heading-3">
      News templates
    </h3>
  </div>
  <div class="col-auto">
    <button rds-secondary-button class="ms-auto" (click)="openCreateTemplate()">
      Create template
    </button>
  </div>
</div>
<!-- <div class="row">
  <div *ngFor="let tile of templateTiles" class="col-xl-3 col-l-4 mb-9">
    <rnb-tile size="small" [tile]="tile" (dispatchAction)="dispatchAction(tile.action)"></rnb-tile>
  </div>
</div> -->
<div class="row">
  <div class="col">
    <rnb-templates></rnb-templates>
  </div>
</div>