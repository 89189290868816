import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../auth/auth-guard.service';
import { ContainerComponent } from '../utils/container/container.component';

import { ChannelsPageComponent } from './components/all-channels/channels-page/channels-page.component';
import { ChannelDetailsPageComponent } from './components/single-channel/channel-details-page/channel-details-page.component';
import { MyChannelsTableContainer } from './components/all-channels/my-channels-table/my-channels-table.container';
import { AllChannelsTableContainer } from './components/all-channels/all-channels-table/all-channels-table.container';
import { ChannelResolver } from './channel.resolver';
import { AllNewsTableContainer } from './components/single-channel/tables/all-news-table/all-news-table.container';
import { ArchivedNewsTableContainer } from './components/single-channel/tables/archived-news-table/archived-news-table.container';
import { NewsSuggestionsTableContainer } from './components/single-channel/tables/news-suggestions-table/news-suggestions-table.container';
import { ChannelFormComponent } from './components/channel-form/channel-form.component';
import { CreateChannelResolver } from './create-channel.resolver';

const routes: Routes = [
  {
    path: 'channels',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        component: ContainerComponent,
        data: { tourId: 'channels' },
        children: [
          {
            path: '',
            component: ChannelsPageComponent,
            children: [
              {
                path: '',
                redirectTo: 'my',
                pathMatch: 'full'
              },
              {
                path: 'my',
                component: MyChannelsTableContainer,
                data: { table: 'my' },
              },
              {
                path: 'all',
                component: AllChannelsTableContainer,
                data: { table: 'all'},
              }
            ]
          }
        ]
      },
      {
        path: 'create',
        component: ContainerComponent,
        resolve: [CreateChannelResolver],
        data: { mode: 'create'},
        children: [
          {
            path: '',
            component: ChannelFormComponent,
          }
        ],
      },
    ]
  },
  {
    path: 'channel/:id',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        component: ContainerComponent,
        resolve: [ChannelResolver],
        data: { tourId: 'channels' },
        children: [
          {
            path: '',
            component: ChannelDetailsPageComponent,
            children: [
              {
                path: '',
                redirectTo: 'all',
                pathMatch: 'full'
              },
              {
                path: 'all',
                component: AllNewsTableContainer,
                data: { table: 'all' },
              },
              {
                path: 'suggestions',
                component: NewsSuggestionsTableContainer,
                data: { table: 'suggestions'},
              },
              {
                path: 'archived',
                component: ArchivedNewsTableContainer,
                data: { table: 'archived'},
              },

            ]
          }
        ],
      },
      {
        path: 'edit',
        component: ContainerComponent,
        resolve: [ChannelResolver],
        data: { mode: 'edit'},
        children: [
          {
            path: '',
            component: ChannelFormComponent,
          }
        ],
      },
      {
        path: 'info',
        component: ContainerComponent,
        resolve: [ChannelResolver],
        data: { mode: 'info'},
        children: [
          {
            path: '',
            component: ChannelFormComponent,
          }
        ],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChannelRoutingModule {}

