import Command from '@ckeditor/ckeditor5-core/src/command';

export default class CustomImageAlternativeCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const selection = model.document.selection;
            const element = selection.getSelectedElement();


            if ( element.hasAttribute( 'alt' ) ) {
                this.value = element.getAttribute( 'alt' );
            } else {
                this.value = false;
            }
            writer.setAttribute('alt', options, element);
            writer.setSelection( element, 'on' );
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = selection.getSelectedElement();

        this.isEnabled = !!element;

        if ( this.isEnabled && element.hasAttribute( 'alt' ) ) {
            this.value = element.getAttribute( 'alt' );
        } else {
            this.value = false;
        }
    }
}
