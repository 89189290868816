import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentType } from '@app/types';
import { Store, select } from '@ngrx/store';
import * as fromTemplateForm from '../../store/template-form'
import { AuthService } from '@app/auth/auth.service';
import { Observable, debounceTime } from 'rxjs';
import { FormStepStatus } from '../news-form/form-status-badge/form-status-badge.component';
@Component({
  selector: 'rnb-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: [ './template-form.component.scss' ]
})
export class TemplateFormComponent implements OnDestroy {
  id: number;

  canSaveTemplate$: Observable<boolean> = this.store$.pipe(select(fromTemplateForm.canSaveTemplate), debounceTime(100));
  disableSaveTemplateTooltip$: Observable<string> = this.store$.pipe(select(fromTemplateForm.disableSaveTemplateTooltip), debounceTime(100));
  generalStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromTemplateForm.selectGeneralStepStatus), debounceTime(100));
  contentStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromTemplateForm.selectContentStepStatus), debounceTime(100));
  permissionsStatus$: Observable<FormStepStatus> = this.store$.pipe(select(fromTemplateForm.selectPermissionsStepStatus), debounceTime(100));
  changesMade$: Observable<boolean> = this.store$.pipe(select(fromTemplateForm.selectChangesMade), debounceTime(100));

  constructor(private auth: AuthService, private store$: Store<any>, private route: ActivatedRoute) {
    this.id = this.route.snapshot.params.id;
    this.store$.dispatch(fromTemplateForm.initAddForm({user: this.auth.currentSimpleUser, id: this.id}));
    if (this.auth.currentUser.isSuperAdmin) {
      this.store$.dispatch(fromTemplateForm.loadSuperAdminsRequest({currentUser: this.auth.currentSimpleUser}));
    }
  }

  ngOnDestroy(): void {
    this.store$.dispatch(fromTemplateForm.clearAll());
  }

  openAddLanguageDialog() {
    this.store$.dispatch(fromTemplateForm.openAddLanguageDialog());
  }

  openPreviewDialog() {
    this.store$.dispatch(fromTemplateForm.openPreviewDialog());
  }

  saveTemplate() {
    this.store$.dispatch(fromTemplateForm.openSaveTemplateDialog());
    // this.store$.dispatch(fromTemplateForm.saveTemplateRequest());
  }

  updateTemplate(id) {
    this.store$.dispatch(fromTemplateForm.openSaveTemplateDialog());
    // this.store$.dispatch(fromTemplateForm.updateTemplateRequest({id}));
  }

  openLeavePageModal() {
    this.store$.dispatch(fromTemplateForm.openLeavePageDialog({url: 'news/create'}));
  }
}
