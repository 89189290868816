import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as fromCore from '@app/root-store/dictionaries/dictionaries.reducer';
import * as fromReducer from './timezones.reducer';

export const selectState = createSelector(
  fromCore.selectState,
  fromCore.getTimezones
);

export const selectAll = createSelector(
  selectState,
  fromReducer.getAll
);

export const selectEntities = createSelector(
  selectState,
  fromReducer.getEntities
);

export const selectIds = createSelector(
  selectState,
  fromReducer.getIds
);

export const selectTotal = createSelector(
  selectState,
  fromReducer.getTotal
);

export const selectEntityById = createSelector(
  selectEntities,
  fromReducer.getEntityById
);

export const selectEntitiesById = createSelector(
  selectEntities,
  fromReducer.getEntitiesById
);
