<div #formField>
  <rds-form-field [formGroup]="form" *ngIf="!disabled" >
    <rds-control-label>
        Enter user name or email
    </rds-control-label>
    <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
    <input
        rdsInput
        (focus)="focus()"
        (blur)="blur()"
        formControlName="search"
    />
  </rds-form-field>
  <div rdsDropdown>
    <button rds-dropdown-toggle-button size="l" class="user__value-container--autocomplete-trigger" [rdsMenuTriggerFor]="menu"
    #trigger="rdsMenuTrigger"
    [isOpen]="trigger.menuOpen"
>Toggle</button>
    <rds-menu #menu="rdsMenu" class="user__value-container--autocompletes">
      <ng-container *ngIf="autocomplete.loading">
        <div  [ngStyle]="{'width.px': formField.offsetWidth}" class="user__value-container--autocompletes__row loading">
          <rds-progress-bar></rds-progress-bar>
        </div>
      </ng-container>
      <ng-container *ngIf="!autocomplete.loading">
        <div *ngIf="autocomplete.suggestions.length === 0"  [ngStyle]="{'width.px': formField.offsetWidth}" class="user__value-container--autocompletes__row not-found">
          <span>
            User not found
          </span>
        </div>
        <div *ngFor="let option of autocomplete.suggestions" (click)="selectedIncludesUser(option.identifier) && canRemove(option.identifier)? remove(option) : select(option)"  [ngStyle]="{'width.px': formField.offsetWidth}" class="user__value-container--autocompletes__row" [class.is-selected]="selectedIncludesUser(option.identifier)" [class.disabled]="!canRemove(option.identifier)">
          <rds-avatar [rdsTooltip]="option.name" [rdsTooltipShowDelay]="300">
            <img *ngIf="!!option.avatar" rds-avatar-image [src]="option.avatar" referrerpolicy="no-referrer"/>
            <span *ngIf="!option.avatar" rds-avatar-initials>{{option.name | initials}}</span>
          </rds-avatar>
          <span [innerHTML]="option.name + ' (' + option.email + ')' | highlight:form.controls.search.value"></span>
          <rds-icon *ngIf="!selectedIncludesUser(option.identifier)" namespace="filled" icon="plus"></rds-icon>
          <rds-icon *ngIf="selectedIncludesUser(option.identifier)" namespace="filled" icon="checkmark"></rds-icon>
        </div>
      </ng-container>
    </rds-menu>
  </div>

  <div *ngIf="selectedUsers?.length > 0" class="user__value-container">
    <div *ngIf="selectedUsers.length > 0" class="user__value-container--selected">
      <div *ngFor="let selected of selectedUsers" class="user__value-container--selected__row">
        <rds-avatar [rdsTooltip]="selected.name" [rdsTooltipShowDelay]="300">
          <img *ngIf="!!selected.avatar" rds-avatar-image [src]="selected.avatar" referrerpolicy="no-referrer"/>
          <span *ngIf="!selected.avatar" rds-avatar-initials>{{selected.name | initials}}</span>
        </rds-avatar>
        <div class="user__value-container--selected__row--text">
          <span>
            <span class="ui-label-m-bold">{{selected.name}}</span>&nbsp;<span class="ui-label-s">({{selected.email}})</span>
          </span>
          <span *ngIf="!!labelFn" class="ui-label-xs">
            {{labelFn(selected) }}
          </span>
        </div>
        <button rds-icon-button size="m" (click)="remove(selected)" [disabled]="!canRemove(selected.identifier) || disabled">
          <rds-icon icon="delete" namespace="outlined"></rds-icon>
        </button>
      </div>
    </div>
  </div>

</div>
<rnb-subscript-wrapper [hasError]="!control.valid && control.touched" [disabled]="disabled">
  <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
  <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rnb-subscript-wrapper><rnb-subscript-wrapper [hasError]="!control.valid && control.touched" [disabled]="disabled">
  <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
  <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rnb-subscript-wrapper>