import { createReducer, Action, on } from '@ngrx/store';

import * as fromActions from './form.actions';
import * as _ from 'lodash';
import { ChannelCreation, ChannelForm, EMPTY_CHANNEL_FORM, SimpleUser } from '@app/types';

export const feature = 'form';

export interface State {

  form: Partial<ChannelForm>;
  initialForm: Partial<ChannelForm>;
  isNameUnique: boolean;
  touched: boolean
}

export const initialState: State = {
  form: EMPTY_CHANNEL_FORM,
  initialForm: EMPTY_CHANNEL_FORM,
  isNameUnique: true,
  touched: false
};

const addReducer = createReducer(
  initialState,
  on(fromActions.initAddForm, (state, { user, channel }) => channel? ({
    ...state,
    form: {
      ...state.form,
      allowAutoAssign: channel?.allowAutoAssign,
      departments: channel?.departments,
      description: channel?.description,
      functions: channel?.functions,
      locations: channel?.locations,
      permissions: {
        managers: channel?.managers,
        owners: channel?.owners,
        publishers: channel?.publishers,
      },
      name: channel?.name,
      id: channel?.id,
      role: channel?.role
    },
    initialForm: {
      ...state.initialForm,
      allowAutoAssign: channel?.allowAutoAssign,
      departments: channel?.departments,
      description: channel?.description,
      functions: channel?.functions,
      locations: channel?.locations,
      permissions: {
        managers: channel?.managers,
        owners: channel?.owners,
        publishers: channel?.publishers,
      },
      name: channel?.name,
      id: channel?.id,
      role: channel?.role
    },
  }) : ({
    ...state,
    form: {
      ...state.form,
      permissions: {
        ...state.form.permissions,
        owners: [user]
      }
    },
    initialForm: {
      ...state.initialForm,
      permissions: {
        ...state.initialForm.permissions,
        owners: [user]
      }
    }
  })),
  on(fromActions.setFormValue, (state, { form }) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.setFormTouched, (state, { touched }) => ({
    ...state,
    touched
  })),
  on(fromActions.isNameUniqueSuccess, (state, {isUnique}) => ({
    ...state,
    isNameUnique: isUnique

  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return addReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;
export const getId = (form: Partial<ChannelForm>) => form.id;
export const getTouched = (state: State) => state.touched;
export const getIsNameUnique = (state: State) => state.isNameUnique;
