import { Injectable } from '@angular/core';
import { NewsTableFilters } from '@app/news/store/news-table';
import { Pagination } from '@app/types/pagination';
import { SortDirection } from '@rds/angular-components';

import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiClient } from '../api-client.service';
import { TourService } from '../tour/tour.service';

import {
  News,
  NewsSuggestion,
  NewsDetails,
  NewsCreation,
  NewsUpdate,
  NewsContent,
  NewsImage,
  NewsListRequest,
  PagedData,
  PageInformation,
  EMPTY_NEWS_DETAILS_FOR_TEMPLATE
} from '../types';
import { AuthService } from '@app/auth/auth.service';
import { Template, TemplateForm } from '@app/types/template';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private _news: BehaviorSubject<News[]> = new BehaviorSubject([]);

  private _mockData: PagedData<News>;
  private _initialDataRequest: NewsListRequest;

  private _loading = new ReplaySubject<boolean>(1);

  get news(): Observable<News[]> {
    return this._news.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable();
  }

  constructor(private client: ApiClient, private tourService: TourService, private auth: AuthService) {
    this.tourService.isTourActive.subscribe((tourState) => {
      if (tourState.tourId !== 'news') {
        return;
      }

      if (tourState.active) {
        this.loadMocks();
        return;
      }

      this.load(this._initialDataRequest, true);
    });
  }

  loadMocks(): void {
    if (this._mockData?.data) {
      this._news.next(this._mockData.data);
      this._loading.next(false);
      return;
    }

    import('../tour/mock-data/news.mock-data').then((res) => {
      this._mockData = res.default;
      this._news.next(res.default.data);
      this._loading.next(false);
    });
  }

  load(request?: NewsListRequest, reload = false): Observable<PageInformation> {
    this._news.next(null);
    this._loading.next(true);

    if (!reload) {
      this._initialDataRequest = request;
    }

    const obs = this.createRequest(request);
    if (reload) {
      obs.subscribe(() => {});
    }

    return obs;
  }

  createRequest(request: NewsListRequest) {
    return this.client.post('/news', request).pipe(
      tap((resp: PagedData<News>) => {
        this._news.next(resp.data);
        this._loading.next(false);
      }),
      map(({ data, ...rest }) => {
        return rest;
      })
    );
  }

  getNews({pageIndex, pageSize, sort, filters}: {
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters,
  }, view: 'my' | 'suggested' | 'archived' | 'channel' | 'approve' | 'carchive' | 'all' = 'my', channelId?: number): Observable<{data: Array<News>, pagination: Pagination}> {
    const request: NewsListRequest = {
      count: pageSize,
      offset: pageSize * pageIndex,
      order: sort.direction,
      sortBy: sort.active,
      query: filters?.search?.value,
      types: filters?.types?.value,
      statuses: filters?.statuses?.value,
      approvalStatuses: filters?.approvalStatuses?.value,
      channelId,
      view
    }
    return this.client.post('/news', request).pipe(
      map((res: PagedData<News>): {data: Array<News>, pagination: Pagination} => {
        return ({
          data: res.data,
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }

  getRelatedNews({pageIndex, pageSize, sort, filters}: {
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters,
  }): Observable<{data: Array<News>, pagination: Pagination}> {
    const query = filters.search.value;
    const scope = filters.scope.value;
    const offset = pageSize * pageIndex;
    const count = pageSize;
    return this.client.post(`/news/search/${scope}/${offset}/${count}`, { query }).pipe(
      map((res: PagedData<News>): {data: Array<News>, pagination: Pagination} => {
        return ({
          data: res.data,
          pagination: {
            isFirst: res.offset === 0,
            isLast: ((res.offset / res.perPage) + 1) * res.perPage >= res.total,
            pageCount: Math.ceil(res.total / res.perPage),
            pageIndex: res.offset / res.perPage,
            pageSize: res.perPage,
            totalCount: res.total,
          }
        }) ;
      })
    );
  }

  getMultipleNewsInfo({ids}: {
    ids: Array<number>
  }): Observable<Array<News>> {
    return this.client.post(`/news/info`, {ids})
  }

  get(id: number): Observable<NewsDetails> {
    return this.client.get(`/news/${id}`);
  }

  getTemplate(id: number): Observable<Template> {
    return this.client.get(`/news/${id}`);
  }

  saveAsTemplate(id: number, imagePreview: string): Observable<NewsDetails> {
    const body = {
      newsId: id,
      imagePreview,
      isPredefined: false
    }
    return this.client.post(`/templates`, body);
  }

  preview(id: number): Observable<Array<NewsContent>> {
    return this.client.get(`/news/${id}/content`);
  }

  create(news: NewsCreation): Observable<any> {
    return this.client.post('/news/create', news);
  }

  createTemplate(template: Partial<TemplateForm>): Observable<any> {
    return this.client.post('/news/create', template);
  }

  edit(newsId: number, news: NewsUpdate): Observable<any> {
    return this.client.put(`/news/${newsId}/edit`, news);
  }

  archive(newsId: number): Observable<any> {
    return this.client.put(`/news/${newsId}/archive`, {});
  }

  bulkArchive(newsIds: Array<number>): Observable<any> {
    return this.client.put(`/news/bulkarchive`, {newsIds});
  }

  unarchive(newsId: number, toDraft: boolean): Observable<any> {
    return this.client.put(`/news/${newsId}/unarchive`, { toDraft });
  }

  bulkUnarchive(newsIds: Array<number>, toDraft: boolean): Observable<any> {
    return this.client.put(`/news/bulkunarchive`, {toDraft, newsIds});
  }

  delete(ids: Array<number>): Observable<any> {
    return this.client.delete(`/news/delete`, ids);
  }

  duplicate(newsId: number): Observable<number> {
    return this.client.post(`/news/${newsId}/duplicate`, {});
  }


  removeFromChannel(newsIds: Array<number>, channelId: number): Observable<any> {

    return this.client.delete(`/news/remove/${channelId}`,newsIds);
  }

  acceptSuggestion(newsId: number, channelId: number): Observable<any> {
    return this.client.put(`/news/${newsId}/suggestion/${channelId}/approve`, {});
  }

  rejectSuggestion(newsId: number, channelId: number, comment?: string): Observable<any> {
    return this.client.put(`/news/${newsId}/suggestion/${channelId}/reject`, { comment });
  }

  removeSuggestion(newsIds: Array<number>, channelId: number): Observable<any> {
    return this.client.delete(`/news/suggestion/${channelId}`, newsIds);
  }

  upload(fileName: string, data: string, verify = true): Observable<any> {
    return this.client.post('/upload', { name: fileName, data, verify });
  }

  fetch(url: string): Observable<any> {
    return this.client.post('/fetch', { url });
  }

  pin(newsId: number, channelId: number, unpin = false): Observable<any> {
    if (unpin) {
      return this.client.put(`/channel/${channelId}/unpin/${newsId}`, {});
    }
    return this.client.put(`/channel/${channelId}/pin/${newsId}`, {});
  }
}

export {
  News,
  NewsSuggestion,
  NewsDetails,
  NewsCreation,
  NewsUpdate,
  NewsContent,
  NewsImage,
  NewsListRequest,
  PageInformation
};
