import { Component, Input } from '@angular/core';
import { FormStepStatus } from '../form-status-badge/form-status-badge.component';

@Component({
  selector: 'rnb-step-content',
  templateUrl: './step-content.component.html',
  styleUrls: ['./step-content.component.scss']
})
export class StepContentComponent {

  @Input() stepNo: number;
  @Input() stepName: string;
  @Input() stepStatus: FormStepStatus;

  constructor() { }
}
