import { createSelector } from '@ngrx/store';

import * as fromAdminPanel from '../admin-panel.reducer';
import * as fromReducer from './api-keys.reducer';

export const selectState = createSelector(
  fromAdminPanel.selectState,
  fromAdminPanel.getApiKeys
);

// [ALL NEWS]
export const selectApiKeysState = createSelector(
  selectState,
  fromReducer.getApiKeys
);

export const selectApiKeysRequestData = createSelector(
  selectApiKeysState,
  fromReducer.getRequestData
);

export const selectApiKeysData = createSelector(
  selectApiKeysState,
  fromReducer.getEventsData
);

export const selectApiKeysPagination = createSelector(
  selectApiKeysState,
  fromReducer.getPagination
);

export const selectApiKeysTotal = createSelector(
  selectApiKeysPagination,
  fromReducer.getTotalCount
);

export const selectApiKeysSort = createSelector(
  selectApiKeysState,
  fromReducer.getSort
);

export const selectApiKeysIsLoading = createSelector(
  selectApiKeysState,
  fromReducer.getIsLoading
);
