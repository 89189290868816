import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ChannelDetails, ContentType as ViewMode, NewsContent, NewsDetails, ChannelAssignment, Language, ChannelBase} from '../../types';
import {environment} from '../../../environments/environment';
import {LanguageService} from '../language.service';
import * as fromLanguages from '@app/root-store/dictionaries/languages';

import { RdsDialogRef, RdsTabsComponent, RDS_DIALOG_DATA } from '@rds/angular-components';
import { AuthService } from '@app/auth/auth.service';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BackButton } from '@app/root-store/ui/back-button';

type ContentMode = 'desktop' | 'mobile';

export interface PreviewButtonState {
  visible: boolean;
  disabled: boolean;
}

export interface PreviewButtons {
  editNews?: PreviewButtonState
  canSaveNews?: PreviewButtonState
  editTemplate?: PreviewButtonState
  canSaveTemplate?: PreviewButtonState
}

export interface PreviewDialogData  {
  news: NewsDetails;
  channel: ChannelBase;
  channelsDict?: Array<ChannelAssignment>;
  context: 'TemplateEditor' | 'NewsEditor' | 'Table' | 'Template' | 'NewsSelect';
  buttons?: PreviewButtons
  backButton?: BackButton;
}

@Component({
  selector: 'rnb-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./editor.scss', './preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {
  @ViewChild('tabs') tabs: RdsTabsComponent;

  context: 'TemplateEditor' | 'NewsEditor' | 'Table' | 'Template' | 'NewsSelect';
  backButton: BackButton;
  buttons: PreviewButtons;
  channelId: number;
  channelName: string;
  channelsDict: Array<ChannelAssignment>;
  languageOptions: Array<{ id: string; name: string }>;
  news: NewsDetails;
  activeLanguage: string;
  viewMode: ViewMode = 'Link';
  contentMode: ContentMode = 'desktop';

  languages$: Observable<Array<Language>> = this.store$.pipe(select(fromLanguages.selectAll));


  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin || this.auth.currentUser.isSuperAdmin;
  }

  get publishDate(): Date {
    return this.news.publishDate ? new Date(this.news.publishDate) : null;
  }

  get showViewToggle() {
    return this.tabs?.selectedIndex > 0;
  }

  get showEdit() {
    return this.news.isOwner || this.news.isEditor || this.isAdmin;
  }

  get activeContent(): NewsContent {
    return this.news.content.find((newsContent) => this.activeLanguage === newsContent.language);
  }

  get activeLanguageName() {
    return this.languageOptions?.find((option) => this.activeLanguage === option.id)?.name;
  }
  

  constructor(
    private store$: Store<any>,
    private auth: AuthService,
    private dialogRef: RdsDialogRef<PreviewDialogComponent>,
    @Inject(RDS_DIALOG_DATA) data: PreviewDialogData
  ) {
    this.news = data.news;
    this.channelId = data.channel?.id;
    this.channelName = data.channel?.name;
    this.channelsDict = data.channelsDict;
    this.activeLanguage = this.news.content[0].language;
    this.context = data?.context;
    this.backButton = data?.backButton;
    this.buttons = data?.buttons;
  }

  ngOnInit(): void {
    this.store$.dispatch(fromLanguages.getLanguagesRequest());
    
    this.languages$.subscribe((languageData) => {
      const contentLanguageIds = this.news.content.map(({language}) => language);
      this.languageOptions = languageData
        .filter((language) => contentLanguageIds.includes(language.code))
        .map(({code: id, name}) => ({id, name}));
    });
  }

  close(status?): void {
    this.dialogRef.close({status});
  }

  setContentMode(contentMode: ContentMode): void {
    this.contentMode = contentMode;
  }

  setActiveLanguage(language): void {
    this.activeLanguage = language.id;
  }

  setViewMode(viewMode: ViewMode): void {
    this.viewMode = viewMode;
  }

  gotoLink(): void {
    window.open(this.activeContent.link, '_blank');
  }

  gotoEmbedded(): void {
    const channelId = this.channelId?.toString() || 'any';
    const url = environment.newsHomeLink
      .replace('{channelId}', channelId)
      .replace('{newsId}', this.news.id.toString());
    window.open(url, '_blank');
  }

}
