import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ImagesAndTextBoxCommand from './images-and-text-box-command';;
import CustomBoxResizeUi from "../blocks/custom-box-resize-ui";
import CustomImageUploadCommand from "../blocks/custom-image-upload-command";
import CustomImageResizeCommand from "../blocks/custom-box-resize-command";

export default class ImagesAndTextBoxEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        this._defineSchema();
        this._defineConverters();


        this.editor.commands.add('insertImagesAndTextBox', new ImagesAndTextBoxCommand(this.editor));
        this.editor.commands.add('customImageUpload', new CustomImageUploadCommand(this.editor));
        this.editor.commands.add('imagesAndTextSize', new CustomBoxResizeUi(this.editor));
        this.editor.commands.add('customImageResize', new CustomImageResizeCommand(this.editor));

    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('imagesAndTextBox', {
            inheritAllFrom: '$blockObject',

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block'
        });

        schema.register('imagesAndTextBoxText', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'imagesAndTextBox',

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        });

        schema.addChildCheck((context, childDefinition) => {
            if (context.endsWith('imagesAndTextBoxText') && childDefinition.name == 'imagesAndTextBox') {
                return false;
            }
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        conversion.for('upcast').elementToElement({
            model: (viewElement, {writer}) => {
                return writer.createElement('imagesAndTextBox');
            },
            view: {
                name: 'section',
                classes: ['images-and-text-box']
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imagesAndTextBox',
            view: {
                name: 'section',
                classes: ['images-and-text-box', 'mb-6']
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'imagesAndTextBox',
            view: (modelElement, {writer: viewWriter}) => {
                const section = viewWriter.createContainerElement('section', {class: 'py-6 my-6 images-and-text-box'});
                return toWidget(section, viewWriter, {label: 'simple box widget'});
            }
        })



        conversion.for('upcast').elementToElement({
            model: 'imagesAndTextBoxText',
            view: {
                name: 'div',
                classes: 'images-and-text-text'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imagesAndTextBoxText',
            view: {
                name: 'div',
                classes: 'images-and-text-text'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'imagesAndTextBoxText',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement('div', {class: 'images-and-text-text'});
                return toWidgetEditable(div, viewWriter);
            }
        });


        
    }
}
export function isHtmlIncluded( dataTransfer ) {
	return Array.from( dataTransfer.types ).includes( 'text/html' ) && dataTransfer.getData( 'text/html' ) !== '';
}
