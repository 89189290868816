<ng-template #formActions>
  <ng-container *ngIf="channel$ | async as channel">
    <button *ngIf="channel.id" [disabled]="channel.role !== 'Owner' && channel.role !== 'Admin'" rds-warning-button size="l" (click)="deleteChannel(channel.id, channel.name)">Delete Channel</button>
    <button rds-secondary-button size="l" [routerLink]="'../'">Cancel</button>
    <button rds-primary-button size="l" [disabled]="form.invalid || nothingChanged" (click)="save(channel.id)">{{channel.id ? 'Save changes' : 'Create Channel' }}</button>
  </ng-container>
</ng-template>
<ng-template #infoActions>
  <ng-container *ngIf="channel$ | async as channel">
    <button rds-secondary-button size="l" [routerLink]="'../'">Back</button>
  </ng-container>
</ng-template>
<ng-template #notDefined>
  <p class="ui-label-l-bold">Not defined</p>
</ng-template>
<div class="row">
  <div class="col lmy-3">
    <rnb-back-button [leavePageWarning]="true" [changesMade]="!nothingChanged" defaultLabel="Channels" defaultRoute="channels/my"></rnb-back-button>
  </div>
</div>
<rnb-view-bar [hideAddNews]="true">
  <span title>
    <ng-container [ngSwitch]="mode">
      <span *ngSwitchCase="'edit'">
        Edit channel information
      </span>
      <span *ngSwitchCase="'create'">
        Create channel
      </span>
      <span *ngSwitchDefault>
        Channel information
      </span>
    </ng-container>
  </span>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'info'">
      <ng-container *ngTemplateOutlet="infoActions"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="formActions"></ng-container>
    </ng-container>
  </ng-container>
</rnb-view-bar>
<div class="row" [formGroup]="form">
  <div class="col bg-white py-10 mb-9">
    <div class="row">
      <div class="col px-11">
        <h4 requireAsterisk class="ui-heading-4">General Information</h4>
        <rds-divider class="mt-5 mb-9"></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mb-5">
        <rds-form-field>
          <rds-control-label>Channel name</rds-control-label>
          <input rdsInput formControlName="name"/>
          <rds-control-hint class="right">{{form.get('name').value?.length || 0}}/70</rds-control-hint>
          <rds-control-error *ngIf="form.get('name').hasError('required') && form.get('name').touched">This field is required</rds-control-error>
          <rds-control-error *ngIf="form.get('name').hasError('maxlength') && form.get('name').touched">Maximum number of characters exceeded</rds-control-error>
          <rds-control-error *ngIf="(form.get('name').hasError('required') || form.get('name').hasError('maxlength')) && form.get('name').touched" class="right">{{form.get('name').value?.length || 0}}/70</rds-control-error>
          <rds-control-error *ngIf="form.controls.name.errors?.isUnique">A channel with this name already exists</rds-control-error>
        </rds-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mb-7">
        <rds-form-field>
          <rds-control-label>Description</rds-control-label>
          <textarea rdsInput formControlName="description"></textarea>
          <rds-control-hint class="right">{{form.get('description').value?.length || 0}}/255</rds-control-hint>
          <rds-control-error *ngIf="form.get('description').hasError('required') && form.get('description').touched">This field is required</rds-control-error>
          <rds-control-error *ngIf="form.get('description').hasError('maxlength') && form.get('description').touched">Maximum number of characters exceeded</rds-control-error>
          <rds-control-error *ngIf="(form.get('description').hasError('required') || form.get('description').hasError('maxlength')) && form.get('description').touched" class="right">{{form.get('description').value?.length || 0}}/255</rds-control-error>
        </rds-form-field>
      </div>
      <div class="col-8 offset-2 mb-10">
        <rds-switch formControlName="allowAutoAssign" checked>Allow anyone to assign news to this channel</rds-switch>
      </div>
    </div>
    <div class="row">
      <div class="col px-11">
        <h4 requireAsterisk class="ui-heading-4">Channel Owners, Managers, Publishers</h4>
        <rds-divider class="mt-5 mb-9"></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mb-10">
        <rnb-people-role-picker 
          (search)="getAutocompletes('channelPermissions', $event)"
          (selected)="getAutocompletes('channelPermissions', '')"
          (removed)="getAutocompletes('channelPermissions', '')"
          [notRemovable]="[userIdentifier]"
          formControlName="permissions"
          [roles]="CHANNEL_ROLES"
          defaultRole="publishers"
          [autocomplete]="channelPermissionsAutocomplete$ | async">
          <rds-control-error *ngIf="form.controls.permissions.errors?.atLeastOneOwnerRequired">At least one Owner is required</rds-control-error>
        </rnb-people-role-picker>
        <!-- <rnb-people-picker 
        *ngIf="(form.controls.owners.disabled && form.controls.owners.value.length > 0 || !form.controls.owners.disabled) else notDefined"
        [disabled]="(channel$ | async).role !== 'Owner'"
        (search)="getAutocompletes('channelOwners', $event)"
        [labelFn]="ownerLabelFn"
        [multi]="true"
        [restrictedUsers]="getRestrictedUsers('owners')"
        formControlName="owners"
        [autocomplete]="ownersAutocomplete$ | async"></rnb-people-picker> -->
      </div>
    </div>
    <!-- <div class="row">
      <div class="col px-11">
        <h4 class="ui-heading-4">Channel Managers</h4>
        <rds-divider class="mt-5 mb-9"></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mb-10">
        <rnb-people-picker 
        *ngIf="(form.controls.managers.disabled && form.controls.managers.value.length > 0 || !form.controls.managers.disabled) else notDefined"
        (search)="getAutocompletes('channelManagers', $event)"
        [labelFn]="userLabelFn"
        [multi]="true"
        [restrictedUsers]="getRestrictedUsers('managers')"
        formControlName="managers"
        [autocomplete]="managersAutocomplete$ | async"></rnb-people-picker>
      </div>
    </div>
    <div class="row">
      <div class="col px-11">
        <h4 class="ui-heading-4">Publishers</h4>
        <rds-divider class="mt-5 mb-9"></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mb-10">
        <rnb-people-picker 
        *ngIf="(form.controls.publishers.disabled && form.controls.publishers.value.length > 0 || !form.controls.publishers.disabled) else notDefined"
        (search)="getAutocompletes('channelPublishers', $event)"
        [labelFn]="userLabelFn"
        [multi]="true"
        [restrictedUsers]="getRestrictedUsers('publishers')"
        formControlName="publishers"
        [autocomplete]="publishersAutocomplete$ | async"></rnb-people-picker>
      </div>
    </div> -->
    <div class="row">
      <div class="col px-11">
        <h4 requireAsterisk class="ui-heading-4">Audience</h4>
        <rds-divider class="mt-5 mb-9"></rds-divider>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mb-5">
        <p *ngIf="!form.disabled" class="ui-label-s mb-9">Pick at least one field describing audience of this channel.<br/>The audience will be based on a combination of the selected dimensions (eg. employees located in Germany AND belonging to the DIA department).</p>
        <h4 class="ui-heading-4 mb-3">Locations</h4>
        <rnb-dropdown
        *ngIf="!form.controls.locations.disabled"
        formControlName="locations"
        placeholder="Select locations"
        chipPlaceholder="Default: All locations"
        size="small"
        [closeOnClickOutside]="true"
        [isMultiple]="true"
        [optionsDictionary]="locationsDict$ | async"
        [optionsModel]="taxonomyOptModel"
      ></rnb-dropdown>
      <rds-chip-list [disabled]="true" *ngIf="form.controls.locations.disabled">
        <rds-chip *ngIf="form.controls.locations.value.length === 0">
          Default: All locations
        </rds-chip>
        <rds-chip *ngFor="let location of form.controls.locations.value">
          {{location | taxonomy:'location' | async}}
        </rds-chip>
      </rds-chip-list>
      <rds-divider class="my-5"></rds-divider>
      <h4 class="ui-heading-4 mb-3">Departments</h4>
      <rnb-dropdown
        *ngIf="!form.controls.departments.disabled"
        formControlName="departments"
        placeholder="Select departments"
        chipPlaceholder="Default: All departments"
        size="small"
        [closeOnClickOutside]="true"
        [isMultiple]="true"
        [optionsDictionary]="departmentsDict$ | async"
        [optionsModel]="taxonomyOptModel"
      ></rnb-dropdown>
      <rds-chip-list [disabled]="true" *ngIf="form.controls.departments.disabled">
        <rds-chip *ngIf="form.controls.departments.value.length === 0">
          Default: All departments
        </rds-chip>
        <rds-chip *ngFor="let department of form.controls.departments.value">
          {{department | taxonomy:'department' | async}}
        </rds-chip>
      </rds-chip-list>
      <rds-divider class="my-5"></rds-divider>
      <h4 class="ui-heading-4 mb-3">Functions</h4>
      <rnb-dropdown
        *ngIf="!form.controls.functions.disabled"
        formControlName="functions"
        placeholder="Select functions"
        chipPlaceholder="Default: All functions"
        size="small"
        [closeOnClickOutside]="true"
        [isMultiple]="true"
        [optionsDictionary]="functionsDict$ | async"
        [optionsModel]="taxonomyOptModel"
      ></rnb-dropdown>
      <rds-chip-list [disabled]="true" *ngIf="form.controls.functions.disabled">
        <rds-chip *ngIf="form.controls.functions.value.length === 0">
          Default: All functions
        </rds-chip>
        <rds-chip *ngFor="let function of form.controls.functions.value">
          {{function | taxonomy:'function' | async}}
        </rds-chip>
      </rds-chip-list>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <rnb-view-bar [hideAddNews]="true">
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'info'">
          <ng-container *ngTemplateOutlet="infoActions"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="formActions"></ng-container>
        </ng-container>
      </ng-container>
    </rnb-view-bar>
  </div>
</div>