import { createAction, props } from '@ngrx/store';
export const setPhrase = createAction(
  '[Header2] Set phrase',
  props<{ phrase: string }>()
);

export const setSuggestionPhrase = createAction(
  '[Header2] Set suggestion phrase',
  props<{ phrase: string }>()
);

export const goToSearch = createAction(
  '[Header2] Go to search',
  props<{ phrase: string }>()
);

export const searchFocused = createAction(
  '[Header2] Search input focused'
);

export const searchBlurred = createAction(
  '[Header2] Search input blurred'
);

