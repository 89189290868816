import {ButtonView, FocusCycler, LabelView, View, ViewCollection, Template} from 'ckeditor5/src/ui';
import {FocusTracker, KeystrokeHandler} from 'ckeditor5/src/utils';

import {iconsMap} from "../blocks-icons-map";
export default class BlocksView extends View {
    constructor(editor) {
        super(editor);
        const locale = editor.locale;
        const t = locale.t;
        this.editor = editor;
        this._peopleAnnouncemenLeft = this._createButton({
            label: t('Image on the left side'),
            class: 'ck-button-dropdown ck-button-announcement--left',
            withText: false,
            icon:  iconsMap.get('paLeft'),
            tooltip: true
        });
        this._peopleAnnouncemenRight = this._createButton({
            label: t('Image on the right side'),
            class: 'ck-button-dropdown ck-button-announcement--right',
            withText: false,
            icon: iconsMap.get('paRight'),
            tooltip: true
        });

        this._imageTextRight = this._createButton({
            label: t('Image on the right side'),
            class: 'ck-button-dropdown ck-button-image-and-text--right',
            withText: false,
            icon: iconsMap.get('imageAndTextRight'),
            tooltip: true
        });

        this._imageTextLeft = this._createButton({
            label: t('Image on the left side'),
            class: 'ck-button-dropdown ck-button-image-and-text--left',
            withText: false,
            icon: iconsMap.get('imageAndTextLeft'),
            tooltip: true
        });

        this._movieTextRight = this._createButton({
            label: t('Movie on the right side'),
            class: 'ck-button-dropdown ck-button-dropdown--right',
            withText: false,
            icon: iconsMap.get('movieAndTextRight'),
            tooltip: true
        });


        this._movieTextLeft = this._createButton({
            label: t('Movie on the left side'),
            class: 'ck-button-dropdown ck-button-dropdown--left',
            withText: false,
            icon: iconsMap.get('movieAndTextLeft'),
            tooltip: true
        });
        this._imageQuoteRight = this._createButton({
            label: t('Image on the right side'),
            class: 'ck-button-dropdown ck-button-dropdown--right',
            withText: false,
            icon: iconsMap.get('imageAndQuoteRight'),
            tooltip: true
        });

        this._imageQuoteLeft = this._createButton({
            label: t('Image on the left side'),
            class: 'ck-button-dropdown ck-button-dropdown--left',
            withText: false,
            icon: iconsMap.get('imageAndQuoteLeft'),
            tooltip: true
        });

        this._textSingleColumn = this._createButton({
            label: t('One column'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('single'),
            tooltip: true
        });
        this._textDoubleColumn = this._createButton({
            label: t('Two columns'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('double'),
            tooltip: true
        });
        this._textBlock = this._createButton({
            label: t('Block column'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('block'),
            tooltip: true
        });
        this._imagesText = this._createButton({
            label: t('Images and text'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('imagesAndText'),
            tooltip: true
        });

        this._images2 = this._createButton({
            label: t('Two images'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('images2'),
            tooltip: true
        });
        this._images3 = this._createButton({
            label: t('Three images'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('images3'),
            tooltip: true
        });
        this._images4 = this._createButton({
            label: t('Four images'),
            class: 'ck-button-dropdown',
            withText: false,
            icon: iconsMap.get('images4'),
            tooltip: true
        });

        this._focusTracker = new FocusTracker();
        this._keystrokes = new KeystrokeHandler();
        this._focusables = new ViewCollection();
        this._announcementFieldSetView = this._createAnnouncementFieldset();
        this._imageTextFieldSetView = this._createImageTextFieldset();
        this._movieTextFieldSetView = this._createMovieTextFieldset();
        this._imageQuoteFieldSetView = this._createImageQuoteFieldset();
        this._textFieldSetView = this._createTextFieldset();
        this._imagesTextFieldSetView = this._createImagesTextFieldset();
        this._imagesFieldSetView = this._createImagesFieldset();
        this._focusCycler = new FocusCycler({
            focusables: this._focusables,
            focusTracker: this._focusTracker,
            keystrokeHandler: this._keystrokes,
            actions: {
                // Navigate form fields backwards using the <kbd>Shift</kbd> + <kbd>Tab</kbd> keystroke.
                focusPrevious: 'shift + tab',
                // Navigate form fields forwards using the <kbd>Tab</kbd> key.
                focusNext: 'tab'
            }
        });

        this.setTemplate({
            tag: 'section',
            attributes: {
                class: [
                    'ck'
                ],
                tabindex: '-1'
            },
            children: [
                this._announcementFieldSetView,
                this._imageTextFieldSetView,
                this._movieTextFieldSetView,
                this._imageQuoteFieldSetView,
                this._textFieldSetView,
                this._imagesFieldSetView,
                this._imagesTextFieldSetView,
            ]
        });
    }

    focus() {
        this._focusCycler.focusFirst();
    }

    _createAnnouncementFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        const command = this.editor.commands.get('insertAnnouncementBox');
        this._peopleAnnouncemenLeft.on('execute', () => {
            this.editor.execute('insertAnnouncementBox',{value:'left' });
            this.editor.editing.view.focus();
        });

        this._peopleAnnouncemenLeft.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._peopleAnnouncemenRight.bind('isEnabled').to(command, 'isEnabled', value => value);

        this._peopleAnnouncemenRight.on('execute', () => {
            this.editor.execute('insertAnnouncementBox',{value:'right' });
            this.editor.editing.view.focus();
            this.focus();
        });
        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-people-announcement']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'People announcement',
                    attributes: {}
                }),
                this._peopleAnnouncemenLeft,
                this._peopleAnnouncemenRight
            ]
        });
        return fieldsetView;
    }

    _createImageTextFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        this._imageTextLeft.on('execute', () => {
            this.editor.execute('imageAndTextBox',{value:'left' });
            this.editor.editing.view.focus();
        });
        this._imageTextRight.on('execute', () => {
            this.editor.execute('imageAndTextBox',{value:'right' });
            this.editor.editing.view.focus();
            this.focus();
        });
        const command = this.editor.commands.get('imageAndTextBox');
        this._imageTextLeft.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._imageTextRight.bind('isEnabled').to(command, 'isEnabled', value => value);

        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-image-and-text']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'Image and text',
                    attributes: {}
                }),
                this._imageTextLeft,
                this._imageTextRight
            ]
        });
        return fieldsetView;
    }
    _createImagesTextFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        this._imagesText.on('execute', () => {
            this.editor.execute('insertImagesAndTextBox',{value:'left' });
            this.editor.editing.view.focus();
        });
        const command = this.editor.commands.get('insertImagesAndTextBox');
        this._imagesText.bind('isEnabled').to(command, 'isEnabled', value => value);
        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-images-and-text']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'Images and text',
                    attributes: {}
                }),
                this._imagesText
            ]
        });
        return fieldsetView;
    }
    _createTextFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        this._textSingleColumn.on('execute', () => {
            this.editor.execute('insertTextBox',{value:'single' });
            this.editor.editing.view.focus();
        });
        this._textDoubleColumn.on('execute', () => {
            this.editor.execute('insertTextBox',{value:'double' });
            this.editor.editing.view.focus();
            this.focus();
        });
        this._textBlock.on('execute', () => {
            this.editor.execute('insertTextBox',{value:'block' });
            this.editor.editing.view.focus();
            this.focus();
        });

        const command = this.editor.commands.get('insertTextBox');
        this._textSingleColumn.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._textDoubleColumn.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._textBlock.bind('isEnabled').to(command, 'isEnabled', value => value);

        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-image-and-text']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'Text',
                    attributes: {}
                }),
                this._textSingleColumn,
                this._textDoubleColumn,
                this._textBlock
            ]
        });
        return fieldsetView;
    }

    _createMovieTextFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        this._movieTextLeft.on('execute', () => {
            this.editor.execute('insertMovieAndTextBox',{value:'left' });
            this.editor.editing.view.focus();
        });
        this._movieTextRight.on('execute', () => {
            this.editor.execute('insertMovieAndTextBox',{value: 'right' });
            this.editor.editing.view.focus();
            this.focus();
        });
        const command = this.editor.commands.get('insertMovieAndTextBox');
        this._movieTextLeft.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._movieTextRight.bind('isEnabled').to(command, 'isEnabled', value => value);
        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-movie-and-text']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'Movie and text',
                    attributes: {}
                }),
                this._movieTextLeft,
                this._movieTextRight
            ]
        });
        return fieldsetView;
    }
    _createImageQuoteFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        this._imageQuoteLeft.on('execute', () => {
            this.editor.execute('imageAndQuoteBox',{value:  'left' });
            this.editor.editing.view.focus();
        });
        this._imageQuoteRight.on('execute', () => {
            this.editor.execute('imageAndQuoteBox',{value: 'right' });
            this.editor.editing.view.focus();
            this.focus();
        });
        const command = this.editor.commands.get('imageAndQuoteBox');
        this._imageQuoteLeft.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._imageQuoteRight.bind('isEnabled').to(command, 'isEnabled', value => value);
        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-image-and-quote']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'Image and quote',
                    attributes: {}
                }),
                this._imageQuoteLeft,
                this._imageQuoteRight
            ]
        });
        return fieldsetView;
    }

    _createImagesFieldset() {
        const locale = this.locale;
        const t = locale.t;
        const fieldsetView = new View(this.locale);
        this._images2.on('execute', () => {
            this.editor.execute('insertImagesBox',{value:  2 });
            this.editor.editing.view.focus();
        });
        this._images3.on('execute', () => {
            this.editor.execute('insertImagesBox',{value: 3 });
            this.editor.editing.view.focus();
            this.focus();
        });
        this._images4.on('execute', () => {
            this.editor.execute('insertImagesBox',{value: 4 });
            this.editor.editing.view.focus();
            this.focus();
        });
        const command = this.editor.commands.get('insertImagesBox');
        this._images2.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._images3.bind('isEnabled').to(command, 'isEnabled', value => value);
        this._images4.bind('isEnabled').to(command, 'isEnabled', value => value);
        fieldsetView.setTemplate({
            tag: 'fieldset',
            attributes: {
                class: ['ck-custom-fieldset', 'ck-image-and-quote']
            },
            children: [
                this._crateLabel({tag: 'p',
                    text: 'Images',
                    attributes: {}
                }),
                this._images2,
                this._images3,
                this._images4
            ]
        });
        return fieldsetView;
    }

    _crateLabel(options) {
        const label = new LabelView(this.locale);
        label.set(options);
        return label;
    }

    _createButton(options) {
        const button = new ButtonView(this.locale);
        button.set(options);
        return button;
    }
}
