import { createReducer, Action, on } from '@ngrx/store';

import * as fromActions from './template-form.actions';
import * as _ from 'lodash';
import { FormStepStatus } from '@app/news/components/news-form/form-status-badge/form-status-badge.component';
import { Template, TemplateForm } from '@app/types/template';
import { SimpleUser } from '@app/types';

export const feature = 'form';

export interface State {
  form: Partial<TemplateForm>;
  initialForm: Partial<TemplateForm>;
  createFromTemplate: Template;
  generalStatus: {
    step: FormStepStatus;
    form: string
  }
  contentStatus: {
    step: FormStepStatus;
    form: string
  }
  permissionsStatus: {
    step: FormStepStatus;
    form: string
  }
  touched: boolean;
  allSuperAdmins: Array<SimpleUser>;
  uploadingImages: Array<string>;
}

export const initialState: State = {
  form: null,
  initialForm: null,
  createFromTemplate: null,
  generalStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID'
  },
  contentStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID'
  },
  permissionsStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'VALID'
  },
  touched: false,
  allSuperAdmins: [],
  uploadingImages: []
};

const addReducer = createReducer(
  initialState,
  on(fromActions.setFormValue, (state, { form }) => ({
    ...state,
    form: {
      ...state.form,
      ...form
    }
  })),
  on(fromActions.setInitialFormValue, (state, { form }) => ({
    ...state,
    initialForm: _.cloneDeep(form)
  })),
  on(fromActions.updatePrimaryLanguage, (state, { oldLang, newLang }) => {
    const currentPrimaryId = state.form.content.findIndex(c => c.language === oldLang );
    const versionExists = state.form.content.findIndex(c => c.language === newLang) > -1;

    return {
      ...state,
      form: {
        ...state.form,
        language: newLang,
        content: state.form.content.map((c, index) => ({
          ...c,
          language: !versionExists && currentPrimaryId === index ? newLang : c.language,
        }))
      }
    }
  }),
  on(fromActions.addLanguageVersion, (state, { language }) => {
    const currentPrimaryImage = {
      ...state.form.content.find(c => c.language === state.form.language ).image
    };

    return {
      ...state,
      form: {
        ...state.form,
        content: [
          ...state.form.content,
          {
            language,
            abstract: null,
            title: null,
            html: null,
            image: currentPrimaryImage,
            link: null,
            syncImage: true
          }
        ]
      }
    }
  }),
  on(fromActions.removeLanguageVersion, (state, { language }) => {
    return {
      ...state,
      form: {
        ...state.form,
        content: state.form.content.filter(c => c.language !== language)
      }
    }
  }),
  on(fromActions.setFormTouched, (state, { touched }) => ({
    ...state,
    touched
  })),
  on(fromActions.setGeneralStepStatus, (state, { status }) => ({
    ...state,
    generalStatus: {
      ...state.generalStatus,
      step: status
    }
  })),
  on(fromActions.setGeneralFormStatus, (state, { status }) => ({
    ...state,
    generalStatus: {
      ...state.generalStatus,
      form: status
    }
  })),
  on(fromActions.setContentStepStatus, (state, { status }) => ({
    ...state,
    contentStatus: {
      ...state.contentStatus,
      step: status
    }
  })),
  on(fromActions.setContentFormStatus, (state, { status }) => ({
    ...state,
    contentStatus: {
      ...state.contentStatus,
      form: status
    }
  })),
  on(fromActions.setPermissionsStepStatus, (state, { status }) => ({
    ...state,
    permissionsStatus: {
      ...state.permissionsStatus,
      step: status
    }
  })),
  on(fromActions.setPermissionsFormStatus, (state, { status }) => ({
    ...state,
    permissionsStatus: {
      ...state.permissionsStatus,
      form: status
    }
  })),
  on(fromActions.getTemplateDetailsSuccess, (state, { template }) => ({
    ...state,
    initialForm: _.cloneDeep(template)
  })),
  on(fromActions.loadSuperAdminsSuccess, (state, { superAdmins }) => ({
    ...state,
    allSuperAdmins: superAdmins
  })),
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
  on(fromActions.imageUploadStarted, (state, {id}) => ({
    ...state,
    uploadingImages: [
      ...state.uploadingImages,
      id
    ]
  })),
  on(fromActions.imageUploadEnded, fromActions.imageUploadAborted, fromActions.imageUploadFailed,  (state, {id}) => ({
    ...state,
    uploadingImages: state.uploadingImages.filter(uId => uId !== id).slice()
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return addReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getInitialForm = (state: State) => state.initialForm;

export const getCreateFromTemplate = (state: State) => state.createFromTemplate;

export const getId = (form: Partial<TemplateForm>) => form.id;
export const getLanguages = (form: Partial<TemplateForm>) => form?.content.map(c => c.language);
export const getPrimaryLanguageTitle = (form: Partial<TemplateForm>) => form?.content.find(c => c.language === form?.language )?.title;
export const getPrimaryLanguage = (form: Partial<TemplateForm>) => form?.language;
export const getPrimaryLanguageImage = (form: Partial<TemplateForm>) => form?.content.find(c => c.language === form?.language )?.image;

export const getGeneralStepStatus = (state: State) => state.generalStatus.step;
export const getGeneralFormStatus = (state: State) => state.generalStatus.form;

export const getContentStepStatus = (state: State) => state.contentStatus.step;
export const getContentFormStatus = (state: State) => state.contentStatus.form;

export const getPermissionsStepStatus = (state: State) => state.permissionsStatus.step;
export const getPermissionsFormStatus = (state: State) => state.permissionsStatus.form;

export const getTouched = (state: State) => state.touched;

export const getAllSuperAdmins = (state: State) => state.allSuperAdmins;
export const getIsUploadInProgress = (state: State) => state.uploadingImages.length > 0;
