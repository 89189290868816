import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface CustomIcon {
  selector: string;
  assetUrl: string;
}

const customIcons: CustomIcon[] = [
  { selector: 'pin-active', assetUrl: '../../../assets/icons/pin-active.svg' },
  { selector: 'pin-inactive', assetUrl: '../../../assets/icons/pin-inactive.svg' },
  { selector: 'monitor', assetUrl: '../../../assets/icons/monitor.svg' },
  { selector: 'phone', assetUrl: '../../../assets/icons/phone.svg' },
  { selector: 'gsite', assetUrl: '../../../assets/icons/google_sites.svg' }
];

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  init() {
    customIcons.forEach((icon: CustomIcon) => {
      this.matIconRegistry.addSvgIcon(icon.selector, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.assetUrl));
    });
  }
}
