import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromTemplates from '@app/news/store/templates';
import * as fromNewsForm from '@app/news/store/form';
import * as fromRouter from '@app/root-store/router';

import { Store, select } from '@ngrx/store';
import { Template } from '@app/types/template';
import { AuthService } from '@app/auth/auth.service';
import { Pagination } from '@app/types/pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { RdsTabsComponent } from '@rds/angular-components';

@Component({
  selector: 'rnb-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChild('tabs') tabs: RdsTabsComponent;
  
  get isSuperAdmin() {
    return this.auth.currentUser.isSuperAdmin
  }

  myTemplates$: Observable<Array<Template>> = this.store$.pipe(select(fromTemplates.selectMyTemplates));
  myTemplatesPagination$: Observable<Pagination> = this.store$.pipe(select(fromTemplates.selectMyTemplatesPagination));
  predefinedTemplates$: Observable<Array<Template>> = this.store$.pipe(select(fromTemplates.selectPredefinedTemplates));
  predefinedTemplatesPagination$: Observable<Pagination> = this.store$.pipe(select(fromTemplates.selectPredefinedPagination));
  sharedTemplates$: Observable<Array<Template>> = this.store$.pipe(select(fromTemplates.selectSharedTemplates));
  sharedTemplatesPagination$: Observable<Pagination> = this.store$.pipe(select(fromTemplates.selectSharedTemplatesPagination));
  readonlyTemplates$: Observable<Array<Template>> = this.store$.pipe(select(fromTemplates.selectReadonlyTemplates));
  readonlyTemplatesPagination$: Observable<Pagination> = this.store$.pipe(select(fromTemplates.selectReadonlyTemplatesPagination));
  sentToMeTemplates$: Observable<Array<Template>> = this.store$.pipe(select(fromTemplates.selectSentToMeTemplates));
  sentToMeTemplatesPagination$: Observable<Pagination> = this.store$.pipe(select(fromTemplates.selectSentToMeTemplatesPagination));
  
  constructor(
    private store$: Store<any>, private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,) {

    }

  ngOnDestroy(): void {
    this.store$.dispatch(fromTemplates.clear());
  }

  ngAfterViewInit(): void {
    if (!!this.route.snapshot.queryParams.activeTab) {
      const activeTab = this.route.snapshot.queryParams.activeTab;
      var tabIndex = 0;
      switch ( activeTab) {
        case 'predefined': {
          tabIndex = 0;
          break;
        }
        case 'mytemplates': {
          tabIndex = 1;
          break;
        }
        case 'readonly':
        case 'shared': {
          tabIndex = 2;
          break;
        }
      }
      this.tabs.selectTab(tabIndex)
      this.router.navigate([], {relativeTo: this.route, queryParams: {activeTab: null}, queryParamsHandling: 'merge'} )
    }
  }

  ngOnInit() {
    this.store$.dispatch(fromTemplates.initTemplates());
  }

  useTemplate(template: Template, listType) {
    this.store$.dispatch(fromTemplates.useTemplate({id: template.id, listType}));
  }

  openPreview(template: Template, listType) {
    this.store$.dispatch(fromTemplates.openTemplatePreview({id: template.id, backButton: {
      label: `Add News`,
      routeBack: `news/create`,
      routeBackQueryParams: {activeTab: listType},
      routeCurrent: `/news/edit/template/${template.id}`
    }}));
  }

  openDeleteTemplateDialog(template: Template, listType) {
    this.store$.dispatch(fromTemplates.openDeleteTemplateDialog({template, listType}));
  }

  openSendTemplateCopyDialog(template: Template) {
    this.store$.dispatch(fromTemplates.openSendTemplateCopyDialog({template}));
  }

  openShareTemplateDialog(template: Template, listType) {
    this.store$.dispatch(fromTemplates.openShareTemplateDialog({template, listType}));
  }

  openRenameTemplateDialog(template: Template, listType) {
    this.store$.dispatch(fromTemplates.openRenameTemplateDialog({template, listType}));
  }

  accept(template: Template) {
    this.store$.dispatch(fromTemplates.acceptTemplateCopyRequest({template}));
  }

  reject(template: Template) {
    this.store$.dispatch(fromTemplates.rejectTemplateCopyRequest({template}));
  }

  loadMore(listType) {
    this.store$.dispatch(fromTemplates.loadMore({listType}));
  }

  loadMoreSentToMe() {
    this.store$.dispatch(fromTemplates.loadMoreSentToMe());
  }

  trackByFn(index, template) {
    return template.title
  }
}
