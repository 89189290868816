// import { createAction, props } from '@ngrx/store';

import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';
import { Pagination } from '@app/types/pagination';
import { News, ChannelDetails, NewsUpdate, NewsDetails } from '@app/types';
import { NewsTableFilters } from './news-table.reducer';
import { BackButton } from '@app/root-store/ui/back-button';

export const setChannelDetails = createAction(
  '[NEWS] set channel details',
  props<{ details: ChannelDetails }>()
);

export const initAllNews = createAction(
  '[NEWS - ALL NEWS TABLE] Initialize News'
);

export const initMyNews = createAction(
  '[NEWS - MY NEWS TABLE] Initialize News'
);

export const initMySuggestedNews = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Initialize News'
);

export const initMyArchivedNews = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Initialize News'
);

export const clearAll = createAction(
  '[NEWS] Clear all'
);

export const getAllNewsRequest = createAction(
  '[NEWS - ALL NEWS TABLE] Get all news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getAllNewsSuccess = createAction(
  '[NEWS - ALL NEWS TABLE] Get all news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getAllNewsFailure = createAction(
  '[NEWS - ALL NEWS TABLE] Get all news Failure',
  props<{ error: any }>()
);

export const deleteAllNewsRequest = createAction(
  '[NEWS - ALL NEWS TABLE] Delete all news Request',
  props<{ids: Array<number>}>()
);

export const deleteAllNewsSuccess = createAction(
  '[NEWS - ALL NEWS TABLE] Delete all news Success',
  props<{count: number}>()
);

export const deleteAllNewsFailure = createAction(
  '[NEWS - ALL NEWS TABLE] Delete all news Failure',
  props<{ error: any }>()
);

export const archiveAllNewsRequest = createAction(
  '[NEWS - ALL NEWS TABLE] Archive all news Request',
  props<{ids: Array<number>}>()
);

export const archiveAllNewsSuccess = createAction(
  '[NEWS - ALL NEWS TABLE] Archive all news Success',
  props<{count: number}>()
);

export const archiveAllNewsFailure = createAction(
  '[NEWS - ALL NEWS TABLE] Archive all news Failure',
  props<{ error: any }>()
);

export const changeAllNewsFilters = createAction(
  '[NEWS - ALL NEWS TABLE] Change all news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeAllNewsSort = createAction(
  '[NEWS - ALL NEWS TABLE] Change all news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeAllNewsPagination = createAction(
  '[NEWS - ALL NEWS TABLE] Change all news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

export const getMyNewsRequest = createAction(
  '[NEWS - MY NEWS TABLE] Get all news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getMyNewsSuccess = createAction(
  '[NEWS - MY NEWS TABLE] Get all news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getMyNewsFailure = createAction(
  '[NEWS - MY NEWS TABLE] Get all news Failure',
  props<{ error: any }>()
);

export const deleteMyNewsRequest = createAction(
  '[NEWS - MY NEWS TABLE] Delete all news Request',
  props<{ids: Array<number>}>()
);

export const deleteMyNewsSuccess = createAction(
  '[NEWS - MY NEWS TABLE] Delete all news Success',
  props<{count: number}>()
);

export const deleteMyNewsFailure = createAction(
  '[NEWS - MY NEWS TABLE] Delete all news Failure',
  props<{ error: any }>()
);

export const archiveMyNewsRequest = createAction(
  '[NEWS - MY NEWS TABLE] Archive all news Request',
  props<{ids: Array<number>}>()
);

export const archiveMyNewsSuccess = createAction(
  '[NEWS - MY NEWS TABLE] Archive all news Success',
  props<{count: number}>()
);

export const archiveMyNewsFailure = createAction(
  '[NEWS - MY NEWS TABLE] Archive all news Failure',
  props<{ error: any }>()
);

export const changeMyNewsFilters = createAction(
  '[NEWS - MY NEWS TABLE] Change all news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeMyNewsSort = createAction(
  '[NEWS - MY NEWS TABLE] Change all news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeMyNewsPagination = createAction(
  '[NEWS - MY NEWS TABLE] Change all news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);


export const getMySuggestedNewsRequest = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Get news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getMySuggestedNewsSuccess = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Get news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getMySuggestedNewsFailure = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Get news Failure',
  props<{ error: any }>()
);

export const changeMySuggestedNewsFilters = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Change news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeMySuggestedNewsSort = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Change news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeMySuggestedNewsPagination = createAction(
  '[NEWS - MY SUGGESTED NEWS TABLE] Change news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);


export const getMyArchivedNewsRequest = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Get news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getMyArchivedNewsSuccess = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Get news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getMyArchivedNewsFailure = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Get news Failure',
  props<{ error: any }>()
);


export const deleteMyArchivedNewsRequest = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Delete news Request',
  props<{ids: Array<number>}>()
);

export const deleteMyArchivedNewsSuccess = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Delete news Success',
  props<{count: number}>()
);

export const deleteMyArchivedNewsFailure = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Delete news Failure',
  props<{ error: any }>()
);

export const changeMyArchivedNewsFilters = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Change news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeMyArchivedNewsSort = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Change news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeMyArchivedNewsPagination = createAction(
  '[NEWS - MY ARCHIVED NEWS TABLE] Change news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

// NEWS ACTIONS

export const openNewsPreview = createAction(
  '[NEWS] Open news preview',
  props<{ newsId: number, backButton: BackButton}>()
);

export const getNewsDetailsRequest = createAction(
  '[NEWS] get news details request',
  props<{newsId: number, isPinned: boolean}>()
);

export const getNewsDetailsSuccess = createAction(
  '[NEWS] get news details success',
  props<{news: News}>()
);

export const getNewsDetailsFailure = createAction(
  '[NEWS] get news details failure'
);

export const duplicateNewsRequest = createAction(
  '[NEWS] Duplicate news request',
  props<{newsId: number}>()
);

export const duplicateNewsSuccess = createAction(
  '[NEWS] Duplicate news success',
  props<{id: number}>()
);

export const duplicateNewsFailure = createAction(
  '[NEWS] Duplicate news failure',
  props<{ error: any }>()
);

export const removeSuggestionRequest = createAction(
  '[NEWS] Remove suggestion request',
  props<{newsIds: Array<number>, channelId: number, channelName: string}>()
);

export const removeSuggestionSuccess = createAction(
  '[NEWS] Remove suggestion success',
  props<{channelName: string}>()
);

export const removeSuggestionFailure = createAction(
  '[NEWS] Remove suggestion failure',
  props<{ error: any }>()
);

export const openAssignementsDialog = createAction(
  '[NEWS] Open assignements dialog',
  props<{newsId: number, isDraft: boolean}>()
);

export const updateNewsAssignmentsRequest = createAction(
  '[NEWS] Update news assignments request',
  props<{newsId: number, newsUpdate: NewsUpdate, hasAssignChanged: boolean, hasSuggestChanged: boolean}>()
);

export const updateNewsAssignmentsSuccess = createAction(
  '[NEWS] Update news assignments success',
  props<{hasAssignChanged: boolean, hasSuggestChanged: boolean}>()
);

export const updateNewsAssignmentsFailure = createAction(
  '[NEWS] Update news assignments failure',
  props<{ error: any }>()
);

export const moveAsDraftRequest = createAction(
  '[NEWS] Move as draft request',
  props<{ids: Array<number>}>()
);

export const moveAsDraftSuccess = createAction(
  '[NEWS] Move as draft success',
);

export const moveAsDraftFailure = createAction(
  '[NEWS] Move as draft failure',
  props<{ error: any }>()
);

export const openUnpublishConfirmDialog = createAction(
  '[NEWS] Open Unpublis confirm modal',
  props<{ title: string, newsId: number}>()
)

export const unpublishNewsRequest = createAction(
  '[NEWS] Unpublish request',
  props<{newsId: number}>()
);

export const unpublishNewsSuccess = createAction(
  '[NEWS] Unpublish success',
);

export const unpublishNewsFailure = createAction(
  '[NEWS] Unpublish failure',
  props<{ error: any }>()
);

export const removeDuplicatedId = createAction(
  '[NEWS] Remove duplicated ID',
  props<{id: number}>()
);

export const saveNewsAsTemplateRequest = createAction(
  '[NEWS] Save news as Template request',
  props<{newsId: number, imagePreview: string}>()
);

export const saveNewsAsTemplateSuccess = createAction(
  '[NEWS] Save news as Template success',
);

export const saveNewsAsTemplateFailure = createAction(
  '[NEWS] Save news as Template failure',
  props<{ error: any }>()
);

export const openSaveAsTemplateDialog = createAction(
  '[NEWS] Open Save template dialog',
  props<{newsId: number}>()

);