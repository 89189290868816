<ng-container [formGroup]="form">
  <div class="row">
    <div class="col mt-9">
      <rds-accordion #accordion="rdsAccordion" (click)="focusAccordeon()">
        <rds-expansion-panel #panel *ngFor="let content of getArrayControlsInOrder; let i = index" [class.disable-expand]="panel.expanded" (expandedChange)="change(accordion, panel)" [formGroup]="content" [expanded]="i === 0" togglePosition="after" (closed)="content.markAsTouched()" >
          <rds-expansion-panel-header>
            <rds-panel-title> 
              <div class="language-version">
                <h4 class="ui-heading-4 c-600-roche-blue">{{content.get('language').value | languageName | async}} language version {{i === 0? '(primary)' : ''}}</h4>
                <button [disabled]="(primaryLanguage$ | async) === content.get('language').value" rds-icon-button size="m" (click)="deleteLanguageClick($event, content.get('language').value)">
                  <rds-icon namespace="outlined" icon="delete"></rds-icon>
                </button>
                <atr-form-status-badge 
                [status]="panel.expanded && languageFocused ? 'IN_PROGRESS' : 
                          !content.touched ? 'NOT_DEFINED' :
                          content.valid ? 'COMPLETED' : 'INCOMPLETE'"
                  ></atr-form-status-badge>
              </div>
            </rds-panel-title>
          </rds-expansion-panel-header>
          <div class="row">
            <div class="col mt-9 ps-10">
              <ng-container *ngIf="(newsType$ | async) === 'Link'">
                <h4 class="ui-heading-4">Link to the news</h4>
                <div class="row">
                  <div class="col mt-5 mb-7">
                    <rds-form-field>
                      <rds-control-label>Link</rds-control-label>
                      <input formControlName="link" rdsInput required/>
                      <rds-control-error *ngIf="content.get('link').hasError('required')">This field is required</rds-control-error>
                      <rds-control-error *ngIf="content.get('link').hasError('url')">The link needs to include the "http://" or "https://" protocol</rds-control-error>
                    </rds-form-field>
                  </div>
                </div>
              </ng-container>
              <h4 class="ui-heading-4 mb-5">Abstract & thumbnail</h4>
              <div class="row">
                <div class="col-8 mt-3">
                  <div class="row">
                    <div class="col">
                      <rds-form-field>
                        <rds-control-label>Title of the News</rds-control-label>
                        <input rdsInput formControlName="title" required/>
                        <rds-control-hint class="right">{{content.get('title').value?.length || 0}}/100</rds-control-hint>
                        <rds-control-error *ngIf="content.get('title').hasError('required') && content.get('title').touched">This field is required</rds-control-error>
                        <rds-control-error *ngIf="content.get('title').hasError('maxlength') && content.get('title').touched">Maximum number of characters exceeded</rds-control-error>
                        <rds-control-error *ngIf="(content.get('title').hasError('required') || content.get('title').hasError('maxlength')) && content.get('title').touched" class="right">{{content.get('title').value?.length || 0}}/100</rds-control-error>
                      </rds-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col mt-5">
                      <rds-form-field>
                        <rds-control-label>Abstract</rds-control-label>
                        <textarea rdsInput formControlName="abstract" required></textarea>
                        <rds-control-hint class="right">{{content.get('abstract').value?.length || 0}}/200</rds-control-hint>
                        <rds-control-error *ngIf="content.get('abstract').hasError('required') && content.get('abstract').touched">This field is required</rds-control-error>
                        <rds-control-error *ngIf="content.get('abstract').hasError('maxlength') && content.get('abstract').touched">Maximum number of characters exceeded</rds-control-error>
                        <rds-control-error *ngIf="(content.get('abstract').hasError('required') || content.get('abstract').hasError('maxlength')) && content.get('abstract').touched" class="right">{{content.get('abstract').value?.length || 0}}/200</rds-control-error>
                      </rds-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p class="ui-label-m-bold">Thumbnail</p>
                      <rds-switch *ngIf="(primaryLanguage$ | async) !== content.get('language').value" formControlName="syncImage" (toggleChange)="syncImageChanged(content)">Use the thumbnail from the primary language</rds-switch>
                      <ng-container *ngIf="(primaryLanguage$ | async) === content.get('language').value || content.get('syncImage').value === false">
                        <p class="ui-label-m">Use media from enabled libraries like: branding.roche.com</p>
                        <rnb-upload [hasDropZone]="true" size="l" formControlName="image">
                        </rnb-upload>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-4 mt=5">
                  <p class="ui-label-l-bold mb-3">News thumbnail preview</p>
                  <div class="card">
                    <rnb-news-card-item
                    id="new"
                    [title]="content.get('title').value || 'Title'"
                    [description]="content.get('abstract').value || 'Description'"
                    [imageUrl]="content.get('image').value?.url"
                    [creationDate]="now"
                    [likeEnabled]="true"
                    [likeCount]="0"
                    [hasBookmarked]="false"
                    [enableBookmark]="true"
                    [hasLiked]="false"
                    [url]="null"
                    [type]="'Content'"
                    [isLikingProcessingIds]="false"
                    [color]="'neutral'">
                  </rnb-news-card-item>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="(newsType$ | async) === 'Embedded'">
                <rds-divider class="mt-5"></rds-divider>
                <div class="row">
                  <div class="col mt-9">
                    <h4 class="ui-heading-4">Add News body in editor</h4>
                    <p class="ui-label-m ">Please, do not create the news Title in this Editor. Only add the body of your article. For accessibility purposes please style the News using available tools as paragraph, headings, list structure and other enabled below. </p>
                    <div class="row">
                      <div class="col mt-5">
                        <rnb-editor formControlName="html">
                          <rds-control-error *ngIf="content.get('html').hasError('required')">This field is required</rds-control-error>
                        </rnb-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </rds-expansion-panel>
      </rds-accordion>
    </div>
  </div>
    <ng-container *ngIf="(newsType$ | async) === 'Embedded'">
      <div class="row" (click)="unfocusAccordeon()" *ngIf="form.get('websiteLink') as websiteLinkForm" [formGroup]="form.get('websiteLink')">
        <div class="col mt-9">
          <h4 class="ui-heading-4">Website link <span class="ui-label-l">(optional)</span></h4>
          <p class="ui-label-m ">Add link to the page you want to highlight it will be placed at the bottom of the content of your News.</p>
          <div class="row">
            <div class="col mt-5">
              <rds-form-field>
                <rds-control-label>Link Name</rds-control-label>
                <input  rdsInput formControlName="name"/>
                <rds-control-hint class="right">{{websiteLinkForm.get('name').value?.length || 0}}/150</rds-control-hint>
                <rds-control-error *ngIf="websiteLinkForm.get('name').hasError('required') && websiteLinkForm.get('name').touched">This field is required</rds-control-error>
                <rds-control-error *ngIf="websiteLinkForm.get('name').hasError('maxlength') && websiteLinkForm.get('name').touched">Maximum number of characters exceeded</rds-control-error>
                <rds-control-error *ngIf="(websiteLinkForm.get('name').hasError('required') || websiteLinkForm.get('name').hasError('maxlength')) && websiteLinkForm.get('name').touched" class="right">{{websiteLinkForm.get('name').value?.length || 0}}/150</rds-control-error>
              </rds-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col mt-5">
              <rds-form-field>
                <rds-control-label>Link URL</rds-control-label>
                <input  rdsInput formControlName="url"/>
                <rds-control-error *ngIf="websiteLinkForm.get('url').hasError('required') && websiteLinkForm.get('url').touched">This field is required</rds-control-error>
                <rds-control-error *ngIf="websiteLinkForm.get('url').hasError('url') && websiteLinkForm.get('url').touched">The link needs to include the "http://" or "https://" protocol</rds-control-error>
              </rds-form-field>
            </div>
          </div>
        </div>
      </div>
      <rds-divider class="mt-5"></rds-divider>
    </ng-container>
  
    <div class="row" (click)="unfocusAccordeon()">
      <div class="col mt-9">
        <h4 class="ui-heading-4">Topics</h4>
        <p class="ui-label-m ">Topics help reader identify what an article is about. Please consider using topics from the existing library. </p>
        <div class="row">
          <div class="col mt-5">
            <rnb-topic-picker 
            (search)="getAutocompletes('topics', $event)"
            [multi]="true"
            formControlName="topics"
            [required]="true"
            [autocomplete]="topicsAutocomplete$ | async">
            <rds-control-hint class="right">{{form.get('topics').value.length}}/5</rds-control-hint>
            <rds-control-error *ngIf="form.get('topics').hasError('notEmptyList') && form.get('topics').touched">At least 1 topic is required</rds-control-error>
            <rds-control-error *ngIf="form.get('topics').hasError('maxLengthList') && form.get('topics').touched">Allowed number of topics exceeded</rds-control-error>
            <rds-control-error *ngIf="(form.get('topics').hasError('notEmptyList') || form.get('topics').hasError('maxLengthList')) && form.get('topics').touched" class="right">{{form.get('topics').value.length}}/5</rds-control-error>
          </rnb-topic-picker>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(newsType$ | async) === 'Embedded'">
      <rds-divider class="mt-5"></rds-divider>
      <div class="row" (click)="unfocusAccordeon()">
        <div class="col mt-9">
          <h4 class="ui-heading-4">Related news <span class="ui-label-l">(optional)</span></h4>
          <div class="row">
            <rds-alert> If not defined, system will automatically assign Related News based on selected topics </rds-alert>
          </div>
          <div class="row">
            <div class="col mt-5">
              <rnb-related-news formControlName="related">
                <rds-control-error *ngIf="form.get('related').hasError('minLengthList')">At least 2 news is required</rds-control-error>
              </rnb-related-news>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
</ng-container>
