import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';

import * as fromActions from './channel-table.actions';
import * as fromSelectors from './channel-table.selectors';
import * as fromReducer from './channel-table.reducer';
import { ChannelService } from '@app/channel/channel.service';


@Injectable()
export class TableEffects {

  public initMyChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initMyChannels),
      switchMap(() => this.store$.pipe(
          select(fromSelectors.selectMyChannelsRequestData),
          debounceTime(300),
          distinctUntilChanged((prev, next) => {
            if (prev.resetIndex && !next.resetIndex) {
              delete prev.resetIndex
              delete next.resetIndex
            }
            return JSON.stringify(prev) === JSON.stringify(next)
          }),
          map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
            filters, 
            sort,
            pageIndex: resetIndex ? 0 : pageIndex,
            pageSize
          }))
        )
      ),
      map((requestData) => fromActions.getMyChannelsRequest(requestData))
    ), { dispatch: true}
  );

  public getMyChannelsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMyChannelsRequest),
      switchMap((requestData) => this.channelService.getChannels(requestData).pipe(
        map(res => ({
          data: res.data, pagination: res.pagination
        }))
      )),
      map(({data, pagination}) => fromActions.getMyChannelsSuccess({data, pagination})),
      catchError(({ message }) => of(fromActions.getMyChannelsFailure({ error: message })))

    ), { dispatch: true}
  );

  public deleteMyChannelsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteMyChannelsRequest),
      switchMap(({ids}) => this.channelService.delete(ids).pipe(
        map(() => ({ids}))
      )),
      map(({ids}) => fromActions.deleteMyChannelsSuccess({count: ids.length}))
    ), { dispatch: true}
  );

  public initAllChannels$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.initAllChannels),
    switchMap(() => this.store$.pipe(
        select(fromSelectors.selectAllChannelsRequestData),
        debounceTime(300),
        distinctUntilChanged((prev, next) => {
          if (prev.resetIndex && !next.resetIndex) {
            delete prev.resetIndex
            delete next.resetIndex
          }
          return JSON.stringify(prev) === JSON.stringify(next)
        }),
        map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
          filters, 
          sort,
          pageIndex: resetIndex ? 0 : pageIndex,
          pageSize
        }))
      )
    ),
    map((requestData) => fromActions.getAllChannelsRequest(requestData))
  ), { dispatch: true}
);

public getAllChannelsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.getAllChannelsRequest),
    switchMap((requestData) => this.channelService.getChannels(requestData, true).pipe(
      map(res => ({
        data: res.data, pagination: res.pagination
      }))
    )),
    map(({data, pagination}) => fromActions.getAllChannelsSuccess({data, pagination})),
    catchError(({ message }) => of(fromActions.getAllChannelsFailure({ error: message })))
  ), { dispatch: true}
);

public deleteAllChannelsRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.deleteAllChannelsRequest),
    switchMap(({ids}) => this.channelService.delete(ids).pipe(
      map(() => ({ids}))
    )),
    map(({ids}) => fromActions.deleteAllChannelsSuccess({count: ids.length}))
  ), { dispatch: true}
);

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private channelService: ChannelService
  ) {}
}
