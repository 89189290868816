<ng-container *ngIf="data.news as news">
<h2 rds-dialog-header>Rejection reason</h2>
<div rds-dialog-content>
    <p class="mt-5 mb-9 ui-body-m">
        {{news.comment}}
    </p>
    <div class="rejection-details">
        <rds-avatar>
            <img *ngIf="!!news.rejectedBy.avatar" rds-avatar-image [src]="news.rejectedBy.avatar" referrerpolicy="no-referrer"/>
            <span *ngIf="!news.rejectedBy.avatar" rds-avatar-initials>{{news.rejectedBy.name | initials}}</span>
        </rds-avatar>
        <div class="ui-label-s">
            {{news.rejectedBy.name}} | {{news.channel.name}} | {{news.responseDate | date: 'shortDate'}}
        </div>
    </div>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-primary-button
        [rds-dialog-close]="null"
        size="m"
    >Back to My Suggested News</button>

</div>
</ng-container>