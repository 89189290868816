<rds-menu #menu="rdsMenu"
class="app-menu"
xPosition="before"
yPosition="below">

  <a rds-menu-item size="s" class="p-3 pe-4"  [href]="rocheHomeLink + '/bookmarks'"  target="_blank">
    <span class="text-low-contrast">
      <rds-icon class="action" namespace="outlined" icon="bookmark"></rds-icon>
      My Bookmarks    
    </span>
  </a>
  <a rds-menu-item size="s" class="p-3 pe-4"  [href]="rocheHomeLink + '/settings'"  target="_blank">
    <span class="text-low-contrast">
      <rds-icon class="action" namespace="outlined" icon="settings"></rds-icon>
      My Settings    
    </span>
  </a>
  <a rds-menu-item size="s" class="p-3 pe-4"  [href]="rocheHomeLink + '/workspace'"  target="_blank" >
    <span class="text-low-contrast">
      <rds-icon class="action" namespace="outlined" icon="edit"></rds-icon>
      My Workspace    
 </span>
  </a>
  <a rds-menu-item size="s" class="p-3 pe-4" [href]="newsBoardLink" [disabled]="true" target="_blank">
    <span class="text-low-contrast">
      <rds-icon class="action" namespace="outlined" icon="message"></rds-icon>
      Newsboard      
    </span>
  </a>
  <button rds-menu-item size="s" class="p-3 pe-4" [rdsMenuTriggerFor]="newsMenu">
    <span class="text-low-contrast">
      <rds-icon class="action" namespace="outlined" icon="write"></rds-icon>
      Add News    
    </span>
  </button>
</rds-menu>
<rds-menu #newsMenu="rdsMenu" class="app-menu">
  <a rds-menu-item size="s" class="p-3 pe-4" [routerLink]="'/news/create/link'">
    <span class="text-low-contrast">
    <rds-icon class="action" namespace="outlined" icon="link"></rds-icon>
    Add via Link      
    </span>
  </a>
  <a rds-menu-item size="s" class="p-3 pe-4" [routerLink]="'/news/create/content'">
      <span class="text-low-contrast">
      <rds-icon class="action" namespace="outlined" icon="note"></rds-icon>
      Create in Editor      
      </span>
  </a>
</rds-menu>