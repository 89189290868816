import Command from '@ckeditor/ckeditor5-core/src/command';
import {createText} from "./text-box-command";

export default class TextBoxChangeTypeCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options.value;
            const selection = model.document.selection;
            const selectedModelName = selection.getSelectedElement()?.name;
            if (selectedModelName === 'textBox') {
                const selectedElement = selection.getSelectedElement()
                const oldValue = selectedElement.getAttribute('custom-type');

                if (value === 'double' && oldValue !== 'double') {
                    const text = createText(writer);
                    writer.append(text, selectedElement);
                }

                if (oldValue === 'double' && value !== 'double') {
                    const children = Array.from(selectedElement.getChildren());
                    const child1 = children[0];
                    const position = writer.createPositionAt(child1, 'after')
                    writer.merge(position);
                }
                if (value !== 'block') {
                    writer.setAttribute('custom-background-class', '', selectedElement)
                } else {
                    writer.setAttribute('custom-background-class', 'bg-neutral-light-colour', selectedElement);
                }

                writer.setAttribute('custom-type', value, selectedElement)
                writer.setSelection(selectedElement, 'on');
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'textBox');
        const element = selection.getSelectedElement();
        const selectedModelName = element?.name;
        this.isEnabled = allowedIn !== null;
        if (selectedModelName === 'textBox') {
            if (this.isEnabled && element.hasAttribute('custom-type')) {
                this.value = element.getAttribute('custom-type');
            } else {
                this.value = false;
            }
        }
    }
}
