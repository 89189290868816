import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, tap} from 'rxjs/operators';
import * as fromActions from './toast.actions';

import * as fromApiKeys from '@app/admin/store/api-keys';

import * as fromChannels from '@app/channel/store/channel-table'; 
import * as fromChannelDetails from '@app/channel/store/channel-details'; 
import * as fromNewsTable from '@app/news/store/news-table'; 
import * as fromNewsForm from '@app/news/store/form'; 
import * as fromChannelForm from '@app/channel/store/form'; 
import * as fromTemplates from '@app/news/store/templates'; 
import * as fromTemplateForm from '@app/news/store/template-form'; 

import {Store} from '@ngrx/store';
import { RdsToastService } from '@rds/angular-components';

@Injectable()
export class ToastEffects {

  public showToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.showToast),
      tap(({message, title, options}) => [
        this.toastService.show(message, title, options)
      ]),
    ), {dispatch: false}
  );

  public apiKeyCopied$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromApiKeys.apiKeyCopiedToClipboard),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `API-Key copied to clipboard`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public deleteChannelsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromChannels.deleteMyChannelsSuccess, fromChannels.deleteAllChannelsSuccess, fromChannelForm.deleteChannelSuccess),
    map(({count}) => fromActions.showToast({
      title: 'Success',
      message: `Channel${count > 1? 's were' :' was'}  deleted`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public pinNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromChannelDetails.pinNewsSuccess),
    map(({isPinned}) => fromActions.showToast({
      title: 'Success',
      message: `News is now ${isPinned? 'pinned to' :'unpinned from'} the top of the channel`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public rejectNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromChannelDetails.rejectNewsSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `The News has beed rejected`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public acceptNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromChannelDetails.acceptNewsSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `Suggestion accepted. News published to your channel`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public duplicateNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromChannelDetails.duplicateNewsSuccess, fromNewsTable.duplicateNewsSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `News duplicated and saved as draft in My News view`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public removeNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromChannelDetails.removeAllNewsSuccess, fromChannelDetails.removeArchivedNewsSuccess),
    map(({count}) => fromActions.showToast({
      title: 'Success',
      message: `The news was removed from channel`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public createChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelForm.createChannelSuccess),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `Channel created`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));

    public updateChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelForm.updateChannelSuccess),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `Changes saved`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));

    public newsAssignedAndSuggested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.updateNewsAssignmentsSuccess),
      filter(({hasAssignChanged, hasSuggestChanged}) => hasAssignChanged && hasSuggestChanged),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `News assignment and suggestion updated`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));
  
    public newsAssigned$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.updateNewsAssignmentsSuccess),
      filter(({hasAssignChanged, hasSuggestChanged}) => hasAssignChanged && !hasSuggestChanged),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `Assignment saved to channel`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));
  
    public newsSuggested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.updateNewsAssignmentsSuccess),
      filter(({hasAssignChanged, hasSuggestChanged}) => !hasAssignChanged && hasSuggestChanged),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `News suggestion updated`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));


  // MISSING https://www.figma.com/file/7GPVGe7UBKJBm4NuhEw2B0/NewsBoard-2.0?type=design&node-id=9314-89706&mode=design&t=4A8rCLFiMvCLFCY1-4
  // MISSING https://www.figma.com/file/7GPVGe7UBKJBm4NuhEw2B0/NewsBoard-2.0?type=design&node-id=9139-54788&mode=design&t=4A8rCLFiMvCLFCY1-4
  // MISSING https://www.figma.com/file/7GPVGe7UBKJBm4NuhEw2B0/NewsBoard-2.0?type=design&node-id=9314-89682&mode=design&t=4A8rCLFiMvCLFCY1-4


  public removeSuggestionSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromNewsTable.removeSuggestionSuccess),
    map(({channelName}) => fromActions.showToast({
      title: 'Success',
      message: `Suggestion removed from Channel ${channelName}`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public deleteNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromNewsTable.deleteMyNewsSuccess, fromNewsTable.deleteAllNewsSuccess, fromNewsTable.deleteMyArchivedNewsSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `News were deleted`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public archiveNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromNewsTable.archiveMyNewsSuccess, fromNewsTable.archiveAllNewsSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `News were archived`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public deleteTemplateSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplates.deleteTemplateSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `Template deleted`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public saveTemplateSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplateForm.saveTemplateSuccess, fromTemplateForm.updateTemplateSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `Template saved`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public sendTemplateCopySuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplates.sendTemplateCopySuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `A copy of the template has been sent.`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public templateShared$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplates.updateTemplateSuccess),
    filter(({updateContext}) => updateContext === 'shared'),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `Template shared`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public templatePermissionsUpdated$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplates.updateTemplateSuccess),
    filter(({updateContext}) => updateContext === '' || updateContext === 'permissionsUpdated'),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `User permissions has been updated`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public templateSharedAndPermissionsUpdated$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplates.updateTemplateSuccess),
    filter(({updateContext}) => updateContext === 'sharedAndPermissionsUpdated'),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `Template has been shared and User permissions has been updated`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));

  public templateRenamed$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromTemplates.updateTemplateSuccess),
    filter(({updateContext}) => updateContext === 'renamed'),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `Template renamed`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));


  public unpublishNewsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromNewsTable.unpublishNewsSuccess),
    map(() => fromActions.showToast({
      title: 'Success',
      message: `News unpublished. It's now in draft status.`,
      options: {
        type: 'success',
        position: 'bottom-left',
      }
    }))
  ));


    public saveAsDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsForm.saveAsDraftSuccess, fromNewsForm.updateAsDraftSuccess),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `News saved as draft`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));

    public saveAsPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsForm.saveAsPublishSuccess, fromNewsForm.updateAsPublishSuccess),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `News published`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));
    
    public saveAsTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsForm.saveAsTemplateSuccess),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `Template saved`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));

    public saveNewsAsTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.saveNewsAsTemplateSuccess),
      map(() => fromActions.showToast({
        title: 'Success',
        message: `News saved as a template`,
        options: {
          type: 'success',
          position: 'bottom-left',
        }
      }))
    ));



  constructor(
    private actions$: Actions,
    private toastService: RdsToastService,
    private store$: Store<any>
  ) {
  }
}
