import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponents2 } from './components/header/header.component';
import { HeaderGcsMenuContainer } from './components/header-gcs-menu/header-gcs-menu.container';
import { HeaderAppMenuContainer } from './components/header-app-menu/header-app-menu.container';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HeaderEffects } from './store/header';
import { feature, reducer } from './store/header.reducer';
import { OverlayModule } from '@angular/cdk/overlay';
import { RdsModule } from '../rds.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderComponents2,
    HeaderGcsMenuContainer,
    HeaderAppMenuContainer
  ],
  imports: [
    OverlayModule,
    RouterModule,
    CommonModule,
    RdsModule,
    StoreModule.forFeature(feature, reducer),
    EffectsModule.forFeature([HeaderEffects]),
  ],
  exports: [
    HeaderComponents2
  ]
})
export class HeaderModule { }
