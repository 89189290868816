import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { mergeMap, map, catchError, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from './timezones.actions';
import * as fromReducer from './timezones.reducer';
import * as fromSlectors from './timezones.selectors';
import { TimezoneService } from '@app/utils/timezone.service';

@Injectable()
export class TimezonesEffects {

  public loadTimezones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTimezones),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getTimezonesRequest()
      ])
    ), { dispatch: true }
  );

  public getTimezonesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTimezonesRequest),
      mergeMap(() =>
        this.timezoneSerivce.getTimezones().pipe(
          map(({timezones}) => fromActions.getTimezonesSuccess({ timezones })),
          catchError(({ message }) => of(fromActions.getTimezonesFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getTimezonesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTimezonesSuccess),
      mergeMap(({ timezones }) => [
        fromActions.timezonesCollectionAddMany({ timezones }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private timezoneSerivce: TimezoneService
  ) {}
}
