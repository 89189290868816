import Command from '@ckeditor/ckeditor5-core/src/command';
import {createImage, getElementWidth} from "./images-box-command";

export default class ImagesBoxChangeCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options.value;
            const selection = model.document.selection;
            const selectedModelName = selection.getSelectedElement()?.name;
            if (selectedModelName === 'imagesBox') {
                const selectedElement = selection.getSelectedElement()
                const children = Array.from(selectedElement.getChildren());
                for (let i = 0; i < children.length; i++) {
                    writer.setAttribute('width', getElementWidth(value), children[i]);
                }


                if (value < children.length) {
                    for (let i = 0; i < children.length; i++) {
                        if (i >= value) {
                            writer.remove(children[i]);
                        }
                    }
                }
                if (value > children.length) {
                    for (let i = 0; i < value - children.length; i++) {
                        const image = createImage(writer);
                        writer.append(image, selectedElement);
                        writer.setAttribute('float', null, image)
                        writer.setAttribute('src', './assets/images/block-placeholder.png', image);
                        writer.setAttribute('width', getElementWidth(value), image);
                    }
                }
                writer.setSelection(selectedElement, 'on');
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'imagesBox');
        const selectedElement = selection.getSelectedElement();
        const selectedModelName = selectedElement?.name;
        this.isEnabled = allowedIn !== null;
        if (selectedModelName === 'imagesBox') {
            if (this.isEnabled && selectedElement.childCount) {
                this.value = selectedElement.childCount;
            } else {
                this.value = false;
            }
        }
    }
}

