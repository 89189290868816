import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import ImagesBoxCommand from './images-box-command';
import ImagesBoxChangeCommand from "./images-box-change-command";
export default class ImagesBoxEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        this._defineSchema();
        this._defineConverters();


        this.editor.commands.add('insertImagesBox', new ImagesBoxCommand(this.editor));
        this.editor.commands.add('imagesChangeBox', new ImagesBoxChangeCommand(this.editor));

        // this.listenTo(this.editor.editing.view.document, 'delete', (evt, data) => {
        //     const element = data.document.selection.getSelectedElement();
        //     if (element?.name === 'section' && element.hasClass('images-box') ) {
        //
        //         evt.stop();
        //     }
        // });
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('imagesBox', {
            inheritAllFrom: '$blockObject',
            allowWhere: '$block',
            allowContentOf: 'customImageBox',
            allowedChildren: ['customImageBox']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast').elementToElement({
            model: (viewElement, {writer}) => {
                return writer.createElement('imageBox');
            },
            view: {
                name: 'section',
                classes: ['image-box']
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imageBox',
            view: {
                name: 'section',
                classes: ['image-box', 'mb-6']
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'imageBox',
            view: (modelElement, {writer: viewWriter}) => {
                const section = viewWriter.createContainerElement('section', {class: 'py-6 image-box'});
                return toWidget(section, viewWriter, {label: 'simple box widget'});
            }
        });

        conversion.for('upcast').elementToElement({
            model: (viewElement, {writer}) => {
                return writer.createElement('imagesBox');
            },
            view: {
                name: 'section',
                classes: ['images-box']
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'imagesBox',
            view: {
                name: 'section',
                classes: ['images-box']
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'imagesBox',
            view: (modelElement, {writer: viewWriter}) => {
                const section = viewWriter.createContainerElement('section', {class: 'py-6 images-box'});
                return toWidget(section, viewWriter, {label: 'simple box widget'});
            }
        });

        
    }
}
export function isHtmlIncluded( dataTransfer ) {
	return Array.from( dataTransfer.types ).includes( 'text/html' ) && dataTransfer.getData( 'text/html' ) !== '';
}
