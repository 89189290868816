import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rnb-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss' ]
})
export class TileComponent {
  @Input() size: 'small' | 'big';
  @Input() tile;

  @Output() dispatchAction: EventEmitter<any> = new EventEmitter();
}
