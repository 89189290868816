import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone } from '@angular/core';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';

export interface StreamingMediaDialogData {
  url: string
}
@Component({
  selector: 'rnb-streaming-media-dialog',
  templateUrl: './streaming-media-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamingMediaDialogComponent {

  constructor(
    private dialogRef: RdsDialogRef<StreamingMediaDialogComponent>,
    private ngZone: NgZone,
    @Inject(RDS_DIALOG_DATA) public data: StreamingMediaDialogData) { }
  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

  confirm(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }
}
