import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PageEvent, RdsDialogService, RdsPaginatorComponent, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import { distinctUntilChanged, filter, Observable, tap } from 'rxjs';
import { SubSink } from 'subsink';
import * as fromDetails from '../../../../store/channel-details';
import * as fromRouter from '@app/root-store/router';
// import * as fromForm from '../../store/form';

import { Channel, ChannelDetails, News } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { AuthService, SimpleUser } from '@app/auth/auth.service';
import { TableColumn } from '@app/utils/table/table.component';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { NewsTableFilters } from '@app/news/store/news-table';
import { getRemoveNewsFromChannelDialog } from '@app/utils/confirm2-dialog/confirm-dialog.models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rnb-all-news-table',
  templateUrl: './all-news-table.container.html',
  styleUrls: ['./all-news-table.container.scss']
})
export class AllNewsTableContainer implements OnInit, OnDestroy {
  subs: SubSink = new SubSink();

  get identifier(): string {
    return this.auth.currentUser.identifier;
  }
  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  roleInChannel: string;

  get showPin() {
    return this.isAdmin || this.isSuperAdmin || this.roleInChannel === 'Owner' || this.roleInChannel === 'Manager';
  }

  get columns(): Array<string> {
    const pin = this.showPin ? ['pin'] : []
    return [...pin, 'title', 'owners', 'assignedBy', 'type', 'published', 'approved', 'status']
  }

  roleInChannel$: Observable<string> = this.store$.pipe(select(fromDetails.selectRoleInChannel));
  channelDetails$: Observable<ChannelDetails> = this.store$.pipe(select(fromDetails.selectChannelDetails));

  news: Array<Partial<News>> = []; 
  news$: Observable<Array<Partial<News>>> = this.store$.pipe(select(fromDetails.selectAllNewsData));
  
  pagination$: Observable<Pagination> = this.store$.pipe(select(fromDetails.selectAllNewsPagination));
  total$: Observable<number> = this.store$.pipe(select(fromDetails.selectAllNewsTotal));

  isLoading$: Observable<boolean> = this.store$.pipe(select(fromDetails.selectAllNewsIsLoading));

  defaultSort$: Observable<{ active: string, direction: SortDirection}> = this.store$.pipe(select(fromDetails.selectAllNewsDefaultSort));

  filters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromDetails.selectAllNewsFilters));
  defaultFilters$: Observable<NewsTableFilters> = this.store$.pipe(select(fromDetails.selectAllNewsDefaultFilters));
  search$: Observable<string> = this.store$.pipe(select(fromDetails.selectAllNewsSearch));

  constructor(
    private store$: Store<fromDetails.State>,
    private dialogService: RdsDialogService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) { 
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.sink = this.roleInChannel$.subscribe(role => this.roleInChannel = role)
  }

  canEditNews(news: News) {
    return this.isAdmin || this.isSuperAdmin || !!news.owners.find(u => u.identifier === this.identifier) || !!news.editors.find(u => u.identifier === this.identifier)
  }

  filtersChanged(filters: NewsTableFilters) {
    this.store$.dispatch(fromDetails.changeAllNewsFilters({filters}));
  }

  sortChanged(event: Sort) {
      this.store$.dispatch(fromDetails.changeAllNewsSort({active: event.active, direction: event.direction}));
  }

  paginationChanged(event: PageEvent) {
    this.store$.dispatch(fromDetails.changeAllNewsPagination({pageIndex: event.pageIndex, pageSize: event.pageSize}));
  }

  selectionChanged(event: SelectionChange<News>) {
  }
  
  removeNews(selection: SelectionModel<News>) {
    const ids = selection.selected.map(n => n.id);
    const data: ConfirmDialogData = getRemoveNewsFromChannelDialog(ids);
    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });

    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromDetails.removeAllNewsRequest({ids: data.ids})));
  }

  removeSingleNews(id: string, newsTitle) {
    const ids = [+id];
    const data: ConfirmDialogData = getRemoveNewsFromChannelDialog(ids, newsTitle);

    const dialog = this.dialogService.open(Confirm2DialogComponent, {
      data
    });
    
    dialog.afterClosed().pipe(
      filter(data => !!data)
    ).subscribe(data => this.store$.dispatch(fromDetails.removeAllNewsRequest({ids: data.ids})));
  }

  onLinkClick({link, linkType}: {link: string, linkType: 'route' | 'url'}, channelName, channelId) {
    this.openNewsPreview(+link, channelName, channelId);
  }

  openNewsPreview(newsId: number, channelName, channelId) {
    this.store$.dispatch(fromDetails.openNewsPreview({newsId, backButton: {
      label: `Channel: ${channelName}`,
      routeBack: `channel/${channelId}/all`,
      routeCurrent: `/news/${newsId}/edit`
    }}))
  }

  onPinClick({id, isPinned}) {
    this.store$.dispatch(fromDetails.pinNewsRequest({newsId: id, isPinned }));
  }

  duplicate(id) {
    this.store$.dispatch(fromDetails.duplicateNewsRequest({newsId: id }));
  }
}
