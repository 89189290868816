import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromBackButton from '@app/root-store/ui/back-button';

@Component({
  selector: 'rnb-add-news-button',
  templateUrl: './add-news-button.component.html',
  styles: []
})
export class AddNewsButtonComponent implements OnInit {
  @Input() breadcrumbRoutes: Array<{label: string, route: string}> = [];
  constructor(
    private store$: Store<any>
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(fromBackButton.setAddNewsMiddleRoute({
      routes: this.breadcrumbRoutes
    }));
  }
}
