import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ROUTER_REQUEST} from '@ngrx/router-store';
import {Title} from '@angular/platform-browser';
import {Location,} from '@angular/common';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromRouterActions from './router.actions';

@Injectable()
export class RouterEffects {

  public updateTitle$ = createEffect(() =>
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data),
      map(data => data.title),
      tap(title => this.titleService.setTitle(title))
    ), {dispatch: false}
  );

  public navigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.go),
      tap(({path, queryParams, extras}) => {
        this.router.navigate([path], {queryParams, ...extras});
      })
    ), {dispatch: false}
  );

  public navigateReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.goReload),
      tap(({path, queryParams, extras}) => {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([path], {queryParams, ...extras});
        });
      }),
    ), {dispatch: false}
  );

  public updateQueryParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.updateQueryParams),
      tap(({queryParams}) => {
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams
          });
      })
    ), {dispatch: false}
  );

  public navigateOutsideInNewTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.goOutsideInNewTab),
      tap(({url, redirect}) => {
        if (redirect) {
          const newTab = window.open(url, '_blank');
          newTab.opener = null;
        }
      })
    ), {dispatch: false}
  );
  public navigateInNewTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.goInNewTab),
      tap(({url, redirect}) => {
        if (redirect) {
          const newTab = window.open(url, '_blank');
          newTab.opener = null;
        }
      })
    ), {dispatch: false}
  );

  public navigateOutsideInCurrentTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.goOutsideInCurrentTab),
      tap(({url}) => {
        window.location.assign(url);
      })
    ), {dispatch: false}
  );

  public navigateBack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.back),
      tap(() => this.location.back())
    ), {dispatch: false}
  );

  public navigateForward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.forward),
      tap(() => this.location.forward())
    ), {dispatch: false}
  );

  public reloadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.reloadPage),
      tap(() => {
        window.location.reload();
      })
    ), {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {
  }
}
