import { Action, createReducer, on} from '@ngrx/store';
import * as fromActions from '../header/header.actions';

export interface State {
  phrase: string;
}

export const initialState: State = {
  // menuOpened: false,
  // menuMobileOpened: false,
  phrase: '',
  
};

export const headerReducer = createReducer(
  initialState,
  on(fromActions.setPhrase, (state, { phrase }) => ({
    ...state,
    phrase,
    suggestionPhrase: phrase,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return headerReducer(state, action);
}
export const getPhrase = (state: State) => state.phrase;

