import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { News, NewsDetails } from '@app/types';
import { Template, mapTemplateToNews } from '@app/types/template';

@Component({
  selector: 'rnb-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCardComponent {

  scrollable = false;

  @Input() attentionNeeded: boolean = false;
  @Input() predefined: boolean = false;
  @Input() selected: boolean = false;
  @Input() title: string;
  @Input() description: string;
  public news: Partial<NewsDetails>;

  private _template: Template;

  @Input() label: string;

  @Input() set template(value: Template) {
    this._template = value;
    this.news = mapTemplateToNews(value);
  }

  get template(): Template {
    return this._template;
  }

  @Output() useTemplate: EventEmitter<any> = new EventEmitter();

  getScale(el: HTMLElement) {
    return el.offsetWidth / 1149;
  }

  showScroll() {
    this.scrollable = true;
  }

  hideScroll() {
    this.scrollable = false;
  }
}
