import { createFeatureSelector, combineReducers, Action } from '@ngrx/store';
import * as fromNewsTable from './news-table/news-table.reducer';
import * as fromForm from './form/form.reducer';
import * as fromTemplateForm from './template-form/template-form.reducer';
import * as fromRelatedNews from './related-news/related-news.reducer';
import * as fromTemplates from './templates/templates.reducer';

export const feature = 'news';

export interface State {
  newsTable: fromNewsTable.State;
  form: fromForm.State;
  templateForm: fromTemplateForm.State;
  relatedNews: fromRelatedNews.State;
  templates: fromTemplates.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    newsTable: fromNewsTable.reducer,
    form: fromForm.reducer,
    templateForm: fromTemplateForm.reducer,
    relatedNews: fromRelatedNews.reducer,
    templates: fromTemplates.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getNewsTable = (state: State) => state.newsTable;
export const getForm = (state: State) => state.form;
export const getTemplateForm = (state: State) => state.templateForm;
export const getRelatedNews = (state: State) => state.relatedNews;
export const getTemplates = (state: State) => state.templates;
