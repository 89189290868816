import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class DividerUi extends Plugin {
	static get pluginName() {
		return 'DividerUi';
	}
	init() {
		const editor = this.editor;
		const t = editor.t;

		// Add the `horizontalLine` button to feature components.
		editor.ui.componentFactory.add( 'divider', locale => {
			const command = editor.commands.get( 'divider' );
			const view = new ButtonView( locale );

			view.set( {
				label: t( 'Divider' ),
				icon: '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">\n' +
					'  <line x1="0" y1="10" x2="20" y2="10" stroke="black" />\n' +
					'</svg>',
				tooltip: true
			} );

			view.bind( 'isEnabled' ).to( command, 'isEnabled' );

			// Execute the command.
			this.listenTo( view, 'execute', () => {
				editor.execute( 'divider' );
				editor.editing.view.focus();
			});

			return view;
		} );
	}
}
