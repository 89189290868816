<div class="rhd__dropdown" #optionsOrigin="cdkOverlayOrigin" cdkOverlayOrigin (rhdClickOutside)="floatingOptions? {} : onOutsideClick()">
  <div
    #contentTrigger
    class="rhd__dropdown--custom-trigger"
    [class.hidden]="noCustomTrigger"
    (click)="toggleDropdown()"
  >
    <ng-content select="[trigger]"></ng-content>
  </div>
  <button rds-dropdown-toggle-button [class.error]="control.touched && !!control.errors" [isOpen]="opened" size="l" (click)="toggleDropdown()">{{placeholder}}</button>
  <!-- <div
    *ngIf="noCustomTrigger && !alwaysOpened"
    [ngClass]="{
      'rhd__dropdown__trigger': true,
      'rhd__dropdown__trigger--small': (size) === 'small',
      'rhd__dropdown__trigger--opened': opened,
      'rhd__dropdown__trigger--error': !opened && invalidTouchedNotOpened
    }"
    (click)="toggleDropdown()"
  >
    <span
      [ngClass]="{
        'rhd__dropdown__trigger__text': true,
        'rhd-label-medium': true
      }"
    >
      {{placeholder}}
    </span>
    <rds-icon class="rds__dropdown__trigger__caret" [icon]="opened? 'caret_up' : 'caret_down'" color="primary"></rds-icon>
  </div> -->
  <ng-container *ngIf="alwaysOpened || opened && !floatingOptions" >
    <ng-container *ngTemplateOutlet="elementsBody"></ng-container>
  </ng-container>
  <div
    *ngIf="showChips"
    [ngClass]="{
      'rhd__dropdown__chosen-elements': true,
      'rhd__dropdown__chosen-elements--small': (size) === 'small'
    }"
  >
  <ng-container *ngIf="chipsList">
    <div class="rhd__dropdown__chosen-elements--chips-container" overflowingContent="86" #overflowing="overflowingContent">
      <rds-chip-list size="s" type="filter">
        <rds-chip *ngIf="chipsList.length === 0" [disabled]="true">{{chipPlaceholder}}</rds-chip>
        <rds-chip *ngFor="let chip of chipsList" rdsChipInput (closeChip)="remove(chip)">
          <span *ngIf="chip | dropdownKey:optionsModel.parentNameKey ?? ''">{{(chip | dropdownKey:optionsModel.parentNameKey ?? '')}}:&nbsp;</span>
          <span>{{chip | dropdownKey:optionsModel.nameKey ?? ''}}</span>
        </rds-chip>
      </rds-chip-list>
    </div>
    <div *ngIf="overflowing.isOverflowing" class="rhd__dropdown__chosen-elements--actions-container">
      <button rds-text-button (click)="overflowing.isExpanded ? overflowing.collapse() : overflowing.expand()">
        Show {{overflowing.isExpanded? 'less' : 'all'}} <rds-icon [icon]="overflowing.isExpanded ? 'chevron_up' : 'chevron_down'"></rds-icon>
      </button>
    </div>
  </ng-container>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="optionsOrigin"
  [cdkConnectedOverlayOpen]="opened && floatingOptions"
  [cdkConnectedOverlayOffsetX]="0"
  [cdkConnectedOverlayOffsetY]="0"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="rhd__options--backdrop"
  (backdropClick)="toggleDropdown()"
  (overlayKeydown)="onKeydown($event)"
>
  <ng-container *ngTemplateOutlet="elementsBody"></ng-container>
</ng-template>

<ng-template #elementsBody>
  <div
    [class.rhd__dropdown__options--opened]="alwaysOpened || opened"
    [class.rhd__dropdown__options--closed]="!alwaysOpened && !opened"
    class="rhd__dropdown__options"
  >
    <form *ngIf="(showSearch) === true" [formGroup]="searchDropdownForm">
      <rds-select-search-input (changed)="searchChanged($event)"></rds-select-search-input>
      <rds-select-option-separator></rds-select-option-separator>
    </form>
    <div class="rhd__dropdown__options__elements">
        <cdk-virtual-scroll-viewport #scrollViewport class="rhd__dropdown__options__elements--scroll-viewport" [style.height]="(size === 'medium'? 54 : 36 ) * (visibleElementsNumber > visibleNodes.length? visibleNodes.length : visibleElementsNumber) + 'px'" [itemSize]="size === 'medium'? 54 : 36" minBufferPx="400" maxBufferPx="800">
          <ng-container *cdkVirtualFor="let node of visibleNodes; let i = index; trackBy: trackByFn">
            <ng-container *ngIf="
            {
              hasChild: hasChild(node),
              isAnyChildSelected: isAnyChildSelected(node),
              areAllChildsSelected: areAllChildsSelected(node),
              isSelected: selection.isSelected(node),
              searchPhrase: searchPhrase,
              isExpanded: treeControl.isExpanded(node),
              isAnyChildMatchingPhrase: isAnyChildMatchingPhrase(node, searchPhrase),
              node: node,
              hasSelectionValue: selection.hasValue(),
              isMultiple: isMultiple,
              size: size,
              optionsModel: optionsModel,
              selectParentWhenAllSelected: selectParentWhenAllSelected
            } as nodeScope">
            <rnb-dropdown-node
              [nodeScope]="nodeScope" 
              (onToggle)="treeControl.toggle($event)" 
              (onCheckboxChange)="toggleNodeChckbx($event)"
              (onNameClick)="select(node)"
              tabindex="0" (click)="isMultiple? setActiveItem(node) : {}"></rnb-dropdown-node>
            </ng-container>
          </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
  </div>
</ng-template>
