import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class TextBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options.value;
            const box = createTextBox(writer);
            const text = createText(writer);
            writer.append(text, box);

            if(value === 'double') {
                const text2 = createText(writer);
                writer.append(text2, box);
            }
            model.insertObject(box, null, {setSelection: 'on', findOptimalPosition: 'auto'});
            writer.setAttribute('custom-type', value, box);
            if(value === 'block') {
                writer.setAttribute('custom-background-class', 'bg-neutral-light-colour', box);
            }
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = model.document.selection.getSelectedElement();
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'textBox');
        const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
        this.isEnabled = allowedIn !== null && !customBlockSelected;
    }
}

export function createTextBox(writer) {
    const textBox = writer.createElement('textBox');
    return textBox;
}

export function createText(writer) {

    const div = writer.createElement('textBoxText');
    const paragraph = writer.createElement('paragraph');
    writer.insertText('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut' +
        ' labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut' +
        ' aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore' +
        ' eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt' +
        ' mollit anim id est laborum', paragraph);
    writer.append(paragraph, div)
    return div;
}
