import { DropdownFlatNode, NestedOptionsModel } from './models';

export function getTreeStructure(data: any, dataKeys: Partial<NestedOptionsModel>) {
  let treeData: Array<any> = []
  if (dataKeys.parentIdKey) {
    let itemsTransformed = 0;
    let currentLevel = 0;

    data.filter((item: any) => !item[(dataKeys.parentIdKey as string)]).forEach(((filteredItem: any) => {
      let node = {
        expandable: data.some((item: any) => filteredItem[(dataKeys.nestKey as string)].includes(item[(dataKeys.idKey as string)])),
        [(dataKeys.idKey as string)]: filteredItem[(dataKeys.idKey as string)],
        isExpanded: false,
        level: currentLevel,
        [(dataKeys.nameKey as string)]: filteredItem[(dataKeys.nameKey as string)],
        [(dataKeys.parentNameKey as string)]: filteredItem[(dataKeys.parentNameKey as string)],
        [(dataKeys.parentIdKey as string)]: filteredItem[(dataKeys.parentIdKey as string)]
      }
      treeData.push(node);
      itemsTransformed++;
    }));
  
    while (data.length - itemsTransformed > 0) {
      if (treeData.filter(node => node.level === currentLevel).length === 0 ) {
        throw new Error('Something wrong with data! Check data integrity');
      }
      treeData.filter(node => node.level === currentLevel).forEach(filteredNode => {
        let nodeIndex = treeData.findIndex(node => node[(dataKeys.idKey as string)] === filteredNode[(dataKeys.idKey as string)]);
        let childNodes: Array<DropdownFlatNode> = data.filter((item: any) => item[(dataKeys.parentIdKey as string)] == filteredNode[(dataKeys.idKey as string)]).map((filteredItem: any) => ({
          expandable: data.some((item: any) => filteredItem.childrenIds.includes(item[(dataKeys.idKey as string)])),
          [(dataKeys.parentNameKey as string)]: filteredItem[(dataKeys.parentNameKey as string)],
          [(dataKeys.parentIdKey as string)]: filteredItem[(dataKeys.parentIdKey as string)],
          [(dataKeys.idKey as string)]: filteredItem[(dataKeys.idKey as string)],
          isExpanded: false,
          level: currentLevel + 1,
          [(dataKeys.nameKey as string)]: filteredItem[(dataKeys.nameKey as string)]
        }));
        itemsTransformed += childNodes.length;
        childNodes = childNodes.sort((a, b) => {
          if ((a as any)[(dataKeys.nameKey as string)] < (b as any)[(dataKeys.nameKey as string)]) { return -1; }
          if ((a as any)[(dataKeys.nameKey as string)] > (b as any)[(dataKeys.nameKey as string)]) { return 1; }
          return 0;
        })
        treeData.splice(nodeIndex + 1, 0, ...childNodes);
      });
      currentLevel++;
    }
  } else {
    data.forEach(((filteredItem: any) => {
      treeData.push({
        expandable: false,
        [(dataKeys.idKey as string)]: filteredItem[(dataKeys.idKey as string)],
        isExpanded: false,
        [(dataKeys.nameKey as string)]: filteredItem[(dataKeys.nameKey as string)]
      });
    }));
  }
  return treeData;
}