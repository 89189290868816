import { Plugin } from 'ckeditor5/src/core';
import BlocksUI from './blocks-ui';
import CustomImageEditing from "./custom-image-editing";

export default class Blocks extends Plugin {
    static get requires() {
        return [BlocksUI, CustomImageEditing];
    }

    static get pluginName() {
        return 'Blocks';
    }

    init() {
        const editor = this.editor;
        editor.model.schema.extend( 'paragraph', { allowAttributes: [ 'class' ] } );
        editor.conversion.elementToElement({ model: 'paragraph', view: {name: 'p', classes: 'article-body-m'}, converterPriority: 'high' });
    }
}
