<rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
<rnb-table
  #table
  [data]="news$ | async"
  [defaultSortActive]="(defaultSort$ | async).active"
  [defaultSortDirection]="(defaultSort$ | async).direction"
  [columns]="columns"
  [hasBulk]="true"
  [bulkPredicate]="bulkPredicate"
  [hasActions]="true"
  [pagination]="pagination$ | async"
  [totalObs]="total$"
  [isLoading]="isLoading$ | async"
  [highlight]="search$ | async"
  (sortChanged)="sortChanged($event)"
  (paginationChanged)="paginationChanged($event)"
  (selectionChanged)="selectionChanged($event)"
  (linkClicked)="onLinkClick($event)"
  (assignClicked)="onAssignClick($event)"
  [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
  >
  <span bulkText>
    {{table.selectedCount}} News selected 
  </span>
  <ng-container bulkActions>
    <button rds-warning-button (click)="deleteNews(table.selection)">Delete</button>
    <div [rdsTooltipDisabled]="canBulkArchive(table.selection)" rdsTooltip="Deselect Archived News to perform this action" [rdsTooltipShowDelay]="300">
      <button rds-primary-button [disabled]="!canBulkArchive(table.selection)" (click)="archiveNews(table.selection)">Achive</button>
    </div>
    <div [rdsTooltipDisabled]="canBulkMoveAsDraft(table.selection)" rdsTooltip="Only Archived News can be moved as draft" [rdsTooltipShowDelay]="300">
      <button rds-primary-button [disabled]="!canBulkMoveAsDraft(table.selection)" (click)="bulkMoveAsDraft(table.selection)">Move as Draft</button>
    </div>
  </ng-container>

  <ng-template #actions let-news>
    <button rds-icon-button size="m" [rdsMenuTriggerFor]="menu"
    #trigger="rdsMenuTrigger">
      <rds-icon namespace="filled" icon="more_vertical"></rds-icon>
    </button>

    <rds-menu #menu="rdsMenu">
      <button [disabled]="!canEditNews(news)" rnbBackButtonSet label="My News" routeBack="news/my" [routeCurrent]="'/news/'+news.id+'/edit'" rds-menu-item [routerLink]="'/news/'+news.id+'/edit'">
        <span>Edit</span>
      </button>
      <button rds-menu-item (click)="duplicate(news.id)">
        <span>Duplicate</span>
      </button>
      <button rds-menu-item [disabled]="news.status !== 'Published' || news.type !== 'Embedded'" (click)="saveAsTemplate(news.id)">
        <span>Save as template</span>
      </button>
      <!-- <button rds-menu-item>
        <span>Unpublish</span>
      </button> -->
      <button *ngIf="news.status === 'Archived'" rds-menu-item (click)="moveAsDraft(news.id)">
        <span>Move as draft</span>
      </button>
      <button *ngIf="news.status !== 'Archived'" rds-menu-item (click)="archive(news.id, news.title)">
        <span>Archive</span>
      </button>
      <button [disabled]="!canDeleteNews(news)" rds-menu-item (click)="deleteSingleNews(news.id, news.title)">
        <span>Delete</span>
      </button>
    </rds-menu>
  </ng-template>

  <span emptyTitle>
    Looks like you don’t have any news here yet.
  </span>
  <span emptySubtitle>
    Fortunately, it's very easy to create news.
    <rnb-add-news-button [breadcrumbRoutes]="[{ label: 'My News', route: 'news/my'}]" class="add-button"></rnb-add-news-button>
  </span>
</rnb-table>
