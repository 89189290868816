import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';

import * as fromActions from './back-button.actions';
import * as fromReducer from './back-button.reducer';
import * as fromSelector from './back-button.selectors';
import * as fromRouter from '@app/root-store/router';

import { switchMap, debounce, map, distinctUntilChanged, filter, mergeMap, first, tap, withLatestFrom } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { fromEvent, timer, of, merge } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ScreenModeEnum, ScreenOrientationEnum } from 'src/app/types/screen';
import { Confirm2DialogComponent, ConfirmDialogData } from '@app/utils/confirm2-dialog/confirm-dialog.component';
import { RdsDialogService } from '@rds/angular-components';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class BackButtonEffects {

  public routerEvent$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => console.log('Navigation finished', event)),
      withLatestFrom(
        this.store$.pipe(select(fromSelector.selectBackConfig))
      ),
      filter(([event, {routeCurrent}]) => {
        let routesToCheck = [];
        if (routeCurrent instanceof Array === false) {
          routesToCheck = [routeCurrent as string]
        } else {
          routesToCheck = routeCurrent as Array<string>;
        }
        return !routesToCheck.includes(event.url)
      }),
      map(() => fromActions.clear())
    ), {dispatch: true}
  );
  
  public openLeavePageDialog$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.openLeavePageDialog),
    filter(({changesMade,}) => !!changesMade),
    map(({defaultLabel, defaultRoute}) =>{
      const data: ConfirmDialogData = {
        ids: [],
        messages: [],
        title: 'Do you want to discard changes?',
        confirmButtonLabel: 'Yes, discard',
        confirmButtonType: 'primary'
      }
      const dialog = this.dialogService.open(Confirm2DialogComponent, {
        data
      });
      return ({dialog, defaultLabel, defaultRoute})
    }),
    switchMap(({dialog, defaultLabel, defaultRoute}) => dialog.afterClosed().pipe(
      filter(data => !!data),
      map(() => fromActions.back({defaultLabel, defaultRoute}))
    )),
  ), {dispatch: true})

  public closeWithoutModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openLeavePageDialog),
    
      filter(({changesMade}) => !changesMade),
      map(({defaultLabel, defaultRoute}) => fromActions.back({defaultLabel, defaultRoute}))
    ), {dispatch: true})

  public back$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.back),
      withLatestFrom(this.store$.pipe(select(fromSelector.selectBackConfig))),
      filter(([action, {label, routeBack, routeCurrent, routeBackQueryParams}]) => !!label && !!routeBack &&!!routeCurrent),
      mergeMap(([action, {label, routeBack, routeCurrent, routeBackQueryParams}]) => [
        fromRouter.go({path: routeBack, queryParams: routeBackQueryParams? routeBackQueryParams : {}}),
        fromActions.clear()
      ])
    ), {dispatch: true})

  public backToDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.back),
      withLatestFrom(this.store$.pipe(select(fromSelector.selectBackConfig))),
      filter(([action, {label, routeBack, routeCurrent}]) => !label || !routeBack || !routeCurrent),
      mergeMap(([{defaultLabel, defaultRoute}, selector]) => [
        fromRouter.go({path: defaultRoute, queryParams: {}}),
        fromActions.clear()
      ])
    ), {dispatch: true})

  constructor(
    private actions$: Actions,
    private dialogService: RdsDialogService,
    private router: Router,
    private store$: Store<fromReducer.State>
  ) {}
}
