import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[requireAsterisk]'
})
export class RequireAsteriskDirective implements OnInit {

  constructor(
    private elementRef: ElementRef<any>,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const asterisk = document.createElement('span');
    this.renderer.addClass(asterisk, 'c-error-normal');
    this.renderer.setProperty(asterisk, 'innerHTML', ' *');
    this.renderer.appendChild(this.elementRef.nativeElement, asterisk);
  }

}
