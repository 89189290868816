import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget, toWidgetEditable} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import AnnouncementBoxCommand from './announcement-box-command';
import CustomBoxImageAlignmentCommand from "../blocks/custom-box-image-alignment-command";

export default class AnnouncementBoxEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add('insertAnnouncementBox', new AnnouncementBoxCommand(this.editor));
        this.editor.commands.add('announcementAlignment', new CustomBoxImageAlignmentCommand(this.editor));
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('announcementBox', {
            inheritAllFrom: '$blockObject',
            allowWhere: '$root',
            allowAttributes: ['data-image-side'],
            allowContentOf: ['announcementBoxDescription', 'customImageBox']
        });

        schema.register('announcementBoxDescription', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: 'announcementBox',

            // Allow content which is allowed in the root (e.g. paragraphs).
            allowContentOf: '$root'
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        conversion.attributeToAttribute({
            model: {
                name: 'announcementBox',
                key: 'data-image-side'
            },
            view: {
                key: 'data-image-side'
            }
        });

        // <announcementBoxImage> converters
        conversion.for('upcast').elementToElement({
            model: 'announcementBox',
            view: {
                name: 'div',
                classes: 'announcement-box'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'announcementBox',
            view: {
                name: 'div',
                classes: 'announcement-box mb-6'
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'announcementBox',
            view: (modelElement, {writer: viewWriter}) => {
                const side = modelElement.getAttribute('data-image-side')
                const section = viewWriter.createContainerElement('section', {class: 'py-6 my-6 announcement-box'});
                return toWidget(section, viewWriter, {label: 'simple box widget'});
            }
        });

        // <announcementBoxDescription> converters
        conversion.for('upcast').elementToElement({
            model: 'announcementBoxDescription',
            view: {
                name: 'div',
                classes: 'announcement-box-description'
            }
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'announcementBoxDescription',
            view: {
                name: 'div',
                classes: 'announcement-box-description'
            }
        });
        conversion.for('editingDowncast').elementToElement({
            model: 'announcementBoxDescription',
            view: (modelElement, {writer: viewWriter}) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createEditableElement('div', {class: 'announcement-box-description'});

                return toWidgetEditable(div, viewWriter);
            }
        });
    }
}
