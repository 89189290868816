import { Component, Input } from '@angular/core';
import { SimpleUser } from '@app/types';
import { RDS_AVATAR_MAX_IN_GROUP } from '@rds/angular-components';

@Component({
  selector: 'rnb-user-group',
  templateUrl: './user-group.component.html',
  styles: [
  ],
  providers: [
    { provide: RDS_AVATAR_MAX_IN_GROUP, useValue: 999}
  ]
})
export class UserGroupComponent {

  @Input() maxInGroup: number = 4;
  
  visibleUsers: Array<SimpleUser>;
  hiddenUsers: Array<SimpleUser>;
  public _users: Array<SimpleUser>;

  @Input() set users(value: Array<SimpleUser>) {
    this._users = value;
    this.visibleUsers = value.slice(0, value.length > this.maxInGroup ? this.maxInGroup - 1 : value.length);
    this.hiddenUsers = value.length > this.maxInGroup ? value.slice(this.maxInGroup - 1) : []
  }

  get users() {
    return this._users;
  }
}
