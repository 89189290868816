import {icons} from 'ckeditor5/src/core';
import singleText from './icons/single-text.svg';
import doubleText from './icons/double-text.svg';
import blockText from './icons/block-text.svg';
import paLeft from './icons/pa-left.svg';
import paRight from './icons/pa-right.svg';
import imagesAndText from './icons/images-and-text.svg';
import images2 from './icons/2-images.svg';
import images3 from './icons/3-images.svg';
import images4 from './icons/4-images.svg';
import imageAndTextLeft from './icons/image-and-text-left.svg';
import imageAndTextRight from './icons/image-and-text-right.svg';
import movieAndTextLeft from './icons/movie-and-text-left.svg';
import movieAndTextRight from './icons/movie-and-text-right.svg';
import imageAndQuoteLeft from './icons/image-and-quote-left.svg';
import imageAndQuoteRight from './icons/image-and-quote-right.svg';
import media from './icons/media.svg';
export const iconsMap = new Map([
    ['left', icons.alignLeft],
    ['right', icons.alignRight],
    ['upload', icons.image],
    ['single', singleText],
    ['double', doubleText],
    ['block', blockText],
    [ 'paLeft', paLeft ],
    [ 'paRight', paRight ],
    [ 'imagesAndText', imagesAndText ],
    [ 'images2', images2 ],
    [ 'images3', images3 ],
    [ 'images4', images4 ],
    [ 'imageAndTextLeft', imageAndTextLeft ],
    [ 'imageAndTextRight', imageAndTextRight ],
    [ 'movieAndTextLeft', movieAndTextLeft ],
    [ 'movieAndTextRight', movieAndTextRight ],
    [ 'imageAndQuoteLeft', imageAndQuoteLeft ],
    [ 'imageAndQuoteRight', imageAndQuoteRight ],
    [ 'alternative', icons.lowVision, ],
    [ 'media', media, ],
]);
