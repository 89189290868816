import { AfterContentInit, Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SaveTemplateDialogComponent } from './save-template-dialog.component';

const LETTERS = "abcdefghijklmnopqrstuvxyz";
const COLORS = ['#0d0208', '#003b00', '#008f11', '#00ff41'];
@Directive({
  selector: '[rnbRandomLetter]'
})
export class RandomLetterDirective {

  
  _status: string;
  get status(): string {
      return this._status;
  }
  @Input() set status(value: string) {
    if (value === 'NOT_CHECKED') {
      this.randomize();
    } else {
      clearInterval(this.randomInterval);
      this.host.nativeElement.textContent = this.originalLetter;
      this.host.nativeElement.style.color = value === 'FAILED' ? '#cc0033' : 'unset';
      this.isRandomizing = false;
    }
      this._status = value;
  }

  @Input() originalLetter;

  isRandomizing = false;
  randomInterval;
  @Output() skeletoned: EventEmitter<any> = new EventEmitter();
  constructor(private host: ElementRef<HTMLSpanElement>, private renderer: Renderer2) {
   }

   randomize() {
    if (!this.isRandomizing) {
      this.isRandomizing = true;
      this.randomInterval = setInterval(() => {
        var letterIdx = Math.floor(LETTERS.length * Math.random());
        var letter = Math.random() < 0.5 ? LETTERS.charAt(letterIdx) : LETTERS.charAt(letterIdx).toUpperCase();

        var colorIdx = Math.floor(COLORS.length * Math.random());
        var color = COLORS[colorIdx];

        this.host.nativeElement.style.color = color;
        this.host.nativeElement.textContent = letter
      }, 50)
    }
   }
}
