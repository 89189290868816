import {Plugin} from 'ckeditor5/src/core';
import {isWidget, WidgetToolbarRepository} from 'ckeditor5/src/widget';
import {createNewButtons} from '../blocks/blocks-utils'

export default class ImagesBoxToolbar extends Plugin {

    static get requires() {
        return [WidgetToolbarRepository];
    }

    static get pluginName() {
        return 'ImagesBoxToolbar';
    }


    afterInit() {
        const editor = this.editor;
        const t = editor.t;
        const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);

        const options = [
            {
                name: 'images2',
                command: 'imagesChangeBox',
                commandValue: 2,
                type: 'action'
            },
            {
                name: 'images3',
                command: 'imagesChangeBox',
                commandValue: 3,
                type: 'action'
            },
            {
                name: 'images4',
                command: 'imagesChangeBox',
                commandValue: 4,
                type: 'action'
            }
        ]
        createNewButtons(this, options);
        const optionsCommand = options.map(option => {
            if (option.commandValue) {
                return `${option.command}:${option.commandValue}`;
            } else {
                return option.command;
            }
        });
        if (options) {
            widgetToolbarRepository.register('ImagesContent', {
                ariaLabel: t('Images box toolbar'),
                items: optionsCommand,
                getRelatedElement: getSelectedImagesWidget,
                balloonClassName: 'ck-custom-fieldset-toolbar'
            });
        }
    }
}

export function getSelectedImagesWidget(selection) {
    const viewElement = selection.getSelectedElement();

    if (viewElement && isImagesWidget(viewElement)) {
        return viewElement;
    }

    return null;
}

export function getImagesWidgetAncestor(selection) {
    const selectionPosition = selection.getFirstPosition();

    if (!selectionPosition) {
        return null;
    }

    let parent = selectionPosition.parent;

    while (parent) {
        if (parent.is('element') && isImagesWidget(parent)) {
            return parent;
        }

        parent = parent.parent;
    }

    return null;
}

function isImagesWidget(viewElement) {
    return !!viewElement.hasClass('images-box') && isWidget(viewElement);
}

