<article class="no-mobile-warning">
  <header>
    <img src="assets/logo-roche.svg" alt="Roche" />
  </header>
  <section>
    <h1>Newsboard is intended for desktop use only.</h1>
    <p>We apologize for the inconvenience.<br />You can access the page on your desktop device.</p>
    <a href="https://home.roche.com">Go to RocheHome</a>
    <figure>
      <img
        srcset="assets/no-mobile-sm.png 792w, assets/no-mobile-lg.png 1260w"
        sizes="(max-width: 767px) 792px, 1260px"
        src="assets/no-mobile-sm.png"
        alt=""
      />
    </figure>
  </section>
</article>
