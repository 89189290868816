import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromLanguages from '@app/root-store/dictionaries/languages';
import * as fromForm from '@app/news/store/form';
import * as fromRelatedNews from '@app/news/store/related-news';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentType, Language, NewsContent, NewsForm, NewsImage } from '@app/types';
import { RdsAccordionDirective, RdsDialogService, RdsExpansionPanelComponent, RdsMenuTriggerDirective, RdsSelectFilterByFunc, RdsSelectOptionComponent } from '@rds/angular-components';
import { AuthService } from '@app/auth/auth.service';
import * as CKEDITOR from '@ckeditor/ckeditor5-build-classic';
import { NewsService } from '@app/news/news.service';
import { FormStepStatus } from '../form-status-badge/form-status-badge.component';
import { SubSink } from 'subsink';
import { CustomValidators } from '@app/utils/validators';
import * as _ from 'lodash';

@Component({
  selector: 'rnb-form-content',
  templateUrl: './form-content.component.html',
  styleUrls: [ './form-content.component.scss' ]
})
export class FormContentComponent implements OnInit, OnDestroy {
  @ViewChildren(RdsExpansionPanelComponent) languagePanels: QueryList<RdsExpansionPanelComponent>
  private subs: SubSink = new SubSink;
  public Editor = CKEDITOR.NewsEditor;
  languageFocused = true;

  _isActive: Array<boolean>;
  get isActive(): Array<boolean> {
      return this._isActive;
  }
  @Input() set isActive(value: Array<boolean>) {
      this._isActive = value;
      this.store$.dispatch(fromForm.setContentFormStatus({status: this.form.status}));
      if (value) {
      this.store$.dispatch(fromForm.setContentStepStatus({status: FormStepStatus.IN_PROGRESS}));
      } else {
        this.form.markAllAsTouched();
        if (this.form.valid) {
          this.store$.dispatch(fromForm.setContentStepStatus({status: FormStepStatus.COMPLETED}));
        } else {
          this.store$.dispatch(fromForm.setContentStepStatus({status: FormStepStatus.INCOMPLETE}));
        }
      }
  }

  topicsAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('topics')))
  languages$: Observable<Array<Language>> = this.store$.pipe(select(fromLanguages.selectAll));
  now = new Date();


  filterBy: RdsSelectFilterByFunc = (
    text: string | null,
    item: RdsSelectOptionComponent
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.value.name.toLowerCase().includes(text.toLowerCase());
    }
  };
  allLanguages$: Observable<Array<string>> = this.store$.pipe(select(fromForm.selectLanguages));

  newsType$: Observable<ContentType> = this.store$.pipe(select(fromForm.selectType));

  primaryLanguage$: Observable<string> = this.store$.pipe(select(fromForm.selectPrimaryLanguage));
  primaryLanguage: string;

  primaryLanguageImage$: Observable<NewsImage> = this.store$.pipe(select(fromForm.selectPrimaryLanguageImage));
  primaryImageForm: FormGroup = new FormGroup({
    image: new FormControl(null)
  });

  form$: Observable<Partial<NewsForm>> = this.store$.pipe(select(fromForm.selectContentForm));
  public form: FormGroup = new FormGroup({
    content: new FormArray([]),
    websiteLink: new FormGroup({
      url: new FormControl(null),
      name: new FormControl(null),
    }),
    topics: new FormControl([], [CustomValidators.notEmptyList, CustomValidators.maxLengthList(5)]),
    related: new FormControl([])
  });

  emptyContentForm = new FormArray([new FormGroup({
        language: new FormControl('en', [Validators.required]),
        link: new FormControl(""),
        title: new FormControl(""),
        abstract: new FormControl(""),
        image: new FormControl(null),
        html: new FormControl(""),
        syncImage: new FormControl(false)
      })
    ]);

  get contentArray() {
    return this.form.controls.content as FormArray
  }

  get primaryContentForm() {
    return this.contentArray.controls.filter((a: FormGroup) => a.controls.language.value === this.primaryLanguage);
  }

  get otherContentForms() {
    return this.contentArray.controls.filter((a: FormGroup) => a.controls.language.value !== this.primaryLanguage);
  }

  get getArrayControlsInOrder() {
    const controlsInOrder = [...this.primaryContentForm, ...this.otherContentForms];
    return controlsInOrder.length > 0 ? [...this.primaryContentForm, ...this.otherContentForms] : [...this.emptyContentForm.controls];
  }

  constructor(private dialogService: RdsDialogService, private newsService: NewsService, private store$: Store<any>, private auth: AuthService) {

  }

  ngOnDestroy(): void {
    this.form.markAllAsTouched();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(fromLanguages.loadLanguages());
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'topic', prop: 'topics'}));

    this.subs.sink = this.primaryLanguage$.subscribe(language => {
      this.primaryLanguage = language;
    });

    this.subs.sink = this.primaryLanguageImage$.pipe(
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
    ).subscribe(image => {
      this.primaryImageForm.controls.image.setValue({ ...image});
    });

    this.subs.sink = this.primaryImageForm.controls.image.valueChanges.subscribe(image => {
      this.otherContentForms.filter(c => c.value.syncImage).map(c => {
        return (c as FormGroup).controls.image.patchValue(image)
      });
    })

    this.subs.sink = this.form$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(news => {
      const formContentArray = (this.form.controls.content as FormArray).value as Array<NewsContent>;
      const formContentKeys = formContentArray.map(c => c.language);
      const newsContentKeys = news.content.map(c => c.language);
      const primaryContent = news.content.find(c => c.language === news.language);
      const toAdd = news.content.filter(c => !formContentKeys.includes(c.language));
      const toRemove = formContentArray.filter(c => !newsContentKeys.includes(c.language));
      toAdd.forEach(content => {
        (this.form.controls.content as FormArray).push(new FormGroup({
          language: new FormControl(content.language, [Validators.required]),
          link: new FormControl(content.link, news.type === 'Link'? [Validators.required, CustomValidators.url] : []),
          title: new FormControl(content.title, [Validators.required, Validators.maxLength(100)]),
          abstract: new FormControl(content.abstract, [Validators.required, Validators.maxLength(200)]),
          image: new FormControl(content.image),
          html: new FormControl(content.html, news.type === 'Link'? [] : [Validators.required]),
          syncImage: new FormControl(content.syncImage)
        }), {emitEvent: false})
      });
      toRemove.forEach(content => {
        const formContentArray = (this.form.controls.content as FormArray).value as Array<NewsContent>;
        const id = formContentArray.findIndex(c => c.language === content.language);
        (this.form.controls.content as FormArray).removeAt(id, {emitEvent: false})
      });
      const newsClone: Partial<NewsForm> = _.cloneDeep(news);
      newsClone.content.map(c => {
        delete c.html
        return c
      });
      this.form.patchValue(newsClone, {emitEvent: false});
      this.store$.dispatch(fromForm.setContentFormStatus({status: this.form.status}));
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(50)
    ).subscribe(form => {
      this.store$.dispatch(fromForm.setFormValue({form}))
    });

    this.subs.sink = this.form.statusChanges.subscribe(value => {
      this.store$.dispatch(fromForm.setContentFormStatus({status: value}));
    });
    let langDifference = 0;
    this.subs.sink = this.allLanguages$.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, next) => {
        langDifference = (prev?.length || 0) - (next?.length || 0)
        return prev.length - next.length === 0;
      })
    ).subscribe(languages => {
      if (langDifference === -1) {
        this.languagePanels.get(languages.length - 1).open()
      }
    });

    this.subs.sink = this.form.controls.websiteLink.valueChanges.pipe(
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next))
    ).subscribe(value => {
      const group: FormGroup = this.form.controls.websiteLink as FormGroup;
      if (!!value.url || !!value.name) {
        group.controls.name.setValidators([Validators.required, Validators.maxLength(150)])
        group.controls.url.setValidators([Validators.required, CustomValidators.url])
      } else {
        group.controls.name.setValidators([]);
        group.controls.url.setValidators([]);
      }
			group.controls.name.markAsTouched();
			group.controls.url.markAsTouched();

			group.controls.name.updateValueAndValidity({emitEvent: false});
			group.controls.url.updateValueAndValidity({emitEvent: false});
			group.updateValueAndValidity();
		});

    this.subs.sink = this.form.controls.related.valueChanges.pipe(
      distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next))
    ).subscribe(value => {
      if (value.length > 0) {
        this.form.controls.related.setValidators([CustomValidators.minLengthList(2)])
      } else {
        this.form.controls.related.setValidators([])
      }
      this.form.controls.related.markAsTouched();
			this.form.controls.related.updateValueAndValidity({emitEvent: false});
    })
  }


  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'topic', prop, phrase: event}))
  }

  deleteLanguageClick(event: MouseEvent, language: string) {
    event.stopPropagation();
    this.store$.dispatch(fromForm.removeLanguageVersion({language}))
  }

  syncImageChanged(form: FormGroup) {
    if (form.controls.syncImage.value === false) {
      form.controls.image.patchValue(this.primaryImageForm.controls.image.value);
    } else {
      form.controls.image.patchValue(null);
    }
  }


  focusAccordeon() {
    this.languageFocused = true;
  }

  unfocusAccordeon() {
    this.languageFocused = false;
  }

  change(accordion: RdsAccordionDirective, panel: RdsExpansionPanelComponent) {
    if (panel.expanded === false && accordion._headers.filter(h => h.panel.expanded === true).length === 0) {
      panel.open();
    }
  }
}
