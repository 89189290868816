import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ImagesAndTextBoxUi from './images-and-text-box-ui';
import ImagesAndTextBoxEditing from './images-and-text-box-editing';
import CustomBoxResizeUi from "../blocks/custom-box-resize-ui";

export default class ImagesAndTextBox extends Plugin {
  static get requires() {
      return [ ImagesAndTextBoxEditing, ImagesAndTextBoxUi, CustomBoxResizeUi ];
  }
}
