import { createSelector } from '@ngrx/store';

import * as fromReducer from './form.reducer';
import * as fromChannels from '../channel.reducer';
import { ChannelCreation } from '@app/types';

export const selectState = createSelector(
  fromChannels.selectState,
  fromChannels.getForm
);

export const selectForm = createSelector(
  selectState,
  fromReducer.getForm
);

export const selectFormForRequest = createSelector(
  selectForm,
  (form): ChannelCreation => {
    return {
      allowAutoAssign: form?.allowAutoAssign,
      departments: form?.departments,
      description: form?.description,
      functions: form?.functions,
      locations: form?.locations,
      managers: form?.permissions?.managers,
      owners: form?.permissions?.owners,
      publishers: form?.permissions?.publishers,
      name: form?.name,
      id: form?.id,
      role: form?.role
    }
  }
);

export const selectInitialForm = createSelector(
  selectState,
  fromReducer.getInitialForm
);

export const selectId = createSelector(
  selectForm,
  fromReducer.getId
);

export const selectIsTouched = createSelector(
  selectState,
  fromReducer.getTouched
);

export const selectIsNameUnique = createSelector(
  selectState,
  fromReducer.getIsNameUnique
);
