import {Plugin} from 'ckeditor5/src/core';
import {WidgetToolbarRepository} from 'ckeditor5/src/widget';
import {createNewButtons} from '../blocks/blocks-utils'
import {iconsMap} from "../blocks/blocks-icons-map";

export default class CustomMovieToolbar extends Plugin {

    static get requires() {
        return [WidgetToolbarRepository];
    }

    static get pluginName() {
        return 'CustomMovieToolbar';
    }


    afterInit() {
        const editor = this.editor;
        const t = editor.t;
        const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);

        const options = [
            {
                name: 'customMovieSrc',
                command: 'customMovieSrc',
                icon: iconsMap.media,
                type: 'dropdown'
            }
        ]
        createNewButtons(this, options);
        const optionsCommand = options.map(option => {
            if (option.commandValue) {
                return `${option.command}:${option.commandValue}`;
            } else {
                return option.command;
            }
        });

        if (options) {
            widgetToolbarRepository.register('MovieSrcBox', {
                ariaLabel: t('Custom movie toolbar'),
                items: optionsCommand,
                getRelatedElement: getSelectedMoviesWidget,
                balloonClassName: 'ck-custom-fieldset ck-custom-fieldset-with-form'
            });
        }
    }
}

export function getSelectedMoviesWidget(selection) {
    const viewElement = selection.getSelectedElement();

    if (viewElement && isMoviesWidget(viewElement)) {
        return viewElement;
    }

    return null;
}


function isMoviesWidget(viewElement) {
    return viewElement.hasClass('media');
}

