import { TimeZone } from '@app/types';
import { createAction, props } from '@ngrx/store';


export const loadTimezones = createAction(
  '[LANGUAGES DICT] load Timezones'
);

export const getTimezonesRequest = createAction(
  '[LANGUAGES DICT] Get Timezones Request'
);

export const getTimezonesSuccess = createAction(
  '[LANGUAGES DICT] Get Timezones Success',
  props<{ timezones: TimeZone[]}>()
);

export const getTimezonesFailure = createAction(
  '[LANGUAGES DICT] Get Timezones Failure',
  props<{ error: any }>()
);

export const timezonesCollectionAddMany = createAction(
  '[LANGUAGES DICT] Timezones collection add many.',
  props<{ timezones: TimeZone[]}>()
);
