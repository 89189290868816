<ng-container [formGroup]="form">
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Title</h4>
      <div class="row">
        <div class="col-12 mt-5">
          <rds-form-field>
            <rds-control-label>Title of the Template</rds-control-label>
            <input formControlName="title" rdsInput required/>
            <rds-control-hint class="right">{{form.get('title').value?.length || 0}}/100</rds-control-hint>
            <rds-control-error *ngIf="form.get('title').hasError('required') && form.get('title').touched">This field is required</rds-control-error>
            <rds-control-error *ngIf="form.get('title').hasError('maxlength') && form.get('title').touched">Maximum number of characters exceeded</rds-control-error>
            <rds-control-error *ngIf="(form.get('title').hasError('required') || form.get('title').hasError('maxlength')) && form.get('title').touched" class="right">{{form.get('title').value?.length || 0}}/100</rds-control-error>
          </rds-form-field>
        </div>
        <!-- <div class="col-12 mt-5">
          <rds-switch formControlName="keepSync" checked>Same title as primary language</rds-switch>
        </div> -->
      </div>
      <rds-divider class="mt-5"></rds-divider>
    </div>
  </div>
  <div class="row">
    <div class="col mt-9">
      <h4 class="ui-heading-4">Primary language</h4>
      <p class="ui-label-m mb-5">Additional langauge versions can be added in Content section</p>
      <div class="row">
        <div class="col-4 mb-5">
          <rds-form-field
          ><rds-control-label>Primary language</rds-control-label>
          <rds-select
            #select
            formControlName="language"
            (valueChange)="select.resetFilterValue()"
            [filterBy]="filterBy"
          >
            <rds-select-search-input #searchInput></rds-select-search-input>
            <rds-select-option-separator></rds-select-option-separator>
            <rds-select-option *ngFor="let language of languages$ | async" [value]="language.code" [id]="language.name" [innerHTML]="language.name | highlight:searchInput.control.value"></rds-select-option>
          </rds-select>
        </rds-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>
