import { PeopleRolePickerModel } from "./people-role-picker.component";

export class RolePickerUtils {
  static getTemplateRoles(userRole): PeopleRolePickerModel {
    return {
      owners: {
        label: 'Owner',
        description: 'Can edit, manage permission and delete the template',
        canEdit: userRole === 'Owner',
      },
      editors: {
        label: 'Editor',
        description: 'Can edit the template',
        canEdit: userRole === 'Owner' || userRole === 'Editor',
      },
      viewers: {
        label: 'Viewer',
        description: 'Can use the template',
        canEdit: userRole === 'Owner' || userRole === 'Editor'
      }
    }
  }
}