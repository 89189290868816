<div class="card-header" #header (click)="showScroll()" [class.scrollable]="scrollable" (mouseleave)="hideScroll()">
<!-- <img src="assets/news-placeholder.png" alt=""> -->
<div class="preview bg-neutral-gradient-top-left">
  <img [src]="template.imagePreview" alt="">
  <!-- <rnb-news-content-preview
  *ngIf="!template.imagePreview"
  tabindex="0"
  [noBackground]="true"
  [languageName]="'English'"
  [language]="'en'"
  [news]="news"
>
</rnb-news-content-preview> -->
</div>
</div>
<div class="card-body" [class.attention-needed]="attentionNeeded" [class.selected]="selected">
  <h4 class="ui-heading-4--l">{{title}}</h4>
  <p class="ui-label-m"><span *ngIf="predefined">Use predefined layout and modify to your needs.</span>&nbsp;</p>
  <div class="actions">
    <ng-content></ng-content>
  </div>
</div>
