import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { RDS_DIALOG_DATA, RdsDialogRef, RdsDialogService } from '@rds/angular-components';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import Cropper from 'cropperjs';

export interface CropImageData {
  event?: Event,
  file?: File,
  aspectRatio?: number
}
@Component({
  selector: 'rnb-crop-image-dialog',
  templateUrl: './crop-image-dialog.component.html',
  styles: []
})
export class CropImageDialogComponent implements OnInit {
  @ViewChild('imageRef', {static: true}) imageRef: ElementRef<HTMLImageElement>;
  image: any;
  cropper: Cropper;
  private fileName = '';
  public dimensions: {height: number, width: number, size: number} = {height: null, width: null, size: null};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  
  keepAspectRatio = false;
  aspectRatio = 1 / 1;

  constructor(
    public dialogRef: RdsDialogRef<CropImageDialogComponent>,
    @Inject(RDS_DIALOG_DATA) data: CropImageData
  ) {
    if (!!data.aspectRatio) {
      this.keepAspectRatio = true;
      this.aspectRatio = data.aspectRatio;
    }
    const reader = new FileReader();
    const that = this;
    reader.onload = function(e) {
      that.image = e.target.result;
    }
    this.fileName = data.file.name;
    reader.readAsDataURL(data.file);

  }

  ngOnInit(): void {
  }

  imgLoaded(e) {
    this.cropper = new Cropper(this.imageRef.nativeElement, {
      dragMode: 'move',
      toggleDragModeOnDblclick: false,
      viewMode: 1,
      aspectRatio: this.aspectRatio,
      ready: (event) => {
        this.cropper.zoomTo(.01)
      },
    })
  }

  public async dataUrlToFile(dataUrl: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], this.fileName, {type: 'image/jpeg'});
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
  }

  confirm() {
    const cropped = this.cropper.getCroppedCanvas().toDataURL();
    
    this.dataUrlToFile(cropped).then((file => {
      this.dialogRef.close(file);
    }));
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.dataUrlToFile(this.croppedImage).then(data => {
      this.dimensions = {height: event.height, width: event.width, size: data.size};
      this.image = data;
    });
  }
}
