<div class="desktop-only">
  <rnb-header2></rnb-header2>
  <div class="container">
    <main class="main-container">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <!-- <rnb-page-fab></rnb-page-fab> -->
    </main>
  </div>

  <rnb-footer2></rnb-footer2>
</div>
<rnb-no-mobile class="mobile-only"></rnb-no-mobile>
