import { Plugin, icons } from 'ckeditor5/src/core';
import { WidgetToolbarRepository } from 'ckeditor5/src/widget';
import { isWidget } from 'ckeditor5/src/widget';
import { createNewButtons} from '../blocks/blocks-utils'


export default class ImageAndTextBoxToolbar extends Plugin {

	static get requires() {
		return [ WidgetToolbarRepository ];
	}

	static get pluginName() {
		return 'ImageAndTextBoxToolbar';
	}


	afterInit() {
		const editor = this.editor;
		const t = editor.t;
		const widgetToolbarRepository = editor.plugins.get( WidgetToolbarRepository );

		const options = [
			{
				name: 'imageAndTextLeft',
				label: 'Image on the left side',
				command: 'imageAndTextBoxAlignment',
				commandValue: 'left',
				type: 'action'
			},
			{
				name: 'imageAndTextRight',
				label: 'Image on the right side',
				command: 'imageAndTextBoxAlignment',
				commandValue: 'right',
				type: 'action'
			},
			{
				name: 'image',
				label: 'Image resize',
				command: 'customBoxResize',
				type: 'dropdown'
			}
		]
		createNewButtons(this, options);
		const optionsCommand = options.map(option => {
				if(option.commandValue){
					return `${option.command}:${option.commandValue}`;
				}else {
					return option.command;
				}
			});
		if ( options ) {
			widgetToolbarRepository.register( 'ImageAndTextContent', {
				ariaLabel: t( 'Image and text box toolbar' ),
				items: optionsCommand,
				getRelatedElement: getSelectedImageAndTextWidget,
				balloonClassName: 'ck-custom-fieldset-toolbar'
			} );
		}
	}
}

export function getSelectedImageAndTextWidget( selection ) {
	const viewElement = selection.getSelectedElement();

	if ( viewElement && isImageAndTextWidget( viewElement ) ) {
		return viewElement;
	}

	return null;
}

export function getImageAndTextWidgetAncestor( selection ) {
	const selectionPosition = selection.getFirstPosition();

	if ( !selectionPosition ) {
		return null;
	}

	let parent = selectionPosition.parent;

	while ( parent ) {
		if ( parent.is( 'element' ) && isImageAndTextWidget( parent ) ) {
			return parent;
		}

		parent = parent.parent;
	}

	return null;
}

function isImageAndTextWidget( viewElement ) {
	return !!viewElement.hasClass( 'image-and-text-box') && isWidget(viewElement);
}

