import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, distinctUntilChanged } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import * as fromTemplateForm from '../../../store/template-form';
import { FormControl, FormGroup } from '@angular/forms';
import { NewsForm } from '@app/types';
import { SubSink } from 'subsink';
import { AuthService, SimpleUser } from '@app/auth/auth.service';
import { CustomValidators } from '@app/utils/validators';
import { FormStepStatus } from '../../news-form/form-status-badge/form-status-badge.component';
import { TEMPLATE_ROLES, TemplateForm } from '@app/types/template';
import { PeopleRolePickerModel } from '@app/utils/custom-controls/people-role-picker/people-role-picker.component';
import { RolePickerUtils } from '@app/utils/custom-controls/people-role-picker/roles';

@Component({
  selector: 'rnb-template-form-assign-editors-and-owners',
  templateUrl: './template-form-assign-editors-and-owners.component.html',
  styleUrls: ['./template-form-assign-editors-and-owners.component.scss']
})
export class TemplateFormAssignEditorsAndOwnersComponent implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();
  TEMPLATE_ROLES = RolePickerUtils.getTemplateRoles('Owner');
  permissionsAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('permissions')))

  get userIdentifier() {
    return this.auth.currentSimpleUser.identifier;
  }

  get isSuperAdmin() {
    return this.auth.currentUser.isSuperAdmin;
  }

  _isActive: Array<boolean>;
  get isActive(): Array<boolean> {
      return this._isActive;
  }
  @Input() set isActive(value: Array<boolean>) {
      this._isActive = value;
      this.store$.dispatch(fromTemplateForm.setPermissionsFormStatus({status: this.form.status}));
      if (value) {
      this.store$.dispatch(fromTemplateForm.setPermissionsStepStatus({status: FormStepStatus.IN_PROGRESS}));
      } else {
        this.form.markAllAsTouched();
        if (this.form.valid) {
          this.store$.dispatch(fromTemplateForm.setPermissionsStepStatus({status: FormStepStatus.COMPLETED}));
        } else {
          this.store$.dispatch(fromTemplateForm.setPermissionsStepStatus({status: FormStepStatus.INCOMPLETE}));
        }
      }
  }

  allSuperAdmins$: Observable<Array<SimpleUser>> = this.store$.pipe(select(fromTemplateForm.selectAllSuperAdmins));
  public superAdminForm: FormGroup = new FormGroup({
    permissions: new FormControl({
      owners: [],
      viewers: [],
    }),
  });
  form$: Observable<Partial<TemplateForm>> = this.store$.pipe(select(fromTemplateForm.selectPermissionsForm));
  public form: FormGroup = new FormGroup({
    permissions: new FormControl({
      owners: [],
      editors: [],
      viewers: [],
    }, CustomValidators.validateTemplatePermissions),
    isPredefined: new FormControl(false)
  });

  constructor(
    private store$: Store<any>,
    private auth: AuthService
    ) {
    
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'permissions'}));
    if (this.isSuperAdmin) {
      this.store$.dispatch(fromTemplateForm.loadSuperAdminsRequest({currentUser: this.auth.currentSimpleUser}));
    }
    
    this.subs.sink = this.allSuperAdmins$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(users => {
      const allRoche: SimpleUser = {
        avatar: '/assets/roche-avatar.png',
        name: 'All users',
        email: null,
        identifier: this.auth.currentSimpleUser.identifier
      }
      this.superAdminForm.patchValue({permissions: {
        owners: users,
        viewers: [allRoche]
      }}, {emitEvent: false});
    });

    this.subs.sink = this.form$.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(template => {
      this.form.patchValue(template, {emitEvent: false});
      if (this.form.controls.isPredefined.value) {
        this.form.controls.permissions.clearValidators();
        this.form.updateValueAndValidity({emitEvent: false});
      }
      this.store$.dispatch(fromTemplateForm.setPermissionsFormStatus({status: this.form.status}));
    });

    this.subs.sink = this.form.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(form => {
      this.store$.dispatch(fromTemplateForm.setFormValue({form}))
    });

    this.form.controls.isPredefined.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300)
    ).subscribe(value => {
      if (value) {
        this.form.controls.permissions.clearValidators();
      } else {
        this.form.controls.permissions.addValidators(CustomValidators.validateTemplatePermissions);
      }
      this.form.updateValueAndValidity();
    });
    
    this.subs.sink = this.form.statusChanges.subscribe(value => {
      this.store$.dispatch(fromTemplateForm.setPermissionsFormStatus({status: value}));
    });
  }

  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
  }

}
