import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class AnnouncementBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change( writer => {
            const value = options.value;
            const box = createAnnouncementBox( writer, value );
            const image = createImage( writer, value );
            const description = createText( writer, value );
            writer.append(image, box);
            writer.append(description, box);
            model.insertContent(box );
            writer.setAttribute('data-image-side', value, box)
            writer.setAttribute('src', './assets/images/block-placeholder.png', image);
            writer.setSelection( box, 'on' );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = model.document.selection.getSelectedElement();
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'announcementBox');
        const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
        this.isEnabled = allowedIn !== null && !customBlockSelected;
    }
}
export function createImage(writer) {
    return  writer.createElement('customImageBox');
}
export function createAnnouncementBox(writer, side) {
   return writer.createElement('announcementBox');
}

export function createText(writer){
    const announcementBoxDescription = writer.createElement('announcementBoxDescription');
    const title = writer.createElement('heading-5-rds');
    const question = writer.createElement('heading-6-rds');
    const answer = writer.createElement('paragraph');
    writer.insertText('Meet John Doe', title)
    writer.insertText('Example: What is your proudest work Achievement?', question)
    writer.insertText('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut' +
        ' labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut' +
        ' aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore' +
        ' eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt' +
        ' mollit anim id est laborum', answer)
    writer.append(title, announcementBoxDescription);
    writer.append(question, announcementBoxDescription);
    writer.append(answer, announcementBoxDescription);
    writer.append(writer.cloneElement(question), announcementBoxDescription);
    writer.append(writer.cloneElement(answer), announcementBoxDescription);
    return announcementBoxDescription;
}
