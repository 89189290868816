export interface Pagination {
  isFirst: boolean;
  isLast: boolean;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  totalCount?: number;
}

export const Pagination: Pagination = {
  isFirst: true,
  isLast: false,
  pageCount: 0,
  pageIndex: 0,
  pageSize: 0,
  totalCount: 0
};
