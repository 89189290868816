import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromBackButton from "@app/root-store/ui/back-button";
import { Observable, map } from 'rxjs';

@Component({
  selector: 'rnb-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  @Input() leavePageWarning: boolean = false;
  @Input() changesMade: boolean = false;
  @Input() defaultLabel: string = '';
  @Input() defaultRoute: string = "../"

  backConfig$: Observable<any> = this.store$.pipe(
    select(fromBackButton.selectBackConfig),
    map(({label, routeBack, routeCurrent}) => ({
      label: !!label ? label : this.defaultLabel,
      routeCurrent: !!routeBack ? routeBack : this.defaultRoute,
    })));
  constructor(private store$: Store<any>) {

  }

  openLeavePageModal() {
    this.store$.dispatch(fromBackButton.openLeavePageDialog({defaultLabel: this.defaultLabel, defaultRoute: this.defaultRoute, changesMade: this.changesMade}));
  }

  back() {
    this.store$.dispatch(fromBackButton.back({defaultLabel: this.defaultLabel, defaultRoute: this.defaultRoute})); ;
  }
}
