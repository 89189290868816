<ng-container [formGroup]="form">
  <div class="row">
    <div class="col mt-9">
      <rds-accordion #accordion="rdsAccordion" (click)="focusAccordeon()">
        <rds-expansion-panel #panel *ngFor="let content of getArrayControlsInOrder; let i = index" [class.disable-expand]="panel.expanded" (expandedChange)="change(accordion, panel)" [formGroup]="content" [expanded]="i === 0" togglePosition="after" (closed)="content.markAsTouched()" >
          <rds-expansion-panel-header>
            <rds-panel-title> 
              <div class="language-version">
                <h4 class="ui-heading-4 c-600-roche-blue">{{content.get('language').value | languageName | async}} language version {{i === 0? '(primary)' : ''}}</h4>
                <button [disabled]="(primaryLanguage$ | async) === content.get('language').value" rds-icon-button size="m" (click)="deleteLanguageClick($event, content.get('language').value)">
                  <rds-icon namespace="outlined" icon="delete"></rds-icon>
                </button>
                <atr-form-status-badge 
                [status]="panel.expanded && languageFocused ? 'IN_PROGRESS' : 
                          !content.touched ? 'NOT_DEFINED' :
                          content.valid ? 'COMPLETED' : 'INCOMPLETE'"
                  ></atr-form-status-badge>
              </div>
            </rds-panel-title>
          </rds-expansion-panel-header>
          <div class="row">
            <div class="col mt-9 ps-10">
              <h4 class="ui-heading-4 mb-5">Abstract & thumbnail</h4>
              <div class="row">
                <div class="col-8 mt-3">
                  <div class="row">
                    <div class="col">
                      <rds-form-field>
                        <rds-control-label>Title of the News</rds-control-label>
                        <input rdsInput formControlName="title" required/>
                        <rds-control-hint class="right">{{content.get('title').value?.length || 0}}/100</rds-control-hint>
                        <rds-control-error *ngIf="content.get('title').hasError('required') && content.get('title').touched">This field is required</rds-control-error>
                        <rds-control-error *ngIf="content.get('title').hasError('maxlength') && content.get('title').touched">Maximum number of characters exceeded</rds-control-error>
                        <rds-control-error *ngIf="(content.get('title').hasError('required') || content.get('title').hasError('maxlength')) && content.get('title').touched" class="right">{{content.get('title').value?.length || 0}}/100</rds-control-error>
                      </rds-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col mt-5">
                      <rds-form-field>
                        <rds-control-label>Abstract</rds-control-label>
                        <textarea rdsInput formControlName="abstract" required></textarea>
                        <rds-control-hint class="right">{{content.get('abstract').value?.length || 0}}/200</rds-control-hint>
                        <rds-control-error *ngIf="content.get('abstract').hasError('required') && content.get('abstract').touched">This field is required</rds-control-error>
                        <rds-control-error *ngIf="content.get('abstract').hasError('maxlength') && content.get('abstract').touched">Maximum number of characters exceeded</rds-control-error>
                        <rds-control-error *ngIf="(content.get('abstract').hasError('required') || content.get('abstract').hasError('maxlength')) && content.get('abstract').touched" class="right">{{content.get('abstract').value?.length || 0}}/200</rds-control-error>
                      </rds-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p class="ui-label-m-bold">Thumbnail</p>
                      <rds-switch *ngIf="(primaryLanguage$ | async) !== content.get('language').value" formControlName="syncImage" (toggleChange)="syncImageChanged(content)">Use the thumbnail from the primary language</rds-switch>
                      <ng-container *ngIf="(primaryLanguage$ | async) === content.get('language').value || content.get('syncImage').value === false">
                        <p class="ui-label-m">Use media from enabled libraries like: branding.roche.com</p>
                        <rnb-upload [hasDropZone]="true" size="l" [id]="'thumbnail-' + content.get('language').value" formControlName="image" (uploadStarted)="uploadStarted($event)" (uploadFailed)="uploadFailed($event)" (uploadEnded)="uploadEnded($event)" (uploadAborted)="uploadAborted($event)">
                        </rnb-upload>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-4 mt=5">
                  <p class="ui-label-l-bold mb-3">News thumbnail preview</p>
                  <div class="card">
                    <rnb-news-card-item
                    id="new"
                    [title]="content.get('title').value || 'Title'"
                    [description]="content.get('abstract').value || 'Description'"
                    [imageUrl]="content.get('image').value?.url"
                    [creationDate]="now"
                    [likeEnabled]="true"
                    [likeCount]="0"
                    [hasBookmarked]="false"
                    [enableBookmark]="true"
                    [hasLiked]="false"
                    [url]="null"
                    [type]="'Content'"
                    [isLikingProcessingIds]="false"
                    [color]="'neutral'">
                  </rnb-news-card-item>
                  </div>
                </div>
              </div>
              <rds-divider class="mt-5"></rds-divider>
              <div class="row">
                <div class="col mt-9">
                  <h4 class="ui-heading-4">Add News body in editor</h4>
                  <p class="ui-label-m ">Please, do not create the news Title in this Editor. Only add the body of your article. For accessibility purposes please style the News using available tools as paragraph, headings, list structure and other enabled below. </p>
                  <div class="row">
                    <div class="col mt-5">
                      <rnb-editor formControlName="html" (uploadStarted)="uploadStarted($event)" (uploadFailed)="uploadFailed($event)" (uploadEnded)="uploadEnded($event)" (uploadAborted)="uploadAborted($event)">
                        <rds-control-error *ngIf="content.get('html').hasError('required')">This field is required</rds-control-error>
                      </rnb-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </rds-expansion-panel>
      </rds-accordion>
    </div>
  </div>
</ng-container>
