import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidgetEditable, Widget, toWidget} from '@ckeditor/ckeditor5-widget/src';
import SectionColorsBoxCommand from './section-colors-box-command';

export default class SectionColorsBoxEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {

        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add('insertSectionColorsBox', new SectionColorsBoxCommand(this.editor));
    }

    _defineSchema() {
        const schema = this.editor.model.schema;
        const options =   this.editor.config.get( 'sectionColorsBox.options' );
        schema.register('sectionColorsBox', {
            inheritAllFrom: '$blockObject',
            allowWhere: '$block',
            allowChildren: '$root'
        });
        schema.register('sectionColorsBoxContent', {
            // Behaves like a self-contained block object (e.g. a block image)
            // allowed in places where other blocks are allowed (e.g. directly in the root).
            allowIn: 'sectionColorsBox',
            inheritAllFrom: '$blockObject',
            allowContentOf: ['$root']
        });

        options.forEach( option => {
            schema.register(option.model, {
                allowIn: ['sectionColorsBox'],
                inheritAllFrom: '$blockObject',
                allowContentOf: ['$root']
            });

        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        const options =   this.editor.config.get( 'sectionColorsBox.options' );

        conversion.for('upcast').elementToElement({
            model: 'sectionColorsBox',
            view:  {
                name: 'div',
                classes: 'bg-class'
            },
        });
        conversion.for('dataDowncast').elementToElement({
            model: 'sectionColorsBox',
            view:  {
                name: 'div',
                classes: 'bg-class'
            },
        });

        conversion.for('editingDowncast').elementToElement({
            model: 'sectionColorsBox',
            view: (modelElement, {writer}) => {
                const label = this.editor.t('Section colors');
                const viewWrapper = writer.createContainerElement('div', {class: 'bg-class py-6 my-6'});
                writer.setCustomProperty('sectionColorsBox', true, viewWrapper);

                return toWidget(viewWrapper, writer, {label});
            }
        });

        options.forEach( option => {
            conversion.for('upcast').elementToElement({
                model: option.model,
                view: {
                    name: 'div',
                    classes: ['bg-class__content', option.model]
                },
            });
        })


        options.forEach( option => {
            conversion.for('dataDowncast').elementToElement({
                model: option.model,
                view: {
                    name: 'div',
                    classes: ['bg-class__content',option.model]
                }
            });
        })


        options.forEach( option => {
            conversion.for('editingDowncast').elementToElement({
                model: option.model,
                view: (modelElement, {writer: viewWriter}) => {

                    const div = viewWriter.createEditableElement('div', {class: 'bg-class__content '+ option.model });
                    return toWidgetEditable(div, viewWriter);
                }
            });
        })
    }
}
