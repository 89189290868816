export function ensureNoEndingSlash(url: string): string {
  if (url[url.length - 1] === '/') {
    return url.substring(0, url.length - 1);
  }
  return url;
}

export function ensureStartsWithSlash(path: string): string {
  if (path[0] !== '/') {
    return '/' + path;
  }
  return path;
}
