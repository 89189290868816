import { createSelector } from '@ngrx/store';

import * as fromUi from '../ui.reducer';
import * as fromScreen from './screen.reducer';

export const selectState = createSelector(
  fromUi.selectState,
  fromUi.getScreen
);

export const selectMobileSetting = createSelector(
  selectState,
  fromScreen.getMobileSetting
);

export const selectActualBreakpoint = createSelector(
  selectState,
  fromScreen.getActualBreakpoint
);

export const selectScreenMode = createSelector(
  selectState,
  fromScreen.getScreenMode
);

export const selectScreenOrientation = createSelector(
  selectState,
  fromScreen.getScreenOrientation
);

export const selectViewportWidth = createSelector(
  selectState,
  fromScreen.getViewportWidth
);

export const selectIsDesktop = createSelector(
  selectState,
  fromScreen.getIsDesktop
);
