import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { mergeMap, map, catchError, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromActions from './languages.actions';
import * as fromReducer from './languages.reducer';
import * as fromSlectors from './languages.selectors';
import { LanguageService } from '@app/utils/language.service';

@Injectable()
export class LanguagesEffects {

  public loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLanguages),
      switchMap(() => this.store$.pipe(
        select(fromSlectors.selectEntities),
        first(entities => this.isEmpty(entities)),
      )),
      mergeMap(() => [
        fromActions.getLanguagesRequest()
      ])
    ), { dispatch: true }
  );

  public getLanguagesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLanguagesRequest),
      mergeMap(() =>
        this.languageService.getLanguages().pipe(
          map((languages) => fromActions.getLanguagesSuccess({ languages })),
          catchError(({ message }) => of(fromActions.getLanguagesFailure({ error: message })))
        )
      )
    ), { dispatch: true }
  );

  public getLanguagesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLanguagesSuccess),
      mergeMap(({ languages }) => [
        fromActions.languagesCollectionAddMany({ languages }),
      ]),
    ), { dispatch: true }
  );

  private isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private languageService: LanguageService
  ) {}
}
