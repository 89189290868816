import { createSelector } from '@ngrx/store';

import * as fromChannel from '../channel.reducer';
import * as fromReducer from './channel-table.reducer';

export const selectState = createSelector(
  fromChannel.selectState,
  fromChannel.getChannelTable
);

// [MY EVENTS]
export const selectMyChannelsState = createSelector(
  selectState,
  fromReducer.getMyChannels
);

export const selectMyChannelsRequestData = createSelector(
  selectMyChannelsState,
  fromReducer.getRequestData
);

export const selectMyChannelsData = createSelector(
  selectMyChannelsState,
  fromReducer.getEventsData
);

// [MY EVENTS] Pagination
export const selectMyChannelsPagination = createSelector(
  selectMyChannelsState,
  fromReducer.getPagination
);

export const selectMyChannelsTotal = createSelector(
  selectMyChannelsPagination,
  fromReducer.getTotalCount
);

// [MY EVENTS] Sort
export const selectMyChannelsSort = createSelector(
  selectMyChannelsState,
  fromReducer.getSort
);

export const selectMyChannelsDefaultSort = createSelector(
  selectMyChannelsState,
  fromReducer.getDefaultSort
);

// [MY EVENTS] Filters
export const selectMyChannelsFilters = createSelector(
  selectMyChannelsState,
  fromReducer.getFilters
);

export const selectMyChannelsDefaultFilters = createSelector(
  selectMyChannelsState,
  fromReducer.getDefaultFilters
);

export const selectMyChannelsSearch = createSelector(
  selectMyChannelsFilters,
  fromReducer.getSearch
);

export const selectMyChannelsIsLoading = createSelector(
  selectMyChannelsState,
  fromReducer.getIsLoading
);


// [MY PAST EVENTS]
export const selectAllChannelsState = createSelector(
  selectState,
  fromReducer.getAllChannels
);

export const selectAllChannelsRequestData = createSelector(
  selectAllChannelsState,
  fromReducer.getRequestData
);

export const selectAllChannelsData = createSelector(
  selectAllChannelsState,
  fromReducer.getEventsData
);

// [MY PAST EVENTS] Pagination
export const selectAllChannelsPagination = createSelector(
  selectAllChannelsState,
  fromReducer.getPagination
);

export const selectAllChannelsTotal = createSelector(
  selectAllChannelsPagination,
  fromReducer.getTotalCount
);

// [MY PAST EVENTS] Sort
export const selectAllChannelsSort = createSelector(
  selectAllChannelsState,
  fromReducer.getSort
);

export const selectAllChannelsDefaultSort = createSelector(
  selectAllChannelsState,
  fromReducer.getDefaultSort
);

// [MY PAST EVENTS] Filters
export const selectAllChannelsFilters = createSelector(
  selectAllChannelsState,
  fromReducer.getFilters
);

export const selectAllChannelsDefaultFilters = createSelector(
  selectAllChannelsState,
  fromReducer.getDefaultFilters
);

export const selectAllChannelsSearch = createSelector(
  selectAllChannelsFilters,
  fromReducer.getSearch
);

export const selectAllChannelsIsLoading = createSelector(
  selectAllChannelsState,
  fromReducer.getIsLoading
);