import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';

import * as fromActions from '@app/root-store/dictionaries/locations-flat/locations-flat.actions';
import { TaxonomyFlat } from '@app/types';

export interface State extends EntityState<TaxonomyFlat> {}

export const adapter: EntityAdapter<TaxonomyFlat> =
  createEntityAdapter<TaxonomyFlat>({
    selectId: location => location.id
});

export const initialState: State = {
  ...adapter.getInitialState()
};

const locationsFlatReducer = createReducer(
  initialState,
  on(fromActions.locationsFlatCollectionAddMany, (state, {locationsFlat}) => (
    adapter.addMany(locationsFlat, state)
  )),
);

export function reducer(state: State | undefined, action: Action) {
  return locationsFlatReducer(state, action);
}

export const getIds = adapter.getSelectors().selectIds;
export const getEntities = adapter.getSelectors().selectEntities;
export const getAll = adapter.getSelectors().selectAll;
export const getAllActive = (taxonomies: Array<TaxonomyFlat>) => {
  const activeTaxo = taxonomies.filter(t => {
    const parent = taxonomies.find(p => !!t.parentId && p.id === t.parentId);
    return (t.isActive && parent && parent.isActive) || (t.isActive && t.parentId === null);
  });
  return activeTaxo;
};

export const getTotal = adapter.getSelectors().selectTotal;

export const getEntityById = (entities: Dictionary<TaxonomyFlat>, {id}) => entities[id];
export const getEntitiesById = (entities: Dictionary<TaxonomyFlat>, {ids}) => ids.map(id => entities[id]);
export const getRootId = (taxonomies: Array<TaxonomyFlat>) => taxonomies.filter((entity) => entity.parentId === null);

