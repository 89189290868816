<h3 rds-dialog-header class="ui-heading-3-bold">Add related news <span class="ui-heading-4">(optional, min. 2 news if provided)</span></h3>
<div rds-dialog-content>
    <rnb-table-filters [tableFilters]="filters$ | async" [defaultFilters]="defaultFilters$ | async" (filtersChanged)="filtersChanged($event)"></rnb-table-filters>
    <rnb-table
      #table
      [data]="news$ | async"
      [defaultSortActive]="(defaultSort$ | async).active"
      [defaultSortDirection]="(defaultSort$ | async).direction"
      [columns]="columns"
      [hasBulk]="true"
      [bulkPredicate]="bulkPredicate"
      [initialSelectionPredicate]="initialSelectionPredicate"
      [hasActions]="true"
      [pagination]="pagination$ | async"
      [pageSizeOptions]="[6]"
      [totalObs]="total$"
      [isLoading]="isLoading$ | async"
      [highlight]="search$ | async"
      (sortChanged)="sortChanged($event)"
      (paginationChanged)="paginationChanged($event)"
      (selectionChanged)="selectionChanged($event)"
      (linkClicked)="onLinkClick($event)"
      [filtersApplied]="((filters$ | async) | isSameObject: (defaultFilters$ | async)) === false"
      >
      <span bulkText>
        {{table.selectedCount}} News selected 
      </span>
      <ng-container bulkActions>
        <button rds-primary-button (click)="addRelated(table.selection)">Add as related</button>
      </ng-container>
      <span emptyTitle>
        No related news
      </span>
      <span emptySubtitle>
        Search by news title to add related news
      </span>
    </rnb-table>
</div>
<div rds-dialog-actions-container align="end">
    <ng-container *ngIf="selectedNews.length > 0">
      <p class="ui-label-m-bold me-5">
        {{selectedNews.length}} related news defined
      </p>
    </ng-container>
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Close</button>
</div>