// import { createAction, props } from '@ngrx/store';

import { createAction, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';
import { Pagination } from '@app/types/pagination';
import { News, ChannelDetails } from '@app/types';
import { NewsTableFilters } from '@app/news/store/news-table';
import { BackButton } from '@app/root-store/ui/back-button';

  
export const setChannelDetails = createAction(
  '[CHANNEL DETAILS] set channel details',
  props<{ details: ChannelDetails }>()
);

export const initAllNews = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Initialize News'
);

export const initNewsSuggestions = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Initialize News'
);

export const initArchivedNews = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Initialize News'
);

export const clearAll = createAction(
  '[CHANNEL DETAILS] Clear all'
);

export const getAllNewsRequest = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Get all news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getAllNewsSuccess = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Get all news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getAllNewsFailure = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Get all news Failure',
  props<{ error: any }>()
);


export const removeAllNewsRequest = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Remove all news Request',
  props<{ids: Array<number>}>()
);

export const removeAllNewsSuccess = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Remove all news Success',
  props<{count: number}>()
);

export const removeAllNewsFailure = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Remove all news Failure',
  props<{ error: any }>()
);

export const changeAllNewsFilters = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Change all news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeAllNewsSort = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Change all news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeAllNewsPagination = createAction(
  '[CHANNEL DETAILS - ALL NEWS TABLE] Change all news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);


export const getNewsSuggestionsRequest = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Get news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getNewsSuggestionsSuccess = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Get news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getNewsSuggestionsFailure = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Get news Failure',
  props<{ error: any }>()
);

export const changeNewsSuggestionsFilters = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Change news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeNewsSuggestionsSort = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Change news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeNewsSuggestionsPagination = createAction(
  '[CHANNEL DETAILS - NEWS SUGGESTIONS TABLE] Change news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);


export const getArchivedNewsRequest = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Get news Request',
  props<{
    pageIndex: number,
    pageSize: number,
    sort: {
      active: string;
      direction: SortDirection
    },
    filters: NewsTableFilters
  }>()
);

export const getArchivedNewsSuccess = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Get news Success',
  props<{ data: Array<Partial<News>>, pagination: Pagination }>()
);

export const getArchivedNewsFailure = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Get news Failure',
  props<{ error: any }>()
);


export const removeArchivedNewsRequest = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Remove news Request',
  props<{ids: Array<number>}>()
);

export const removeArchivedNewsSuccess = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Remove news Success',
  props<{count: number}>()
);

export const removeArchivedNewsFailure = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Remove news Failure',
  props<{ error: any }>()
);

export const changeArchivedNewsFilters = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Change news filters',
  props<{ filters: NewsTableFilters }>()
);

export const changeArchivedNewsSort = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Change news sort',
  props<{ active: string, direction: SortDirection }>()
);

export const changeArchivedNewsPagination = createAction(
  '[CHANNEL DETAILS - ARCHIVED NEWS TABLE] Change news pagination',
  props<{ pageIndex: number, pageSize: number }>()
);

// NEWS ACTIONS
export const pinNewsRequest = createAction(
  '[CHANNEL DETAILS] Pin news request',
  props<{newsId: number, isPinned: boolean}>()
);

export const pinNewsSuccess = createAction(
  '[CHANNEL DETAILS] Pin news success',
  props<{isPinned: boolean}>()
);

export const pinNewsFailure = createAction(
  '[CHANNEL DETAILS] Pin news failure',
  props<{ error: any }>()
);

export const openNewsPreview = createAction(
  '[CHANNEL DETAILS] Open news preview',
  props<{ newsId: number, backButton: BackButton}>()
);

export const getNewsDetailsRequest = createAction(
  '[CHANNEL DETAILS] get news details request',
  props<{newsId: number, isPinned: boolean}>()
);

export const getNewsDetailsSuccess = createAction(
  '[CHANNEL DETAILS] get news details success',
  props<{news: News}>()
);

export const getNewsDetailsFailure = createAction(
  '[CHANNEL DETAILS] get news details failure'
);

export const duplicateNewsRequest = createAction(
  '[CHANNEL DETAILS] Duplicate news request',
  props<{newsId: number}>()
);

export const duplicateNewsSuccess = createAction(
  '[CHANNEL DETAILS] Duplicate news success',
  props<{id: number}>()
);

export const duplicateNewsFailure = createAction(
  '[CHANNEL DETAILS] Duplicate news failure',
  props<{ error: any }>()
);

export const rejectNewsRequest = createAction(
  '[CHANNEL DETAILS] Reject news request',
  props<{newsId: number, reason: string}>()
);

export const rejectNewsSuccess = createAction(
  '[CHANNEL DETAILS] Reject news success',
);

export const rejectNewsFailure = createAction(
  '[CHANNEL DETAILS] Reject news failure',
  props<{ error: any }>()
);

export const acceptNewsRequest = createAction(
  '[CHANNEL DETAILS] Accept news request',
  props<{newsId: number}>()
);

export const acceptNewsSuccess = createAction(
  '[CHANNEL DETAILS] Accept news success',
);

export const acceptNewsFailure = createAction(
  '[CHANNEL DETAILS] Accept news failure',
  props<{ error: any }>()
);