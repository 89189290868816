import { createFeatureSelector, combineReducers, Action } from '@ngrx/store';

import * as fromFunctionsFlat from '@app/root-store/dictionaries/functions-flat/functions-flat.reducer';
import * as fromLocationsFlat from '@app/root-store/dictionaries/locations-flat/locations-flat.reducer';
import * as fromDepartmentsFlat from '@app/root-store/dictionaries/departments-flat/departments-flat.reducer';
import * as fromLanguages from '@app/root-store/dictionaries/languages/languages.reducer';
import * as fromTimezones from '@app/root-store/dictionaries/timezones/timezones.reducer';

export const feature = 'dictionaries';

export interface State {
  departmentsFlat: fromDepartmentsFlat.State;
  functionsFlat: fromFunctionsFlat.State;
  locationsFlat: fromLocationsFlat.State;
  languages: fromLanguages.State;
  timezones: fromTimezones.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    departmentsFlat: fromDepartmentsFlat.reducer,
    functionsFlat: fromFunctionsFlat.reducer,
    locationsFlat: fromLocationsFlat.reducer,
    languages: fromLanguages.reducer,
    timezones: fromTimezones.reducer,
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);

export const getDepartmentsFlat = (state: State) => state.departmentsFlat;
export const getFunctionsFlat = (state: State) => state.functionsFlat;
export const getLocationsFlat = (state: State) => state.locationsFlat;
export const getLanguages = (state: State) => state.languages;
export const getTimezones = (state: State) => state.timezones;
