import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ensureNoEndingSlash, ensureStartsWithSlash } from '../api-client.utils';

import { User, SimpleUser, UserLogin, UserRole } from '../types/user';

declare const gapi: any;

const APP_STORAGE_KEY = 'newsboard_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authState = new ReplaySubject<boolean>(1);

  get isAuthenticated(): Observable<boolean> {
    return this._authState.asObservable();
  }

  private _accessToken: string;
  private _accessTokenExpiry: number;

  get accessToken(): string {
    // get token from storage if present
    if (this._accessToken === undefined) {
      this._accessToken = localStorage.getItem(APP_STORAGE_KEY) || null;
    }
    // make sure token is not expired
    if (this._accessToken && this._accessTokenExpiry && this._accessTokenExpiry < Date.now()) {
      this.invalidateToken();
    }
    // return token
    return this._accessToken;
  }

  currentUser: UserLogin;

  get currentSimpleUser(): SimpleUser {
    return {
      identifier: this.currentUser.identifier,
      name: this.currentUser.name,
      email: this.currentUser.email,
      avatar: this.currentUser.avatar
    };
  }

  redirectUrl: string;

  constructor(private router: Router, private http: HttpClient) {
    this.initialize();
  }

  private initialize(): void {
    this.validate(this.accessToken);
  }

  signIn(idToken: string): Observable<boolean> {
    return new Observable((obs) => {
      // try to sign in to backend, by posting google id token
      const url = `${ensureNoEndingSlash(environment.apiBase)}${ensureStartsWithSlash('auth')}`;
      this.http
        .post(url, { idToken }, { withCredentials: true })
        .pipe(
          catchError((err) => {
            // backend communication failure (or invalid token), return empty response (which means validation will fail and failure status will be returned)
            return of({});
          })
        )
        .subscribe((resp: { jwt: string }) => {
          // sign in successfull, check for proper jwt
          const jwt = resp.jwt;
          if (this.validate(jwt)) {
            // update current token
            this.updateToken(jwt);
            // return success
            obs.next(true);
            obs.complete();
          }
          // invalid token return failure
          obs.next(false);
          obs.complete();
        });
    });
  }

  private validate(token: string): boolean {
    // parse token
    const payload = this.parseToken(token);
    // validate payload
    const isValid = this.isValidPayload(payload);
    if (isValid) {
      // update user instance
      this.currentUser = {
        identifier: payload.identifier,
        name: payload.name,
        email: payload.email,
        avatar: payload.avatar,
        locale: payload.locale,
        isAdmin: ['Admin', 'SuperAdmin'].includes(payload.role),
        isSuperAdmin: payload.role === 'SuperAdmin'
      };
      // update expiry
      this._accessTokenExpiry = payload.exp * 1000;
    }
    // update authentication state
    this._authState.next(isValid);
    // return current state
    return isValid;
  }

  private updateToken(jwt: string): void {
    // update local storage and instance
    this._accessToken = jwt;
    localStorage.setItem(APP_STORAGE_KEY, jwt);
  }

  invalidateToken(): void {
    // remove token from storage and instance (and also reset expiry)
    this._accessToken = null;
    this._accessTokenExpiry = 0;
    localStorage.removeItem(APP_STORAGE_KEY);
    // disable authentication state
    this._authState.next(false);
  }

  private parseToken(jwt: string): JwtPayload {
    if (jwt && jwt.length) {
      // try to parse
      try {
        const payload = this.parseJwt(jwt);
        return payload;
      } catch (e) {
        console.error('invalid JWT: ', jwt, e);
      }
    }
    // no valid token found
    return null;
  }

  private isValidPayload(payload: JwtPayload): boolean {
    // check if payload is present
    if (!payload) {
      console.warn('JWT not found!');
      return false;
    }
    // get current date in seconds
    const now = Math.ceil(Date.now() / 1000);
    // check jwt not-valid-before
    if (payload.nbf > now) {
      console.warn('JWT not valid yet!', payload.nbf, now);
      // return false; // remove check for now till we can figure out mismatch
    }
    // check jwt expiration
    if (payload.exp <= now) {
      console.warn('JWT expired!', payload.nbf, now);
      return false;
    }
    // all checks complete -> valid token
    return true;
  }

  private parseJwt(token: string): any {
    // unicode safe JWT parser
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

interface JwtPayload {
  // custom payload properties
  nameid: string;
  identifier: string;
  name: string;
  email: string;
  role: string;
  avatar: string;
  locale: string;
  // jwt standard properties
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
}

export { User, UserLogin, SimpleUser, UserRole };
