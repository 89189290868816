import { Component, Input } from '@angular/core';
import { SimpleUser } from '@app/types';

@Component({
  selector: 'rnb-user-single',
  templateUrl: './user-single.component.html',
  styles: [
  ]
})
export class UserSingleComponent {
  @Input() user: SimpleUser;
}
