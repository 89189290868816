import { createAction, props } from '@ngrx/store';
import { ScreenModeEnum, ScreenOrientationEnum } from 'src/app/types/screen';

export const openLeavePageDialog = createAction(
  '[BACK BUTTON] Open Leave Page dialog',
  props<{
    defaultLabel: string,
    defaultRoute: string
    changesMade: boolean
  }>()
);

export const back = createAction(
  '[BACK BUTTON] Back',
  props<{
    defaultLabel: string,
    defaultRoute: string
  }>()
);

export const set = createAction(
  '[BACK BUTTON] Set',
  props<{
    label: string,
    routeBack: string,
    routeCurrent: string | Array<string>,
    routeBackQueryParams?: {[key: string]: string}
  }>()
);

export const clear = createAction(
  '[BACK BUTTON] Clear',
);

export const setAddNewsMiddleRoute = createAction(
  '[BACK BUTTON] Set add news middle route',
  props<{routes: Array<{
    label: string,
    route: string
  }>}>()
);
