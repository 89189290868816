import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'rnb-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @ViewChild('headerRef', { static: true}) headerRef!: ElementRef<HTMLElement>;
  @ViewChild('titleRef', { static: true}) titleRef!: ElementRef<HTMLElement>;
  @ViewChild('descriptionRef', { static: true}) descriptionRef!: ElementRef<HTMLElement>;

  @Input() disableAnimation: boolean = false;
  @Input() forceHoverState: boolean = false;
  
  get animatedCard() {
    return this.hasHeader && this.hasTitle && this.hasDescription;
  }

  get hasHeader() {
    return this.headerRef.nativeElement.childNodes.length > 0;
  }
  get hasTitle() {
    return this.titleRef.nativeElement.childNodes.length > 0;
  }
  get hasDescription() {
    return this.descriptionRef.nativeElement.childNodes.length > 0;
  }

  constructor() { }
}
