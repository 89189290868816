import { Component, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import * as fromRouter from '@app/root-store/router';
import * as fromScreen from '@app/root-store/ui/screen';
import * as fromNews from '../../../store/news-table';
import { RdsBreadcrumbItem, RdsTabsComponent } from '@rds/angular-components';
import { ChannelDetails } from '@app/types';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'rnb-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('tabs') tabs: RdsTabsComponent;

  get isAdmin(): boolean {
    return this.auth.currentUser.isAdmin;
  }

  get isSuperAdmin(): boolean {
    return this.auth.currentUser.isSuperAdmin;
  }

  breadcrumbs: Array<RdsBreadcrumbItem> = [
    { text: 'NewsBoard', title: '/' },
    { text: 'My News' },
  ];

  activeTable: 'my' | 'suggested' | 'archived' | 'all' = 'my';

  selectIsDesktop$: Observable<boolean> = this.store$.pipe(
    select(fromScreen.selectIsDesktop),
  );

  constructor(
    private store$: Store<fromScreen.State>,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
  }

  ngOnInit() {
    this.store$.dispatch(fromNews.initAllNews());
    this.store$.dispatch(fromNews.initMyNews());
    this.store$.dispatch(fromNews.initMySuggestedNews());
    this.store$.dispatch(fromNews.initMyArchivedNews());
  }

  ngAfterViewInit(): void {
    this.activeTable = this.route.snapshot.firstChild.data.table;
    var tabIndex = 0;
    switch ( this.activeTable) {
      case 'my': {
        tabIndex = 0;
        break;
      }
      case 'suggested': {
        tabIndex = 1;
        break;
      }
      case 'archived': {
        tabIndex = 2;
        break;
      }
      case 'all': {
        tabIndex = 3;
        break;
      }
    }
    this.tabs.selectTab(tabIndex)
}

  ngOnDestroy(): void {
      this.store$.dispatch(fromNews.clearAll());
  }


  tabsClicked(tabs: RdsTabsComponent) {
    switch ( tabs.selectedIndex) {
      case 0: {
        this.store$.dispatch(fromRouter.go({path: `news/my`, queryParams: {}}));
        break;
      }
      case 1: {
        this.store$.dispatch(fromRouter.go({path: `news/suggested`, queryParams: {}}));
        break;
      }
      case 2: {
        this.store$.dispatch(fromRouter.go({path: `news/archived`, queryParams: {}}));
        break;
      }
      case 3: {
        this.store$.dispatch(fromRouter.go({path: `news/all`, queryParams: {}}));
        break;
      }
    }
  }


  breadcrumbItemClicked(event) {
    if (event.title) {
      this.store$.dispatch(fromRouter.go({path: event.title, queryParams: {}}));
    }
  }
}
