import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

import * as fromDetails from './store/channel-details';
import * as fromForm from './store/form';

import { ChannelService, ChannelBase } from './channel.service';
import { AuthService, SimpleUser } from '@app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChannelResolver implements Resolve<boolean> {
  constructor(private service: ChannelService, private router: Router, private store$: Store<any>, private auth: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id: number = +route.params.id || +route.parent.params.id;
    return this.store$.pipe(
      select(fromDetails.selectChannelDetails),
      switchMap(channel => {
        if (!!channel && id === channel.id) {
          if (route.data.mode === 'edit' && channel.role === 'Publisher') {
            this.router.navigate([`/channel/${id}/info`]);
          } else {
            if (route.data.mode === 'edit' || route.data.mode === 'info') {
              let user: SimpleUser = this.auth.currentSimpleUser;
              this.store$.dispatch(fromForm.initAddForm({user, channel}))
            }
            return of(true);
          }
        } else {
          return this.service.get(id).pipe(
            tap((fetchedChannel) => {
              if (route.data.mode === 'edit' && fetchedChannel.role === 'Publisher') {
                this.router.navigate([`/channel/${id}/info`]);
                return of(true);
              }
            }),
            map((fetchedChannel) => {
              this.store$.dispatch(fromDetails.setChannelDetails({details: fetchedChannel}))
              if (route.data.mode === 'edit' || route.data.mode === 'info') {
                let user: SimpleUser = this.auth.currentSimpleUser;
                this.store$.dispatch(fromForm.initAddForm({user, channel: fetchedChannel}))
              }
              return true
            }),
            catchError((err, obs) => {
              this.router.navigate(['/channels']);
              return of(false);
            }))
        }
      } )
    );
  }
}
