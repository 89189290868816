<div class="login-container">
  <div class="login shadow-strong">
    <div class="header">
      <h1>NewsBoard Login</h1>
    </div>
    <div id="gapi-signin"></div>
    <div class="error" *ngIf="error">
      <div class="error-header"><rhd-icon icon="warning-triangule"></rhd-icon> Error</div>
      <p>{{ error }}</p>
    </div>
  </div>
</div>
