import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class ImagesAndTextBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options.value;
            const box = createImagesAndTextBox(writer);
            const image = createImage(writer);
            const image2 = createImage(writer);
            const text = createText(writer);

            writer.append(image, box);
            writer.append(text, box);
            writer.append(image2, box);
            model.insertContent(box);

            writer.setAttribute('float', 'left', image2)
            writer.setAttribute('src', './assets/images/block-placeholder.png', image2);
            writer.setAttribute('width', '100%', image2);

            writer.setAttribute('float', 'right', image)
            writer.setAttribute('src', './assets/images/block-placeholder.png', image);
            writer.setAttribute('width', '100%', image);
            writer.setSelection(box, 'on');
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = model.document.selection.getSelectedElement();
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'imagesAndTextBox');
        const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
        this.isEnabled = allowedIn !== null && !customBlockSelected;
    }
}

export function createImagesAndTextBox(writer) {
    return writer.createElement('imagesAndTextBox');
}
export function createImage(writer) {
    return writer.createElement('customImageBox');
}

export function createText(writer) {

    const div = writer.createElement('imagesAndTextBoxText');
    const paragraph = writer.createElement('paragraph');
    writer.insertText('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut' +
        ' labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut' +
        ' aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore' +
        ' eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident', paragraph);
    writer.append(paragraph, div)
    return div;
}
