import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date, ...args: string[]): string {
    const date = new Date(value);

    if (!date || !date.getTime) {
      return '';
    }

    const classification = [47347200, 47260800, 27561600, 3888000, 2160000, 126000, 75600, 5340, 2640, 89, 44];
    const strings = [
      '{y} years ago',
      'a year ago',
      '{M} months ago',
      'a month ago',
      '{d} days ago',
      'a day ago',
      '{h} hours ago',
      'an hour ago',
      '{m} minutes ago',
      'a minute ago',
      'a few seconds ago'
    ];

    const val = new Date().getTime() / 1000 - date.getTime() / 1000;

    for (let i = classification.length - 1; i > -1; i--) {
      if (val <= classification[i]) {
        return this.parseTimeString(strings[i], val);
      }
    }
    return this.parseTimeString(strings[0], val);
  }

  private parseTimeString(str: string, val: number) {
    if (str.indexOf('{y}') !== -1) {
      return str.replace('{y}', String(Math.round(val / 31536000)));
    }
    if (str.indexOf('{M}') !== -1) {
      return str.replace('{M}', String(Math.round(val / 2592000)));
    }
    if (str.indexOf('{d}') !== -1) {
      return str.replace('{d}', String(Math.ceil(val / 86400)));
    }
    if (str.indexOf('{h}') !== -1) {
      return str.replace('{h}', String(Math.ceil(val / 3600)));
    }
    if (str.indexOf('{m}') !== -1) {
      return str.replace('{m}', String(Math.ceil(val / 60)));
    }
    return str;
  }
}
