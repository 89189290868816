import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'isSameObject'
})
export class IsSameObjectPipe implements PipeTransform {
  transform(value: any, compare: any): any {
    const v = {...value};
    const c = {...compare};
    return !!v && !!c ? JSON.stringify(v) === JSON.stringify(c) : null
  }
}
