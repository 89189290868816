import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ImagesBoxUi from './images-box-ui';
import ImagesBoxEditing from './images-box-editing';
import CustomBoxResizeUi from "../blocks/custom-box-resize-ui";
import CustomImageAlternativeTextUI from "../blocks/custom-image-alternative-text-ui";
export default class ImagesBox extends Plugin {
  static get requires() {
      return [ ImagesBoxEditing, ImagesBoxUi, CustomBoxResizeUi, CustomImageAlternativeTextUI ];
  }
}
