import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/auth.service';
import { Autocomplete } from '@app/root-store/suggestions';
import { Template } from '@app/types/template';
import { RolePickerUtils } from '@app/utils/custom-controls/people-role-picker/roles';
import { CustomValidators } from '@app/utils/validators';
import { Store, select } from '@ngrx/store';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { Observable, debounceTime } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';

export interface SendTemplateCopyDialogData {
  template: Template
}
@Component({
  selector: 'atr-send-template-copy-dialog',
  templateUrl: './send-template-copy-dialog.component.html',
  styleUrls: ['./send-template-copy-dialog.component.scss']
})
export class SendTemplateCopyDialogComponent {
  autocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('send-copy')))

  currentUser = this.auth.currentSimpleUser;

  public form: FormGroup = new FormGroup({
    users: new FormControl([], CustomValidators.notEmptyList)
  });

  constructor(@Inject(RDS_DIALOG_DATA) public data: SendTemplateCopyDialogData, private auth: AuthService, private store$: Store<any>) {
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'send-copy'}));
   }
   
  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
  }
}
