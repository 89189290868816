import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  private checkLogin(url: string): Observable<boolean> {
    return new Observable((observer) => {
      // need to return false after some time in case we stall
      const fallback = setTimeout(() => {
        this.redirectToLogin(url);
        observer.next(false);
        observer.complete();
      }, 500);
      // actual handler
      this.auth.isAuthenticated.subscribe((state) => {
        clearTimeout(fallback);
        if (!state || !this.auth.currentUser.identifier) {
          this.redirectToLogin(url);
        }
        observer.next(state);
        observer.complete();
      });
    });
  }

  private redirectToLogin(returnUrl: string) {
    this.auth.redirectUrl = returnUrl;
    this.router.navigate(['/login']);
  }
}
