import { Directive, HostListener, Input } from '@angular/core';
import * as fromBackButton from '@app/root-store/ui/back-button';
import { Store } from '@ngrx/store';
@Directive({
  selector: '[rnbBackButtonSet]'
})
export class BackButtonSetDirective {
  @Input() label: string;
  @Input() routeCurrent: string;
  @Input() routeBack: string;
  @Input() routeBackQueryParams: {[key: string]: string} = null;

  @HostListener('click') setBackButton() {
    this.store$.dispatch(fromBackButton.set({
      label: this.label,
      routeBack: this.routeBack,
      routeCurrent: this.routeCurrent,
      routeBackQueryParams: this.routeBackQueryParams
    }));
  }
  constructor(private store$: Store<any>) { }

}
