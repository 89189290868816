import { createReducer, Action, on} from '@ngrx/store';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromActions from './api-keys.actions';

import { SortDirection } from '@rds/angular-components';
import { Pagination } from '@app/types/pagination';
import { ApiKey } from '@app/types';

export const feature = 'details';

export interface TableState {
  data: EntityState<Partial<ApiKey>>,
  pagination: Pagination,
  sort: {
    active: string;
    direction: SortDirection
  },
  isLoading: boolean;
  resetIndex: boolean;
}


export const adapter: EntityAdapter<Partial<ApiKey>> =
 createEntityAdapter<Partial<ApiKey>>({
  selectId: (e) => e.id
 });

export interface State {
  apiKeys: TableState;
}

export const initialState: State = {
  apiKeys: {
    data: adapter.getInitialState(),
    pagination: {
      ...Pagination,
      pageIndex: 0,
      pageSize: 10,
    },
    sort: {
      active: 'createdDate',
      direction: 'desc'
    },
    isLoading: false,
    resetIndex: false,
  },
};

const tableReducer = createReducer(
  initialState,
  on(fromActions.getApiKeysRequest, (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      isLoading: true
    }
  })),
  on(fromActions.getApiKeysSuccess, fromActions.getApiKeysFailure, (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      isLoading: false
    }
  })),
  on(fromActions.getApiKeysSuccess, (state, {data, pagination}) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      data: adapter.setAll(data, state.apiKeys.data),
      pagination,
      resetIndex: false,
    }
  })),
  on(fromActions.deleteApiKeysSuccess, fromActions.addApiKeysSuccess, (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      resetIndex: true
    }
  })),
  on(fromActions.changeApiKeysSort, (state, {active, direction}) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      sort: {
        active,
        direction
      }
    }
  })),
  on(fromActions.changeApiKeysPagination, (state, {pageIndex, pageSize}) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      pagination: {
        ...state.apiKeys.pagination,
        pageIndex,
        pageSize
      }
    }
  })),
 
  on(fromActions.clearAll, (state) => ({
    ...initialState
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return tableReducer(state, action);
}


export const getApiKeys = (state: State) => state.apiKeys;

export const getPagination = (table: TableState) => table.pagination;
export const getSort = (table: TableState) => table.sort;
export const getIsLoading = (table: TableState) => table.isLoading;

export const getRequestData = ({pagination, sort, resetIndex}: TableState) => ({
  pageIndex: pagination.pageIndex,
  pageSize: pagination.pageSize,
  sort,
  resetIndex});

export const getEventsData = (table: TableState) => adapter.getSelectors().selectAll(table.data);

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields

export const getEntitiesByIds = (entities: Dictionary<ApiKey>, ids: string[]): ApiKey[] => ids.map(id => entities[id]);
