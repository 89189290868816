import { Component } from '@angular/core';

@Component({
  selector: 'rnb-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  title = 'news-board';
}
