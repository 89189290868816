import { Pipe, PipeTransform } from '@angular/core';
import { DropdownFlatNode } from './models';

@Pipe({
  name: 'dropdownKey'
})
export class DropdownKeyPipe implements PipeTransform {

  transform(value: DropdownFlatNode, key: string): string {
    return (value as any)[key as string];
  }

}
