import { SimpleUser, UserRole } from './user';
import { Taxonomy } from './taxonomy';

export interface ChannelBase {
  id: number;
  name: string;
}

export interface ChannelAssignment extends ChannelBase {
  assignedBy: SimpleUser
  assignmentStatus: 'None' | 'Approved';
}

export interface Channel extends ChannelBase {
  description: string;
  allowAutoAssign?: boolean;
  role?: 'Owner' | 'Manager' | 'Publisher' | 'Admin';
  owners: Array<SimpleUser>;
  managers: Array<SimpleUser>;
  publishers: Array<SimpleUser>;
}

export interface ChannelDetails extends Channel {
  owners: Array<UserRole>;
  managers: Array<UserRole>;
  publishers: Array<UserRole>;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
}

export interface ChannelForm {
  id?: number;
  name: string;
  description: string;
  allowAutoAssign: boolean;
  permissions: {
    owners: Array<SimpleUser>;
    managers: Array<SimpleUser>;
    publishers: Array<SimpleUser>;
  };
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
  role?: string
}

export interface ChannelCreation {
  id?: number;
  name: string;
  description: string;
  allowAutoAssign: boolean;
  owners: Array<SimpleUser>;
  managers: Array<SimpleUser>;
  publishers: Array<SimpleUser>;
  locations: Array<string>;
  departments: Array<string>;
  functions: Array<string>;
  role?: string
}

export interface ChannelListRequest {
  offset?: number;
  count?: number;
  sortBy?: string;
  order?: string;
  query?: string;
  roles?: Array<string>;
  showAll?: boolean;
  // remove
  role?: string;
}

export const INITIAL_CHANNEL_LIST_REQUEST: ChannelListRequest = {
  offset: 0,
  count: 10,
  sortBy: null,
  order: null,
  query: null,
  roles: [],
  showAll: false
}

export const EMPTY_CHANNEL_FORM: ChannelForm = {
  id: null,
  name: '',
  description: '',
  allowAutoAssign: false,
  permissions: {
    owners: [],
    managers: [],
    publishers: [],
  },
  locations: [],
  departments: [],
  functions: [],
  role: ''
}