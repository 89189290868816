import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth/auth.service';

import { environment } from '../environments/environment';
import { ensureNoEndingSlash, ensureStartsWithSlash } from './api-client.utils';

@Injectable({
  providedIn: 'root'
})
export class ApiClient {
  private apiBase: string;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.apiBase = ensureNoEndingSlash(environment.apiBase);
  }

  get(path: string): Observable<any> {
    const url = this.constructUrl(path);
    const opts = this.getDefaultRequestOptions();
    return this.http.get(url, opts).pipe(catchError((err) => this.handleError(err)));
  }

  put(path: string, model: any): Observable<any> {
    const url = this.constructUrl(path);
    const opts = this.getDefaultRequestOptions();
    return this.http.put(url, model, opts).pipe(catchError((err) => this.handleError(err)));
  }

  post(path: string, model: any): Observable<any> {
    const url = this.constructUrl(path);
    const opts = this.getDefaultRequestOptions();
    return this.http.post(url, model, opts).pipe(catchError((err) => this.handleError(err)));
  }

  postFile(path: string, file: File, reportProgress = false): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = this.constructUrl(path);
    let  opts = this.getDefaultRequestOptions();
    opts = {
      ...opts,
      reportProgress,
      observe: reportProgress? 'events' : 'body',
    }
    return this.http.post(url, formData, opts).pipe(catchError((err) => this.handleError(err)));
  }

  delete(path: string, model?: any): Observable<any> {
    const url = this.constructUrl(path);
    const opts = this.getDefaultRequestOptions(model);
    return this.http.delete(url, opts).pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(error: any): Observable<any> {
    // make sure we have error information
    if (error && error.status) {
      // detect 401 unauthorized
      if (error.status === 401) {
        // invalidate current token
        this.auth.invalidateToken();
      }
    }
    throw error;
    return of(null);
  }

  private constructUrl(path: string): string {
    return `${this.apiBase}${ensureStartsWithSlash(path)}`;
  }

  private getDefaultRequestOptions(body?: any): { [key: string]: any } {
    return {
      headers: { Authorization: `Bearer ${this.auth.accessToken}` },
      body
    };
  }
}
