// import { createAction, props } from '@ngrx/store';

import { BackButton } from '@app/root-store/ui/back-button';
import { SimpleUser } from '@app/types';
import { Pagination } from '@app/types/pagination';
import { Template, TemplateUpdate, TemplatesListType, TemplatesRefresh } from '@app/types/template';
import { createAction, props } from '@ngrx/store';

export const initTemplates = createAction(
  '[TEMPLATES] Init templates',
);

export const saveTemplate = createAction(
  '[TEMPLATES] Save template',
  props<{template: Template}>()
);

export const useTemplate = createAction(
  '[TEMPLATES] Use template',
  props<{id: number, listType: TemplatesListType}>()
);

export const openTemplatePreview = createAction(
  '[TEMPLATES] Open preview',
  props<{id: number, backButton: BackButton}>()
);

export const clear = createAction(
  '[TEMPLATES] Clear'
);

export const incrementTotalCount = createAction(
  '[TEMPLATES] Increment total count',
  props<{listType: TemplatesListType, by: number}>()
)

export const getMy = createAction(
  '[TEMPLATES] Get my ',
  props<{refresh?: TemplatesRefresh}>()
);

export const getShared = createAction(
  '[TEMPLATES] Get shared ',
  props<{refresh?: TemplatesRefresh}>()
);

export const getReadonly = createAction(
  '[TEMPLATES] Get shared readonly',
  props<{refresh?: TemplatesRefresh}>()
);

export const getPredefined = createAction(
  '[TEMPLATES] Get predefined ',
  props<{refresh?: TemplatesRefresh}>()
);

export const getListRequest = createAction(
  '[TEMPLATES] Get list request',
  props<{listType: TemplatesListType, refresh?: TemplatesRefresh, pagination: Pagination}>()
);

export const getListSuccess = createAction(
  '[TEMPLATES] Get list success',
  props<{listType: TemplatesListType, data: Array<Template>, pagination: Pagination, refresh?: TemplatesRefresh}>()
);

export const getListFailure = createAction(
  '[TEMPLATES] Get list failure',
  props<{ listType: TemplatesListType, error: any }>()
);

export const deleteTemplateRequest = createAction(
  '[TEMPLATES] Delete template request',
  props<{id: number, listType: TemplatesListType}>()
);

export const deleteTemplateSuccess = createAction(
  '[TEMPLATES] Delete template success',
  props<{id: number, listType: TemplatesListType}>()
);

export const deleteTemplateFailure = createAction(
  '[TEMPLATES] Delete template failure',
  props<{ error: any }>()
);

export const loadMore = createAction(
  '[TEMPLATES] load more',
  props<{listType: TemplatesListType}>()
);

export const openDeleteTemplateDialog = createAction(
  '[TEMPLATES] Open delete template dialog',
  props<{template: Template, listType: TemplatesListType}>()
);

export const openShareTemplateDialog = createAction(
  '[TEMPLATES] Open share template dialog',
  props<{template: Template, listType: TemplatesListType}>()
);

export const openSendTemplateCopyDialog = createAction(
  '[TEMPLATES] Open send template copy dialog',
  props<{template: Template}>()
);

export const openRenameTemplateDialog = createAction(
  '[TEMPLATES] Open rename template dialog',
  props<{template: Template, listType: TemplatesListType}>()
);

export const updateTemplateRequest = createAction(
  '[TEMPLATES] Update template request',
  props<{id: number, templateUpdate: Partial<TemplateUpdate>, updateContext: string, listType: TemplatesListType, listsToRefresh: Array<{list: TemplatesListType, refresh: TemplatesRefresh}>}>()
);

export const updateTemplateSuccess = createAction(
  '[TEMPLATES] Update template success',
  props<{updateContext: string, listType: TemplatesListType, listsToRefresh: Array<{list: TemplatesListType, refresh: TemplatesRefresh}>}>()
);

export const updateTemplateFailure = createAction(
  '[TEMPLATES] Update template failure',
  props<{ error: any }>()
);

export const sendTemplateCopyRequest = createAction(
  '[TEMPLATES] Send template copy request',
  props<{id: number, users: Array<SimpleUser>}>()
);

export const sendTemplateCopySuccess = createAction(
  '[TEMPLATES] Send template copy success',
);

export const sendTemplateCopyFailure = createAction(
  '[TEMPLATES] Send template copy failure',
  props<{ error: any }>()
);

export const getSentToMeRequest = createAction(
  '[TEMPLATES] Get sent to me request',
  props<{refresh?: TemplatesRefresh}>()
);

export const getSentToMeSuccess = createAction(
  '[TEMPLATES] Get sent to me success',
  props<{data: Array<Template>, pagination: Pagination, refresh?: TemplatesRefresh}>()
);

export const getSentToMeFailure = createAction(
  '[TEMPLATES] Get sent to me failure',
  props<{ error: any }>()
);

export const acceptTemplateCopyRequest = createAction(
  '[TEMPLATES] accept template copy request',
  props<{template: Template}>()
);

export const acceptTemplateCopySuccess = createAction(
  '[TEMPLATES] accept template copy success',
);

export const acceptTemplateCopyFailure = createAction(
  '[TEMPLATES] accept template copy failure',
  props<{ error: any }>()
);

export const rejectTemplateCopyRequest = createAction(
  '[TEMPLATES] reject template copy request',
  props<{template: Template}>()
);

export const rejectTemplateCopySuccess = createAction(
  '[TEMPLATES] reject template copy success',
);

export const rejectTemplateCopyFailure = createAction(
  '[TEMPLATES] reject template copy failure',
  props<{ error: any }>()
);

export const loadMoreSentToMe = createAction(
  '[TEMPLATES] load more sent to me',
);