/// <reference types="google.accounts" />

import { Component, OnInit, Renderer2, NgZone } from '@angular/core';

import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import { AuthService } from '../auth.service';
import { ApiClient } from '../../api-client.service';

const DEFAULT_ERROR_MSG = 'Login failed, please try again later.';

@Component({
  selector: 'rnb-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  error: string;

  private idToken: string;

  constructor(
    private auth: AuthService,
    private client: ApiClient,
    private router: Router,
    private renderer: Renderer2,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    const gis = this.renderer.createElement('script');
    gis.type = 'text/javascript';
    gis.src ='https://accounts.google.com/gsi/client';
    gis.defer = true;
    gis.onload = this.onGISLoaded.bind(this);
    this.renderer.appendChild(document.head, gis);

    // await login
    this.auth.isAuthenticated.subscribe((state) => {
      // check if successful
      if (state) {
        // if redirectUrl is set, redirect back
        if (this.auth.redirectUrl && this.auth.redirectUrl.length) {
          this.router.navigate([this.auth.redirectUrl]);
          // only do this once, so clear redirectUrl
          this.auth.redirectUrl = null;
        } else {
          // redirect to dashboard
          this.router.navigate(['/']);
        }
      }
    });
  }

  private onGISLoaded() {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      auto_select: true,
      callback: (credentials) => {
        this.idToken = credentials.credential;
        this.signInStateChange(true);
      }
    });

    const googleButton = document.getElementById('gapi-signin');
    google.accounts.id.renderButton(googleButton, {
      type: 'standard',
      shape: 'rectangular',
      size: 'large',
      text: 'continue_with',
      theme: 'filled_blue',
    });
    
  }

  private signInStateChange(state: boolean): void {
    if (state && this.idToken && this.idToken.length) {
      // try to sign in (run inside NgZone as this event is triggered from outside angular)
      this.zone.run(() => {
        this.auth.signIn(this.idToken).subscribe((result) => {
          if (!result) {
            // login failed, display error
            this.error = DEFAULT_ERROR_MSG;
          }
        });
      });
    }
  }
}
