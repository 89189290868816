<rnb-card *ngIf="id" [disableAnimation]="disableAnimation" [forceHoverState]="forceHoverState" (click)="openNews()">
  <img body-header class="grid-image cursor-pointer"  [src]="imageUrl || 'assets/news-placeholder.png'" >

  <ng-container body-title>
    <rds-badge *ngIf="channelName" (click)="openChannel($event)" [label]="channelName || 'Channel name'" [class.placeholder]="isEdit && !channelName" [type]="'minimal'" [color]="color" #tooltip="rdsTooltip"
               [rdsTooltip]="channelName" size="m"
               ></rds-badge>
    <p
    [class.text-2-lines]="channelName"
    [class.text-4-lines]="!channelName"
    [class.placeholder]="!title"
    [rdsTooltipDisabled]="!!title" [rdsTooltip]="'Title is not defined'"
    class="ui-label-m-bold text-low-contrast">
      <span class="cursor-pointer" >{{title || 'Title of the news for ' + languageName + ' version'}} </span>
    </p>
  </ng-container>
  <ng-container body-description>
    <p class="ui-body-s text-low-contrast" [class.placeholder]="!description" [rdsTooltipDisabled]="!!description" [rdsTooltip]="'Abstract is not defined'">
    <span [innerHTML]="description || 'Abstract of the news  for '+ languageName + ' version'"></span>
    </p>
  </ng-container>
  <ng-container footer>
    <p class="ui-component-text-s text-low-contrast text-uppercase"  style="display: flex;
       align-items: center;
       justify-content: space-between;">
       <span [class.placeholder]="!creationDate"
       [rdsTooltipDisabled]="!!creationDate" [rdsTooltip]="'Publish date is not defined'">
        {{creationDate? (creationDate | timeAgo) : 'Not published'}}
       </span>
      <span *ngIf="hasRead === false || hasRead === true" class="read-mark ui-label-xs" [class.show]="forceHoverState" [class.read]="hasRead" [class.noanimation]="disableAnimation"><span class="read-label">{{hasRead? 'Read' : 'Not read'}}</span></span>
    </p>
    <p class="ui-component-text-s text-low-contrast text-uppercase" style="display: flex;
    align-items: center;
    justify-content: space-between;
column-gap: 4px;">
      <rds-icon *ngIf="!hasLiked" class="result__button" icon="heart" size="s" namespace="outlined" (click)="toggleLike($event)" ></rds-icon>
      <rds-icon *ngIf="hasLiked" class="result__button" icon="heart" size="s" namespace="filled" (click)="toggleLike($event)" ></rds-icon>
      {{likeCount}}
      <ng-container *ngIf="enableBookmark">
        <rds-icon *ngIf="hasBookmarked"  class="result__button" icon="bookmark" size="s" namespace="filled" (click)="toggleBookmark($event)"></rds-icon>
        <rds-icon *ngIf="!hasBookmarked"  class="result__button" icon="bookmark" size="s" namespace="outlined" (click)="toggleBookmark($event)"></rds-icon>
      </ng-container>
    </p>
  </ng-container>
</rnb-card>
