import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TourDialogData } from '../../types';

@Component({
  selector: 'rnb-tour-dialog',
  templateUrl: './tour-dialog.component.html',
  styles: []
})
export class TourDialogComponent implements OnInit {
  public static dialogDefaults = {
    'top-full-width': {
      width: '100vw',
      maxWidth: '100vw',
      autoFocus: false,
      position: {
        top: '0',
        right: '0',
        left: '0',
        bottom: 'auto',
      }
    },
    'full-page': {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      autoFocus: true,
      hasBackdrop: false,
      position: {
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
      }
    },
    default: {
      width: '100%',
      maxWidth: '1260px',
      autoFocus: false,
    },
  };
  public title: string;
  public content: string;
  public videoUrl: string;

  @HostBinding('class') private hostClass: object = {};

  constructor(
    private dialogRef: MatDialogRef<TourDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TourDialogData) {
  }

  ngOnInit(): void {
    this.hostClass[this.data.dialogType] = true;
  }
}
