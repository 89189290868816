import Command from '@ckeditor/ckeditor5-core/src/command';
import {checkIsCustomBlock} from "../blocks/blocks-utils";

export default class ImagesBoxCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        model.change(writer => {
            const value = options.value;
            const box = createImagesBox(writer);

            let images = {};

            for(let i = 0; i < value; i++) {


                const image = createImage(writer);
                images[i] = image;
                writer.append(image, box);
            }
            model.insertContent(box);
            for(let i =0;  i < value; i++ ) {
                writer.setAttribute('float', null, images[i])
                writer.setAttribute('src', './assets/images/block-placeholder.png', images[i]);
                writer.setAttribute('width', '100%', images[i]);
            }

            writer.setSelection(box, 'on');
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const element = model.document.selection.getSelectedElement();
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'imagesBox');
        const customBlockSelected = element?.name ? checkIsCustomBlock(element.name) : false;
        this.isEnabled = allowedIn !== null && !customBlockSelected;
    }
}

export function createImagesBox(writer) {
    return writer.createElement('imagesBox');
}
export function createImage(writer) {
    return writer.createElement('customImageBox');
}

export function getElementWidth(value) {
    return value === 4 ? '25%' : value === 3 ? '33.33%' : '50%'
}
