import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const go = createAction(
  '[Router] Go',
  props<{ path, queryParams, extras?: NavigationExtras }>()
);

export const goReload = createAction(
  '[Router] Go and Reload page',
  props<{ path, queryParams, extras?: NavigationExtras }>()
);

export const goInNewTab = createAction(
  '[Router] Go In New Tab',
  props<{ url: string, redirect: boolean }>()
);

export const goOutsideInNewTab = createAction(
  '[Router] Go Outside In New Tab',
  props<{ url: string, redirect: boolean }>()
);

export const goOutsideInCurrentTab = createAction(
  '[Router] Go Outside In Current Tab',
  props<{ url: string }>()
);

export const mailToInNewTab = createAction(
  '[Router] Mail in New Tab with ',
  props<{ body: string, content: string }>()
);

export const back = createAction(
  '[Router] Back',
);

export const updateQueryParams = createAction(
  '[Router] Update Query Params',
  props<{ queryParams: any }>()
);

export const forward = createAction(
  '[Router] Forward'
);

export const reloadPage = createAction(
  '[Router] Reload'
);

export const forceSearchResluts = createAction(
  '[ALL RESULTS - FORCE SEARCH RESULTS] Force to search for results research results'
);
