import { Component } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';

import * as fromRouter from '@app/root-store/router';
import * as fromBackButton from '@app/root-store/ui/back-button';

import { RdsBreadcrumbItem } from '@rds/angular-components';
import { Tile } from '@app/types/dashboard';
import { Observable, map } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'rnb-create-news-dashboard',
  templateUrl: './create-news-dashboard.component.html',
  styles: [
  ],
})
export class CreateNewsDashboardComponent {
  private subs: SubSink = new SubSink();

  breadcrumbs: Array<RdsBreadcrumbItem> = [
    { text: 'NewsBoard', title: '/' },
    { text: 'Add News' },
  ]; 

  newsTiles: Array<Tile> = [
    { order: 1, icon: 'link', title: 'Add news via link', action: fromRouter.go({path: 'news/create/link', queryParams: {}}) },
    { order: 2, icon: 'document', title: 'Create news in Editor', action: fromRouter.go({path: 'news/create/content', queryParams: {}}) },
  ]

  templateTiles: Array<Tile> = [
    { order: 1, icon: 'plate_management', title: 'Create template', action: fromRouter.go({path: 'news/create/template', queryParams: {}}) },
  ];

  breadcrumbsMiddleRoute$: Observable<any> = this.store$.pipe(select(fromBackButton.selectAddNewsMiddleRoute))

  constructor(private store$: Store<any>) {
    this.subs.sink = this.store$.pipe(select(fromBackButton.selectAddNewsMiddleRoute)).subscribe((routes) => {
      if (routes.length > 0) {
        this.breadcrumbs = [
          { text: 'NewsBoard', title: '/' },
          ...routes.map(r => ({text: r.label, title: r.route})),
          { text: 'Add News' },
        ];
      } else {
        this.breadcrumbs = [
          { text: 'NewsBoard', title: '/' },
          { text: 'Add News' },
        ]; 
      }

    })
  }
  
  breadcrumbItemClicked(event) {
    if (event.title) {
      this.store$.dispatch(fromRouter.go({path: event.title, queryParams: {}}));
    }
  }
  
  dispatchAction(action: Action) {
    this.store$.dispatch(action);
  }
  
  openCreateTemplate() {
    this.store$.dispatch(fromRouter.go({path: 'news/create/template', queryParams: {}}));
  }
}
