<div rds-dialog-content>
  <div style="width: 100%; height: 100%">
    <img #imageRef (load)="imgLoaded($event)" style="height: 100%;" [src]="image" alt="">
  </div>
</div>
<div rds-dialog-actions-container align="end">
  <button
      rds-secondary-button
      [rds-dialog-close]="null"
      size="m"
  >Cancel</button>
  <button
      rds-primary-button
      (click)="confirm()"
      size="m"
  >Confirm</button>
</div>
