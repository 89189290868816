<h2 rds-dialog-header>Send template copy</h2>
<div rds-dialog-content>
  <div class="row" [formGroup]="form">
    <div class="col mt-9">
      <p class="ui-label-l">Template will appear in recipients "My templates" tab. </p>
      <div class="row">
        <div class="col mt-5">
          <rnb-people-picker 
          (search)="getAutocompletes('send-copy', $event)"
          [multi]="true"
          formControlName="users"
          [autocomplete]="autocomplete$ | async"></rnb-people-picker>
        </div>
      </div>
    </div>
  </div>
</div>
<div rds-dialog-actions-container align="end">
    <button
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >Cancel</button>
    <button
        rds-primary-button
        [disabled]="form.get('users').valid === false"
        [rds-dialog-close]="form.get('users').value"
        size="m"
    >Send template</button>
</div>