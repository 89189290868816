import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ContentTypeEnum } from '@app/types';

@Component({
  selector: 'rnb-news-card-item',
  templateUrl: './news-card-item.component.html',
  styleUrls: ['./news-card-item.component.scss']
})
export class NewsCardItemComponent {

  // @Input() result!: NewsResult;

  @Input() id!: string;
  @Input() hasRead!: boolean;
  @Input() imageUrl!: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() creationDate!: Date;
  @Input() likeEnabled!: boolean;
  @Input() isLikingProcessingIds!: string[];
  @Input() likeCount!: number;
  @Input() hasBookmarked!: boolean;
  @Input() hasLiked!: boolean;
  @Input() url!: string;
  @Input() type!: ContentTypeEnum;
  @Input() channelName!: string;
  @Input() channelId!: string;
  @Input() enableBookmark = false;
  @Input() color: string = 'neutral';
  @Input() forceHoverState: boolean = false;
  @Input() disableAnimation: boolean = false;

  @Input() isEdit: boolean = false;
  @Input() languageName: string;

  @Output() newsOpened: EventEmitter<{ newTab: boolean, url: string, id: string, title: string }> = new EventEmitter();
  @Output() channelOpened: EventEmitter<{ channelId: string}> = new EventEmitter();
  @Output() likeToggled: EventEmitter<{ hasLiked: boolean, newsId: string, likeCount: number }> = new EventEmitter();
  @Output() bookmarkToggled: EventEmitter<{ hasBookmarked: boolean, newsId: string }> = new EventEmitter();

  constructor() {
  }

  openNews(): void {
    const newTab = this.type === ContentTypeEnum.LINK
    this.newsOpened.emit({newTab, url: this.url, id: this.id, title: this.title})
  }

  openChannel(ev: Event): void {
    this.channelOpened.emit({channelId: this.channelId});
    ev.stopPropagation();
  }

  toggleLike(ev: Event): void {
    if ((!!this.isLikingProcessingIds && this.isLikingProcessingIds.indexOf(this.id) === -1) || !this.isLikingProcessingIds) {
      this.likeToggled.emit({hasLiked: this.hasLiked, newsId: this.id, likeCount: this.likeCount})
    }
    ev.stopPropagation();
  }

  toggleBookmark(ev: Event): void {
    this.bookmarkToggled.emit({hasBookmarked: this.hasBookmarked, newsId: this.id})
    ev.stopPropagation();
  }
}
